<section id="empty">
  <div class="col-md-3 p-0 m-auto">
    <form novalidate>
      <div class="p-3 text-center">
        <img class="img-fluid" src="{{ 'logo.png' | path }}" alt="セレモニー" />
      </div>
      <div class="form-goup p-2">
        <input
          id="Code"
          name="Code"
          #code="ngModel"
          type="text"
          [(ngModel)]="funeralId"
          class="form-control col-10 m-auto"
          maxlength="10"
          placeholder="{{ 'enterFuneralIdPlaceholder' | i18n }}"
          required
        />
        <div class="error-message text-center" *ngIf="idNotFound">
          {{ 'enterFuneralIdErrorMsg' | i18n }}
        </div>
      </div>
      <div class="p-3 text-center">
        <button
          id="empty-page-submit-button"
          type="submit"
          class="btn btn-primary"
          [disabled]="code.invalid"
          (click)="Check()"
        >
          {{ 'goToMediaPage' | i18n }}
        </button>
      </div>
    </form>
  </div>
</section>
