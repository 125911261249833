<div class="payment-method-container">
  <!-- credit card -->
  <div class="credit-card-method d-flex" *ngIf="enabledMethods.includes(PaymentType.paypal)">
    <input
      type="radio"
      class="mr-2"
      name="payment-method"
      [value]="PaymentType.paypal"
      id="credit-card"
      [(ngModel)]="selectedMainPaymentMethod"
      (ngModelChange)="updatePaymentMethod()"
    />
    <label for="credit-card">{{ 'payWithCreditCard' | i18n }}</label>
    <!-- <div class="ml-4">
      <div class="master-visa">
        <input
          id="master-visa-card"
          name="card-type"
          type="radio"
          class="mr-2"
          value="master-visa-card"
          [(ngModel)]="selectedSubPaymentMethod"
        />
        <label for="master-visa-card" class="m-0">Mastercard、Visa を利用 </label>
        <div class="visa-master-images">
          <img src="assets/svg/payment/master-card.svg" alt="master-card" />
          <img src="assets/svg/payment/visa-card.svg" alt="visa-card" />
        </div>
      </div>
      <div class="other-card">
        <input
          id="other-card"
          name="card-type"
          type="radio"
          class="mr-2"
          value="other-card"
          [(ngModel)]="selectedSubPaymentMethod"
        />
        <label for="other-card">上記以外のブランドを利用</label>
      </div>
    </div> -->
  </div>
  <!-- other payment -->
  <div class="other-payment-method" *ngIf="displayOtherPaymentMethods">
    <div class="d-flex">
      <input
        type="radio"
        class="mr-2"
        name="payment-method"
        value="other"
        id="other-payment-method"
        [(ngModel)]="selectedMainPaymentMethod"
        (ngModelChange)="updatePaymentMethod()"
        [disabled]="disableOtherPaymentMethods"
      />
      <label for="other-payment-method" class="mb-3">{{ 'otherPaymentMethods' | i18n }}</label>
    </div>
    <div class="ml-4">
      <div class="bank d-flex" *ngIf="displayBankPaymentMethod">
        <input
          id="payment-method-bank"
          name="other-payment-method"
          type="radio"
          class="mr-2"
          [value]="PaymentType.bank_transfer"
          [(ngModel)]="selectedSubPaymentMethod"
          (ngModelChange)="updatePaymentMethod()"
          [disabled]="disableSubPaymentMethods"
        />
        <label for="payment-method-bank" class="mb-3"
          >{{ 'bankTransfer' | i18n
          }}<span class="xx-small-font">{{ 'pleaseNoteYouWillPayBankTransferFee' | i18n }}</span></label
        >
      </div>
      <!-- <div class="kombini d-flex" *ngIf="displayConvenienceStorePaymentMethod">
        <input
          id="payment-method-convenience-store"
          name="other-payment-method"
          type="radio"
          class="mr-2"
          [value]="PaymentType.convenience_store"
          [(ngModel)]="selectedSubPaymentMethod"
          (ngModelChange)="updatePaymentMethod()"
          [disabled]="disableSubPaymentMethods"
        />
        <label for="payment-method-convenience-store" class="mb-3"
          >{{ 'payAtConvenienceStore' | i18n
          }}<span class="xx-small-font">{{ 'pleaseNoteYouWillPayTransferFee' | i18n }}</span></label
        >
      </div> -->
      <div class="cash d-flex" *ngIf="displayCashPaymentMethod">
        <input
          id="payment-method-cash"
          name="other-payment-method"
          type="radio"
          class="mr-2"
          [value]="PaymentType.cash"
          [(ngModel)]="selectedSubPaymentMethod"
          (ngModelChange)="updatePaymentMethod()"
          [disabled]="disableSubPaymentMethods"
        />
        <label for="payment-method-cash" class="mb-3"
          >{{ 'cashOnDelivery' | i18n }}
          <span class="xx-small-font">{{ 'cashOnDeliveryPaymentOnTheDayOfTheFuneral' | i18n }}</span></label
        >
      </div>
    </div>
  </div>
  <!-- footer -->
  <div class="mx-0 mx-md-4 payment-method-footer">
    <span class="mb-3">{{ 'paymentProcess' | i18n }}</span>
    <div class="footer-items-container">
      <div class="footer-item">
        <span class="xx-small-font">{{ 'confirmOrder' | i18n }}</span>
        <img src="assets/svg/payment/step-1.svg" alt="step-1" />
      </div>
      <img class="arrow" src="assets/svg/payment/arrow.svg" alt="arrow" />
      <div class="footer-item">
        <span class="xx-small-font">{{ 'confirmIdentityByPhone' | i18n }}</span>
        <img src="assets/svg/payment/step-2.svg" alt="step-2" />
      </div>
      <img class="arrow" src="assets/svg/payment/arrow.svg" alt="arrow" />
      <div class="footer-item">
        <span class="xx-small-font">{{ 'sendInvoiceByEmail' | i18n }}</span>
        <img src="assets/svg/payment/step-3.svg" alt="step-3" />
      </div>
      <img class="arrow" src="assets/svg/payment/arrow.svg" alt="arrow" />
      <div class="footer-item">
        <span class="xx-small-font">{{ 'payment' | i18n }}</span>
        <img src="assets/svg/payment/step-4.svg" alt="step-4" />
      </div>
    </div>
    <div class="footer-remarks-container">
      <span class="remark">
        <span class="counter">注1)　</span
        >お電話にて、ご本人様確認をさせて頂きます。　確認後、請求書などをご決済者様のご住所に郵送にて送付いたしますご本人様確認が取れない場合は、お申込み内容をキャンセルさせて頂きますので、ご了承願います
      </span>
      <span class="remark">
        <span class="counter">注2)　</span>ご本人様確認、請求書送付につきましては、数日お時間を頂く場合がございます
      </span>
    </div>
  </div>
</div>
