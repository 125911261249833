<div class="memorial-share-album">
  <div class="memorial-share-album-container">
    <div
      *ngFor="let item of currentPages; let i = index"
      class="memorial-share-item-container"
      (click)="openModal(content, item)"
    >
      <div class="memorial-share-item-img"></div>
      <span class="memorial-share-item-title">
        {{ item }}テキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト
      </span>
    </div>
  </div>
  <div class="album-buttons-container">
    <button class="album-button" [style.visibility]="showPreviousPageButton" (click)="previousPage()">
      {{ 'return' | i18n }}
    </button>
    <button class="album-button" [style.visibility]="showNextPageButton" (click)="nextPage()">めくる</button>
  </div>
</div>

<ng-template #content let-modal>
  <!-- mobile view -->
  <div *ngIf="mobileView">
    <div class="modal-container">
      <button type="button" class="close close-button" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
      <div class="main-container" *ngFor="let item of currentPagesMobile">
        <div class="image-container"></div>
        <div class="comment-container">
          <span> {{ item }}テキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト </span>
        </div>
      </div>
    </div>
  </div>
  <!-- web view -->
  <div *ngIf="!mobileView" class="modal-container">
    <!-- left arrow -->
    <img
      *ngIf="showPreviousImageButton"
      src="assets/svg/back.svg"
      alt=""
      class="arrow left-arrow"
      (click)="previousImage()"
    />
    <div class="main-container">
      <button type="button" class="close close-button" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
      <div class="image-container"></div>
      <div class="comment-container">
        <span>
          {{
            memorialShareAlbum[currentItemIndex]
          }}テキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト
        </span>
      </div>
    </div>
    <!-- right arrow -->
    <img *ngIf="showNextImageButton" src="assets/svg/next.svg" alt="" class="arrow right-arrow" (click)="nextImage()" />
  </div>
</ng-template>
