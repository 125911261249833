import { Component, OnInit } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { CardMessageService, CardRelationshipService } from 'src/services';

import { CardMessage } from 'src/models';

@Component({
  selector: 'app-card-message',
  templateUrl: './card-message.component.html',
  styleUrls: ['./card-message.component.scss'],
})
export class CardMessageComponent implements OnInit {
  constructor(
    private cardRelationshipService: CardRelationshipService,
    private cardMessageService: CardMessageService,
    public activeModal: NgbActiveModal
  ) {}

  cardMessages: CardMessage[] = [];
  originalCardMessages: CardMessage[] = [];
  groups: string[] = [];
  titles: string[] = [];
  selectedGroup: string = '';
  selectedTitle: string = '';

  public ngOnInit(): void {
    this.cardRelationshipService.gets().then((groups: string[]) => {
      this.groups = [...new Set(groups)];
    });

    this.cardMessageService.gets().then((cardMessages: CardMessage[]) => {
      this.cardMessages = cardMessages;
      cardMessages.forEach((cardMessage) => {
        this.originalCardMessages.push(Object.assign({}, cardMessage));
      });
    });
  }

  setPhrases() {
    if (this.selectedTitle) {
      this.cardMessages.map((cardMessage, index) => {
        cardMessage.message = this.originalCardMessages[index].message.replace('〇〇', this.selectedTitle);
      });
    } else {
      this.cardMessages.map((cardMessage, index) => {
        cardMessage.message = this.originalCardMessages[index].message;
      });
    }
  }

  setTitles() {
    if (this.selectedGroup) {
      this.cardRelationshipService.getsBy(this.selectedGroup).then((titles: any) => {
        this.titles = titles;
        if (this.selectedTitle && this.titles.indexOf(this.selectedTitle) === -1) {
          this.selectedTitle = '';
        }
      });
    } else {
      this.selectedTitle = '';
      this.titles = [];
      this.setPhrases();
    }
  }
}
