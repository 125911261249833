import { Component, OnInit, Input, TemplateRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-photo-album',
  templateUrl: './photo-album.component.html',
  styleUrls: ['./photo-album.component.scss'],
})
export class PhotoAlbumComponent implements OnInit {
  @Input() album;
  @Input('type') mediaType: string;

  currentItemIndex: number = 0;

  constructor(private modalService: NgbModal) {}

  ngOnInit() {}

  openModal(template: TemplateRef<any>, index: number) {
    this.currentItemIndex = index;
    this.modalService.open(template, { centered: true, size: 'xl' });
  }

  nextImage(): void {
    this.currentItemIndex = this.currentItemIndex + 1;
  }

  previousImage(): void {
    this.currentItemIndex = this.currentItemIndex - 1;
  }

  get today(): string {
    const date: Date = new Date();
    const formattedDate: string = date.toLocaleDateString('ja-JP', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });
    return formattedDate;
  }

  get showNextImageButton() {
    return this.currentItemIndex < this.album.length - 1 && this.isTypeMemorialShare;
  }

  get showPreviousImageButton() {
    return this.currentItemIndex > 0 && this.isTypeMemorialShare;
  }

  get isTypeMemorialShare() {
    return this.mediaType === 'image';
  }

  get itemNumber(): number {
    return this.currentItemIndex + 1;
  }

  get totalItemsNumber(): number {
    return this.album.length;
  }
}
