import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { Funeral } from 'src/models';
import { GlobalStorage } from 'src/app/global/storage';
import { AccessLevel, APIService, LambdaAddMemorialShareInput } from '@kokusai/smacere-shared/api';
import { FilesService } from 'src/services/files.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
// tslint:disable-next-line:max-line-length
import { EditMemorialShareModalComponent } from 'src/app/pages/memorial-share/edit-memorial-share-modal/edit-memorial-share-modal.component';
import { environment } from 'src/environments/environment';
import { NgForm } from '@angular/forms';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-memorial-share-upload',
  templateUrl: './memorial-share-upload.component.html',
  styleUrls: ['./memorial-share-upload.component.scss'],
})
export class MemorialShareUploadComponent implements OnInit {
  Funeral: Funeral = {} as Funeral;
  firstName: string;
  firstNameKana: string;
  lastName: string;
  lastNameKana: string;
  saving: boolean;
  isSubmitted: boolean = false;
  isAgreedToUserTerms: boolean = false;
  files: Array<{
    file: File;
    thumbnail: string;
    progress: number;
    description: string;
    accessLevel: AccessLevel;
    media: string;
  }> = [];
  images: Array<{
    thumbnail: string;
    input: LambdaAddMemorialShareInput;
  }> = [];
  @ViewChild('fileInput') fileInput: ElementRef<HTMLInputElement>;

  constructor(
    private location: Location,
    private storage: GlobalStorage,
    private apiService: APIService,
    private filesService: FilesService,
    private modalService: NgbModal
  ) {
    this.Funeral = this.storage.Funeral;
  }

  ngOnInit(): void {}

  async save() {
    if (this.saving) {
      return;
    }

    if (!this.isAgreedToUserTerms) {
      Swal.fire({
        icon: 'warning',
        title: '投稿時の注意点について',
        text: '内容をご確認いただき、ご承諾にチェックをお願いします',
      });
      return;
    }

    if (this.images.length === 0) {
      Swal.fire({
        icon: 'warning',
        title: '画像・動画',
        text: '画像・動画を選択してください',
      });
      return;
    }

    this.saving = true;
    const media = this.images.map((image) => image.input);
    const result = await this.apiService.AddMemorialShare(environment.tenant, this.Funeral.funeralId, media);
    if (result.statusCode !== 201) {
      Swal.fire({ icon: 'error', title: 'Error while saving' }).then(() => {
        this.saving = false;
      });
      return;
    }

    Swal.fire({
      html: `
        <div class="pop-up-container">
          <div class="pop-up-header">
            <span>ご投稿いただき、誠にありがとうございます。</span>
            <span>メモリアルシェアへの投稿を受付いたしました。</span>
          </div>
          <div class="pop-up-reception-number">
            <span>受付番号：   ${result.referenceCode}</span>
          </div>
          <div class="pop-up-sub-footer">
          <span>メモリアルシェアへのご投稿などに関して、お問合せをいただく際には、</span>
          <span>上記の受付番号が必要となりますのでお控えいただくようお願い致します。</span>
          </div>
          <div class="pop-up-footer">
            <span>注)香典受付の際に、投稿された画像・動画につきましては、香典などの決済処理を完了する必要がございます。  受付を途中でやめれらた場合、画像・動画の投稿はキャンセルといたします。</span>
          </div>
        </div>
        `,
      confirmButtonText: 'OK',
    }).then(() => {
      this.files = [];
      this.images = [];
      this.saving = false;
      this.location.back();
    });
  }

  back() {
    this.location.back();
  }

  onDrop(event: Array<File>) {
    this.addFiles(event);
  }

  onChange(event: Event) {
    this.addFiles((event.target as HTMLInputElement).files);
  }

  protected addFiles(fileList: FileList | Array<File>) {
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < fileList.length; i++) {
      this.addFile(fileList[i]);
    }
  }

  protected addFile(file: File) {
    const item: MemorialShareUploadComponent['files'][0] = {
      file,
      thumbnail: null,
      progress: 0,
      description: '',
      accessLevel: AccessLevel.Private,
      media: null,
    };

    this.filesService.getThumbnail(file).then((result) => (item.thumbnail = result));
    const upload = this.filesService.uploadFile(file, 'memorial-share');
    upload.progress.subscribe((value) => {
      item.progress = value;
    });
    upload.result.then((value) => {
      item.media = value;
    });

    this.files.push(item);
  }

  public addImages(form: NgForm) {
    this.isSubmitted = true;
    // check customer form
    if (form.invalid) {
      for (const [key, control] of Object.entries(form.controls)) {
        if (control.invalid) {
          document.getElementById(key)?.focus();
          return;
        }
      }
      return;
    }

    for (const file of this.files) {
      this.images.push({
        thumbnail: file.thumbnail,
        input: {
          media: file.media,
          firstName: this.firstName,
          firstNameKana: this.firstNameKana,
          lastName: this.lastName,
          lastNameKana: this.lastNameKana,
          description: file.description,
          accessLevel: file.accessLevel,
        },
      });
    }
    this.files = [];
    this.fileInput.nativeElement.value = '';
    this.isSubmitted = false;
  }

  deleteAll() {
    this.images = [];
  }

  deleteFile(index: number) {
    this.files.splice(index, 1);
  }

  deleteImage(index: number) {
    this.images.splice(index, 1);
  }

  public editImage(image: MemorialShareUploadComponent['images'][0], index: number) {
    const modalRef = this.modalService.open(EditMemorialShareModalComponent, {
      size: 'xl',
      centered: true,
    });

    const component: EditMemorialShareModalComponent = modalRef.componentInstance;
    component.image = image;
    component.imageIndex = index;
    component.images = this.images;
  }
}
