<div class="modal-header no-border">
  <a href="javascript:void(0);" class="modal-close-icon" (click)="activeModal.close()"></a>
  <h4 class="modal-title" id="modal-basic-title">【特定商取引法(特商法)及びその他の法令に基づく表示】</h4>
</div>
<div class="sct-body modal-body">
  <ul>
    <div *ngIf="displayForCeremony">
      <li>事業者名　株式会社埼玉冠婚葬祭センター</li>
      <li>所在地　埼玉県さいたま市浦和区常盤九丁目4番13号</li>
      <li>電話番号　048-822-5047</li>
      <li>商品等の販売価格　商品申込ページをご覧ください。</li>
      <li>商品の受け渡し　会場にお届けいたします。</li>
      <li>代金の支払時期　通夜式、告別式の前々日まで</li>
      <li>代金の支払い方法　クレジット</li>
      <li>商品の引き渡し　通夜式、告別式開始までに会場にセットもしくはご葬家にお渡しいたします。</li>
      <li>キャンセルと払い戻しお客様のご都合による変更、取消し、払い戻しは承りません。</li>
    </div>
    <div *ngIf="displayForJoin">
      <li>事業者名　株式会社ジョイン</li>
      <li>所在地　山形県山形市元木一丁目１３番２５号</li>
      <li>電話番号　(023)633-8000</li>
      <li>商品等の販売価格　商品申込ページをご覧ください。</li>
      <li>商品の受け渡し　会場にお届けいたします。</li>
      <li>代金の支払時期　通夜式、告別式の前々日まで</li>
      <li>代金の支払い方法　クレジット</li>
      <li>商品の引き渡し　通夜式、告別式開始までに会場にセットもしくはご葬家にお渡しいたします。</li>
      <li>キャンセルと払い戻しお客様のご都合による変更、取消し、払い戻しは承りません。</li>
    </div>
  </ul>
</div>
