import { Component, OnDestroy, OnInit } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';
import { Funeral } from 'src/models';
import { GlobalStorage } from 'src/app/global/storage';
import { ReceptionCloseService, SetFontSizeService } from 'src/services';

@Component({
  selector: 'app-stream',
  templateUrl: './media.component.html',
  styleUrls: ['./media.component.scss'],
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [style({ opacity: 0 }), animate('1s ease-out', style({ opacity: 1 }))]),
      transition(':leave', [style({ opacity: 1 }), animate('1s ease-in', style({ opacity: 0 }))]),
    ]),
  ],
})
export class MediaComponent implements OnInit, OnDestroy {
  Funeral: Funeral = {} as Funeral;
  funeralCloseDateTime: Date;
  fontSize: number;
  viewWidth: number;

  constructor(
    private storage: GlobalStorage,
    private receptionCloseService: ReceptionCloseService,
    private setFontSizeService: SetFontSizeService
  ) {
    this.Funeral = this.storage.Funeral;
  }

  ngOnInit(): void {
    this.setFontSize();
    window.addEventListener('resize', this.setFontSize);

    const receptionStatus = this.receptionCloseService.getReceptionStatus(this.Funeral);
    this.funeralCloseDateTime = receptionStatus.funeralCloseDateTime;
  }

  ngOnDestroy(): void {
    window.removeEventListener('resize', this.setFontSize);
  }

  setFontSize(): void {
    if (!this.setFontSizeService) {
      return;
    }

    this.fontSize = this.setFontSizeService.setWebFontSize(
      this.Funeral.deceasedName,
      this.Funeral.deceasedNamePrefix,
      this.Funeral.deceasedNameSuffix,
      this.Funeral.deceasedNamePostfix,
      this.viewWidth,
      1.65
    );
  }
}
