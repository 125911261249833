export * from './money-gift.service';
export * from './paper.service';
export * from './funeral.service';
export * from './present.service';
export * from './card-message.service';
export * from './card-relationship.service';
export * from './address.service';
export * from './dictionary.service';
export * from './card.service';
export * from './reception.close.service';
export * from './payment.service';
export * from './head.service';
export * from './product.service';
export * from './product-storage.service';
export * from './set-font-size.service';

import { MoneyGiftService } from './money-gift.service';
import { PaperService } from './paper.service';
import { FuneralService } from './funeral.service';
import { PresentsService } from './present.service';
import { CardMessageService } from './card-message.service';
import { CardRelationshipService } from './card-relationship.service';
import { AddressService } from './address.service';
import { DictionaryService } from './dictionary.service';
import { CardService } from './card.service';
import { ReceptionCloseService } from './reception.close.service';
import { PaymentService } from './payment.service';
import { HeadService } from './head.service';
import { SetFontSizeService } from './set-font-size.service';

export const Services = [
  FuneralService,
  PaperService,
  PresentsService,
  MoneyGiftService,
  CardMessageService,
  CardRelationshipService,
  AddressService,
  DictionaryService,
  CardService,
  ReceptionCloseService,
  PaymentService,
  HeadService,
  SetFontSizeService,
];
