import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalStorage } from 'src/app/global/storage';
import { MediaObjectInput, MemorialType } from '@kokusai/smacere-shared/api';

const THANKS_IMAGE_TIME_OUT = 5000;

@Component({
  selector: 'app-thank-you',
  templateUrl: './thank-you.component.html',
  styleUrls: ['./thank-you.component.scss'],
})
export class ThankYouComponent implements OnInit {
  @ViewChild('video', { static: true })
  video: ElementRef<HTMLVideoElement>;

  thankYouVideo: MediaObjectInput = {};
  // displayMediaPage: boolean = false;
  displayPlayButton: boolean = true;

  public funeralid: string = '';

  constructor(private storage: GlobalStorage, private router: Router) {
    this.thankYouVideo = this.storage.Funeral.thankYouVideo;
    this.funeralid = this.storage.Funeral.funeralId;
    // this.displayMediaPage =
    //   this.storage.Funeral.displayStreaming || this.storage.Funeral.displayMemorial !== MemorialType.None;
  }

  ngOnInit(): void {
    this.video.nativeElement.onplaying = this.OnPlaying;
    if (!this.thankYouVideo?.url == null) {
      this.AutoRedirectToMedia();
    }
  }

  OnPlaying(): void {
    this.displayPlayButton = false;
  }

  PlayVideo(): void {
    this.video.nativeElement.play();
  }

  Skip(): void {
    this.router.navigate([this.funeralid, 'media']);
  }

  OnThankYouVideoEnd(): void {
    if (this.funeralid) {
      if (this.displayMediaButton) {
        this.router.navigate([this.funeralid, 'media']);
      }
    }
  }

  get isThankYouVideoEmpty(): boolean {
    const result = this.thankYouVideo?.url == null;
    return result;
  }

  private AutoRedirectToMedia(): void {
    this.displayPlayButton = false;
    setTimeout(() => {
      this.router.navigate([this.funeralid, 'media']);
    }, THANKS_IMAGE_TIME_OUT);
  }

  get displayMediaButton() {
    return (
      this.storage.Funeral.displayStreaming ||
      this.storage.Funeral.displayMemorialShare ||
      this.storage.Funeral.displayCondolenceVideos ||
      this.storage.Funeral.displayMemorial !== MemorialType.None
    );
  }
}
