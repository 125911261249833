<div class="card paypal-use-guide font-mincho">
  <div class="card-header">
    <div class="row m-0">
      <div class="col-12 col-md-6 p-2 p-md-0 d-flex align-items-center justify-content-center">
        {{ 'paypalProcedure' | i18n }}
      </div>
      <div class="col-12 col-md-6 p-0">
        <a class="btn btn-guide-pop" href="/paypal-guide" target="_balnk">
          {{ 'seeOnLargeScreenButton' | i18n }}
          <br class="d-lg-none" />
          （{{ 'anotherWindowWillOpen' | i18n }})
        </a>
      </div>
    </div>
  </div>
  <div class="card-body p-1">
    <ngu-carousel #guide [inputs]="carouselTile" [dataSource]="guides">
      <ngu-tile *nguCarouselDef="let item; let i = index" class="guide-conteiner">
        <div class="card">
          <div class="card-header guide-step">
            {{ 'step' | i18n }}<span>{{ item.step }}</span>
          </div>
          <div class="card-body">
            <div class="guide-title" [innerHTML]="item.title"></div>
            <div class="text-center">
              <img [src]="item.imgPath" class="img-fluid" />
            </div>
          </div>
        </div>
      </ngu-tile>
      <button id="left-rs" NguCarouselPrev class="leftRs" [style.opacity]="guide.isFirst ? 0.5 : 1">&lt;</button>
      <button id="right-rs" NguCarouselNext class="rightRs" [style.opacity]="guide.isLast ? 0.5 : 1">&gt;</button>
    </ngu-carousel>
  </div>
</div>
