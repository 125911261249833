<!-- customer summary -->
<div class="card customer-summary-container">
  <div class="card-body">
    <div class="row">
      <!-- Left side -->
      <div class="col-md-1 p-md-0">
        <div class="right-line p-2 p-md-2 font-weight-bold title-border-bottom-mobile">
          <span>{{ 'orderer' | i18n }}</span>
        </div>
      </div>

      <!-- right side -->
      <div class="col-md-11">
        <!-- address -->
        <div class="row m-md-3 pb-md-5 sub-order-item">
          <div class="col-md-9">
            <!-- name -->
            <div class="row mb-3" *ngIf="order?.CustomerAddress">
              <div class="col-md-4 font-weight-bold">{{ 'name' | i18n }}</div>
              <div class="col-md-8">{{ order?.CustomerAddress | fullName }}</div>
            </div>
            <!-- relationship -->
            <div class="row mb-3" *ngIf="order?.CustomerAddress?.relationship">
              <div class="col-md-4 font-weight-bold">{{ 'relationship' | i18n }}</div>
              <div class="col-md-8">{{ order?.CustomerAddress?.relationship }}</div>
            </div>
            <!-- company -->
            <div class="row mb-3" *ngIf="order?.CustomerAddress?.company">
              <div class="col-md-4 font-weight-bold">{{ 'company' | i18n }}</div>
              <div class="col-md-8">{{ order?.CustomerAddress?.company }}</div>
            </div>
            <!-- position -->
            <div class="row mb-3" *ngIf="order?.CustomerAddress?.position">
              <div class="col-md-4 font-weight-bold">{{ 'position' | i18n }}</div>
              <div class="col-md-8">{{ order?.CustomerAddress?.position }}</div>
            </div>
            <!-- address -->
            <div class="row mb-4" *ngIf="order?.CustomerAddress">
              <div class="col-md-4 font-weight-bold">{{ 'address' | i18n }}</div>
              <div class="col-md-8">
                {{
                  order?.CustomerAddress
                    | address
                      : {
                          omitPostcode: true,
                          noSpaces: false
                        }
                }}
              </div>
            </div>
            <!-- telephone -->
            <div class="row mb-3" *ngIf="order?.CustomerAddress?.telephone">
              <div class="col-md-4 font-weight-bold">{{ 'telephone' | i18n }}</div>
              <div class="col-md-8">{{ order?.CustomerAddress?.telephone | phoneNumber }}</div>
            </div>
            <!-- email -->
            <div class="row mb-3" *ngIf="order?.CustomerAddress?.email">
              <div class="col-md-4 font-weight-bold">{{ 'email' | i18n }}</div>
              <div class="col-md-8">{{ order?.CustomerAddress?.email }}</div>
            </div>
          </div>
          <!-- customer address update button -->
          <div class="col-md-3 mb-2 mb-md-0">
            <button class="btn btn-outline-secondary col-12 col-lg-5 mr-2 mb-2" (click)="edit()">
              {{ 'flowerOrderModifyButton' | i18n }}
            </button>
          </div>
        </div>

        <!-- Shipping address -->
        <div class="row m-md-3 pb-md-5 sub-order-item">
          <div class="col-md-3 font-weight-bold">
            {{ 'shippingAddress' | i18n }}
          </div>
          <div class="col-md-6">
            <ng-container *ngIf="order?.ShippingAddress; else sameAddress">
              <p>
                {{ order?.ShippingAddress | fullName }}
              </p>
              <p>
                {{
                  order?.ShippingAddress
                    | address
                      : {
                          omitPostcode: true,
                          noSpaces: false
                        }
                }}
              </p>
              <p *ngIf="order?.ShippingAddress?.telephone">
                {{ order?.ShippingAddress?.telephone | phoneNumber }}
              </p>
            </ng-container>
            <ng-template #sameAddress>
              {{ 'shippingAddressIsSameAsDefault' | i18n }}
            </ng-template>
          </div>
          <div class="col-md-3">
            <button class="btn btn-outline-secondary col-12 col-lg-5 mb-2 mr-2" (click)="edit()">
              {{ 'flowerOrderModifyButton' | i18n }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
