import {
  Component,
  EventEmitter,
  Output,
  OnInit,
  AfterViewInit,
  Input,
  OnChanges,
  forwardRef,
  NgZone,
} from '@angular/core';
import { Address } from 'src/models/Address';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS,
  Validator,
  AbstractControl,
  ValidationErrors,
} from '@angular/forms';
import { environment } from '../../environments/environment';
import { PostCode } from '@kokusai/postcode-lib';

@Component({
  selector: 'PostalCode',
  template: `
    <input
      [id]="id"
      #PostalCode="ngModel"
      placeholder="例）3300061"
      style="font-size:14px"
      type="tel"
      class="form-control"
      [class]="IsInvalid"
      [(ngModel)]="Address.postalCode"
      pattern="[0-9]{3}[-]{0,1}[0-9]{4}"
      [disabled]="disabled"
      maxlength="8"
      (change)="input($event)"
      (blur)="onTouched()"
      [readonly]="readonly"
    />
  `,
  styles: ['input::placeholder { color: #cecbcb; }'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PostalCodeComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => PostalCodeComponent),
      multi: true,
    },
  ],
})
export class PostalCodeComponent implements OnInit, AfterViewInit, OnChanges, ControlValueAccessor, Validator {
  @Output()
  change: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  prefectures: [];

  @Input()
  IsInvalid: string = '';

  @Input()
  readonly: boolean = false;

  @Input()
  id: string = 'postal-code';

  Address: Address = new Address();

  private IsValid: boolean = false;

  _value: Address;

  disabled: boolean;

  private POSTAL_REGX = /[0-9\uFF10-\uFF19]{3}[-\uFF0D]{0,1}[0-9\uFF10-\uFF19]{4}/;

  constructor(private zone: NgZone) {}

  get value() {
    return this._value;
  }

  set value(val) {
    this._value = val;
    this.Address = val ?? new Address();
  }

  onChange: any = () => {};

  fullWidthNumConvert(fullWidthNum: string): string {
    return fullWidthNum.replace(/[-\uFF10-\uFF19]/g, (m) => {
      if (m === '-') {
        return m;
      }

      if (m === 'ー') {
        return '-';
      }
      return String.fromCharCode(m.charCodeAt(0) - 0xfee0);
    });
  }

  prepareGoogleAddress(addresses: google.maps.GeocoderAddressComponent[]): Address {
    const address = new Address();
    const total: number = addresses?.length;

    for (let i = total; i > 0; i--) {
      const item = addresses[i - 1];
      if (item.types.includes(GoogleMapAddressType.Prefecture)) {
        address.prefecture = item.long_name;
      }

      if (item.types.includes(GoogleMapAddressType.PostalCode)) {
        address.postalCode = item.long_name;
      }

      if (item.types.includes(GoogleMapAddressType.District)) {
        address.city += item.long_name;
      }

      if (item.types.includes(GoogleMapAddressType.City)) {
        address.city += item.long_name;
      }

      if (item.types.includes(GoogleMapAddressType.Street1)) {
        address.city = item.long_name;
      }

      if (item.types.includes(GoogleMapAddressType.Street2)) {
        address.city += item.long_name;
      }
    }

    return address;
  }

  input(e: any): void {
    let newVal: string = e.target.value.replace(/[^0-9-\uFF0D\uFF10-\uFF19]/g, '');

    if (newVal.length > 0 && newVal.match(this.POSTAL_REGX)) {
      newVal = this.fullWidthNumConvert(newVal);

      this.query(newVal).then((address: Address) => {
        this.zone.run(() => {
          if (address) {
            this.value.postalCode = address.postalCode;
            this.value.prefecture = address.prefecture;
            this.value.city = address.city;
            this.value.street = address.street;
            this.IsValid = true;
            this.onTouched();
          }

          this.onChange(this.value);
          this.writeValue(this.value);
        });
      });
    } else {
      this.IsValid = false;
      e.target.value = newVal;
      this.value.postalCode = newVal;
      this.onChange(this.value);
      this.writeValue(this.value);
      this.onTouched();
    }
  }

  validate(control: AbstractControl): ValidationErrors {
    if (!this.IsValid) {
      control.setErrors({ notValid: true });
      return { postalCodeInvalid: true };
    }
    return null;
  }

  query(value: string): Promise<Address | null> {
    if (environment.postalCodeApi === 'google') {
      return this.queryGoogleMaps(value);
    }

    return this.queryYubinBango(value);
  }

  private queryGoogleMaps(value: string): Promise<Address> {
    return new Promise((resolve) => {
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode(
        {
          componentRestrictions: {
            country: 'JP',
            postalCode: value,
          },
        },
        (results) => {
          resolve(this.prepareGoogleAddress(results[0]?.address_components));
        }
      );
    });
  }

  private queryYubinBango(value: string): Promise<Address | null> {
    return PostCode.lookupAddress(value).then(
      (result): Address => ({
        postalCode: value,
        prefecture: result?.prefecture ?? '',
        city: result?.address ?? '',
        street: '',
      })
    );
  }

  onTouched(): void {}

  writeValue(value: Address): void {
    if (value?.postalCode?.match(this.POSTAL_REGX)) {
      this.IsValid = true;
    }

    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  ngOnChanges(changes: any): void {
    this.checkRequiredFields(this.prefectures);
  }

  ngOnInit(): void {
    this.checkRequiredFields(this.prefectures);
  }

  ngAfterViewInit(): void {}

  private checkRequiredFields(input): void {
    if (input === null) {
      throw new Error('Attribute prefectures is required');
    }
  }
}

export enum GoogleMapAddressType {
  PostalCode = 'postal_code',
  Street1 = 'sublocality_level_1',
  Street2 = 'sublocality_level_2',
  City = 'locality',
  District = 'administrative_area_level_2',
  Prefecture = 'administrative_area_level_1',
}
