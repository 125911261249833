import { Component, OnInit, Input, ViewChild, ChangeDetectorRef } from '@angular/core';
import { AddressType, APIService, LookupType } from '@kokusai/smacere-shared/api';
import { Dictionary } from 'src/models';
import { GlobalStorage } from 'src/app/global/storage';
import { AddressService, DictionaryService } from 'src/services';
import { NgForm, NgModel } from '@angular/forms';
import { Address } from 'src/models/Address';
import { Order } from 'src/models';
import { StorageAddressType } from 'src/app/components/address/types/storage-address-type.type';
import { Permissions } from 'src/app/components/address/settings/permissions';
import { Labels } from 'src/app/components/address/settings/labels';
import { ErrorMessages } from 'src/app/components/address/settings/errorMessages';
import { ItemType } from 'src/app/components/address/types/item-type.type';
import { ViewType } from 'src/app/components/address/types/view-type.type';

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss'],
})
export class AddressComponent implements OnInit {
  @Input() type: AddressType = AddressType.customer;
  @Input() myStyle: any;
  @Input() myClass: any;
  @Input() itemType: ItemType = ItemType.none;
  @Input() readonly: boolean = false;
  @Input() labelOnTop: boolean = false;
  @Input() viewType: ViewType = ViewType.fullAddress;
  @Input() specialName: boolean = false;
  @Input() isSubmit: boolean = false;
  @Input() useMaidenName: boolean = false;
  @Input() disableNameAndEmail: boolean = false;
  @Input() nameRemark: boolean = false;
  @Input() isCustomerAddressSame: boolean = true;

  @ViewChild('userForm', { static: true }) userForm: NgForm;
  @ViewChild('postalCodeRef', { static: false }) postalCodeRef: NgModel;
  @ViewChild('birthdayRef', { static: false }) birthdayRef: NgModel;
  @ViewChild('prefectureRef', { static: false }) prefectureRef: NgModel;
  @ViewChild('positionRef', { static: false }) positionRef: NgModel;
  @ViewChild('relationshipRef', { static: false }) relationshipRef: NgModel;

  order: Order;
  address: Address = new Address();
  defaultAddress: Address = new Address();
  Relationships: string[] = [];
  Positions: string[] = [];
  funeralId: string = '';
  Prefectures: string[] = [];
  storageAddress: Address;
  // isPositionRequired: boolean = false;
  permissions: Permissions = new Permissions();
  COMPANY_RELATIONSHIP: string = '会社関係';
  labels = Labels;
  errorMessages = ErrorMessages;
  AddressType = AddressType;
  defaultItem: string = String.fromCharCode(0x200b);

  constructor(
    private dictionaryService: DictionaryService,
    private apiService: APIService,
    private storage: GlobalStorage,
    private addressService: AddressService,
    private cdRef: ChangeDetectorRef
  ) {
    this.funeralId = this.storage.Funeral.funeralId;
    if (!this.storage.Order) {
      this.storage.Order = new Order();
    }
    this.order = this.storage.Order;
  }

  ngOnInit(): void {
    this.apiService.LookupByType(LookupType.Relationship).then((result) => {
      this.Relationships = result.items.map((item) => item.message);
    });

    this.dictionaryService.getCompanyTitles().then((items: Dictionary[]) => {
      this.Positions = items.map((x) => x.title);
    });

    this.addressService.GetPrefectures().subscribe((prefectures) => {
      this.Prefectures = prefectures;
    });

    this.initAddress();
    this.initStorageAddress();
    this.initPermissions();
  }

  get isCompanyRequired(): boolean {
    return this.address.relationship === this.COMPANY_RELATIONSHIP && this.isCustomerAddressSame;
  }

  public refreshData(disableNameAndEmail: boolean) {
    if (!this.storage.Order) {
      return;
    }

    this.order = this.storage.Order;
    for (const [key, value] of Object.entries(this.order[this.addressType] ?? {})) {
      this.address[key] = value;
    }
    // populate confirm address when logging in
    if (!this.address.confirmEmail && this.isAddressTypeCustomer) {
      this.address.confirmEmail = this.address.email;
    }

    this.postalCodeRef?.control.patchValue(this.address);
    this.birthdayRef?.control.patchValue(this.address.birthday);
    this.prefectureRef?.control.patchValue(this.address.prefecture);
    this.positionRef?.control.setValue(this.address.position);
    this.relationshipRef?.control.setValue(this.address.relationship);

    this.disableNameAndEmail = disableNameAndEmail;

    this.cdRef.detectChanges();
  }

  public resetData() {
    if (!this.storage.Order) {
      return;
    }

    this.order = this.storage.Order;
    for (const key in this.order[this.addressType]) {
      this.address[key] = '';
    }

    this.postalCodeRef?.control.patchValue({});
    this.birthdayRef?.control.patchValue('');
    this.prefectureRef?.control.patchValue('');
    this.positionRef?.control.setValue('');
    this.relationshipRef?.control.setValue('');

    this.disableNameAndEmail = false;

    this.cdRef.detectChanges();
  }

  get useLastName() {
    return !this.useMaidenName;
  }

  get useLastNameKana() {
    return !this.useMaidenName;
  }

  get displayNameRemark() {
    return this.nameRemark;
  }

  initAddress() {
    this.address = new Address();

    switch (this.itemType) {
      case ItemType.none:
        this.defaultAddress = { ...this.order[this.addressType] };
        break;
      case ItemType.flower:
        this.defaultAddress = { ...this.order.FlowerOrder[this.addressType] };
        break;
      case ItemType.card:
        this.defaultAddress = { ...this.order.CardOrder[this.addressType] };
        break;
      case ItemType.condolenceVideo:
        this.defaultAddress = { ...this.order.CondolenceVideoOrder[this.addressType] };
        break;
      case ItemType.moneyGift:
        this.defaultAddress = { ...this.order.MoneyGiftOrder[this.addressType] };
        break;
    }

    if (this.storageAddress) {
      this.address = { ...this.storageAddress };
    } else {
      this.address = { ...this.defaultAddress };
    }
  }

  initPermissions() {
    switch (this.viewType) {
      case ViewType.moneyGiftAddress:
        this.permissions.isBirthday = false;
        this.permissions.customName = true;
        break;
      case ViewType.subFullAddress:
        this.permissions.isKana = false;
        this.permissions.isMaidenName = false;
        this.permissions.isRelationship = false;
        this.permissions.isPosition = false;
        this.permissions.isBirthday = false;
        this.permissions.isCompany = false;
        this.permissions.showNotice = false;
        break;
      case ViewType.shortAddress:
        this.permissions.isKana = false;
        this.permissions.isMaidenName = false;
        this.permissions.isRelationship = false;
        this.permissions.isPosition = false;
        this.permissions.isEmail = false;
        this.permissions.isConfirmEmail = false;
        this.permissions.isBirthday = false;
        this.permissions.showNotice = false;
        this.permissions.isCompany = false;
        break;
      case ViewType.nameOnly:
        this.permissions.isMaidenName = false;
        this.permissions.isRelationship = false;
        this.permissions.isAddress = false;
        this.permissions.isTelephone = false;
        this.permissions.isEmail = false;
        this.permissions.isConfirmEmail = false;
        this.permissions.isBirthday = false;
        this.permissions.showNotice = false;
        break;
      case ViewType.fullAddress:
      default:
        this.permissions.customName = false;
        break;
    }
  }

  initStorageAddress() {
    switch (this.itemType) {
      case ItemType.none:
        this.storageAddress = { ...this.storage.Order[this.addressType] };
        break;
      case ItemType.flower:
        this.storageAddress = { ...this.storage.Order.FlowerOrder[this.addressType] };
        break;
      case ItemType.card:
        this.storageAddress = { ...this.storage.Order.CardOrder[this.addressType] };
        break;
      case ItemType.condolenceVideo:
        this.storageAddress = { ...this.storage.Order.CondolenceVideoOrder[this.addressType] };
        break;
      case ItemType.moneyGift:
        this.storageAddress = { ...this.storage.Order.MoneyGiftOrder[this.addressType] };
        break;
    }
  }

  get addressType(): string {
    return StorageAddressType[this.type];
  }

  get isAddressTypeCustomer(): boolean {
    return this.addressType === StorageAddressType.customer;
  }

  get isAddressTypeBilling(): boolean {
    return this.addressType === StorageAddressType.billing;
  }

  get isAddressTypeShipping(): boolean {
    return this.addressType === StorageAddressType.shipping;
  }

  get isLabelPositionDefault() {
    return !this.labelOnTop;
  }

  get itemTypeString(): string {
    return this.itemType;
  }

  setPlaceholder(value: string) {
    return !this.readonly ? value : '';
  }

  get nameAndEmailReadOnly() {
    return this.readonly || (this.disableNameAndEmail && this.type === AddressType.customer);
  }
}
