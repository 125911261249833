import { Directive, HostListener, Input, Component, OnInit } from '@angular/core';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { MediaObjectInput } from '@kokusai/smacere-shared/api';

import { Path } from '../core/utilities';

let modal;
@Component({
  template: `<div class="imageContainer">
    <img class="img-fluid" [src]="imageSrc" /><span class="closeButton" (click)="onClose()">&times;</span>
  </div>`,
  styles: [
    '.imageContainer { position: relative; } .closeButton { position: absolute; top: 0; right: 0; cursor: pointer; color: white; font-size: 1.5em; padding: 5px;}',
  ],
})
export class ZoomBodyComponent {
  public imageSrc: string;
  onClose() {
    modal.close();
  }
}

@Directive({
  selector: '[zoomImage]',
})
export class ZoomImageDirective {
  @Input()
  public object: MediaObjectInput;
  constructor(private modalService: NgbModal) {}

  @HostListener('click') OnClick() {
    const image = new Image();
    image.src = Path.getS3Path(this.object);

    modal = this.modalService.open(ZoomBodyComponent, {
      windowClass: 'image-zoom-modal',
    });
    modal.componentInstance.imageSrc = image.src;
    return false;
  }
}
