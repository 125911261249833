<div class="media-home-container">
  <div class="row-container">
    <!-- overnight stream -->
    <div *ngIf="IsOvernightStreamVisible" class="row-section" (click)="goToStream(StreamType.overnight)">
      <div class="font-mincho section-title">
        <span>{{ Funeral.overnightFuneralLabel }} ライブ配信</span>
        <span>
          {{ 'streamingAvailableFrom' | i18n }}
          {{ Funeral.overnightFuneralDate | date: 'M月d日'
          }}{{ Funeral.overnightFuneralDate + ' ' + Funeral.overnightFuneralStartTime | date: 'H時mm分' }}より
        </span>
      </div>
      <div class="section-wrapper overnight-stream" [class]="streamStatus[StreamType.overnight]"></div>
      <div class="section-footer">
        <span class="live-footer" [class]="{ hidden: streamStatus[StreamType.overnight] !== 'live' }">{{
          'watchTheStreaming' | i18n
        }}</span>
        <span class="wait-footer" [class]="{ hidden: streamStatus[StreamType.overnight] !== 'wait' }">
          <ng-container *ngIf="TimeOvernightWait != ''"
            >{{ TimeOvernightWait }}{{ 'streamingStartsSoon' | i18n }}</ng-container
          >
        </span>
        <span class="view-footer" [class]="{ hidden: streamStatus[StreamType.overnight] !== 'view' }">{{
          'playAndWatch' | i18n
        }}</span>
      </div>
    </div>
    <!-- funeral stream -->
    <div *ngIf="IsFuneralStreamVisible" class="row-section" (click)="goToStream(StreamType.funeral)">
      <div class="font-mincho section-title">
        <span>{{ Funeral.funeralLabel }} ライブ配信</span>
        <span>
          {{ 'streamingAvailableFrom' | i18n }}
          {{ Funeral.funeralDate | date: 'M月d日' }}
          <span *ngIf="Funeral.funeralStartTime"
            >{{ Funeral.funeralDate + ' ' + Funeral.funeralStartTime | date: 'H時mm分' }}より</span
          >
        </span>
      </div>
      <div class="section-wrapper funeral-stream" [class]="streamStatus[StreamType.funeral]"></div>
      <div class="section-footer">
        <span class="live-footer" [class]="{ hidden: streamStatus[StreamType.funeral] !== 'live' }">{{
          'watchTheStreaming' | i18n
        }}</span>
        <span class="wait-footer" [class]="{ hidden: streamStatus[StreamType.funeral] !== 'wait' }">
          <ng-container *ngIf="TimeFuneralWait != ''"
            >{{ TimeFuneralWait }}{{ 'streamingStartsSoon' | i18n }}</ng-container
          >
        </span>
        <span class="view-footer" [class]="{ hidden: streamStatus[StreamType.funeral] !== 'view' }">{{
          'playAndWatch' | i18n
        }}</span>
      </div>
    </div>
  </div>

  <div class="row-container">
    <!-- memorial photo -->
    <div *ngIf="IsMemoryAlbumVisible" (click)="goToMemorial()" class="row-section">
      <span class="section-title font-mincho">
        {{ (Funeral.displayMemorial == 'Slides' ? 'memorialPhoto' : 'memorialMovie') | i18n }}
      </span>
      <div class="section-wrapper memorial-photo view"></div>
      <span class="section-footer">{{ 'playAndWatch' | i18n }}</span>
    </div>
    <!-- condolence video -->
    <div *ngIf="displayCondolenceVideos" class="row-section" (click)="goToCondolenceVideo()">
      <span class="section-title font-mincho">{{ 'smartCondolenceMessage' | i18n }}</span>
      <div class="section-wrapper condolence-video"></div>
      <span class="section-footer">{{ 'watch' | i18n }}</span>
    </div>
    <!-- memorial share -->
    <div *ngIf="displayMemorialShare" class="row-section" (click)="goToMemorialShare()">
      <span class="section-title font-mincho">{{ 'memorialShare' | i18n }}</span>
      <div class="section-wrapper memorial-share"></div>
      <span class="section-footer">{{ 'watch' | i18n }}</span>
    </div>
  </div>
</div>
