import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

import Swal from 'sweetalert2';

import { I18n } from '@aws-amplify/core';

import { GlobalStorage } from 'src/app/global/storage';

import { PaymentService } from 'src/services';

import { Funeral, Payment } from 'src/models';

@Component({
  selector: 'app-payment-check',
  templateUrl: './payment-check.component.html',
  styleUrls: ['./payment-check.component.scss'],
})
export class PaymentCheckComponent implements OnInit {
  inputValue: string;
  isNotFound: boolean = false;
  isSubmit = false;

  constructor(private paymentService: PaymentService, private router: Router, private storage: GlobalStorage) {}

  ngOnInit(): void {
    if (this.storage.Payment) {
      this.router.navigate([this.storage.Funeral.funeralId, 'media']);
    }
  }

  // TODO: We do not need to check again mourningEmail, but again the customerAddress.email
  private IsMourningEmail(): boolean {
    if (this.storage.Funeral.mourningEmails.some((email) => email === this.inputValue)) {
      this.storage.MournerEmail = this.inputValue;
      this.storage.FuneralId = this.storage.Funeral.funeralId;
      return true;
    }

    return false;
  }

  private IsPasscodeCorrect(): boolean {
    if (this.storage.Funeral.streamingPassword === this.inputValue) {
      this.storage.StreamingPassword = this.inputValue;
      this.storage.FuneralId = this.storage.Funeral.funeralId;
      return true;
    }

    return false;
  }

  private showErrorMessage() {
    const text: string = I18n.get(
      this.isProductEnabled ? 'paymentCheckCheckEmailPasswordNotFoundMsg' : 'paymentCheckCheckPasswordNotFoundMsg'
    );

    Swal.fire({
      icon: 'error',
      text: text,
      customClass: {
        content: 'custom-style',
      },
    });
  }

  private get isProductEnabled(): boolean {
    const funeral: Funeral = this.storage.Funeral;
    const result: boolean = funeral.displayFlower || funeral.displayCard || funeral.displayMoney;
    return result;
  }

  get inputPlaceholderText(): string {
    return I18n.get(this.isProductEnabled ? 'paymentCheckEmailPasswordPlaceholder' : 'paymentCheckPasswordPlaceholder');
  }

  get requiredValidationMsg(): string {
    return I18n.get(
      this.isProductEnabled ? 'paymentCheckEmailPasswordPlaceholder' : 'paymentCheckPasswordIsRequiredValidationMsg'
    );
  }

  check(): void {
    this.isSubmit = true;

    if (this.IsMourningEmail() || this.IsPasscodeCorrect()) {
      this.router.navigate([this.storage.Funeral.funeralId, 'media']);
      return;
    }

    this.paymentService.checkByEmail(this.storage.Funeral.funeralId, this.inputValue).subscribe(
      (payment: Payment) => {
        if (payment) {
          this.storage.Payment = payment;
          this.router.navigate([this.storage.Funeral.funeralId, 'media']);
        } else {
          this.showErrorMessage();
        }
      },
      () => {
        this.showErrorMessage();
      }
    );
  }

  back(): void {
    this.router.navigate([this.storage.Funeral.funeralId]);
  }
}
