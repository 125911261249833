import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ProductType } from '@kokusai/smacere-shared/api';
import { BaseService } from './base.service';
import { MoneyGift } from 'src/models/MoneyGift';
import moment from 'moment';

@Injectable()
export class MoneyGiftService extends BaseService {
  gets(): Observable<MoneyGift[]> {
    return new Observable<MoneyGift[]>((subscribe) => {
      const today = moment().format('YYYY-MM-DD');
      this.api // TODO: Why using ListProduct without limit? Should use ProductByType
        .ListProducts(
          {
            and: [
              { productType: { eq: ProductType.MoneyGift } },
              {
                or: [{ fromDate: { attributeExists: false } }, { fromDate: { eq: null } }, { fromDate: { le: today } }],
              },
              { or: [{ toDate: { attributeExists: false } }, { toDate: { eq: null } }, { toDate: { ge: today } }] },
            ],
          },
          1000
        )
        .then((moneyResult) => {
          const moneyGifts = moneyResult.items
            .sort((a, b) => {
              if (a.price < b.price) {
                return -1;
              }
              if (a.price > b.price) {
                return 1;
              }
              return 0;
            })
            .map(
              (money) =>
                new MoneyGift({
                  id: money.id,
                  name: money.name,
                  price: money.price,
                  sku: money.sku,
                  picture: null,
                })
            );

          if (moneyGifts.find((item) => item.price === 0)) {
            const customMoneyIndex = moneyGifts.findIndex((item) => item.price === 0);
            //TODO: Check behavior when BE send ID to the FE.
            moneyGifts.splice(customMoneyIndex, 1);
          }

          subscribe.next(moneyGifts);
        })
        .catch((error) => subscribe.error(error));
    });
  }
}
