<div class="bg-grey p-2">
  <div class="container font-mincho">
    <h4 class="page-header">
      {{ 'applicationContentConfirmationScreen' | i18n }}
    </h4>
    <!-- customer summary -->
    <app-customer-summary></app-customer-summary>
    <!-- cart summary -->
    <app-cart-summary></app-cart-summary>
  </div>
</div>
<div class="container">
  <div class="btn-toolbar justify-content-between mt-2 mb-4" role="toolbar">
    <button id="is-processing-button" class="btn btn-secondary m-auto" (click)="next()">
      {{ 'isProcessingTrue' | i18n }}
    </button>
  </div>
</div>
