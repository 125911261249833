import { Pipe, PipeTransform } from '@angular/core';
import { Format } from '@kokusai/utilities';

@Pipe({
  name: 'phoneNumber',
  pure: true,
})
export class PhoneNumberPipe implements PipeTransform {
  transform(value: string): string {
    return Format.phoneNumber(value);
  }
}
