import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BaseService } from './base.service';
import { ModelProductFilterInput, ProductType } from '@kokusai/smacere-shared/api';
import { Present } from 'src/models';

@Injectable()
export class PresentsService extends BaseService {
  gets(type: ProductType, price: number, funeralDate?: string): Observable<Present[]> {
    return new Observable<Present[]>((subscribe) => {
      const filters: ModelProductFilterInput[] = [{ productType: { eq: ProductType.Present } }];
      if (funeralDate) {
        filters.push({
          or: [{ fromDate: { attributeExists: false } }, { fromDate: { eq: null } }, { fromDate: { le: funeralDate } }],
        });
        filters.push({
          or: [{ toDate: { attributeExists: false } }, { toDate: { eq: null } }, { toDate: { ge: funeralDate } }],
        });
        filters.push({ isDeleted: { ne: true } });
      }
      this.api
        .ListProducts({ and: filters }) // TODO: Why is this using ListProduct without limit?
        .then((result) => {
          const presents: Present[] = result.items
            .filter((item) =>
              item.presentSelection?.some(
                (select) => select.productType === type && price >= select.fromPrice && price <= select.toPrice
              )
            )
            .map((product) => {
              return new Present({
                id: product.id,
                sku: product.sku,
                picture: product.picture,
                name: product.name,
                price: product.price,
              });
            });

          subscribe.next(presents);
        })
        .catch((error) => subscribe.error(error));
    });
  }
}
