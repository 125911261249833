import { AppComponent } from './app.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { FuneralMissingComponent } from './pages/funeral-missing/funeral-missing.component';
import { MediaComponent } from './pages/media/media.component';
import { StreamComponent, MemorialComponent, MediaHomeComponent } from './pages/media';
import { ThankYouComponent } from './pages/thank-you/thank-you.component';
import { FuneralGuard } from './guard/funeral.guard';
import { OrderComponent } from './pages/order/order.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import {
  FlowersComponent,
  FlowersHomeComponent,
  FlowersSelectComponent,
  FlowersOrderComponent,
  FlowersPresentComponent,
} from './pages/order/flowers';

import {
  CardComponent,
  CardHomeComponent,
  CardSelectComponent,
  CardOrderComponent,
  CardOrderDetailsComponent,
  CardPresentComponent,
} from './pages/order/card';

import { CardMainComponent } from './pages/order/card/card-main/card-main.component';

import {
  MoneyGiftComponent,
  MoneyGiftHomeComponent,
  MoneyGiftSelectionComponent,
  MoneyGiftPresentSelectionComponent,
  MoneyGiftOrderComponent,
} from './pages/order/money-gift';

import {
  CondolenceVideoComponent,
  CondolenceVideoHomeComponent,
  CondolenceVideoUploadComponent,
  CondolenceVideoOrderComponent,
  CondolenceVideoOrderDetailsComponent,
  CondolenceVideoPresentComponent,
} from './pages/condolence-video';

import {
  MemorialShareComponent,
  MemorialShareHomeComponent,
  MemorialShareUploadComponent,
} from './pages/memorial-share';

import { ConfirmComponent } from './pages/order/confirm';
import {
  CheckoutComponent,
  CheckoutHomeComponent,
  CheckoutBillingComponent,
  CheckoutPaymentComponent,
} from './pages/order/checkout';
import { PaymentCheckComponent } from './pages/payment-check/payment-check.component';
import { PaypalGuideWindowComponent } from './pages/order/checkout/paypal-guide/paypal-guide-window/paypal-guide-window.component';
import { OrderCheckGuard } from './guard/order-check.guard';
import { FuneralClosedComponent } from './pages/funeral-closed/funeral-closed.component';
import { GuestBookComponent } from './pages/order/guest-book/guest-book.component';
import { CardSummaryComponent } from './pages/order/card/card-summary/card-summary.component';
import { FlowersSummaryComponent } from './pages/order/flowers/flowers-summary/flowers-summary.component';
import { MoneyGiftSummaryComponent } from './pages/order/money-gift/money-gift-summary/money-gift-summary.component';

const routes: Routes = [
  {
    path: 'paypal-guide',
    component: PaypalGuideWindowComponent,
    pathMatch: 'full',
  },
  {
    path: ':id',
    component: AppComponent,
    canActivate: [FuneralGuard],
    children: [
      {
        path: '',
        component: HomeComponent,
      },
      {
        path: 'order',
        component: OrderComponent,

        children: [
          {
            path: 'guest-book',
            component: GuestBookComponent,
          },
          {
            path: 'flowers',
            component: FlowersComponent,
            children: [
              {
                path: '',
                component: FlowersHomeComponent,
              },
              {
                path: 'products',
                component: FlowersSelectComponent,
              },
              {
                path: 'order',
                component: FlowersOrderComponent,
              },
              {
                path: 'presents',
                component: FlowersPresentComponent,
              },
              {
                path: 'summary',
                component: FlowersSummaryComponent,
              },
            ],
          },
          {
            path: 'cards',
            component: CardComponent,
            children: [
              {
                path: '',
                component: CardHomeComponent,
              },
              {
                path: 'main',
                component: CardMainComponent,
              },
              {
                path: 'products',
                component: CardSelectComponent,
              },
              {
                path: 'order',
                component: CardOrderComponent,
              },
              {
                path: 'order-details',
                component: CardOrderDetailsComponent,
              },
              {
                path: 'presents',
                component: CardPresentComponent,
              },
              {
                path: 'summary',
                component: CardSummaryComponent,
              },
            ],
          },
          {
            path: 'condolence-video',
            component: CondolenceVideoComponent,
            children: [
              // {
              //   path: '',
              //   component: CondolenceVideoHomeComponent,
              // },
              {
                path: '',
                redirectTo: 'upload',
                pathMatch: 'prefix',
              },
              {
                path: 'upload',
                component: CondolenceVideoUploadComponent,
              },
              {
                path: 'order',
                component: CondolenceVideoOrderComponent,
              },
              {
                path: 'order-details',
                component: CondolenceVideoOrderDetailsComponent,
              },
              {
                path: 'presents',
                component: CondolenceVideoPresentComponent,
              },
            ],
          },
          {
            path: 'gift-money',
            component: MoneyGiftComponent,
            children: [
              {
                path: '',
                component: MoneyGiftHomeComponent,
              },
              {
                path: 'products',
                component: MoneyGiftSelectionComponent,
              },
              {
                path: 'presents',
                component: MoneyGiftPresentSelectionComponent,
              },
              {
                path: 'order',
                component: MoneyGiftOrderComponent,
              },
              {
                path: 'summary',
                component: MoneyGiftSummaryComponent,
              },
              {
                path: 'memorial-share',
                component: MemorialShareComponent,
                children: [
                  {
                    path: '',
                    component: MemorialShareUploadComponent,
                  },
                  {
                    path: 'upload',
                    component: MemorialShareUploadComponent,
                  },
                ],
              },
            ],
          },
          {
            path: 'confirm',
            component: ConfirmComponent,
          },
          {
            path: 'checkout',
            component: CheckoutComponent,
            children: [
              // {
              //   path: '',
              //   component: CheckoutHomeComponent,
              // },
              {
                path: '',
                component: CheckoutBillingComponent,
              },
              {
                path: 'billing',
                component: CheckoutBillingComponent,
              },
              {
                path: 'payment',
                component: CheckoutPaymentComponent,
              },
            ],
          },
        ],
      },
      {
        path: 'thankyou',
        component: ThankYouComponent,
      },
      {
        path: 'memorial-share',
        component: MemorialShareComponent,
        children: [
          {
            path: '',
            component: MemorialShareHomeComponent,
          },
          {
            path: 'upload',
            component: MemorialShareUploadComponent,
          },
        ],
      },
      {
        path: 'media',
        component: MediaComponent,
        canActivate: [OrderCheckGuard],
        children: [
          {
            path: '',
            component: MediaHomeComponent,
            canActivate: [OrderCheckGuard],
          },
          {
            path: 'stream/:type',
            component: StreamComponent,
            canActivate: [OrderCheckGuard],
          },
          {
            path: 'memorial',
            component: MemorialComponent,
            canActivate: [OrderCheckGuard],
          },
          {
            path: 'condolence-video',
            component: CondolenceVideoHomeComponent,
          },
        ],
      },
      {
        path: 'payment-check',
        component: PaymentCheckComponent,
      },
      {
        path: 'no-reception',
        component: FuneralClosedComponent,
      },
    ],
  },
  {
    path: '',
    component: FuneralMissingComponent,
  },
  {
    path: '**',
    component: NotFoundComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
