import { MoneyGiftOrder } from './MoneyGiftOrder';
import { Present } from './Present';
import { CardOrder } from './CardOrder';
import { FlowerOrder } from './FlowerOrder';
import { Food } from './Food';
import { Address } from './Address';
import { CondolenceVideoOrder } from './CondolenceVideoOrder';
import { PaymentType } from '@kokusai/smacere-shared/api';
export class Order {
  constructor() {
    this.BillingAddress = new Address();
    this.ShippingAddress = new Address();
    this.CustomerAddress = new Address();
    this.IsDestinationSame = true;
    this.IsBillingDifferent = false;
    this.IsComplete = false;
    this.MoneyGiftOrder = new MoneyGiftOrder();
  }

  /// need refactoring, all order property move to array items

  public Id?: string;
  public CartId: string;
  public Relationship: string;
  public FlowerOrder: FlowerOrder;
  public FlowerPresent: Present;
  public CardOrder: CardOrder;
  public CardPresent: Present;
  public CondolenceVideoOrder: CondolenceVideoOrder;
  public CondolenceVideoPresent: Present;
  public MoneyGiftOrder: MoneyGiftOrder;
  public MoneyPresent: Present;
  public MoneyFood: Food;
  public BillingAddress: Address;
  public ShippingAddress: Address;
  public CustomerAddress: Address;
  public IsDestinationSame: boolean;
  public IsBillingDifferent: boolean;
  public IsComplete: boolean;
  public PaymentMethod: PaymentType;
  public DisclaimerConfirmed: boolean = false;
  public RegisterAccount: boolean = false;
  birthday?: string;

  public get amount(): number {
    const flowerSum = this.FlowerOrder?.amount ?? 0;
    const cardSum = this.CardOrder?.amount ?? 0;
    const moneySum = this.MoneyGiftOrder?.amount ?? 0;
    const amount = flowerSum + cardSum + moneySum;
    return amount;
  }

  public get taxAmount(): number {
    const flowerTax = this.FlowerOrder?.taxAmount ?? 0;
    const cardTax = this.CardOrder?.taxAmount ?? 0;
    return flowerTax + cardTax;
  }

  public get totalAmount(): number {
    return this.amount + this.taxAmount;
  }
}
