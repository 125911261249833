import { Component, AfterViewInit, OnDestroy } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';
import { Funeral } from 'src/models/Funeral';
import { GlobalStorage } from 'src/app/global/storage';
import { Router } from '@angular/router';
import { ReceptionCloseService } from 'src/services';
import { StepType, FuneralStatusType } from 'src/types';

const WELCOME_TIME_OUT: number = 2000;
const DECEASED_TIME_OUT: number = 100000;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [style({ opacity: 0 }), animate('1s ease-out', style({ opacity: 1 }))]),
      transition(':leave', [style({ opacity: 1 }), animate('1s ease-in', style({ opacity: 0 }))]),
    ]),
  ],
})
export class HomeComponent implements AfterViewInit, OnDestroy {
  constructor(
    private storage: GlobalStorage,
    private router: Router,
    private receptionCloseService: ReceptionCloseService
  ) {
    this.funeral = this.storage.Funeral;
  }

  timeOutId: any;

  funeral: Funeral = null;
  stepActive: number = 0; // TODO: Not used?
  funeralCloseDateTime: Date;

  isSplashScreenActive: boolean = true;
  isWelcomeScreenActive: boolean = false;
  isDeceasedPersonScreenActive: boolean = false;
  isPaymentCheckScreenActive: boolean = false;
  isCloseScreenActive: boolean = false;

  ngAfterViewInit(): void {
    const receptionStatus = this.receptionCloseService.getReceptionStatus(this.funeral);
    this.funeralCloseDateTime = receptionStatus.funeralCloseDateTime;

    if (receptionStatus.isFuneralClosed || this.funeral?.statusCode === FuneralStatusType.End) {
      setTimeout(() => {
        this.isSplashScreenActive = false;
        this.isCloseScreenActive = true;
      }, WELCOME_TIME_OUT);
    } else {
      setTimeout(() => {
        this.isSplashScreenActive = false;
        this.isWelcomeScreenActive = true;
      }, WELCOME_TIME_OUT);
    }
  }

  getNextStep(funeral: Funeral): StepType {
    if (funeral.displayFlower) {
      return StepType.Flower;
    }
    if (funeral.displayCard || funeral.displayCondolenceVideos) {
      return StepType.Card;
    }
    if (funeral.displayMoney) {
      return StepType.MoneyGift;
    }
  }

  private goToOrder(): void {
    const type = this.getNextStep(this.funeral);
    this.router.navigate([this.funeral.funeralId, 'order', type], {
      queryParams: {
        openExternalBrowser: '1',
      },
    });
  }

  onWelcomeScreenClick(): void {
    this.isWelcomeScreenActive = false;
    this.isDeceasedPersonScreenActive = true;

    this.timeOutId = setTimeout(() => {
      this.isDeceasedPersonScreenActive = false;
      this.goToOrder();
    }, DECEASED_TIME_OUT);
  }

  onDeathPersonSkip(): void {
    this.goToOrder();
  }

  onBack(): void {
    clearTimeout(this.timeOutId);
    this.isWelcomeScreenActive = true;
    this.isDeceasedPersonScreenActive = false;
  }

  ngOnDestroy(): void {
    clearTimeout(this.timeOutId);
  }
}
