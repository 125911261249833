import { Injectable } from '@angular/core';
import { I18n } from '@aws-amplify/core';
import { MessageService } from '@progress/kendo-angular-l10n';

@Injectable({
  providedIn: 'root',
})
export class KendoMessageService extends MessageService {
  public get(key: string): string {
    const shortKey = key.split('.').pop();
    return I18n.get(key, null) ?? I18n.get(shortKey, null) ?? super.get(key);
  }
}
