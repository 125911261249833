import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { NgForm } from '@angular/forms';
import { GlobalStorage } from 'src/app/global/storage';
import { CondolenceVideoOrder, Funeral, Order } from 'src/models';
import { AddressType, ProductType } from '@kokusai/smacere-shared/api';
import { AddressComponent } from '../../../components/address/address.component';
import { BundleCartService, BundleType } from '../../../services/bundle-cart.service';
import { I18n } from 'aws-amplify';
import { DiscountsService } from 'src/app/services/discounts.service';
import { AuthService } from 'src/app/services/auth.service';
import { ViewType } from 'src/app/components/address/types/view-type.type';

@Component({
  selector: 'app-condolence-video-order',
  templateUrl: './condolence-video-order.component.html',
  styleUrls: ['./condolence-video-order.component.scss'],
})
export class CondolenceVideoOrderComponent implements OnInit {
  constructor(
    private storage: GlobalStorage,
    private route: Router,
    private location: Location,
    private bundleCartService: BundleCartService,
    private authService: AuthService,
    private discountsService: DiscountsService
  ) {}

  Funeral: Funeral;
  Order: Order;
  CondolenceVideoOrder: CondolenceVideoOrder;
  cardMessageMaxLength = 255;
  AddressType = AddressType;
  isSameOrderInformation: boolean = true;
  useMaidenName: boolean = false;
  hasPresents: boolean = false;
  isSubmit = false;
  isSmacereClubMember;
  ViewType = ViewType;

  @ViewChild('cardOrderForm') cardOrderForm: NgForm;
  @ViewChild('customerForm') customerForm: AddressComponent;
  // @ViewChild('shippingForm') shippingForm: AddressComponent;

  get CondolenceSymbolsTotalLeft() {
    return this.CondolenceVideoOrder.Comment?.length > 0
      ? this.cardMessageMaxLength - this.CondolenceVideoOrder.Comment.length
      : this.cardMessageMaxLength;
  }

  ngOnInit(): void {
    this.bundleCartService.checkStep(BundleType.CondolenceVideo, 'order', 'order-details');

    this.Funeral = this.storage.Funeral;
    this.Order = this.storage.Order;
    this.hasPresents = this.storage.Funeral.displayCardPresent;

    const isCondolenceVideoOrderCustomerAddress = Object.values(
      this.storage.Order.CondolenceVideoOrder.CustomerAddress
    ).some((item) => Boolean(item));
    if (isCondolenceVideoOrderCustomerAddress) {
      this.CondolenceVideoOrder = {} as CondolenceVideoOrder;
      this.CondolenceVideoOrder.CustomerAddress = { ...this.storage.Order.CondolenceVideoOrder.CustomerAddress };
    } else {
      this.storage.Order.CondolenceVideoOrder.CustomerAddress = { ...this.storage.Order.CustomerAddress };
    }

    const isCondolenceVideoOrderShippingAddress = Object.values(
      this.storage.Order.CondolenceVideoOrder.ShippingAddress
    ).some((item) => Boolean(item));
    const isOrderShippingAddress = Object.values(this.storage.Order.ShippingAddress).some((item) => Boolean(item));
    if (isCondolenceVideoOrderShippingAddress) {
      this.CondolenceVideoOrder = {} as CondolenceVideoOrder;
      this.CondolenceVideoOrder.ShippingAddress = { ...this.storage.Order.CondolenceVideoOrder.ShippingAddress };
    } else if (isOrderShippingAddress) {
      this.storage.Order.CondolenceVideoOrder.ShippingAddress = { ...this.storage.Order.ShippingAddress };
    } else {
      this.storage.Order.CondolenceVideoOrder.ShippingAddress = { ...this.storage.Order.CustomerAddress };
    }

    this.CondolenceVideoOrder = this.storage.Order.CondolenceVideoOrder;

    this.isSmacereClubMember = this.authService.isSmacereClubMember;

    window.scrollTo(0, 0);
  }

  capitalize(value: string) {
    return value[0].toUpperCase() + value.slice(1);
  }

  async next(): Promise<void> {
    this.isSubmit = true;

    if (this.cardOrderForm.invalid) {
      for (const [key, control] of Object.entries(this.cardOrderForm.controls)) {
        if (control.invalid) {
          document.getElementById(key).focus();
          return;
        }
      }
      return;
    }

    // validate customer form
    if (!this.CondolenceVideoOrder.isCustomerAddressSame && this.customerForm.userForm.invalid) {
      for (const [key, control] of Object.entries(this.customerForm.userForm.controls)) {
        if (control.invalid) {
          document.getElementById(`customer${this.capitalize(key)}`).focus();
          return;
        }
      }
      return;
    }
    for (const [key, control] of Object.entries(this.customerForm.userForm.controls)) {
      this.CondolenceVideoOrder.CustomerAddress[key] = key === 'postalCode' ? control.value.postalCode : control.value;
    }

    this.CondolenceVideoOrder.SenderFirstName = this.customerForm.userForm.controls.firstName?.value;
    this.CondolenceVideoOrder.SenderFirstNameKana = this.customerForm.userForm.controls.firstNameKana?.value;
    this.CondolenceVideoOrder.SenderLastName = this.CondolenceVideoOrder.useMaidenName
      ? this.customerForm.userForm.controls.maidenName?.value
      : this.customerForm.userForm.controls.lastName?.value;
    this.CondolenceVideoOrder.SenderLastNameKana = this.CondolenceVideoOrder.useMaidenName
      ? this.customerForm.userForm.controls.maidenName?.value
      : this.customerForm.userForm.controls.lastNameKana?.value;
    this.CondolenceVideoOrder.SenderPosition = this.customerForm.userForm.controls.position?.value;
    this.CondolenceVideoOrder.SenderCompany = this.customerForm.userForm.controls.company?.value;

    // validate shipping form
    // if (!this.CondolenceVideoOrder.IsDestinationSame && this.shippingForm.userForm.invalid) {
    //   for (const [key, control] of Object.entries(this.shippingForm.userForm.controls)) {
    //     if (control.invalid) {
    //       document.getElementById(`shipping${this.capitalize(key)}`).focus();
    //       return;
    //     }
    //   }
    //   return;
    // }
    // for (const [key, control] of Object.entries(this.shippingForm.userForm.controls)) {
    //   this.CondolenceVideoOrder.ShippingAddress[key] = key === 'postalCode' ? control.value.postalCode : control.value;
    // }

    const order = this.storage.Order;
    order.CondolenceVideoOrder = this.CondolenceVideoOrder;

    this.storage.Order = order;

    await this.route.navigate([this.storage.Funeral.funeralId, 'order', 'condolence-video', 'order-details']);
  }

  back(): void {
    this.location.back();
  }

  handleIsCustomerAddressSame(event) {
    if (!event) {
      this.CondolenceVideoOrder.useMaidenName = false;
      this.customerForm.useMaidenName = false;
    }
  }

  handleUseMaidenNameChange(useMaidenName: boolean) {
    if (this.Order?.CustomerAddress?.maidenName) {
      this.customerForm.useMaidenName = useMaidenName;
    }
  }

  get returnButtonLabel(): string {
    return this.bundleCartService.isEdit() ? I18n.get('goToApplicationConfirmation') : I18n.get('return');
  }

  get discountedPrice() {
    return this.discountsService.getDiscountedPrice(
      this.CondolenceVideoOrder.Product?.price,
      ProductType.CondolenceVideo
    );
  }

  get disableUseMaidenName(): boolean {
    return !Boolean(this.Order?.CustomerAddress?.maidenName) || !this.CondolenceVideoOrder.isCustomerAddressSame;
  }
}
