<div [@inOutAnimation]>
  <div class="bg-grey pt-1">
    <div class="container mt-4">
      <div class="row justify-content-between">
        <div class="col-12 col-md-5 col-lg-4 text-center flower-data">
          <img class="img-fluid form-group" vtMedia [object]="FlowerOrder.Product.picture" />
          <ul class="list-unstyled">
            <li>
              <div class="row form-group">
                <div class="col-3 text-left">{{ 'flowerOrderProductName' | i18n }}</div>
                <div class="col-8 text-left">{{ FlowerOrder.Product.name }}</div>
              </div>
            </li>
            <li>
              <div class="row form-group">
                <div class="col-3 text-left">{{ 'price' | i18n }}</div>
                <div class="col-8 text-left">
                  {{ FlowerOrder.Product.price | priceWithTax }}<small>({{ 'taxIncluded' | i18n }})</small>
                </div>
              </div>
            </li>
            <li>
              <div class="row form-group">
                <div class="col-3 text-left">{{ 'size' | i18n }}</div>
                <div class="col-8 text-left">
                  <small>{{ 'height' | i18n }}{{ FlowerOrder.Product.flowerHeight }}{{ 'cm' | i18n }}</small>
                  &nbsp;
                  <small>{{ 'width' | i18n }}{{ FlowerOrder.Product.flowerWidth }}{{ 'cm' | i18n }}</small>
                </div>
              </div>
            </li>
            <li>
              <div class="row form-group">
                <div class="col-12 text-left">{{ 'orderProductDescription' | i18n }}</div>
                <div class="col-12 text-left">
                  <small [innerHTML]="FlowerOrder.Product.description"></small>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="col-12 col-md-7 col-lg-7">
          <form #flowerOrderForm="ngForm" novalidate>
            <div class="form-group collapse">
              <div class="form-group">
                <label for="total">{{ 'quantity' | i18n }}</label>
                <input
                  id="total"
                  name="total"
                  type="number"
                  class="form-control col-md-3 col-sm-8"
                  readonly
                  [(ngModel)]="FlowerOrder.quantity"
                />
              </div>
            </div>
            <div class="form-group">
              <label for="bill-name">
                {{ 'billName' | i18n }}
                <span class="require">{{ 'required' | i18n }}</span>
              </label>
              <button
                id="open-flower-name-button"
                class="btn btn-primary btn-popup side-round-button"
                type="button"
                (click)="OpenFlowerNameSampleModal()"
              >
                {{ 'openFlowerName' | i18n }}
              </button>
              <textarea
                rows="9"
                id="billName"
                name="billName"
                class="form-control"
                [class]="{
                  'is-invalid': billName.invalid && (!billName.untouched || isSubmit)
                }"
                #billName="ngModel"
                required
                [(ngModel)]="FlowerOrder.flowerName"
                maxlength="255"
                placeholder="例１)&#13;&#10;埼玉　太郎&#13;&#10;&#13;&#10;例２)&#13;&#10;株式会社〇〇　取締役社長　埼玉　太郎&#13;&#10;&#13;&#10;例３)&#13;&#10;株式会社〇〇　取締役社長&#13;&#10;埼玉　太郎&#13;&#10;埼玉　次郎"
              ></textarea>
              <div
                class="validate-message text-danger"
                *ngIf="billName.errors?.required && (!billName.untouched || isSubmit)"
              >
                {{ 'billNameIsRequiredMsg' | i18n }}
              </div>
            </div>
            <div class="form-group">
              <label for="remarks">{{ 'orderRemarks' | i18n }}</label>
              <textarea
                rows="9"
                id="remarks"
                name="remarks"
                class="form-control"
                style.resize="none"
                [(ngModel)]="FlowerOrder.flowerRemark"
                maxlength="255"
              >
              </textarea>
            </div>
          </form>
        </div>
      </div>
      <!-- shipping address -->
      <div class="shipping-address-container" *ngIf="hasPresents">
        <p>{{ 'returnOfIncenseFlowerAndDeliveryOfReturnedGoods' | i18n }}</p>
        <div class="form-group">
          <input
            type="radio"
            name="DestinationSame"
            id="DestinationSameYes"
            [value]="true"
            [(ngModel)]="FlowerOrder.IsDestinationSame"
          />
          <label for="DestinationSameYes"> {{ 'destinationSameFlowerYes' | i18n }} </label>
        </div>
        <div class="form-group">
          <input
            type="radio"
            name="DestinationSame"
            id="DestinationSameNo"
            [value]="false"
            [(ngModel)]="FlowerOrder.IsDestinationSame"
          />
          <label for="DestinationSameNo">{{ 'destinationSameNo' | i18n }}</label>
        </div>
      </div>
      <!-- shipping address form container -->
      <app-address
        #shippingForm
        myStyle="padding-left: 3em"
        [style.display]="FlowerOrder.IsDestinationSame ? 'none' : 'initial'"
        [type]="AddressType.shipping"
        itemType="flower"
        [viewType]="ViewType.shortAddress"
      ></app-address>
    </div>
  </div>
  <!-- buttons -->
  <div class="buttons-container">
    <button id="return-button" class="nav-button return-button" (click)="back()">
      {{ 'return' | i18n }}
    </button>
    <button id="add-button" class="nav-button add-button" (click)="addAction()" *ngIf="!hasPresents && !isEdit">
      {{ 'addNewOrder' | i18n }}
    </button>
    <button id="next-button" class="nav-button next-button" (click)="nextAction()">
      {{ 'decideAndNext' | i18n }}
    </button>
  </div>
  <!-- <div class="container pt-3">
    <div class="btn-toolbar justify-content-between mt-2 mb-5" role="toolbar">
      <button id="flower-order-return-button" class="btn btn-outline-secondary" (click)="back()">
        {{ 'return' | i18n }}
      </button>
      <button
        id="flower-order-add-button"
        class="btn btn-secondary"
        (click)="addAction()"
        *ngIf="!hasPresents && !isEdit"
      >
        {{ 'addNewOrder' | i18n }}
      </button>
      <button id="flower-order-decide-and-next-button" class="btn btn-secondary" (click)="nextAction()">
        {{ 'decideAndNext' | i18n }}
      </button>
    </div>
  </div> -->
</div>
