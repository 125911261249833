<div class="modal-header"></div>
<div class="modal-body">
  <div class="card-preview font-mincho" [style.background-image]="cardPicture">
    <div class="row card-body justify-content-center">
      <div class="col-1 text-left pt-55">
        <span class="sub-name">{{ Order.cardSenderPosition }}</span>
        <span class="name">{{ Order.cardSenderLastName }}&nbsp;{{ Order.cardSenderFirstName }}</span>
      </div>
      <div class="col-1 text-left pt-55">{{ Order.cardSenderCompany }}</div>
      <div class="col-5">
        <div class="condolence-input text-break">
          {{ Order.cardMessage }}
        </div>
      </div>
      <div class="col-2">
        <span class="sub-name">{{ 'mourning' | i18n }}</span>
        <span class="name"> {{ Order.cardDestinationLastName }}&nbsp;{{ Order.cardDestinationFirstName }} </span>
        <span>{{ 'nameSuffix' | i18n }}</span>
      </div>
    </div>
  </div>
</div>
