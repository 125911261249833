<form
  [style]="myStyle"
  [class]="myClass"
  class="address-form"
  #userForm="ngForm"
  [mustMatch]="['email', 'confirmEmail']"
  novalidate
>
  <!-- Name -->
  <div class="address-item" [ngClass]="{ 'flex-direction-column': !isLabelPositionDefault }" *ngIf="permissions.isName">
    <label
      [for]="type + 'LastName'"
      class="mb-1"
      [ngClass]="{
        'address-label': isLabelPositionDefault,
        'full-width': !isLabelPositionDefault,
        'flex-direction-column-reverse ': specialName
      }"
    >
      <span class="mr-1">{{ labels[type][itemType]?.name || ('name' | i18n) }}</span>
      <span
        class="require d-inline-block mb-1"
        [ngClass]="{
          'm-0': specialName
        }"
        >{{ labels[type][itemType]?.required || 'required' | i18n }}</span
      >
      <p *ngIf="displayNameRemark" class="name-remark m-0">
        ※連盟、一同などをご入力する際には、代表者の方をご入力ください
      </p>
    </label>
    <div
      class="d-flex p-0 name-container"
      [ngClass]="{ 'address-input': isLabelPositionDefault, 'full-width': !isLabelPositionDefault }"
    >
      <div class="half-width" *ngIf="useLastName; else noLastName">
        <input
          [id]="type + 'LastName'"
          name="lastName"
          #LastNameRef="ngModel"
          type="text"
          class="form-control"
          [class]="{ 'is-invalid': LastNameRef.invalid && !readonly && (!LastNameRef.untouched || isSubmit) }"
          [(ngModel)]="address.lastName"
          [placeholder]="setPlaceholder('例）田中')"
          maxlength="15"
          [readonly]="nameAndEmailReadOnly"
          [required]="useLastName"
        />
        <div
          class="validate-message text-danger"
          *ngIf="LastNameRef.errors?.required && !readonly && (!LastNameRef.untouched || isSubmit)"
        >
          {{ errorMessages[type][itemType]?.name || 'nameRequired' | i18n }}
        </div>
      </div>
      <ng-template #noLastName>
        <div class="half-width">
          <input
            [id]="type + 'MaidenName'"
            name="maidenName"
            #MaidenNameRef="ngModel"
            type="text"
            class="form-control"
            [class]="{ 'is-invalid': MaidenNameRef.invalid && !readonly && (!MaidenNameRef.untouched || isSubmit) }"
            [(ngModel)]="address.maidenName"
            [placeholder]="setPlaceholder('例）田中')"
            maxlength="15"
            [readonly]="readonly"
            [required]="!useLastName"
          />
          <div
            class="validate-message text-danger"
            *ngIf="MaidenNameRef.errors?.required && !readonly && (!MaidenNameRef.untouched || isSubmit)"
          >
            {{ errorMessages[type][itemType]?.name || 'nameRequired' | i18n }}
          </div>
        </div>
      </ng-template>
      <div class="half-width">
        <input
          [id]="type + 'FirstName'"
          name="firstName"
          #FirstName="ngModel"
          type="text"
          class="form-control"
          [class]="{
            'is-invalid': FirstName.invalid && !readonly && (!FirstName.untouched || isSubmit)
          }"
          [(ngModel)]="address.firstName"
          [placeholder]="setPlaceholder('例）太郎')"
          maxlength="15"
          [readonly]="nameAndEmailReadOnly"
          required
        />
        <div
          class="validate-message text-danger"
          *ngIf="FirstName.invalid && !readonly && (!FirstName.untouched || isSubmit)"
          [ngSwitch]="type"
        >
          {{ errorMessages[type][itemType]?.name || 'nameRequired' | i18n }}
        </div>
      </div>
    </div>
  </div>

  <!-- Name Kana -->
  <div class="address-item" [ngClass]="{ 'flex-direction-column': !isLabelPositionDefault }" *ngIf="permissions.isKana">
    <label
      [for]="type + 'LastNameKana'"
      [ngClass]="{ 'address-label': isLabelPositionDefault, 'full-width': !isLabelPositionDefault }"
    >
    </label>
    <div
      class="d-flex p-0 name-container"
      [ngClass]="{ 'address-input': isLabelPositionDefault, 'full-width': !isLabelPositionDefault }"
    >
      <div class="half-width" *ngIf="useLastNameKana; else noLastNameKana">
        <input
          [id]="type + 'LastNameKana'"
          name="lastNameKana"
          #LastNameKana="ngModel"
          type="text"
          class="form-control"
          [class]="{ 'is-invalid': LastNameKana.invalid && !readonly && (!LastNameKana.untouched || isSubmit) }"
          [(ngModel)]="address.lastNameKana"
          [placeholder]="setPlaceholder('例）タナカ')"
          maxlength="20"
          [readonly]="nameAndEmailReadOnly"
          [required]="useLastNameKana"
          pattern="(?=.*?[\u30A1-\u30FC])[\u30A1-\u30FC\s]*"
        />
        <div
          class="validate-message text-danger"
          *ngIf="LastNameKana.errors?.required && !readonly && (!LastNameKana.untouched || isSubmit)"
        >
          {{ 'kanaRequired' | i18n }}
        </div>
        <div
          class="validate-message text-danger"
          *ngIf="LastNameKana.errors?.pattern && (!LastNameKana.untouched || isSubmit)"
        >
          {{ 'kanaRequired' | i18n }}
        </div>
      </div>
      <ng-template #noLastNameKana>
        <div class="half-width">
          <input
            [id]="type + 'MaidenNameKana'"
            name="maidenNameKana"
            #MaidenNameKanaRef="ngModel"
            type="text"
            class="form-control"
            [class]="{
              'is-invalid': MaidenNameKanaRef.invalid && !readonly && (!MaidenNameKanaRef.untouched || isSubmit)
            }"
            [(ngModel)]="address.maidenNameKana"
            [placeholder]="setPlaceholder('例）タナカ')"
            maxlength="20"
            [readonly]="readonly"
            [required]="!useLastNameKana"
            pattern="(?=.*?[\u30A1-\u30FC])[\u30A1-\u30FC\s]*"
          />
          <div
            class="validate-message text-danger"
            *ngIf="MaidenNameKanaRef.errors?.required && !readonly && (!MaidenNameKanaRef.untouched || isSubmit)"
          >
            {{ 'kanaRequired' | i18n }}
          </div>
          <div
            class="validate-message text-danger"
            *ngIf="MaidenNameKanaRef.errors?.pattern && (!MaidenNameKanaRef.untouched || isSubmit)"
          >
            {{ 'kanaRequired' | i18n }}
          </div>
        </div>
      </ng-template>
      <div class="half-width">
        <input
          [id]="type + 'FirstNameKana'"
          name="firstNameKana"
          #FirstNameKana="ngModel"
          type="text"
          class="form-control"
          [class]="{
            'is-invalid': FirstNameKana.invalid && !readonly && (!FirstNameKana.untouched || isSubmit)
          }"
          [(ngModel)]="address.firstNameKana"
          [placeholder]="setPlaceholder('例）タロウ')"
          maxlength="20"
          [readonly]="nameAndEmailReadOnly"
          required
          pattern="(?=.*?[\u30A1-\u30FC])[\u30A1-\u30FC\s]*"
        />
        <div
          class="validate-message text-danger"
          *ngIf="FirstNameKana.errors?.required && !readonly && (!FirstNameKana.untouched || isSubmit)"
        >
          {{ 'kanaRequired' | i18n }}
        </div>
        <div
          class="validate-message text-danger"
          *ngIf="FirstNameKana.errors?.pattern && (!FirstNameKana.untouched || isSubmit)"
        >
          {{ 'kanaRequired' | i18n }}
        </div>
      </div>
    </div>
  </div>

  <!-- custom name -->
  <div
    *ngIf="permissions.customName"
    class="address-item"
    [ngClass]="{ 'flex-direction-column': !isLabelPositionDefault }"
  >
    <label
      for="customName"
      class="d-flex flex-column"
      [ngClass]="{ 'address-label': isLabelPositionDefault, 'full-width': !isLabelPositionDefault }"
    >
      <p
        class="d-flex m-0"
        [ngClass]="{ 'flex-column-reverse': isLabelPositionDefault, 'flex-row': !isLabelPositionDefault }"
      >
        <span [ngClass]="{ 'mr-3': !isLabelPositionDefault }">{{ 'customName' | i18n }}</span>
        <!-- <span class="custom-name-required m-0">{{ 'customNameIsRequired' | i18n }}</span> -->
      </p>
      <div class="d-flex flex-column">
        <span> {{ 'pleaseEnterInKana1' | i18n }} </span>
        <span class="ml-3"> {{ 'pleaseEnterInKana2' | i18n }} </span>
      </div>
    </label>
    <div [ngClass]="{ 'address-input': isLabelPositionDefault, 'full-width': !isLabelPositionDefault }">
      <textarea
        id="customName"
        name="customName"
        class="form-control"
        rows="9"
        placeholder="
        例1 連盟）   田中 一郎（タナカ イチロウ）
        　　　　　　　鈴木 二郎（スズキ ジロウ）

        例2 一同）　〇〇〇会社　営業部一同（〇〇〇ガイシャ　エイギョウブイチドウ）
        "
        [(ngModel)]="address.customName"
        [readonly]="readonly"
      ></textarea>
    </div>
  </div>

  <!-- Maiden Name -->
  <div
    class="address-item"
    [ngClass]="{ 'flex-direction-column': !isLabelPositionDefault }"
    *ngIf="permissions.isMaidenName && itemTypeString !== 'moneyGift'"
  >
    <label
      [for]="type + 'MaidenName'"
      [ngClass]="{ 'address-label': isLabelPositionDefault, 'full-width': !isLabelPositionDefault }"
    >
      {{ 'maidenName' | i18n }}（{{ 'nameKana' | i18n }}）
    </label>
    <div
      class="d-flex p-0 name-container"
      [ngClass]="{ 'address-input': isLabelPositionDefault, 'full-width': !isLabelPositionDefault }"
    >
      <div class="half-width">
        <input
          [id]="type + 'MaidenName'"
          name="maidenName"
          #MaidenName="ngModel"
          type="text"
          class="form-control"
          [class]="{
            'is-invalid':
              address.maidenNameKana?.length &&
              !address.maidenName?.length &&
              !readonly &&
              (!MaidenName.untouched || isSubmit)
          }"
          [(ngModel)]="address.maidenName"
          [placeholder]="setPlaceholder('例）鈴木')"
          maxlength="15"
          [readonly]="readonly"
        />
        <div
          class="validate-message text-danger"
          *ngIf="
            address.maidenNameKana?.length &&
            !address.maidenName?.length &&
            !readonly &&
            (!MaidenName.untouched || isSubmit)
          "
        >
          {{ 'maidenNameRequired' | i18n }}
        </div>
      </div>
      <div class="half-width">
        <input
          [id]="type + 'MaidenNameKana'"
          name="maidenNameKana"
          #MaidenNameKana="ngModel"
          type="text"
          class="form-control"
          [class]="{
            'is-invalid':
              ((address.maidenName?.length && !address.maidenNameKana?.length) || MaidenNameKana.errors?.pattern) &&
              !readonly &&
              (!MaidenNameKana.untouched || isSubmit)
          }"
          [(ngModel)]="address.maidenNameKana"
          [placeholder]="setPlaceholder('例）スズキ')"
          maxlength="20"
          [readonly]="readonly"
          pattern="(?=.*?[\u30A1-\u30FC])[\u30A1-\u30FC\s]*"
        />
        <div
          class="validate-message text-danger"
          *ngIf="
            ((address.maidenName?.length && !address.maidenNameKana?.length) || MaidenNameKana.errors?.pattern) &&
            !readonly &&
            (!MaidenNameKana.untouched || isSubmit)
          "
        >
          {{ 'kanaRequired' | i18n }}
        </div>
      </div>
    </div>
  </div>

  <!-- Relationship -->
  <div
    class="address-item"
    [ngClass]="{ 'flex-direction-column': !isLabelPositionDefault }"
    *ngIf="permissions.isRelationship"
  >
    <label
      [for]="type + 'Relationship'"
      [ngClass]="{ 'address-label': isLabelPositionDefault, 'full-width': !isLabelPositionDefault }"
      >{{ 'relationship' | i18n }}</label
    >
    <div [ngClass]="{ 'width-50': isLabelPositionDefault, 'full-width': !isLabelPositionDefault }">
      <!-- <kendo-combobox
        [id]="type + 'Relationship'"
        name="relationship"
        #relationshipRef="ngModel"
        class="grey-border"
        MaxLength="20"
        [allowCustom]="true"
        [data]="Relationships"
        [(ngModel)]="address.relationship"
        [readonly]="readonly"
      >
      </kendo-combobox> -->
      <kendo-dropdownlist
        [id]="type + 'Relationship'"
        name="relationship"
        #relationshipRef="ngModel"
        class="grey-border"
        [data]="Relationships"
        [(ngModel)]="address.relationship"
        [defaultItem]="defaultItem"
        [readonly]="readonly"
      >
      </kendo-dropdownlist>
      <div
        class="validate-message text-danger"
        *ngIf="relationshipRef.errors?.maxLengthError && !readonly && (!relationshipRef.untouched || isSubmit)"
      >
        {{ 'relationshipMaxLengthMessage' | i18n }}
      </div>
    </div>
  </div>

  <!-- Company -->
  <div
    class="address-item"
    [ngClass]="{ 'flex-direction-column': !isLabelPositionDefault }"
    *ngIf="permissions.isCompany"
  >
    <label
      [for]="type + 'Company'"
      [ngClass]="{ 'address-label': isLabelPositionDefault, 'full-width': !isLabelPositionDefault }"
      ><span class="mr-1">{{ labels[type][itemType]?.company || ('company' | i18n) }}</span
      ><span *ngIf="isCompanyRequired" class="require">{{ 'required' | i18n }}</span></label
    >
    <div [ngClass]="{ 'address-input': isLabelPositionDefault, 'full-width': !isLabelPositionDefault }">
      <input
        [id]="type + 'Company'"
        name="company"
        #Company="ngModel"
        type="text"
        class="form-control"
        [class]="{
          'is-invalid': Company.invalid && !readonly && (!Company.untouched || isSubmit)
        }"
        [(ngModel)]="address.company"
        maxlength="40"
        [required]="isCompanyRequired"
        [readonly]="readonly"
      />
      <div
        class="validate-message text-danger"
        *ngIf="Company.errors?.required && !readonly && (!Company.untouched || isSubmit)"
      >
        {{ 'companyIsRequiredWhenCompanyPositionIsSelected' | i18n }}
      </div>
    </div>
  </div>

  <!-- Position -->
  <div
    class="address-item"
    [ngClass]="{ 'flex-direction-column': !isLabelPositionDefault }"
    *ngIf="permissions.isPosition"
  >
    <label
      [for]="type + 'Position'"
      [ngClass]="{ 'address-label': isLabelPositionDefault, 'full-width': !isLabelPositionDefault }"
      ><span class="mr-1">{{ labels[type][itemType]?.position || ('position' | i18n) }}</span>
      <!-- <span *ngIf="isPositionRequired" class="require">{{ 'required' | i18n }}</span> -->
    </label>
    <div [ngClass]="{ 'width-50': isLabelPositionDefault, 'full-width': !isLabelPositionDefault }">
      <!-- <kendo-combobox
        [id]="type + 'Position'"
        name="position"
        #positionRef="ngModel"
        class="grey-border"
        MaxLength="25"
        [allowCustom]="true"
        [data]="Positions"
        [(ngModel)]="address.position"
        [readonly]="readonly"
        >
      </kendo-combobox> -->
      <kendo-dropdownlist
        [id]="type + 'Position'"
        name="position"
        #positionRef="ngModel"
        class="grey-border"
        [data]="Positions"
        [(ngModel)]="address.position"
        [defaultItem]="defaultItem"
        [readonly]="readonly"
      ></kendo-dropdownlist>
      <div
        class="validate-message text-danger"
        *ngIf="positionRef.errors?.required && !readonly && (!positionRef.untouched || isSubmit)"
      >
        {{ 'positionIsRequiredWhenCompanyPositionIsSelected' | i18n }}
      </div>
      <div
        class="validate-message text-danger"
        *ngIf="positionRef.errors?.maxLengthError && !readonly && (!positionRef.untouched || isSubmit)"
      >
        {{ 'senderPositionMaxLengthMessage' | i18n }}
      </div>
    </div>
  </div>
  <!-- Postal Code -->
  <div
    *ngIf="permissions.isAddress"
    class="address-item"
    [ngClass]="{ 'flex-direction-column': !isLabelPositionDefault }"
  >
    <label
      [for]="type + 'PostalCode'"
      [ngClass]="{
        'address-left-label d-flex align-items-start flex-wrap': isLabelPositionDefault,
        'full-width': !isLabelPositionDefault
      }"
      ><span class="mr-1">{{ 'address' | i18n }}</span
      ><span class="require">{{ 'required' | i18n }}</span></label
    >
    <label
      [ngClass]="{ 'address-right-label': isLabelPositionDefault, 'full-width': !isLabelPositionDefault }"
      [for]="type + 'PostalCode'"
      >〒</label
    >
    <div [ngClass]="{ 'width-25': isLabelPositionDefault, 'full-width': !isLabelPositionDefault }">
      <PostalCode
        [id]="type + 'PostalCode'"
        name="postalCode"
        #postalCodeRef="ngModel"
        [prefectures]="Prefectures"
        myclass="form-control"
        [(ngModel)]="address"
        [IsInvalid]="{
          'is-invalid': postalCodeRef.invalid && !readonly && (!postalCodeRef.untouched || isSubmit)
        }"
        tabindex="0"
        [readonly]="readonly"
        required
      >
      </PostalCode>
      <div
        class="validate-message text-danger col-12 p-0"
        *ngIf="postalCodeRef.invalid && !readonly && (!postalCodeRef.untouched || isSubmit)"
      >
        {{ 'postalCodeIsInvalidMsg' | i18n }}
      </div>
    </div>
  </div>
  <!-- Prefecture -->
  <div
    *ngIf="permissions.isAddress"
    class="address-item"
    [ngClass]="{ 'flex-direction-column': !isLabelPositionDefault }"
  >
    <!-- <label [ngClass]="{ 'col-md-2': isLabelPositionDefault, 'full-width': !isLabelPositionDefault }"></label> -->
    <label
      [ngClass]="{ 'address-label text-align-right': isLabelPositionDefault, 'full-width': !isLabelPositionDefault }"
      [for]="type + 'Prefecture'"
      >{{ 'prefecture' | i18n }}</label
    >
    <div [ngClass]="{ 'width-25': isLabelPositionDefault, 'full-width': !isLabelPositionDefault }">
      <!-- <ng-select
        [id]="type + 'Prefecture'"
        name="prefecture"
        #prefectureRef="ngModel"
        [items]="Prefectures"
        bindValue="code"
        bindLabel="name"
        [(ngModel)]="address.prefecture"
        [placeholder]="setPlaceholder('【選択してください】')"
        tabindex="0"
        [readonly]="readonly"
        required
        [class]="{
          'is-invalid': prefectureRef.invalid && !readonly && (!prefectureRef.untouched || isSubmit)
        }"
      >
      </ng-select> -->
      <kendo-dropdownlist
        [id]="type + 'Prefecture'"
        name="prefecture"
        class="grey-border px-0"
        #prefectureRef="ngModel"
        [data]="Prefectures"
        [(ngModel)]="address.prefecture"
        [readonly]="readonly"
        tabindex="0"
        required
        [class]="{
          'is-invalid': prefectureRef.invalid && !readonly && (!prefectureRef.untouched || isSubmit)
        }"
      >
      </kendo-dropdownlist>
      <div
        class="validate-message text-danger col-12 p-0"
        *ngIf="prefectureRef.invalid && !readonly && (!prefectureRef.untouched || isSubmit)"
      >
        {{ 'prefectureIsInvalidMsg' | i18n }}
      </div>
    </div>
  </div>
  <!-- city -->
  <div
    *ngIf="permissions.isAddress"
    class="address-item"
    [ngClass]="{ 'flex-direction-column': !isLabelPositionDefault }"
  >
    <!-- <label [ngClass]="{ 'col-md-2': isLabelPositionDefault, 'full-width': !isLabelPositionDefault }"></label> -->
    <label
      [ngClass]="{ 'address-label text-align-right': isLabelPositionDefault, 'full-width': !isLabelPositionDefault }"
      [for]="type + 'City'"
      >{{ 'city' | i18n }}</label
    >
    <div [ngClass]="{ 'width-40': isLabelPositionDefault, 'full-width': !isLabelPositionDefault }">
      <input
        [id]="type + 'City'"
        name="city"
        #city="ngModel"
        type="text"
        class="form-control"
        [(ngModel)]="address.city"
        [class]="{
          'is-invalid': city.invalid && !readonly && (!city.untouched || isSubmit)
        }"
        maxlength="30"
        [readonly]="readonly"
        required
      />
      <div
        class="validate-message text-danger col-12 p-0"
        *ngIf="city.errors?.required && !readonly && (!city.untouched || isSubmit)"
      >
        {{ 'cityIsRequiredMsg' | i18n }}
      </div>
    </div>
  </div>
  <!-- Street Number -->
  <div
    *ngIf="permissions.isAddress"
    class="address-item"
    [ngClass]="{ 'flex-direction-column': !isLabelPositionDefault }"
  >
    <!-- <label [ngClass]="{ 'col-md-2': isLabelPositionDefault, 'full-width': !isLabelPositionDefault }"></label> -->
    <label
      [ngClass]="{ 'address-label  text-align-right': isLabelPositionDefault, 'full-width': !isLabelPositionDefault }"
      [for]="type + 'Street'"
      >{{ 'streetNumber' | i18n }}</label
    >
    <div [ngClass]="{ 'width-40': isLabelPositionDefault, 'full-width': !isLabelPositionDefault }">
      <input
        [id]="type + 'Street'"
        name="street"
        #Street="ngModel"
        type="text"
        class="form-control"
        [class]="{
          'is-invalid': Street.invalid && !readonly && (!Street.untouched || isSubmit)
        }"
        [(ngModel)]="address.street"
        maxlength="20"
        [readonly]="readonly"
        required
      />
      <div
        class="validate-message text-danger col-12 p-0"
        *ngIf="Street.errors?.required && !readonly && (!Street.untouched || isSubmit)"
      >
        {{ 'streetNumberIsRequiredMsg' | i18n }}
      </div>
    </div>
  </div>
  <!-- building -->
  <div
    *ngIf="permissions.isAddress"
    class="address-item"
    [ngClass]="{ 'flex-direction-column': !isLabelPositionDefault }"
  >
    <!-- <label [ngClass]="{ 'col-md-2': isLabelPositionDefault, 'full-width': !isLabelPositionDefault }"></label> -->
    <label
      [ngClass]="{ 'address-label text-align-right': isLabelPositionDefault, 'full-width': !isLabelPositionDefault }"
      [for]="type + 'Building'"
      >{{ 'buildingNumber' | i18n }}</label
    >
    <div [ngClass]="{ 'address-input': isLabelPositionDefault, 'full-width': !isLabelPositionDefault }">
      <input
        [id]="type + 'Building'"
        name="building"
        #Building="ngModel"
        type="text"
        class="form-control"
        [(ngModel)]="address.building"
        maxlength="80"
        [readonly]="readonly"
      />
      <div
        class="validate-message text-danger offset-4 offset-md-3 p-0"
        *ngIf="Building.errors?.required && !readonly && (!Building.untouched || isSubmit)"
      >
        {{ 'streetNumberIsRequiredMsg' | i18n }}
      </div>
    </div>
  </div>
  <!-- Telephone -->
  <div
    *ngIf="permissions.isAddress"
    class="address-item"
    [ngClass]="{ 'flex-direction-column': !isLabelPositionDefault }"
  >
    <label
      [for]="type + 'Telephone'"
      [ngClass]="{ 'address-label': isLabelPositionDefault, 'full-width': !isLabelPositionDefault }"
      ><span class="mr-1">{{ 'phone' | i18n }}</span
      ><span class="require">{{ 'required' | i18n }}</span>
    </label>
    <div [ngClass]="{ 'width-40': isLabelPositionDefault, 'full-width': !isLabelPositionDefault }">
      <input
        phoneValidator
        [id]="type + 'Telephone'"
        name="telephone"
        #Telephone="ngModel"
        type="tel"
        class="form-control"
        [class]="{
          'is-invalid': Telephone.invalid && !readonly && (!Telephone.untouched || isSubmit)
        }"
        [(ngModel)]="address.telephone"
        [placeholder]="setPlaceholder('例) 080-1234-5678')"
        maxlength="13"
        minlength="10"
        [readonly]="readonly"
        required
      />
      <div
        class="validate-message text-danger"
        *ngIf="Telephone.errors?.required && !readonly && (!Telephone.untouched || isSubmit)"
      >
        {{ 'phoneIsRequiredMsg' | i18n }}
      </div>
      <div
        class="validate-message text-danger"
        *ngIf="Telephone.errors?.phoneValidationError && !readonly && (!Telephone.untouched || isSubmit)"
      >
        {{ 'phonePatternErrorMsg' | i18n }}
      </div>
    </div>
  </div>
  <!-- Email -->
  <div
    class="address-item"
    [ngClass]="{ 'flex-direction-column': !isLabelPositionDefault }"
    *ngIf="permissions.isEmail"
  >
    <label
      [for]="type + 'Email'"
      [ngClass]="{ 'address-label': isLabelPositionDefault, 'full-width': !isLabelPositionDefault }"
      ><span class="mr-1">{{ 'email' | i18n }}</span
      ><span class="require">{{ 'required' | i18n }}</span>
    </label>
    <div [ngClass]="{ 'address-input': isLabelPositionDefault, 'full-width': !isLabelPositionDefault }">
      <input
        [id]="type + 'Email'"
        name="email"
        pattern="^(?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*|(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\[\x01-\x09\x0b\x0c\x0e-\x7f])*&quot;)@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-zA-Z0-9-]*[a-zA-Z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$"
        #Email="ngModel"
        type="email"
        class="form-control"
        [class]="{
          'is-invalid': Email.invalid && !readonly && (!Email.untouched || isSubmit)
        }"
        [(ngModel)]="address.email"
        [placeholder]="setPlaceholder('例）abc@xxx.co.jp')"
        [readonly]="nameAndEmailReadOnly"
        required
      />
      <div
        class="validate-message text-danger"
        *ngIf="Email.errors?.required && !readonly && (!Email.untouched || isSubmit)"
      >
        {{ 'emailIsRequiredMsg' | i18n }}
      </div>
      <div
        class="validate-message text-danger"
        *ngIf="Email.errors?.pattern && !readonly && (!Email.untouched || isSubmit)"
      >
        {{ 'emailPatternErrorMsg' | i18n }}
      </div>
    </div>
  </div>
  <!-- Confirm Email -->
  <div *ngIf="permissions.isConfirmEmail" class="address-item">
    <label
      [for]="type + 'ConfirmEmail'"
      [ngClass]="{ 'address-label': isLabelPositionDefault, 'full-width': !isLabelPositionDefault }"
      ><span class="mr-1">{{ 'confirmEmail' | i18n }}</span
      ><span class="require">{{ 'required' | i18n }}</span>
    </label>
    <div [ngClass]="{ 'address-input': isLabelPositionDefault, 'full-width': !isLabelPositionDefault }">
      <input
        [id]="type + 'ConfirmEmail'"
        name="confirmEmail"
        pattern=".+@.+"
        #ConfirmEmail="ngModel"
        type="email"
        class="form-control"
        [class]="{ 'is-invalid': ConfirmEmail.invalid && !readonly && (!ConfirmEmail.untouched || isSubmit) }"
        [(ngModel)]="address.confirmEmail"
        [placeholder]="setPlaceholder('例）abc@xxx.co.jp')"
        [readonly]="nameAndEmailReadOnly"
        required
      />
      <div
        class="validate-message text-danger"
        *ngIf="ConfirmEmail.errors?.required && !readonly && (!ConfirmEmail.untouched || isSubmit)"
      >
        {{ 'emailIsRequiredMsg' | i18n }}
      </div>
      <div
        class="validate-message text-danger"
        *ngIf="ConfirmEmail.errors?.pattern && !readonly && (!ConfirmEmail.untouched || isSubmit)"
      >
        {{ 'emailPatternErrorMsg' | i18n }}
      </div>
      <div
        class="validate-message text-danger"
        *ngIf="ConfirmEmail.errors?.mustMatch && !readonly && (!ConfirmEmail.untouched || isSubmit)"
      >
        {{ 'confirmEmailMatchErrorMsg' | i18n }}
      </div>
    </div>
  </div>
  <!-- notice -->
  <p class="mb-3 notice" *ngIf="permissions.showNotice">
    {{ 'informationWillBeProvidedToMournerMsg' | i18n }}
  </p>
  <!-- Birthday -->
  <div
    class="address-item"
    [ngClass]="{ 'flex-direction-column': !isLabelPositionDefault }"
    *ngIf="permissions.isBirthday"
  >
    <label
      [ngClass]="{ 'address-label': isLabelPositionDefault, 'full-width': !isLabelPositionDefault }"
      [for]="type + 'Birthday'"
      >{{ 'birthday' | i18n }}</label
    >
    <div [ngClass]="{ item: isLabelPositionDefault, 'full-width': !isLabelPositionDefault }">
      <birth-date
        [id]="type + 'Birthday'"
        name="birthday"
        [(ngModel)]="address.birthday"
        #birthdayRef="ngModel"
        tabindex="0"
        [readonly]="readonly"
      ></birth-date>
      <div
        class="validate-message text-danger ml-3"
        *ngIf="birthdayRef.errors?.invalid_format && !readonly && (!birthdayRef.untouched || isSubmit)"
      >
        {{ 'birthdayIsInvalidMsg' | i18n }}
      </div>
    </div>
  </div>
</form>
