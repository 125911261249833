import { Component, OnInit, Input } from '@angular/core';

import { Path } from 'src/app/core/utilities';

import { CardOrder } from 'src/models/CardOrder';

@Component({
  selector: 'app-card-preview',
  templateUrl: './card-preview.component.html',
  styleUrls: ['./card-preview.component.scss'],
})
export class CardPreviewComponent implements OnInit {
  @Input()
  Order: CardOrder;

  cardPicture: string;

  constructor() {}

  ngOnInit(): void {
    this.cardPicture = `url(${Path.getS3Path(this.Order.Paper.picture)})`;
  }
}
