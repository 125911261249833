export * from './stream/stream.component';
export * from './memorial/components/memory-video/memory-video.component';
export * from './memorial/components/memory-slides/memory-slides.component';
export * from './memorial/memorial.component';
export * from './media-home/media-home.component';
import { StreamComponent } from './stream/stream.component';
import { MemoryVideoComponent } from './memorial/components/memory-video/memory-video.component';
import { MemorySlidesComponent } from './memorial/components/memory-slides/memory-slides.component';
import { MemorialComponent } from './memorial/memorial.component';
import { MediaHomeComponent } from './media-home/media-home.component';

export const MediaModules = [
  StreamComponent,
  MemoryVideoComponent,
  MemorySlidesComponent,
  MemorialComponent,
  MediaHomeComponent,
];
