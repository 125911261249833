<div class="modal-header no-border">
  <a href="javascript:void(0);" class="modal-close-icon" (click)="activeModal.close()"></a>
  <h4 class="modal-title" id="modal-basic-title">スマートセレモニー利用規約</h4>
</div>
<div class="tos-body modal-body">
  <p>
    <span *ngIf="displayForCeremony">株式会社埼玉冠婚葬祭センター</span
    ><span *ngIf="displayForJoin">株式会社ジョイン</span
    >（以下「当社」といいます。）は、スマートセレモニー（以下「本サービス」といいます。）の利用規約（以下「スマセレ規約」といいます。）を定め、スマセレ規約に基づき本サービスを提供します。本サービスを利用するためには、スマセレ規約および「WEBサービスに関するプライバシーポリシー」（以下「本プライバシーポリシー」といいます。）」に同意していただく必要があります。
  </p>
  <!-- #1 -->
  <p class="clause"><strong>第1条（利用者資格）</strong></p>
  <p>
    1．
    当社は、スマセレ規約および本プライバシーポリシーに同意し、かつご葬家より下記の方法（以下「本アクセス手段」といいます。）により本サービスのアクセス先を提供されたお客様（以下「利用者様」といいます。）に対して、本サービスを利用する資格を付与します。
  </p>
  <ul>
    <li>ご葬家からのメールに添付されたハイパーリンク</li>
    <li>訃報届に印刷されたQRコード、URL</li>
    <li>FAX等の書面により配布されたQRコード、URL</li>
  </ul>
  <p>2．利用者様は、前項の利用者資格を第三者に譲渡することはできません。</p>
  <p>3．本サービスをご利用できる利用者様の選択は、ご葬家が行います。</p>

  <!-- #2 -->
  <p class="clause"><strong>第2条（利用料）</strong></p>
  <p>
    1．
    本サービスの利用料は無料とします。ただし、本サービスを利用するために必要な通信機器等（以下「端末」といいます。）の設備、インターネット接続にかかわる通信費用等は利用者様が負担するものとします。
  </p>
  <p>
    2．
    前項の定めにかかわらず、本サービスの一部について個別に利用料等が定められた場合は、その一部のサービスをご利用される利用者様は、当該利用料等をお支払いいただきます。
  </p>

  <p class="clause"><strong>第3条（本サービスの内容）</strong></p>
  <p>
    1.
    利用者様は、当社が本サービス上で提供する、供花、弔電、香典等（以下「本商品」といいます。）の申込み、もしくはご葬家の開催する通夜式、葬儀・告別式等葬送に関する儀式（以下「ご葬儀」といいます。）の中継又は録画を閲覧することが出来ます。
  </p>
  <p>
    2.
    利用者様は、自身が著作権を所有する画像もしくは動画のうち、ご葬儀に関する画像もしくは動画を本サービス上より投稿（以下「投稿画像」といいます。）し、ご葬家および他の利用者様に公開（視聴・閲覧）すること（以下「投稿機能」といいます。）が出来ます。
  </p>
  <p>3. 利用者様は、投稿機能にて投稿画像の公開範囲を下記いずれかで定めることが出来ます。</p>
  <ul>
    <li>ご葬家のみに視聴、閲覧を認め公開する</li>
    <li>ご葬家および他の利用者様全員に視聴、閲覧を認め公開する。</li>
  </ul>
  <p>
    4. 利用者様は、前項(2)を公開範囲と定められた他の利用者様の投稿画像を本サービス上より視聴、閲覧することが出来ます。
  </p>
  <p>5. 投稿画像は、本サービス上に公開された日からご葬儀の日より起算し7日後の期日まで本サービス上にて公開されます。</p>

  <!-- #4 -->
  <p class="clause"><strong>第4条（利用者情報の取得、利用目的等）</strong></p>
  <p>
    1.
    利用者様が本サービスをご利用になる際に、当社が取得し、管理し、利用する利用者様に関する各種情報（以下「利用者情報」といいます。）とその利用目的等については、本プライバシーポリシーのとおりとします。利用者様により本商品お申し込み時に登録された個人情報は、本サービスの利用・本商品のお届けおよびそれに係る連絡（登録内容の不備や商品変更等の必要な場合にのみ電話やメール等において連絡いたします。）のためにのみ利用いたします。
  </p>
  <p>2. 利用者様が入力されたクレジットカードに関する情報は、当社には保存いたしません。</p>

  <!-- #5 -->
  <p class="clause"><strong>第5条（ログイン）</strong></p>
  <p>
    利用者様が、当社が定める方法でログインされた場合、当社はその行為を利用者様の行為であるとみなします。第三者により不正に利用者様に代わりログインが行われ、その行為により利用者様が不利益および損害を被った場合、当社は一切の責任を負いません。
  </p>

  <!-- #6 -->
  <p class="clause"><strong>第6条（反社会的勢力の排除）</strong></p>
  <p>
    　当社は以下の事項に該当する方の利用を認めません。利用者様が以下の条項に該当することが判明した場合、アクセスを拒否し、ログインを遮断する場合があります。
  </p>
  <ul>
    <li>
      自らまたは第三者を利用して、当社またはご葬家に対し、暴力的行為、詐術、脅迫的言辞、業務妨害行為などの行為をした者。
    </li>
    <li>
      暴力団、暴力団員（暴力団員でなくなった時から5年を経過しない者を含みます。）、暴力団準構成員、暴力団関係企業・その他の反社会的勢力（以下、あわせて「反社会的勢力」といいます。）である者。
    </li>
    <li>反社会的勢力に対して資金等を提供し、または便宜を供与するなどの関与をしている者。</li>
    <li>反社会的勢力を不当に利用している者。</li>
    <li>その他反社会的勢力と社会的に非難されるべき関係を有している者及び当社がご利用を不適当と認めた者。</li>
  </ul>

  <!-- #7 -->
  <p class="clause"><strong>第7条（登録情報の変更）</strong></p>
  <p>
    1．
    利用者様が登録した登録情報に変更が生じた場合、利用者様は、速やかに当社の定める方法により登録情報を変更するものとします。
  </p>
  <p>
    2．
    利用者様が登録情報の変更を行わなかったことにより、本サービスの利用ができない等の不利益および損害を被った場合、当社は一切の責任を負いません。
  </p>

  <!-- #8 -->
  <p class="clause"><strong>第8条（本商品の申込み）</strong></p>
  <p>
    1．
    本商品の申込みに伴い、本サービス上で、利用者様が入力・登録した申込内容等を確認の上で申込する旨のボタンをクリックし、その後、当社より利用者様がご登録されたメールアドレスに対して、注文内容確認のメールを発信したときをもって、利用者様と当社の間に当該本商品に関する契約が成立したものとします。当該本商品の代金等のお支払につきましては、当社が指定するクレジット決済代行会社または、銀行振込等の所定の手続きにて決済をおこなってください。代金等が決済された時点で、利用者様と当社の間における当該本商品に関する契約が完了し、終了したものとさせていただきます。
  </p>
  <p>
    2．
    前項の規定にかかわらず、本サービスの利用に関して不正な行為、不適当な行為、当社が指定する期日までに所定の手続きをとらなかった場合、または当社が指定する決済期日までに代金が支払われなかった場合、当社は当該本商品に関する申込みについて取消、解除その他の適切な措置を取ることができるものとします。
  </p>

  <!-- #9 -->
  <p class="clause"><strong>第9条（動画・画像の投稿）</strong></p>
  <p>1．投稿機能にて、投稿した投稿画像は、下記の用途のみに使用いたします。</p>
  <ul>
    <li>本サービス上にて、投稿画像を公開（視聴・閲覧）します。</li>
    <li>当社又はご葬家にて、ご葬儀会場のモニター等を使用して公開（視聴・閲覧）します。</li>
    <li>ご葬家へ有料にて提供する動画提供サービスの記録媒体に収録する場合がございます。</li>
  </ul>
  <p>
    2．利用者様は、前項の用途について、当社もしくはご葬家に、投稿画像の使用を認め、使用料等の請求を一切しないものとします。
  </p>
  <p>
    3．当社およびご葬家は、投稿画像に対し、ご葬儀には内容が不適切と判断した場合、投稿された利用者様に承諾を得ることなく削除等の適切な措置を取ることができるものとします。
  </p>
  <p>4．当社は、前項の対象となった投稿画像を投稿した利用者様に対し、投稿機能の利用料等の返金は致しません。</p>
  <p>
    5．利用者様は、投稿画像に対する責任を負うものとし、投稿画像は、第三者の肖像権、その他の権利等を侵害しないことを保証するものとします。万が一投稿された投稿画像の権利等について第三者との間で、損害やトラブルが発生した場合、すべて投稿した利用者様の責任において解決するものとし、当社およびご葬家は一切の責任を負わないものとします。
  </p>
  <p>
    6．利用者様が投稿した投稿画像に対し、ご葬家もしくは他の利用者様による不正使用に対して、当社は一切の責任を負いません。
  </p>
  <p>
    7．利用者様にて、投稿した投稿画像を削除することはできません。削除をご希望される利用者様は、当社へ連絡し、当社の指定する削除手続きをする必要がございます。
  </p>

  <!-- #10 -->
  <p class="clause"><strong>第10条（本サービスの情報）</strong></p>
  <p>
    当社は、本サービスにおいて提供する本商品もしくは本サービスの内容（価格・仕様等）を予告なく変更する場合があります。
  </p>

  <!-- #11 -->
  <p class="clause"><strong>第11条（本サービスの停止・終了）</strong></p>
  <p>
    当社が必要と認める場合、当社はなんらの通知を行うことなく、本サービスの全部または一部の提供を中止（一時的なものを含む。）または終了することがあります。
  </p>

  <!-- #12 -->
  <p class="clause"><strong>第12条（著作権等）</strong></p>
  <p>
    1．本サービスで表示される各種情報等に関する知的財産権等（著作権、特許権、実用新案権、意匠権、商標権等を含みますがこれらに限定されません。以下同じ。）は、当社に帰属します。
  </p>
  <p>2．投稿機能で投稿された投稿画像の著作権は、利用者様に帰属します。</p>

  <!-- #13 -->
  <p class="clause"><strong>第13条（禁止事項）</strong></p>
  <p>1．利用者様は、本サービスを利用するに際し、次に掲げる行為を行わないものとします。</p>
  <ul>
    <li>
      本サービスに使用されている画像、データ、情報等のすべてについて、その有償無償や形態の如何を問わず、事前に当社から書面による承諾を得ることなく、複製、転載、再配布等する行為。
    </li>
    <li>他の利用者様に送付された本アクセス手段を使用して本サービスを利用する行為。</li>
    <li>当社のサービス、当社の配信する広告または当社のサイト上で提供されているサービス・広告等を妨害する行為。</li>
    <li>当社または第三者の知的財産権等、肖像権、プライバシーの権利、名誉、その他の権利または利益を侵害する行為。</li>
    <li>本サービスの提供または他の利用者様による本サービスの使用の妨害、もしくはそれらに支障をきたす行為。</li>
    <li>法令または公序良俗に反する行為、犯罪行為に関連する行為、犯罪につながると客観的に判断される行為。</li>
    <li>本サービスを、個人的な利用範囲を超えて、商業または営利目的において利用する行為。</li>
    <li>第三者に成りすます行為。</li>
    <li>当社のネットワークまたはシステム等に不正アクセスし、または不正なアクセスを試みる行為。</li>
    <li>その他、当社が合理的な理由に基づき不適切と判断する行為。</li>
  </ul>
  <p>
    2．
    利用者様は本サービスを利用した営業活動を行うことができません。利用者様は、本サービスを利用した営業活動によって当社が損害を被った場合、その損害を賠償しなければなりません。
  </p>
  <p>
    3．
    利用者様は、本サービスの利用権限、その他の利用契約上の地位を第三者に譲渡、贈与もしくは移転できず、また、これを担保として提供することができません。
  </p>

  <!-- #14 -->
  <p class="clause"><strong>第14条（免責）</strong></p>
  <p>
    1．
    当社は、本サービスに不具合、障害等の瑕疵がないこと、および本サービスが中断なく稼動することをなんら保証しません。また、特定の目的に対する適応性、知的財産権等その他の権利の侵害等の不存在に対してもなんら保証しません。当社は、本サービスにいかなる不備があってもそれを回復、訂正等する義務を負いません。
  </p>
  <p>
    2．
    当社は、利用者様が本サービスを使用すること、または、使用できなかったことによって損害、トラブル等が生じた場合であっても、いかなる責任も負いません。また、当社は、利用者様が本サービスを使用することに起因、関連して発生した事故・トラブル、本サービスの利用による利用者様の機器の不具合（コンピュータウイルスの感染を含みます。）、または本サービスに表示される情報およびその変更、更新等により利用者様が損害を被った場合であっても、一切の責任を負いません。
  </p>
  <p>
    3．
    当社は、次に掲げる場合（利用者様の情報の消失、毀損を含みますがこれらに限定されません。）において、利用者様に生じる損害、トラブル等に関して、その原因の如何を問わず、いかなる責任も負いません。
  </p>
  <ul>
    <li>当社が本サービスを変更、または本サービスの提供を中止、または終了した場合。</li>
    <li>
      本サービスの使用により、利用者様の端末、オペレーションシステム、ブラウザ等各種ソフトウェア、その他付属機器に不具合が生じ、または利用者様のデータが消失、毀損等した場合。
    </li>
    <li>
      本サービスにおいて、利用者様同士または利用者様とご葬家との間で法令または公序良俗に反する行為、名誉毀損、侮辱、プライバシー侵害、脅迫、誹謗中傷、いやがらせ等が生じた場合。
    </li>
  </ul>
  <p>
    4．
    当社は、本サービス上に掲載される情報等について、明示または黙示を問わず、その正確性、完全性、最新性、および品質等についてなんら保証しません。また、当社は、本サービスに表示される情報等およびその変更、更新等に関連して、利用者様に生じた一切の損害、トラブルに関していかなる責任も負いません。
  </p>
  <p>
    5．
    当社は、自然災害、ディードス(DDOS)攻撃、クラウド事業者、IDC障害、電気通信事業者の回線障害またはそれに準ずる不可抗力により本サービスが提供できない場合であっても、一切の責任を負いません。
  </p>
  <p>6．当社は、本サービスの仕様に関するご質問には一切お答えできません。</p>
  <p>
    7．当社は、ご葬家と利用者様の間、もしくは利用者様と他の利用者様の間に生じたいかなるトラブル（本サービスのご利用により生じたトラブルも含みます）に対しても、一切の責任を負いません。
  </p>

  <!-- #15 -->
  <p class="clause"><strong>第15条（スマセレ規約等の変更）</strong></p>
  <p>
    スマセレ規約の内容は、当社が必要と判断した場合には、予告なしに変更される場合があります。利用者様は本サービスをご利用の際には、その都度スマセレ規約にご同意いただく必要があります。
  </p>

  <!-- #16 -->
  <p class="clause"><strong>第16条（利用停止、損害賠償）</strong></p>
  <p>
    1．
    利用者様がスマセレ規約に定める事項に違反した場合、または、利用者様が本サービスの利用を継続することで他の利用者様もしくは当社に不利益や損害が発生するおそれがあると当社が判断した場合、当社はなんらの通知を行うことなく直ちにログインを遮断できるものとします。また、利用者様がスマセレ規約に違反した結果、当社が損害を被った場合、利用者様はその損害を負担するものとします。
  </p>
  <p>
    2．
    利用者様に関するメールアドレスが不通になったことが判明した場合、もしくは当社がメールアドレスを利用して連絡が取れないと判断した場合は、なんらの通知を行うことなくお申込みいただいたサービス（本商品のお申し込みを含む）は無効となります。ただし、本商品のお申し込みが正常に行われ、利用者様がご登録されたメールアドレスに対して、当社が注文内容確認のメールを送信したにも関わらず、このメールが届かなかった場合には、本商品の代金はお支払いいただきます。
  </p>
  <p>
    3．
    その他当社が適当と判断した場合は、なんらの通知を行うことなく本サービスの利用停止もしくはログインを遮断いたします。
  </p>

  <!-- #17 -->
  <p class="clause"><strong>第17条（委託および再委託）</strong></p>
  <p>
    　当社は本サービスの提供業務の全部または一部を第三者に委託することができます。また、受託者は受託業務をさらに第三者に再委託することができます。
  </p>

  <!-- #18 -->
  <p class="clause"><strong>第18条（法令等の遵守）</strong></p>
  <p>
    利用者様は、本サービスの使用にあたって、スマセレ規約に加え、関連する法律、政令、省令、条例、規則および命令等を遵守するものとします。
  </p>

  <!-- #19 -->
  <p class="clause"><strong>第19条（準拠法および裁判管轄）</strong></p>
  <p>
    　スマセレ規約は日本法に準拠します。また、当社と利用者様との間で紛争が生じた場合、さいたま地方裁判所を第一審の専属的合意管轄裁判所とします。
  </p>

  <!-- date footer -->
  <p class="text-right w-100" *ngIf="displayForCeremony">2020 年 4 月 14 日　制定</p>
  <p class="text-right w-100" *ngIf="displayForCeremony">2021 年 8 月 1 日　改定</p>
  <p class="text-right w-100" *ngIf="displayForJoin">2020年11月1日 制定</p>
</div>
