import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

import { GlobalStorage } from '../global/storage';

@Injectable({
  providedIn: 'root',
})
export class OrderCheckGuard implements CanActivate {
  constructor(private router: Router, private storage: GlobalStorage) {}

  private isStreamingPasswordValid() {
    return this.storage.StreamingPassword === this.storage.Funeral.streamingPassword;
  }

  private isFuneralIdValid() {
    return this.storage.FuneralId === this.storage.Funeral.funeralId;
  }

  private isMournerEmailValid() {
    return this.storage.Funeral.mourningEmails.some((x) => x === this.storage.MournerEmail);
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const funeralId = this.storage.Funeral.funeralId;
    const payment = this.storage.Payment;

    if (this.storage.MournerEmail) {
      if (this.isMournerEmailValid() && this.isFuneralIdValid()) {
        return true;
      }
    }

    if (this.storage.StreamingPassword) {
      if (this.isStreamingPasswordValid() && this.isFuneralIdValid()) {
        return true;
      }
    }

    if (!payment) {
      this.router.navigate([funeralId, 'payment-check']);
      return false;
    }

    if (payment.funeralId !== funeralId) {
      this.router.navigate([funeralId, 'payment-check']);
      return false;
    }

    return true;
  }
}
