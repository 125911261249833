<div class="guide-wripe">
  <div class="container">
    <div class="card paypal-use-guide font-mincho">
      <div class="card-header">{{ 'paypalProcedure' | i18n }}</div>
      <div class="card-body">
        <div class="row">
          <div class="col-12 col-md-2 mb-2 mb-md-0">
            <div class="guide-step">{{ 'step' | i18n }}<span>1</span></div>
          </div>
          <div class="col-12 col-md-10">
            <div class="guide-title">
              {{ 'paypalStepOneMsg' | i18n }}
            </div>
            <img src="/assets/img/paypal_steps/paypal_step_1.jpg" class="img-fluid" />
          </div>
          <div class="col-12 col-md-2">
            <div class="guide-step">{{ 'step' | i18n }}<span>2</span></div>
          </div>
          <div class="col-12 col-md-10">
            <div class="guide-title">
              {{ 'paypalStepTwoMsg' | i18n }}
            </div>
            <img src="/assets/img/paypal_steps/paypal_step_2.jpg" class="img-fluid" />
          </div>
          <div class="col-12 col-md-2">
            <div class="guide-step">{{ 'step' | i18n }}<span>3</span></div>
          </div>
          <div class="col-12 col-md-10">
            <div class="guide-title">
              {{ 'paypalStepSixFirstMsg' | i18n }}
              <span class="text-danger">{{ 'paypalStepSixMiddleMsg' | i18n }}</span>
              {{ 'paypalStepSixLastMsg' | i18n }}
            </div>
            <img src="/assets/img/paypal_steps/paypal_step_6.jpg" class="img-fluid" />
          </div>
          <div class="col-12 col-md-2">
            <div class="guide-step">{{ 'step' | i18n }}<span>4</span></div>
          </div>
          <div class="col-12 col-md-10">
            <div class="guide-title">
              {{ 'paypalStepSevenMsg' | i18n }}
            </div>
            <img src="/assets/img/paypal_steps/paypal_step_7.jpg" class="img-fluid" />
          </div>
          <div class="col-12 col-md-2">
            <div class="guide-step">STEP{{ 'step' | i18n }}<span>5</span></div>
          </div>
          <div class="col-12 col-md-10">
            <div class="guide-title">
              {{ 'paypalStepEightMsg' | i18n }}
            </div>
            <img src="/assets/img/paypal_steps/paypal_step_8.jpg" class="img-fluid" />
          </div>
          <div class="col-12 col-md-2">
            <div class="guide-step">{{ 'step' | i18n }}STEP<span>6</span></div>
          </div>
          <div class="col-12 col-md-10">
            <div class="guide-title">
              {{ 'paypalStepNineMsg' | i18n }}
            </div>
            <img src="/assets/img/paypal_steps/paypal_step_9.jpg" class="img-fluid" />
          </div>
          <div class="col-12 col-md-2">
            <div class="guide-step">{{ 'step' | i18n }}<span>7</span></div>
          </div>
          <div class="col-12 col-md-10">
            <div class="guide-title">
              {{ 'paypalStepTenMsgFirst' | i18n }}
              {{ 'paypalStepTenMsgMiddle' | i18n }}
              {{ 'paypalStepTenMsgLast' | i18n }}
            </div>
            <img src="/assets/img/paypal_steps/paypal_step_10.jpg" class="img-fluid" />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
