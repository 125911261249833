import { Component, OnInit } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';
import { GlobalStorage } from 'src/app/global/storage';
import { ReceptionCloseService } from 'src/services';
import { StepType } from 'src/types';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-condolence-video',
  templateUrl: './condolence-video.component.html',
  styleUrls: ['./condolence-video.component.scss'],
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [style({ opacity: 0 }), animate('1s ease-out', style({ opacity: 1 }))]),
      transition(':leave', [style({ opacity: 1 }), animate('1s ease-in', style({ opacity: 0 }))]),
    ]),
  ],
})
export class CondolenceVideoComponent implements OnInit {
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private storage: GlobalStorage,
    private receptionCloseService: ReceptionCloseService
  ) {}

  public ngOnInit(): void {}

  public ngAfterViewChecked(): void {
    const receptionStatus = this.receptionCloseService.getReceptionStatus(
      this.storage.Funeral,
      StepType.CondolenceVideo
    );
    const { snapshot } = this.activatedRoute;

    const funeral = this.storage.Funeral;
    if (!funeral.displayCondolenceVideos) {
      if (funeral.displayMoney) {
        this.router.navigate([funeral.funeralId, 'order', StepType.MoneyGift]);
      } else {
        this.router.navigate([funeral.funeralId, 'order', 'confirm']);
      }
      return;
    }

    if (snapshot.children[0].routeConfig.path.length && receptionStatus.isFuneralClosed) {
      this.router.navigate(['../cards'], { relativeTo: this.activatedRoute });
    }
  }
}
