<div class="album-container">
  <div *ngFor="let item of album; let index = index" class="album-item">
    <div class="media">
      <img
        *ngIf="!(item?.media | isS3ObjectVideo); else video"
        oncontextmenu="return false;"
        class="media-thumbnail"
        [src]="item?.media | getS3Url"
        alt="thumbnail"
        (click)="openModal(content, index)"
      />
      <ng-template #video>
        <video
          oncontextmenu="return false;"
          muted
          class="video-thumbnail"
          [src]="item?.media | getS3Url"
          (click)="openModal(content, index)"
        ></video>
        <img class="play-button" src="assets/svg/play.svg" alt="play" (click)="openModal(content, index)" />
      </ng-template>
    </div>
    <span class="media-footer">{{ isTypeMemorialShare ? '拡大表示' : '再生' }}</span>
    <span *ngIf="item.lastName && item.firstName" class="media-name"
      >{{ item?.lastName }}　{{ item?.firstName }}　{{ 'nameSuffix' | i18n
      }}{{ isTypeMemorialShare ? 'より' : '' }}</span
    >
  </div>
</div>

<ng-template #content let-modal>
  <div class="modal-container">
    <!-- left arrow -->
    <img
      *ngIf="showPreviousImageButton"
      src="assets/svg/back.svg"
      alt=""
      class="arrow left-arrow"
      (click)="previousImage()"
    />
    <div class="main-container">
      <div class="image-container">
        <img
          *ngIf="!(album[currentItemIndex]?.media | isS3ObjectVideo); else video"
          oncontextmenu="return false;"
          class="media-thumbnail"
          [src]="album[currentItemIndex].media | getS3Url"
          alt="thumbnail"
        />
        <ng-template #video>
          <video
            muted
            controls
            controlsList="nodownload"
            oncontextmenu="return false;"
            class="video-thumbnail"
            [src]="album[currentItemIndex].media | getS3Url"
          ></video>
        </ng-template>
      </div>

      <!-- memorial share -->
      <div *ngIf="isTypeMemorialShare; else condolenceVideo" class="memorial-share-container">
        <span *ngIf="album[currentItemIndex].lastName && album[currentItemIndex].firstName" class="memorial-share-name"
          >{{ album[currentItemIndex].lastName }}　{{ album[currentItemIndex].firstName }}　{{ 'nameSuffix' | i18n
          }}{{ 'より' }}</span
        >
        <span>{{ today }}　{{ 'contributedIn' | i18n }}</span>
      </div>

      <div class="comment-container" *ngIf="album[currentItemIndex].description">
        <span>{{ album[currentItemIndex].description }}</span>
      </div>

      <!-- condolence video -->
      <ng-template #condolenceVideo>
        <div class="condolence-video-container">
          <span *ngIf="album[currentItemIndex].cardSenderName" class="video-sender"
            >{{ 'sender' | i18n }}：{{ album[currentItemIndex].cardSenderName }}　{{ 'nameSuffix' | i18n }}</span
          >
          <br />
          <span *ngIf="album[currentItemIndex].customName"
            ><span>{{ 'customName' | i18n }}：</span>{{ album[currentItemIndex].customName }}　{{
              'nameSuffix' | i18n
            }}</span
          >
          <span *ngIf="album[currentItemIndex].company">{{ album[currentItemIndex].company }}</span>
          <span *ngIf="album[currentItemIndex].position">{{ album[currentItemIndex].position }}</span>
          <br />
          <span *ngIf="album[currentItemIndex].cardDestinationName"
            ><span>{{ 'destinationName' | i18n }}：</span>{{ album[currentItemIndex].cardDestinationName }}　{{
              'nameSuffix' | i18n
            }}</span
          >
        </div>
        <div class="card-message-container" *ngIf="album[currentItemIndex].cardMessage">
          <span>{{ album[currentItemIndex].cardMessage }}</span>
        </div>
      </ng-template>

      <div class="modal-footer">
        <button id="modal-return-button" class="modal-return-button" (click)="modal.dismiss('Cross click')">
          {{ 'return' | i18n }}
        </button>
        <span *ngIf="isTypeMemorialShare"
          >{{ itemNumber }}{{ 'photosCounter' | i18n }} / {{ totalItemsNumber }}{{ 'photosCounter' | i18n }}</span
        >
      </div>
    </div>
    <!-- right arrow -->
    <img *ngIf="showNextImageButton" src="assets/svg/next.svg" alt="" class="arrow right-arrow" (click)="nextImage()" />
  </div>
</ng-template>
