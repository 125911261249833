import { Injectable } from '@angular/core';

import { BaseService } from './base.service';

import { Card } from 'src/models/Card';
import { ProductType } from '@kokusai/smacere-shared/api';

@Injectable()
export class CardService extends BaseService {
  gets(): Card[] {
    return this.productStorage.getsByType(ProductType.Card).map(
      (product) =>
        new Card({
          id: product.id,
          sku: product.sku,
          picture: product.picture,
          name: product.name,
          nameKana: product.nameKana,
          price: product.price,
          cardImageOpen: product.attributes?.cardImageOpen,
          cardMaterialType: product.attributes?.cardMaterialType,
          cardOpenSide: product.attributes?.cardOpenSide,
        })
    );
  }
}
