import { Injectable } from '@angular/core';
import { Funeral } from 'src/models';
import { environment } from 'src/environments/environment';
import { GlobalStorage } from '../global/storage';

@Injectable({
  providedIn: 'root',
})
export class DisplayElementsService {
  funeral: Funeral = null;
  constructor(private storage: GlobalStorage) {
    this.funeral = this.storage.Funeral;
  }

  get displayFlower() {
    return environment.modules.displayFlower && this.funeral?.displayFlower;
  }

  get displayCard() {
    return environment.modules.displayCard && this.funeral?.displayCard;
  }

  get displayCondolenceVideos() {
    return environment.modules.displayCondolenceVideos && this.funeral?.displayCondolenceVideos;
  }

  get displayMoney() {
    return environment.modules.displayMoney && this.funeral?.displayMoney;
  }

  get displayMemorialShare() {
    return environment.modules.displayMemorialShare && this.funeral?.displayMemorialShare;
  }
}
