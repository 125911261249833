<div class="modal-header no-border">
  <a href="javascript:void(0);" class="modal-close-icon" (click)="activeModal.close()"></a>
</div>
<div class="flower-name-sample-body modal-body">
  <p class="text-center">
    {{ 'flowerNameSample' | i18n }}
  </p>
  <div class="text-center">
    <img class="img-fluid" src="/assets/img/flowers/name-sample.svg" alt="お札名サンプル" />
  </div>
</div>
