import { environment } from 'src/environments/environment';
import { MediaObject, MediaObjectInput, S3Object } from '@kokusai/smacere-shared/api';

export class Path {
  static getPath(fileName: string): string {
    return `/assets/tenant/${environment.tenant}/${fileName}`;
  }

  static getS3Path(object: MediaObjectInput | MediaObject): string {
    return `https://${object.file.bucket}.s3.${object.file.region}.amazonaws.com/${object.file.key}`;
  }

  static getImagePathFromAWS(media: S3Object) {
    return `https://${media.bucket}.s3.${media.region}.amazonaws.com/${media.key}`;
  }
}
