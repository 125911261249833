<div class="container">
  <h4 class="page-header">{{ 'incense' | i18n }}</h4>
  <h5 class="text-center">{{ 'incenseAmount' | i18n }}</h5>
</div>

<div id="select-money">
  <div class="container">
    <div class="btn-group btn-group-toggle price-list" data-toggle="buttons">
      <label
        class="btn price-label"
        [class]="{ active: item.id == selectedMoneyGift?.id }"
        *ngFor="let item of moneyGift; let i = index"
      >
        <input
          id="{{ 'gift-money-' + (i + 1) }}"
          type="radio"
          name="money"
          [value]="item"
          [checked]="item.id == selectedMoneyGift?.id"
          (change)="choosePrice(item)"
        />
        {{ showButtonPrice(item) }}
      </label>
      <div class="custom-gift-money-container">
        <label for="custom-gift-money" class="custom-gift-money-label-container">
          <p style="margin: unset">{{ 'moreThanTenMan' | i18n }}</p>
          <p style="margin: unset; font-size: 10px">{{ 'fiveManIsMinimumStep' | i18n }}</p>
        </label>
        <div class="custom-gift-money-value-container">
          <kendo-dropdownlist
            id="custom-gift-money"
            name="custom-gift-money"
            class="custom-gift-money-input"
            [defaultItem]="customMoneyGiftDefaultValue"
            [textField]="'text'"
            [valueField]="'value'"
            [data]="customMoneyGiftList"
            (valueChange)="chooseCustomPrice($event)"
          ></kendo-dropdownlist>
          <span class="custom-gift-money-unit">円</span>
        </div>
      </div>
    </div>
    <!-- custom amount -->
    <div class="col-12 text-right total-sum-area">
      <span id="total-sum"
        ><small>{{ 'moneyGiftTotalAmount' | i18n }}</small> {{ getTotalPrice() | number }}円</span
      >
    </div>
  </div>
</div>
<!-- moneyGiftMessage -->
<div class="container mt-3 message-container">
  <form class="message-form">
    <!-- family only -->
    <div class="form-group row">
      <label class="col-md-12" for="family-only"
        ><input
          type="radio"
          id="family-only"
          name="moneyGiftRecipient"
          class="mr-2"
          value="family-only"
          [(ngModel)]="attributes.moneyGiftRecipient"
        />{{ 'family-only' | i18n }}
      </label>
      <div class="row col-md-12 ml-1">
        <!-- moneyGiftRelationship -->
        <div class="relationship-container form-item">
          <label class="relationship-label" for="moneyGiftRelationship">{{ 'moneyGiftRelationship' | i18n }}: </label>
          <!-- <kendo-combobox
            id="moneyGiftRelationship"
            name="moneyGiftRelationship"
            class="form-combobox"
            MaxLength="20"
            [allowCustom]="true"
            [data]="moneyGiftRelationshipData"
            [(ngModel)]="attributes.moneyGiftRelationship"
          >
          </kendo-combobox> -->
          <kendo-dropdownlist
            id="moneyGiftRelationship"
            name="moneyGiftRelationship"
            class="col-lg-7 col-md-7"
            placeholder="選択してください"
            [data]="moneyGiftRelationshipData"
            [(ngModel)]="attributes.moneyGiftRelationship"
          >
          </kendo-dropdownlist>
        </div>
        <!-- moneyGiftYearsKnown -->
        <div class="years-known-container form-item">
          <label class="years-known-label" for="moneyGiftYearsKnown">{{ 'moneyGiftYearsKnown' | i18n }}: </label>
          <div class="years-known-dropdown-container">
            <kendo-dropdownlist
              id="moneyGiftYearsKnown"
              name="moneyGiftYearsKnown"
              class="years-known-dropdown"
              placeholder="選択してください"
              [data]="moneyGiftYearsKnownData"
              [(ngModel)]="attributes.moneyGiftYearsKnown"
            >
            </kendo-dropdownlist>
            <span class="year-suffix">年</span>
          </div>
        </div>
      </div>
    </div>
    <!-- everyone -->
    <div class="form-group row">
      <label class="col-md-12" for="everyone"
        ><input
          type="radio"
          id="everyone"
          name="moneyGiftRecipient"
          class="mr-2"
          value="everyone"
          [(ngModel)]="attributes.moneyGiftRecipient"
        />{{ 'everyone' | i18n }}
      </label>
    </div>
    <!-- moneyGiftMessage -->
    <div class="form-group row">
      <label class="col-md-12" for="moneyGiftMessage">{{ 'moneyGiftMessageLabel' | i18n }}</label>
      <textarea
        id="moneyGiftMessage"
        name="moneyGiftMessage"
        class="col-md-12 form-textarea"
        rows="5"
        [style.resize]="'none'"
        [(ngModel)]="attributes.moneyGiftMessage"
      ></textarea>
    </div>
    <div
      *ngIf="displayMemorialShare"
      class="form-group row d-flex justify-content-around align-items-center message-footer"
    >
      <div class="d-flex align-items-center">
        <img class="mr-2" src="assets/svg/image-icon.svg" alt="" width="35" height="35" />
        <div class="d-flex flex-column">
          <span>{{ 'memorialShareNote1' | i18n }}</span>
          <span style="font-size: x-small">（{{ 'memorialShareNote2' | i18n }}） </span>
        </div>
      </div>
      <button type="button" class="memorial-share-button" (click)="goToMemorialShare()">
        {{ 'memorialShare' | i18n }}
      </button>
    </div>
  </form>
</div>
<!-- buttons -->
<div class="buttons-container">
  <button id="return-button" class="nav-button return-button" (click)="back()">
    <!-- {{ 'return' | i18n }} -->
    {{ returnButtonLabel }}
  </button>
  <button id="next-button" class="nav-button next-button" (click)="save()">
    {{ 'decideAndNext' | i18n }}
  </button>
</div>
<!-- <div class="presents-container">
  <h4 class="text-center" *ngIf="IsCorporate">
    <br />
    <b>{{ 'presentDeliveryWarning' | i18n }}</b>
  </h4>
  <div id="mone-present-header" *ngIf="DisplayMoneyPresent">
    <h4 class="page-header">{{ 'offeringPresent' | i18n }}</h4>
    <h5 class="text-center">
      {{ 'moneyGiftSelectionMsg' | i18n }}
    </h5>
    <h5 class="text-center">
      <u>{{ 'shippingWarning' | i18n }}</u>
    </h5>
    <h5 class="text-center bold">
      <b
        ><u>{{ 'moneyGiftSelectMsgWarning' | i18n }}</u></b
      >
    </h5>
  </div>
  <div id="money-present-body" *ngIf="DisplayMoneyPresent">
    <app-gift-money-present [amount]="getMoneyGiftAmount" [(ngModel)]="selectedMoneyPresent"></app-gift-money-present>
  </div>
  <div id="money-food-header" *ngIf="DisplayFood" class="mt-5" [hidden]="!HasFood">
    <h4 class="page-header">{{ 'moneyFood' | i18n }}</h4>
    <h5 class="text-center">
      {{ 'moneyGiftFoodSelectionMsg' | i18n }}
    </h5>
  </div>
  <div id="money-present-body" *ngIf="DisplayFood" [hidden]="!HasFood">
    <app-gift-money-food
      (onChanged)="OnFoodSelectionChanged($event)"
      [(ngModel)]="selectedFood"
      [sum]="getMoneyGiftAmount"
    ></app-gift-money-food>
  </div>
</div> -->
<!-- <div class="container">
  <div class="btn-toolbar justify-content-between mt-5 mb-5" role="toolbar">
    <button id="gift-money-select-return-button" class="btn btn-outline-secondary" (click)="back()">
      {{ 'return' | i18n }}
    </button>
    <button id="gift-money-select-decide-and-next-button" class="btn btn-secondary" (click)="save()">
      {{ 'decideAndNext' | i18n }}
    </button>
  </div>
</div> -->
