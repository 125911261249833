import { BaseProductOrder } from './BaseProductOrder';
import { Address } from './Address';
import { AccessLevel } from 'src/types/access-level';
import { CondolenceVideo } from './CondolenceVideo';

export class CondolenceVideoOrder extends BaseProductOrder<CondolenceVideo> {
  constructor() {
    super();
    this.DestinationLastName = '';
    this.DestinationFirstName = '';
    this.SenderLastName = '';
    this.SenderLastNameKana = '';
    this.SenderFirstName = '';
    this.SenderFirstNameKana = '';
    this.SenderCompany = '';
    this.SenderPosition = '';
    this.attributes = { customName: '' };
    this.Thumbnail = null;
    this.UploadedVideo = null;
    this.AccessLevel = AccessLevel.Public;
    this.Comment = '';
    this.IsDestinationSame = true;
    this.isCustomerAddressSame = true;
    this.useMaidenName = false;
    this.ShippingAddress = new Address();
    this.CustomerAddress = new Address();
    this.useMaidenName = false;
  }

  DestinationLastName: string | null;
  DestinationFirstName: string | null;
  Thumbnail: string | null;
  UploadedVideo: string | null;
  AccessLevel: AccessLevel | null;
  Comment?: string | null;
  SenderCompany?: string | null;
  SenderPosition?: string | null;
  SenderLastName?: string | null;
  SenderLastNameKana?: string | null;
  SenderFirstName?: string | null;
  SenderFirstNameKana?: string | null;
  IsDestinationSame: boolean;
  isCustomerAddressSame: boolean;
  useMaidenName: boolean;
  ShippingAddress?: Address | null;
  CustomerAddress?: Address | null;
}
