import { PaymentActive } from 'src/models/paymentActive';
import { PaymentMethodType } from 'src/types';
import { dictionary } from 'src/app/core/settings/tenant/ceremony/i18n/japanese.dictionary';
import { EnvironmentConfig } from 'src/environments/type';

export const defaults: EnvironmentConfig = {
  env: '',
  production: false,
  tenant: 'ceremony',
  tenantEmail: 'ceremony.jp',
  showFooter: true,
  corporate: false,
  taxRate: 10,
  funeralDaysOff: 7,
  moneyDaysOff: 3,
  moneyGiftMaxAmount: 500000,
  moneyGiftIncrementAmount: 50000,
  condolenceVideoAutoplay: true,
  condolenceVideoPauseSeconds: 2,
  google: {
    googleKey: 'AIzaSyBVkJJ9C_vO0TJjUhFNgPQAkN0di2C7bwY',
    googleAnalytics: 'UA-172162263-1',
  },
  payment: {
    payPalType: 'sandbox',
    paymentDefault: PaymentMethodType.Paypal, // should be part of Configuration instead.
    paymentMethods: [new PaymentActive(PaymentMethodType.Paypal, 'PayPal', true)],
  },
  aws: {
    lamdaAccessKeyId: 'AKIAX5Y7A265EEPCFLOC',
    lamdaSecretAccessKey: '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',
  },
  dictionary: dictionary,
  modules: {
    displayFlower: true,
    displayFlowerPresent: true,
    displayCard: true,
    displayCardPresent: true,
    displayMoney: true,
    displayMoneyPresent: true,
    displayFood: true,
    displayStreaming: true,
    displayMemorialShare: true,
    displayCondolenceVideos: true,
    displayFuneralDate: true,
    displayFuneralLocation: true,
  },
};
