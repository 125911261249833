import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { GlobalStorage } from 'src/app/global/storage';
import { BundleCartService } from 'src/app/services/bundle-cart.service';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss'],
})
export class ConfirmComponent implements OnInit {
  constructor(private storage: GlobalStorage, private router: Router, private bundleCartService: BundleCartService) {}

  public ngOnInit(): void {
    this.bundleCartService.resetCurrentStep();
    this.bundleCartService.reset();

    const OrderSteps = this.storage.OrderSteps;
    OrderSteps.isOrderConfirmationActive = true;
    this.storage.OrderSteps = OrderSteps;

    window.scrollTo(0, 0);
  }

  public async next(): Promise<void> {
    if (this.bundleCartService.getBundles().length === 0) {
      await Swal.fire({
        icon: 'error',
        title: 'お申込み内容確認画面に移動できません',
        text: '1点以上のお申込が必要です。',
      });
      return;
    }

    await this.router.navigate([this.storage.Funeral.funeralId, 'order', 'checkout', 'billing']);
  }
}
