import deepmerge from 'deepmerge';
import { tenantConfiguration } from 'src/environments/tenant/ceremony';
import { EnvironmentConfig } from 'src/environments/type';

export const environment: EnvironmentConfig = deepmerge(tenantConfiguration, {
  production: false,
  env: 'dev',
  payment: {
    payPalKey: 'AQj523bFobtQLGz1ivGRfS4JoHuKG_O1QrOTsRr5cxiok6W09xkB5nRfMTts2v-iKatKiqVCXFH0wGcd',
  },
});
