<div id="stream">
  <div class="stream-header row m-0">
    <div class="col-3">
      <a [routerLink]="['/', Funeral.funeralId]">
        <img src="{{ 'logo.png' | path }}" class="media-logo img-fluid" />
      </a>
    </div>
    <div class="stream-period col-9"></div>
  </div>
</div>
