import { Injectable } from '@angular/core';

import { BaseService } from './base.service';

import { Paper } from 'src/models/Paper';
import { ProductType } from '@kokusai/smacere-shared/api';

@Injectable()
export class PaperService extends BaseService {
  gets() {
    return this.productStorage.getsByType(ProductType.Paper).map(
      (product) =>
        new Paper({
          id: product.id,
          sku: product.sku,
          picture: product.picture,
          name: product.name,
          price: product.price,
          paperSize: product.attributes.paperSize,
        })
    );
  }
}
