import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import Swal from 'sweetalert2';

import { GlobalStorage } from 'src/app/global/storage';

import { ReceptionCloseService } from 'src/services';

import { Funeral } from 'src/models';

import { StepType } from 'src/types';
import { BundleCartService, BundleType } from '../../../../services/bundle-cart.service';

@Component({
  selector: 'app-gift-money-home',
  templateUrl: './money-gift-home.component.html',
  styleUrls: ['./money-gift-home.component.scss'],
})
export class MoneyGiftHomeComponent implements OnInit {
  constructor(
    private storage: GlobalStorage,
    private route: Router,
    private receptionCloseService: ReceptionCloseService,
    private bundleCartService: BundleCartService
  ) {}

  Funeral: Funeral;
  funeralCloseDateTime: Date;
  isFuneralClosed: boolean;
  displaySkipButton: boolean = false;

  ngOnInit(): void {
    this.bundleCartService.resetCurrentStep();
    this.Funeral = this.storage.Funeral;

    // funeral-closed
    const receptionStatus = this.receptionCloseService.getReceptionStatus(this.Funeral, StepType.MoneyGift);
    this.funeralCloseDateTime = receptionStatus.funeralCloseDateTime;
    this.isFuneralClosed = receptionStatus.isFuneralClosed;

    this.bundleCartService.hasNextStep(StepType.MoneyGift).then((hasNextStep) => {
      this.displaySkipButton = hasNextStep || !this.bundleCartService.isEmpty();
    });
  }

  async next(): Promise<void> {
    await this.bundleCartService.goToAdd(BundleType.GiftMoney);
  }

  async skip(): Promise<void> {
    if (!this.displaySkipButton) {
      await Swal.fire({
        icon: 'error',
        title: 'お申込み内容確認画面に移動できません',
        text: '1点以上のお申込が必要です。',
      });
      return;
    }

    const order = this.storage.Order;
    order.MoneyGiftOrder = undefined;
    order.MoneyPresent = undefined;
    order.MoneyFood = undefined;
    this.storage.Order = order;

    const steps = this.storage.OrderSteps;
    steps.isMoneyGiftStepCompleted = true;
    this.storage.OrderSteps = steps;

    await this.bundleCartService.goToNextStep(BundleType.GiftMoney);
  }
}
