import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { onAuthUIStateChange, CognitoUserInterface, AuthState } from '@aws-amplify/ui-components';

@Component({
  selector: 'app-member-login-form',
  templateUrl: './member-login-form.component.html',
  styleUrls: ['./member-login-form.component.scss'],
})
export class MemberLoginFormComponent implements OnInit {
  // public isLoggedIn: boolean = false;
  title = 'amplify-angular-auth';
  user: CognitoUserInterface | undefined;
  authState: AuthState;

  constructor(private cdRef: ChangeDetectorRef, private authService: AuthService) {}

  ngOnInit(): void {
    onAuthUIStateChange((authState, authData) => {
      this.authState = authState;
      this.user = authData as CognitoUserInterface;
      this.cdRef.detectChanges();
    });

    // this.authService.isLoggedIn.subscribe((value) => {
    //   this.isLoggedIn = value;
    //   this.cdRef.detectChanges();
    // });
    // this.isLoggedIn = this.authService.isLoggedIn.getValue();
    // this.cdRef.detectChanges();
  }

  ngOnDestroy() {
    return onAuthUIStateChange;
  }

  // async signOut() {
  //   await this.authService.signOut();
  // }
}
