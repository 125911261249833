<div class="paypal-processing" *ngIf="displayProcessing">
  <div>
    <div class="spinner-border spinner-border-sm" role="status"></div>
    <span>{{ 'pleaseWaitMsg' | i18n }}</span>
  </div>
</div>
<div *ngIf="Order.PaymentMethod !== PaymentType.paypal">
  <!-- billing info -->
  <div class="billing-info">
    <h4 class="billing-info-header">
      {{ 'detailsOfPaymentInfo' | i18n }}
    </h4>
    <div class="billing-info-contents">
      <div class="item-container">
        <span class="item-label">{{ 'name' | i18n }}</span>
        <span class="item-value">{{ Order.BillingAddress.lastName }}　{{ Order.BillingAddress.firstName }}</span>
      </div>
      <div class="item-container">
        <div class="item-label">
          <span class="item-label-left address">{{ 'address' | i18n }}</span
          ><span class="item-label-right">〒</span>
        </div>
        <span class="item-value">{{ Order.BillingAddress.postalCode }}</span>
      </div>
      <div class="item-container">
        <div class="item-label">
          <span class="item-label-left"></span><span class="item-label-right">{{ 'prefecture' | i18n }}</span>
        </div>
        <span class="item-value">{{ Order.BillingAddress.prefecture }}</span>
      </div>
      <div class="item-container">
        <div class="item-label">
          <span class="item-label-left"></span><span class="item-label-right">{{ 'city' | i18n }}</span>
        </div>
        <span class="item-value">{{ Order.BillingAddress.city }}</span>
      </div>
      <div class="item-container">
        <div class="item-label">
          <span class="item-label-left"></span><span class="item-label-right">{{ 'streetNumber' | i18n }}</span>
        </div>
        <span class="item-value">{{ Order.BillingAddress.street }}</span>
      </div>
      <div class="item-container">
        <div class="item-label">
          <span class="item-label-left"></span><span class="item-label-right">{{ 'buildingNumber' | i18n }}</span>
        </div>
        <span class="item-value">{{ Order.BillingAddress.building }}</span>
      </div>
      <div class="item-container">
        <div class="item-label">
          <span class="item-label-left"></span><span class="item-label-right">{{ 'companyNameShort' | i18n }}</span>
        </div>
        <span class="item-value">{{ Order.BillingAddress.company }}</span>
      </div>
      <div class="item-container">
        <span class="item-label">{{ 'phone' | i18n }}</span>
        <span class="item-value">{{ Order.BillingAddress.telephone | phoneNumber }}</span>
      </div>
      <div class="item-container">
        <span class="item-label">{{ 'email' | i18n }}</span>
        <span class="item-value">{{ Order.BillingAddress.email }}</span>
      </div>
      <div class="item-container">
        <span class="item-label">{{ 'paymentMethod' | i18n }}</span>
        <span class="item-value">{{ Order.PaymentMethod | i18n }}</span>
      </div>
    </div>
  </div>

  <!-- other payment -->
  <div class="other-payment-container">
    <button class="confirm-button" (click)="placeCashOrder()">{{ 'confirmOrderDetails' | i18n }}</button>
  </div>
</div>

<!-- Payment Method -->
<div *ngIf="Order.PaymentMethod === PaymentType.paypal" class="bg-grey pt-2">
  <div class="container">
    <div class="col-md-10 ml-auto mr-auto">
      <h4 class="page-header font-mincho">
        {{ 'paymentScreen' | i18n }}
      </h4>
      <p class="text-center">
        {{ 'paymentScreenHeaderOne' | i18n }}
        <br />
        {{ 'paymentScreenHeaderTwo' | i18n }}
      </p>
      <p class="text-center">
        <small>
          {{ 'paymentScreenSubHeaderOne' | i18n }}
          <br />
          {{ 'paymentScreenSubHeaderTwo' | i18n }}
          <a href="https://www.paypal.com/jp/home" target="_blank">{{ 'here' | i18n }}</a>
        </small>
      </p>
    </div>

    <div [ngSwitch]="PaymentMethod">
      <div *ngSwitchCase="PaymentMethodType.Paypal" class="text-center">
        <paypal-button
          id="checkout-paypal-button"
          [OnCreateOrder]="OnPayPalCreateOrder"
          [OnApproved]="OnPayPalApproved"
          [onCancel]="OnPayPalCancel"
          [onError]="OnPayPalError"
        ></paypal-button>
        <span class="spinner-border spinner-border-sm mb-3" role="status" *ngIf="isCreatingOrder"></span>

        <paypal-guide></paypal-guide>

        <paypal-button
          id="checkout-paypal-button-bottom"
          [OnCreateOrder]="OnPayPalCreateOrder"
          [OnApproved]="OnPayPalApproved"
          [onCancel]="OnPayPalCancel"
          [onError]="OnPayPalError"
        ></paypal-button>
      </div>
    </div>
  </div>
  <div [@inOutAnimation] *ngIf="displayBackButton">
    <div class="container">
      <div class="btn-toolbar justify-content-between mt-2 mb-5" role="toolbar">
        <button id="checkout-return-button-2" class="btn btn-outline-secondary" (click)="Back()">
          {{ 'return' | i18n }}
        </button>
      </div>
    </div>
  </div>
</div>

<!-- buttons -->
<div class="buttons-container">
  <button id="return-button" class="nav-button return-button" (click)="return()">
    {{ 'return' | i18n }}
  </button>
</div>
<!-- <div class="container">
  <div class="btn-toolbar justify-content-between mt-2 mb-5" role="toolbar">
    <button id="checkout-return-button" class="btn btn-outline-secondary" (click)="return()">
      {{ 'return' | i18n }}
    </button>
  </div>
</div> -->
