import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { GlobalStorage } from 'src/app/global/storage';
import { Funeral } from 'src/models/Funeral';
import { Order } from 'src/models';
import { AddressType, APIService } from '@kokusai/smacere-shared/api';
import { AuthService } from 'src/app/services/auth.service';
import { Auth } from 'aws-amplify';
import { AddressComponent } from '../../../components/address/address.component';
import { environment } from 'src/environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { I18n } from '@aws-amplify/core';
import Swal from 'sweetalert2';
import { BundleCartService } from '../../../services/bundle-cart.service';
import { ViewType } from 'src/app/components/address/types/view-type.type';
import { onAuthUIStateChange, AuthState } from '@aws-amplify/ui-components';
import { BusinessCardApiDataFormat } from '../../../components/card-upload-form/card-upload-form.component';
import { FooterSmacereTOSComponent } from 'src/app/shared/footer/footer-smacere-tos/footer-smacere-tos.component';

@Component({
  selector: 'app-guest-book',
  templateUrl: './guest-book.component.html',
  styleUrls: ['./guest-book.component.scss'],
})
export class GuestBookComponent implements OnInit {
  funeral: Funeral = null;
  Order: Order;
  isSubmit: boolean = false;
  AddressType = AddressType;
  showSmacereSection: boolean;
  disableNameAndEmail: boolean = false;
  ViewType = ViewType;
  authState: AuthState;

  @ViewChild('customerForm') customerForm: AddressComponent;
  @ViewChild('shippingForm') shippingForm: AddressComponent;

  constructor(
    private router: Router,
    private storage: GlobalStorage,
    private authService: AuthService,
    private apiService: APIService,
    private cdRef: ChangeDetectorRef,
    private modalService: NgbModal,
    private bundleCartService: BundleCartService
  ) {}

  ngOnInit(): void {
    this.Order = this.storage.Order;
    this.funeral = this.storage.Funeral;

    onAuthUIStateChange((authState, authData) => {
      this.authState = authState;
      this.cdRef.detectChanges();
    });

    this.authService.isLoggedIn.subscribe((loggedIn) => {
      if (loggedIn) {
        this.showSmacereSection = false;
        this.cdRef.detectChanges();

        Auth.currentUserInfo().then((user) => {
          this.apiService.GetCustomer(user.username).then((customer) => {
            let addressUpdated = false;
            if (customer?.address) {
              for (const [key, value] of Object.entries(customer?.address ?? {})) {
                this.Order.BillingAddress[key] = value;
                this.Order.CustomerAddress[key] = value;
              }
              this.Order.CustomerAddress.email = customer.email;
              this.Order.CustomerAddress.confirmEmail = customer.email;
              this.disableNameAndEmail = true;
              addressUpdated = true;
            }

            if (this.displayShippingAddress && customer?.shippingAddress) {
              const shippingAddress = customer?.shippingAddress;
              if (shippingAddress.hasOwnProperty('__typename')) {
                delete shippingAddress.__typename;
              }
              const customerShippingAddress = Object.values(customer?.shippingAddress);
              if (customerShippingAddress.length > 0 && customerShippingAddress.some((item) => Boolean(item))) {
                this.Order.IsDestinationSame = false;
                for (const [key, value] of Object.entries(customer?.shippingAddress ?? {})) {
                  this.Order.ShippingAddress[key] = value;
                }
                this.cdRef.detectChanges();
                addressUpdated = true;
              }
            }

            if (addressUpdated) {
              this.storage.Order = this.Order;
              this.customerForm.refreshData(true);
              if (this.displayShippingAddress) {
                this.shippingForm.refreshData(true);
              }
            }
          });

          if (sessionStorage.getItem('sameDayFuneralNoticeSeen') !== this.funeral.funeralDate) {
            this.apiService.OrderByCustomerId(user.username, { eq: this.funeral.funeralDate }).then((orders) => {
              const previousOrders = orders.items.filter((order) => order.funeralId !== this.funeral.funeralId);
              if (previousOrders.length === 0) {
                return;
              }

              const messageParameters = {
                previousFuneralName: previousOrders[0].funeral.funeralName,
                currentFuneralName: this.funeral.funeralName,
              };
              Swal.fire({
                icon: 'warning',
                title: I18n.get('doubleBookingAlertTitle').replace(
                  /{([a-zA-Z]+)}/g,
                  (_, match) => messageParameters[match] ?? ''
                ),
                html: I18n.get('doubleBookingAlertMessage').replace(
                  /{([a-zA-Z]+)}/g,
                  (_, match) => messageParameters[match] ?? ''
                ),
              }).then(() => {
                sessionStorage.setItem('sameDayFuneralNoticeSeen', this.funeral.funeralDate);
              });
            });
          }
        });
      } else {
        this.showSmacereSection = true;
        this.disableNameAndEmail = false;

        this.storage.resetStorageOrder();
        this.Order = this.storage.Order;
        this.customerForm?.resetData();
        if (this.displayShippingAddress) {
          this.shippingForm?.resetData();
        }

        this.cdRef.detectChanges();

        if (this.storage.Order?.BillingAddress) {
          this.Order = this.storage.Order;
        }
      }
    });
  }

  ngOnDestroy() {
    return onAuthUIStateChange;
  }

  private goToOrder(): void {
    const steps = this.storage.OrderSteps;
    steps.isGuestBookStepCompleted = true;
    this.storage.OrderSteps = steps;

    this.bundleCartService.goToNextStep();
  }

  capitalize(value: string) {
    return value[0].toUpperCase() + value.slice(1);
  }

  next(customerForm: NgForm, shippingForm: NgForm): void {
    this.isSubmit = true;
    // check customer form
    const controls = Object.entries(customerForm.controls);
    if (customerForm.invalid) {
      for (const [key, control] of controls) {
        if (control.invalid) {
          document.getElementById(`customer${this.capitalize(key)}`)?.focus();
          return;
        }
      }
      return;
    }
    for (const [key, control] of controls) {
      this.Order.CustomerAddress[key] = key === 'postalCode' ? control.value.postalCode : control.value;
      this.Order.BillingAddress[key] = key === 'postalCode' ? control.value.postalCode : control.value;
    }

    if (this.displayShippingAddress) {
      // check shipping form
      const shippingControls = Object.entries(shippingForm.controls);
      if (!this.Order.IsDestinationSame) {
        if (shippingForm.invalid) {
          for (const [key, control] of shippingControls) {
            if (control.invalid) {
              document.getElementById(`shipping${this.capitalize(key)}`)?.focus();
              return;
            }
          }
          return;
        }
        for (const [key, control] of shippingControls) {
          this.Order.ShippingAddress[key] = key === 'postalCode' ? control.value.postalCode : control.value;
        }
      }
    }

    this.storage.Order = this.Order;
    this.goToOrder();
  }

  return(): void {
    this.router.navigate([this.funeral.funeralId]);
  }

  get env(): string {
    return environment.env;
  }

  public OpenTOSModal() {
    this.modalService.open(FooterSmacereTOSComponent, { windowClass: 'common-modal' });
  }

  get displayShippingAddress(): boolean {
    return (
      this.funeral.displayFlowerPresent ||
      this.funeral.displayCardPresent ||
      this.funeral.displayMoneyPresent ||
      this.funeral.displayFood
    );
  }

  public dataFromBusinessCard(data: BusinessCardApiDataFormat) {
    let refresh = false;
    const address = this.Order.CustomerAddress;

    if (data.address) {
      refresh = true;
      address.postalCode = '';
      address.prefecture = '';
      address.city = '';
      address.street = '';
      address.building = '';

      if (data.address.postal_code) {
        address.postalCode = data.address.postal_code;
      }
      if (data.address.broad_region) {
        address.prefecture = data.address.broad_region;
      }

      const city = [];
      if (data.address.narrow_region) {
        city.push(data.address.narrow_region);
      }
      if (data.address.locality) {
        city.push(data.address.locality);
      }
      if (data.address.sublocality) {
        city.push(data.address.sublocality);
      }
      if (city.length > 0) {
        address.city = city.join('');
      }

      const street = [];
      if (data.address.street_name) {
        street.push(data.address.street_name);
      }
      if (data.address.street_number) {
        street.push(data.address.street_number);
      }
      if (street.length > 0) {
        address.street = street.join('');
      }
    }
    if (data.phoneNumber) {
      refresh = true;
      address.telephone = data.phoneNumber;
    }
    if (data.company) {
      refresh = true;
      address.company = data.company;
    }
    if (data.position) {
      refresh = true;
      address.position = data.position;
    }
    if (data.email) {
      refresh = true;
      address.email = data.email;
      address.confirmEmail = data.email;
    }
    if (refresh) {
      this.customerForm.refreshData(false);
    }
  }
}
