import 'hammerjs';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from 'src/app/app.module';
import { environment } from 'src/environments/environment';

import { I18n } from 'aws-amplify';
import { defaultTranslations } from 'src/app/core/settings/i18n/japanese.dictionary';
import { MergeTranslation } from 'src/app/core/utilities/index';

const dict = MergeTranslation(defaultTranslations, environment.dictionary, 'ja-JP');
I18n.putVocabularies(dict);
I18n.setLanguage('ja-JP');

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
