import { I18n } from '@aws-amplify/core';

export const DefaultErrorMessages = {};

export const ErrorMessages = {
  customer: {},
  shipping: {
    none: {
      name: I18n.get('shippingNameRequired'),
    },
    card: {
      name: I18n.get('shippingNameRequired'),
    },
    moneyGift: {
      name: I18n.get('shippingNameRequired'),
    },
  },
  billing: {},
};
