import { Component, Input, ViewChild, forwardRef, ElementRef, Output, EventEmitter } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'RadioButton',
  styleUrls: ['./radio-button.component.scss'],
  template: `
    <div class="radio">
      <input
        [id]="id"
        #RadioButton
        [name]="name"
        type="radio"
        [value]="value"
        [checked]="checked"
        [disabled]="disabled"
        (change)="onChange($event)"
      />
      <label [for]="id" class="radio-label"></label>
    </div>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RadioButtonComponent),
      multi: true,
    },
  ],
})
export class RadioButtonComponent implements ControlValueAccessor {
  @ViewChild('RadioButton', { static: true })
  radioButton: ElementRef;

  @Input()
  name: string;

  @Input()
  id: string;

  @Input()
  disabled: boolean;

  @Input()
  value: any;

  @Input()
  checked: boolean;

  @Output()
  change: EventEmitter<any> = new EventEmitter<any>();

  // Function to call when the rating changes.
  onChange(value: any): void {
    this.change.emit(value);
  }

  // Function to call when the input is touched.
  onTouched = () => {};

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: (vlue: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
