import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-paypal-guide-window',
  templateUrl: './paypal-guide-window.component.html',
  styleUrls: ['./paypal-guide-window.component.scss'],
})
export class PaypalGuideWindowComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
