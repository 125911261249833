import { Directive, ElementRef, HostListener } from '@angular/core';
import { NG_VALIDATORS, Validator, ValidationErrors } from '@angular/forms';

@Directive({
  selector: '[phoneValidator]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: PhoneValidatorDirective,
      multi: true,
    },
  ],
})
export class PhoneValidatorDirective implements Validator {
  private target: any;
  @HostListener('ngModelChange') onNgModelChange() {
    this.isInputCorrect();
  }

  constructor(private element: ElementRef) {
    this.target = this.element.nativeElement;
  }

  isInputCorrect() {
    const replaced = this.target.value.replace(/[━.*‐.*―.*－.*\-.*ー.*\-]/gi, '');
    return replaced.length === 0 || replaced.match(/^(0[5-9]0[0-9]{8}|0[1-9][1-9][0-9]{7})$/);
  }

  validate(): ValidationErrors {
    return this.isInputCorrect() ? null : { phoneValidationError: true };
  }
}
