<section id="welcome-screen" class="no-flex">
  <div class="logo-container">
    <img src="{{ 'logo_white.png' | path }}" class="img-fluid" />
    <!-- <img src="{{ 'logo_white.png' | path }}" class="logo-md img-fluid" />
    <img src="{{ 'logo_mobile.png' | path }}" class="logo-sm img-fluid md-hi" /> -->
  </div>
  <div
    class="background-image-mobile background-video"
    [style.backgroundImage]="'url(' + backgroundImageMobile + ')'"
  ></div>
  <div class="background-image background-video gif" [style.backgroundImage]="'url(' + backgroundImage + ')'"></div>
  <div class="container">
    <div class="row funeral-block justify-content-center align-items-center">
      <div class="col-sm-12 col-md-10 col-lg-9">
        <div class="row align-items-center justify-content-center mb-3 mt-3 font-mincho">
          <!-- <div class="col-12 company-name" *ngIf="Funeral.companyName">{{ Funeral.companyName }}</div>
          <div class="col-12 company-name" *ngIf="Funeral.companyTitle">{{ Funeral.companyTitle }}</div> -->
          <div class="col-12 deceased-name-container" [style.fontSize]="fontSize + 'px'">
            <div class="deceased-name">
              <div *ngIf="Funeral.deceasedNamePrefix" class="title">{{ Funeral.deceasedNamePrefix + '&#12288;' }}</div>
              <div *ngIf="Funeral.deceasedName" class="name">{{ Funeral.deceasedName }}</div>
              <div *ngIf="Funeral.deceasedNameSuffix" class="title">{{ '&#12288;' + Funeral.deceasedNameSuffix }}</div>
            </div>
            <div *ngIf="Funeral.deceasedNamePostfix" class="postfix">
              <span class="postfix-space">{{ '&#12288;' }}</span
              >{{ Funeral.deceasedNamePostfix }}
            </div>
          </div>
        </div>

        <div class="date-location-main-container">
          <div class="date-location-container">
            <div class="funeral-location font-mincho" *ngIf="displayFuneralLocation">
              <div class="left-label-style font-mincho">
                <span>{{ 'in' | i18n }}</span>
              </div>
              <div class="funeral-date" *ngIf="Funeral.funeralLocationAltName || Funeral.FuneralHallName">
                <span>{{
                  Funeral.funeralLocationAltName ? Funeral.funeralLocationAltName : Funeral.FuneralHallName
                }}</span>
              </div>
            </div>

            <div class="funeral-date-area font-mincho" *ngIf="displayOvernightFuneralDate">
              <div class="left-label-style font-mincho" *ngIf="Funeral.overnightFuneralLabel">
                <span>{{ Funeral.overnightFuneralLabel }}</span>
              </div>
              <div class="funeral-date" *ngIf="Funeral.overnightFuneralDate">
                <span *ngIf="Funeral.overnightFuneralDate">{{ Funeral.overnightFuneralDate | date: 'M月d日' }}</span>
                <span *ngIf="overnightFuneralStartTime"> ／ {{ overnightFuneralStartTime | date: 'H時mm分〜' }}</span>
              </div>
            </div>

            <div class="funeral-date-area font-mincho" *ngIf="displayFuneralDate">
              <div
                class="left-label-style font-mincho"
                *ngIf="Funeral.funeralLabel"
                [innerHTML]="Funeral.funeralLabel"
              ></div>
              <div *ngIf="Funeral.funeralDate" class="funeral-date">
                <span>{{ Funeral.funeralDate | date: 'M月d日' }}</span>
                <span *ngIf="funeralStartTime"> ／ {{ funeralStartTime | date: 'H時mm分〜' }}</span>
                <span *ngIf="funeralEndTime">{{ funeralEndTime | date: 'H時mm分' }}</span>
              </div>
            </div>
            <div class="line"></div>
          </div>
        </div>
        <div *ngIf="Funeral.welcomeMessage" class="ml-2 p-2 my-1 font-mincho remark">
          <span class="inner">{{ Funeral.welcomeMessage }}</span>
        </div>
        <!-- buttons -->
        <!-- <div
          class="row align-items-start p-3 mt-md-5 p-md-0 button-area noto-font"
          [class]="{
            'justify-content-between': !isFuneralClosed && !hideStreamingButton,
            'justify-content-center': isFuneralClosed || hideStreamingButton
          }"
        > -->
        <div
          class="row align-items-start p-3 mt-md-5 p-md-0 button-area noto-font"
          [class]="{
            'justify-content-between': displayOrderButton && displayMediaButton,
            'justify-content-center': !displayOrderButton || !displayMediaButton
          }"
        >
          <!-- <div *ngIf="!isFuneralClosed" class="col-md-6 noto-font mb-4 mb-md-0"> -->
          <div *ngIf="displayOrderButton" class="col-md-6 noto-font mb-4 mb-md-0">
            <button
              id="no"
              class="btn btn-outline-light text-center col-12 round-button-style"
              (click)="onNextClick()"
              [disabled]="!displayOrderButton"
            >
              <div class="round-button-title">
                <img src="assets/img/btn-icons/pen.svg" class="btn-icon" />
                <!-- <span>{{ (isFuneralClosed ? 'receptionFinished' : 'receptionIsHere') | i18n }}</span> -->
                <span>{{ (displayOrderButton ? 'receptionIsHere' : 'receptionFinished') | i18n }}</span>
              </div>
              <span class="arrow mr-2"><i class="fa fa-angle-right"></i></span>
            </button>
            <!-- TODO: delete once we make sure that we do not want to use it -->
            <!-- <a href="https://{{ env }}.club.smacere.com/" target="_blank" class="smacere-link"
              >{{ 'toSmacereClub' | i18n }} ▶</a
            > -->
          </div>
          <!-- <div class="col-md-6" *ngIf="!hideStreamingButton"> -->
          <div class="col-md-6" *ngIf="displayMediaButton">
            <button
              id="yes"
              class="btn btn-outline-light text-center col-12 round-button-style"
              (click)="GoToPaymentCheck()"
            >
              <div class="round-button-title">
                <img src="assets/img/btn-icons/media.svg" class="btn-icon" />
                <div class="d-flex flex-column">
                  <span>{{ 'liveStreamIsHere' | i18n }}</span>
                  <small>{{ 'alreadyAcceptedHere' | i18n }}</small>
                </div>
              </div>
              <span class="arrow mr-2"><i class="fa fa-angle-right"></i></span>
            </button>
          </div>
        </div>

        <!-- TODO: remove oce we decide to use the old button styles -->
        <!-- <div
          class="buttons-container font-mincho"
          [style.justifyContent]="!isFuneralClosed && !hideStreamingButton ? 'space-between' : 'center'"
        >
          <div *ngIf="!isFuneralClosed" class="reception buttons-container-section">
            <span class="section-title">＜{{ 'reception' | i18n }}＞</span>
            <div class="reception-buttons-container section-container">
              <div class="member-button-container">
                <button
                  id="member-button"
                  class="round-button-style"
                  (click)="onNextClick()"
                  [disabled]="isFuneralClosed"
                >
                  <img src="assets/img/btn-icons/pen.svg" class="btn-icon" />
                  <span>{{ isFuneralClosed ? i18n.get('receptionFinished') : i18n.get('receptionIsHere') }}</span>
                  <span class="arrow"><i class="fa fa-angle-right"></i></span>
                </button>
                <a href="https://{{ env }}.club.smacere.com/" target="_blank" class="smacere-link"
                  >{{ 'toSmacereClub' | i18n }} ▶</a
                >
              </div>
              <button
                id="guest-button"
                class="round-button-style guest-button"
                (click)="onGuestClick()"
                [disabled]="isFuneralClosed"
              >
                <img src="assets/img/btn-icons/pen.svg" class="btn-icon" />
                <span>{{ isFuneralClosed ? i18n.get('receptionFinished') : i18n.get('generalIsHere') }}</span>
                <span class="arrow"><i class="fa fa-angle-right"></i></span>
              </button>
            </div>
          </div>
          <div *ngIf="!hideStreamingButton" class="mourner buttons-container-section">
            <span class="section-title">＜{{ 'mourner' | i18n }}＞</span>
            <div class="mourner-buttons-container section-container">
              <button id="yes" class="round-button-style" (click)="GoToPaymentCheck()">
                <img src="assets/img/btn-icons/media.svg" class="btn-icon" />
                <div class="d-flex flex-column">
                  <span>{{ 'liveStreamIsHere' | i18n }}</span>
                  <small>{{ 'alreadyAcceptedHere' | i18n }}</small>
                </div>
                <span class="arrow"><i class="fa fa-angle-right"></i></span>
              </button>
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </div>
  <app-footer class="footer-fix"></app-footer>
</section>
