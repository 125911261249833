import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { environment } from 'src/environments/environment';

@Pipe({
  name: 'priceWithTax',
  pure: true,
})
export class PriceWithTaxPipe extends DecimalPipe implements PipeTransform {
  result;
  transform(price: number, symbol: string = '¥', position: string = 'left'): string {
    const tax = (price * environment.taxRate) / 100;
    const priceWithTax = Math.floor(price + tax);
    if (position !== 'left') {
      return super.transform(priceWithTax) + symbol;
    } else {
      return symbol + super.transform(priceWithTax);
    }
  }
}
