<div class="container">
  <ul class="product-list present row">
    <li
      *ngFor="let item of presents; let i = index"
      class="col-6 col-md-4 d-flex flex-column justify-content-between"
      [ngClass]="{ 'product-active': item.id == selectedMoneyPresent?.id }"
    >
      <label for="present-{{ i }}">
        <img class="img-fluid" vtMedia [object]="item.picture" />
        <div class="product-title">
          {{ item.name }}
        </div>
      </label>
      <div class="choice">
        <RadioButton
          id="present-{{ i }}"
          name="present"
          [value]="item.id"
          [checked]="item.id == selectedMoneyPresent?.id"
          (change)="OnSelectionChange(item)"
        ></RadioButton>
      </div>
    </li>
  </ul>
</div>
