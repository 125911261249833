import { MediaObjectInput } from '@kokusai/smacere-shared/api';
import { Product } from 'src/models/Product';
export class Card extends Product {
  constructor(params: Card = {} as Card) {
    super(params);
    let { nameKana = '', cardMaterialType = '', cardOpenSide = '', cardImageOpen = {} } = params; //Default value;

    this.nameKana = nameKana;
    this.cardMaterialType = cardMaterialType;
    this.cardOpenSide = cardOpenSide;
    this.cardImageOpen = cardImageOpen;
  }

  public nameKana: string;
  public cardMaterialType: string;
  public cardOpenSide: string;
  public cardImageOpen: MediaObjectInput;
}
