import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { GlobalStorage } from 'src/app/global/storage';
import { Funeral } from 'src/models/Funeral';
import { SetFontSizeService } from 'src/services';
import { Router } from '@angular/router';

@Component({
  selector: 'app-deceased-person',
  templateUrl: './deceased-person.component.html',
  styleUrls: ['./deceased-person.component.scss'],
})
export class DeceasedPersonComponent implements OnInit, OnDestroy {
  // @Output()
  // onSkip: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  onBack: EventEmitter<any> = new EventEmitter<any>();

  Funeral: Funeral;
  fontSize: number;
  viewWidth: number;

  constructor(private storage: GlobalStorage, private setFontSizeService: SetFontSizeService, private router: Router) {}

  ngOnInit(): void {
    this.Funeral = this.storage.Funeral;
    this.setFontSize();
    window.addEventListener('resize', this.setFontSize);
  }

  ngOnDestroy(): void {
    window.removeEventListener('resize', this.setFontSize);
  }

  setFontSize(): void {
    if (!this.setFontSizeService) {
      return;
    }

    this.fontSize = this.setFontSizeService.setWebFontSize(
      this.Funeral.deceasedName,
      this.Funeral.deceasedNamePrefix,
      this.Funeral.deceasedNameSuffix,
      this.Funeral.deceasedNamePostfix,
      this.viewWidth,
      1.65
    );
  }

  skipClick(): void {
    this.router.navigate([this.Funeral.funeralId, 'order', 'guest-book']);
    // this.onSkip.emit();
  }

  returnClick(): void {
    this.onBack.emit();
  }
}
