import { Component, OnInit, OnDestroy } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';

import { ScreenType, StepType } from 'src/types';

import { EventStep } from 'src/models';
import { GlobalStorage } from 'src/app/global/storage';
import { ReceptionCloseService } from 'src/services';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-gift-money',
  templateUrl: './money-gift.component.html',
  styleUrls: ['./money-gift.component.scss'],
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [style({ opacity: 0 }), animate('1s ease-out', style({ opacity: 1 }))]),
      transition(':leave', [style({ opacity: 1 }), animate('1s ease-in', style({ opacity: 0 }))]),
    ]),
  ],
})
export class MoneyGiftComponent implements OnInit {
  public home: boolean = false;
  public select: boolean = false;
  public from: StepType;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private storage: GlobalStorage,
    private receptionCloseService: ReceptionCloseService
  ) {}

  public ngOnInit(): void {}

  public ngAfterViewChecked(): void {
    const receptionStatus = this.receptionCloseService.getReceptionStatus(this.storage.Funeral, StepType.MoneyGift);
    const { snapshot } = this.activatedRoute;

    const funeral = this.storage.Funeral;
    if (!funeral.displayMoney) {
      this.router.navigate([funeral.funeralId, 'order', 'confirm']);
      return;
    }

    if (snapshot.children[0].routeConfig.path.length && receptionStatus.isFuneralClosed) {
      this.router.navigate(['../gift-money'], {
        relativeTo: this.activatedRoute,
      });
    }
  }

  public BackToHome(): void {
    this.home = true;
    this.select = false;
  }

  public GoToSelect(): void {
    this.home = false;
    this.select = true;
  }
}
