import { Order } from 'src/models/Order';
import { Funeral } from 'src/models/Funeral';
import { EventEmitter, Injectable } from '@angular/core';
import { APIService, Funeral as ApiFuneral, Product } from '@kokusai/smacere-shared/api';
import {
  Card,
  CardOrder,
  CondolenceVideo,
  CondolenceVideoOrder,
  Flower,
  FlowerOrder,
  Food,
  MoneyGift,
  MoneyGiftOrder,
  OrderSteps,
  Paper,
  Payment,
  Present,
} from 'src/models';

const FUNERAL_NAME = 'Funeral';
const ORDER_NAME = 'Order';
const ORDER_STEPS = 'OrderSteps';
const PAYMENT = 'Payment';
const MOURNER_EMAIL = 'MournerEmail';
const STREAMING_PASSWORD = 'StreamingPassword';
const PRODUCTS = 'Products';
const FUNERAL_ID = 'funeralId';
const CART = 'Cart';

@Injectable()
export class GlobalStorage {
  constructor(private apiService: APIService) {}

  //#region Funeral
  get Funeral(): Funeral {
    const jsonData = sessionStorage.getItem(FUNERAL_NAME);
    return JSON.parse(jsonData);
  }

  set Funeral(value: Funeral) {
    const jsonData = JSON.stringify(value);
    sessionStorage.setItem(FUNERAL_NAME, jsonData);
    sessionStorage.setItem('funeralId', value.funeralId);
  }

  private funeralModel: ApiFuneral = null;
  get FuneralModel(): Promise<ApiFuneral> | ApiFuneral | null {
    if (this.funeralModel !== null) {
      return this.funeralModel;
    }
    const funeralId = sessionStorage.getItem('funeralId');
    if (funeralId) {
      return this.apiService.GetFuneral(funeralId).then((result) => (this.funeralModel = result));
    }
    return null;
  }
  //#endregion

  //#region Order
  private localOrder: Order;
  public readonly OrderChanged: EventEmitter<Order> = new EventEmitter<Order>();
  get Order(): Order {
    if (typeof this.localOrder !== 'undefined') {
      return this.localOrder;
    }

    const jsonData = sessionStorage.getItem(ORDER_NAME);
    const obj = JSON.parse(jsonData) ?? {};
    const order: Order = Object.assign(new Order(), obj);

    if (obj.FlowerOrder) {
      order.FlowerOrder = Object.assign(new FlowerOrder(), obj.FlowerOrder);
      order.FlowerOrder.Product = Object.assign(new Flower(), obj.FlowerOrder.Product);

      if (obj.FlowerPresent) {
        order.FlowerPresent = Object.assign(new Present(), obj.FlowerPresent);
      }
    }

    if (obj.CardOrder) {
      order.CardOrder = Object.assign(new CardOrder(), obj.CardOrder);
      order.CardOrder.Product = Object.assign(new Card(), obj.CardOrder.Product);
      order.CardOrder.Paper = Object.assign(new Paper(), obj.CardOrder.Paper);

      if (obj.CardPresent) {
        order.CardPresent = Object.assign(new Present(), obj.CardPresent);
      }
    }

    if (obj.CondolenceVideoOrder) {
      order.CondolenceVideoOrder = Object.assign(new CondolenceVideoOrder(), obj.CondolenceVideoOrder);
      order.CondolenceVideoOrder.Product = Object.assign(new CondolenceVideo(), obj.CondolenceVideoOrder.Product);

      if (obj.CondolenceVideoPresent) {
        order.CondolenceVideoPresent = Object.assign(new Present(), obj.CondolenceVideoPresent);
      }
    }

    if (obj.MoneyGiftOrder) {
      order.MoneyGiftOrder = Object.assign(new MoneyGiftOrder(), obj.MoneyGiftOrder);
      order.MoneyGiftOrder.Product = Object.assign(new MoneyGift(), obj.MoneyGiftOrder.Product);
      if (obj.MoneyPresent) {
        order.MoneyPresent = Object.assign(new Present(), obj.MoneyPresent);
      }
      if (obj.MoneyFood) {
        order.MoneyFood = Object.assign(new Food(), obj.MoneyFood);
      }
    }

    this.localOrder = order;
    return order;
  }

  set Order(value: Order) {
    if (value !== this.localOrder) {
      this.OrderChanged.emit(value);
    }

    this.localOrder = value;
    const jsonData = JSON.stringify(value);
    sessionStorage.setItem(ORDER_NAME, jsonData);
  }

  resetStorageOrder() {
    const jsonData = JSON.stringify(new Order());
    sessionStorage.setItem(ORDER_NAME, jsonData);
  }

  public saveOrder() {
    this.Order = this.localOrder;
  }
  //#endregion

  get OrderSteps(): OrderSteps {
    const jsonData = sessionStorage.getItem(ORDER_STEPS);
    const data = JSON.parse(jsonData) as OrderSteps;
    return data || new OrderSteps();
  }

  set OrderSteps(value: OrderSteps) {
    const jsonData = JSON.stringify(value);
    sessionStorage.setItem(ORDER_STEPS, jsonData);
  }

  public get Payment(): Payment {
    const jsonData = localStorage.getItem(PAYMENT);
    const data = JSON.parse(jsonData) as Payment;
    return data || new Payment();
  }

  public set Payment(value: Payment) {
    const jsonData = JSON.stringify(value);
    localStorage.setItem(PAYMENT, jsonData);
  }

  public get MournerEmail() {
    return localStorage.getItem(MOURNER_EMAIL);
  }

  public set MournerEmail(value: string) {
    localStorage.setItem(MOURNER_EMAIL, value);
  }

  public get StreamingPassword() {
    return localStorage.getItem(STREAMING_PASSWORD);
  }

  public set StreamingPassword(value: string) {
    localStorage.setItem(STREAMING_PASSWORD, value);
  }

  public get FuneralId() {
    return localStorage.getItem(FUNERAL_ID);
  }

  public set FuneralId(value: string) {
    localStorage.setItem(FUNERAL_ID, value);
  }

  public get Products(): Array<Product> {
    const jsonData = sessionStorage.getItem(PRODUCTS);
    return JSON.parse(jsonData) || [];
  }

  public set Products(value: Array<Product>) {
    const jsonData = JSON.stringify(value);
    sessionStorage.setItem(PRODUCTS, jsonData);
  }

  get Cart() {
    const jsonData = sessionStorage.getItem(CART);
    return JSON.parse(jsonData) || [];
  }

  set Cart(value) {
    const jsonData = JSON.stringify(value);
    sessionStorage.setItem(CART, jsonData);
  }
}
