import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MediaObjectInput, StreamingType } from '@kokusai/smacere-shared/api';
import { GlobalStorage } from 'src/app/global/storage';
import { FuneralService } from 'src/services';
import { Funeral } from 'src/models';
import { Stream, StreamButton } from 'src/models/streams';
import { Path } from 'src/app/core/utilities';

@Component({
  selector: 'app-stream',
  templateUrl: './stream.component.html',
  styleUrls: ['./stream.component.scss'],
})
export class StreamComponent implements OnInit {
  @ViewChild('video', { static: true }) video: ElementRef<HTMLVideoElement>;

  streamButtons: StreamButton[] = [];
  streamingUrl: string = null;
  funeral: Funeral = null;
  isPlaying: boolean = false;
  private _streamingType;

  constructor(
    private storage: GlobalStorage,
    private funeralService: FuneralService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.funeral = this.storage.Funeral;
  }

  get isStreamNotEmpty(): boolean {
    return this.streamButtons.length > 0;
  }

  private streamOnPlaying(): void {
    this.isPlaying = true;
  }

  private streamOnPause(): void {
    this.isPlaying = false;
  }

  private onErrorVideoLoad(): void {
    this.video.nativeElement.poster = Path.getPath('reception_over.jpg');
  }

  private initStreamButton(type: StreamingType): void {
    this.streamButtons = [];

    const streams: Stream[] = this.getStreams(type);

    if (streams.length > 0) {
      streams.forEach((stream: Stream, index: number) => {
        this.playOnlineStream(stream);
        this.addToStreamArray(stream, ++index);
      });

      this.setPlayStreamUrl();
    } else {
      this.streamButtons = [];
      this.goBack();
    }
  }

  private getStreams(type: StreamingType): Stream[] {
    let result: Stream[] = [];
    if (
      type === StreamingType.funeral &&
      (this.funeral.overnightFuneralDate === null || this.funeral.overnightFuneralDate === '')
    ) {
      result = this.funeral.streamingUrls.filter((stream) => !stream.isDeleted);
    } else {
      result = this.funeral.streamingUrls.filter((stream) => stream.type === type && !stream.isDeleted);
    }

    return result;
  }

  private setPlayStreamUrl(): void {
    if (this.streamButtons.every((item) => item.isOnline === false) && !this.isPlaying) {
      const firstStream = this.streamButtons[0];
      this.streamingUrl = firstStream.url;
    }
  }

  private playOnlineStream(stream: Stream): void {
    if (stream.isOnline) {
      this.streamingUrl = stream.url;
    }
  }

  private addToStreamArray(stream: Stream, index: number): void {
    this.streamButtons.push({
      index: index,
      text: this.getText(stream),
      url: stream.url,
      isOnline: stream.isOnline,
    });
  }

  private getText(stream: Stream): string {
    return stream.isOnline ? 'ライブ中継中' : `${stream.date} ${stream.time}`;
  }

  ngOnInit(): void {
    if (this.video) {
      this.video.nativeElement.onerror = this.onErrorVideoLoad;
      this.video.nativeElement.onplaying = this.streamOnPlaying;
      this.video.nativeElement.onpause = this.streamOnPause;
    }
    this.route.params.subscribe((param: any) => {
      if (
        (param.type === StreamingType.funeral && !this.funeral.funeralRecording) ||
        (param.type === StreamingType.overnight && !this.funeral.overnightFuneralRecording)
      ) {
        this.initStreamButton(param.type);
      }
      this._streamingType = param.type;
      this.funeralService.onFuneralUpdate(this.funeral.funeralId).subscribe((funeral) => {
        this.funeral = funeral;
        this.initStreamButton(param.type);
      });
    });
  }

  trackByMethod(index: number): number {
    return index;
  }

  goBack(): void {
    this.router.navigate([this.funeral.funeralId, 'media']);
  }

  get streamingType() {
    return this._streamingType;
  }

  get displayRecording(): boolean {
    switch (this.streamingType) {
      case 'overnight':
        return Boolean(this.funeral?.overnightFuneralRecording);
      case 'funeral':
        return Boolean(this.funeral?.funeralRecording);
      default:
        return false;
    }
  }

  get recordingMedia(): MediaObjectInput {
    switch (this.streamingType) {
      case 'overnight':
        return this.funeral?.overnightFuneralRecording ?? null;
      case 'funeral':
        return this.funeral?.funeralRecording ?? null;
      default:
        return null;
    }
  }
}
