<div class="guest-book-container">
  <div class="guest-book-header-container">
    <h4 class="guest-book-header font-mincho">{{ 'enterMournerInfo' | i18n }}</h4>
    <span class="guest-book-sub-header">
      {{ 'smacereCanBeUsedWithoutRegistration' | i18n }}
    </span>
    <div class="forms-container">
      <app-log-in-form *ngIf="false"></app-log-in-form>
      <app-card-upload-form *ngIf="authState !== 'signedin'" (data)="dataFromBusinessCard($event)"></app-card-upload-form>
      <app-member-login-form *ngIf="true"></app-member-login-form>
    </div>
  </div>
  <div class="guest-book-contents">
    <p class="mourner-info font-mincho">{{ 'mournerInfo' | i18n }}</p>
    <!-- customer address form container -->
    <app-address
      [isSubmit]="isSubmit"
      [disableNameAndEmail]="disableNameAndEmail"
      [viewType]="ViewType.fullAddress"
      #customerForm
    ></app-address>
    <!-- shipping address -->
    <div *ngIf="displayShippingAddress">
      <div class="shipping-address-form">
        <p class="destinationTitle">{{ 'destinationTitle' | i18n }}</p>
        <div class="form-group">
          <input
            type="radio"
            name="DestinationSame"
            id="DestinationSameYes"
            [value]="true"
            [(ngModel)]="Order.IsDestinationSame"
          />
          <label for="DestinationSameYes">
            {{ 'destinationSameYes' | i18n }}
          </label>
        </div>
        <div class="form-group">
          <input
            type="radio"
            name="DestinationSame"
            id="DestinationSameNo"
            [value]="false"
            [(ngModel)]="Order.IsDestinationSame"
          />
          <label for="DestinationSameNo">{{ 'destinationSameNo' | i18n }}</label>
        </div>
      </div>
      <!-- shipping address form container -->
      <app-address
        #shippingForm
        [style.display]="Order.IsDestinationSame ? 'none' : 'initial'"
        [type]="AddressType.shipping"
        [viewType]="ViewType.shortAddress"
        [isSubmit]="isSubmit"
      ></app-address>
    </div>

    <!-- ceremony club container -->
    <div class="ceremony-club-container" *ngIf="showSmacereSection">
      <!-- header -->
      <div class="ceremony-club-header">
        <span>{{ 'toSmartCeremonyGuests' | i18n }}</span>
        <span>{{ 'aConvenientMembershipProgramGuide' | i18n }}</span>
      </div>
      <!-- contents -->
      <div class="ceremony-club-contents">
        <div class="ceremony-club-contents-header">
          <span>{{ 'ifYouBecomeAMemberYouWillRecieveDiscount' | i18n }}</span>
        </div>
        <div class="ceremony-club-contents-main">
          <!-- money-item -->
          <div class="ceremony-club-item">
            <div class="item-left-circle item-left-circle-money"></div>
            <div class="item-right-circle">
              <span class="item-title">{{ 'membershipFee' | i18n }}・</span>
              <span class="item-title">{{ 'annualFeesAndCharges' | i18n }}</span>
              <span class="free-of-charge">{{ 'freeOfCharge' | i18n }}</span>
            </div>
          </div>
          <!-- card-item -->
          <div class="ceremony-club-item">
            <div class="item-left-circle item-left-circle-card"></div>
            <div class="item-right-circle">
              <span class="item-title">{{ 'fromThePriceOfCondolenceOffering' | i18n }}</span>
              <span class="item-offer">5% </span>
              <span class="item-offer">OFF </span>
            </div>
          </div>
          <!-- flower-item -->
          <div class="ceremony-club-item">
            <div class="item-left-circle item-left-circle-flower"></div>
            <div class="item-right-circle">
              <span class="item-title">{{ 'fromThePriceOfFlowerOffering' | i18n }}</span>
              <span class="item-offer">5% </span>
              <span class="item-offer">OFF </span>
            </div>
          </div>
        </div>
        <div class="links-container">
          <a href="https://{{ env }}.club.smacere.com/" target="_blank" class="first-link">{{
            'clickHereForTheSmacereClubWebsite' | i18n
          }}</a>
          <a href="javascript:void(0);" (click)="OpenTOSModal()" class="second-link">{{
            'smacereClubMembershipAgreement' | i18n
          }}</a>
        </div>
      </div>
      <!-- footer -->
      <div class="ceremony-club-footer">
        <form class="form-container">
          <label class="main-label">{{ 'smacereClubMembershipRegistration' | i18n }}</label>
          <label class="sub-label" for="register"
            ><input
              type="checkbox"
              id="register"
              name="register"
              class="checkbox"
              [(ngModel)]="Order.RegisterAccount"
            />{{ 'agreeAndRegisterAsAMember' | i18n }}</label
          >
        </form>
        <div class="remarks-container">
          <div class="remarks-wrapper">
            <span>{{ 'ifYouUseSNSToRegisterYourInformation' | i18n }} </span>
            <span>{{ 'ifYouRegisteredYourInformationByCard' | i18n }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- buttons -->
  <div class="buttons-container">
    <button id="return-button" class="nav-button return-button" (click)="return()">
      {{ 'return' | i18n }}
    </button>
    <button
      id="next-button"
      class="nav-button next-button"
      (click)="next(customerForm?.userForm, shippingForm?.userForm)"
    >
      {{ 'decideAndNext' | i18n }}
    </button>
  </div>
</div>
