import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import Swal from 'sweetalert2';
import { GlobalStorage } from 'src/app/global/storage';
import { CardService, PaperService } from 'src/services';
import { Card, Order, Paper } from 'src/models';
import { AuthService } from 'src/app/services/auth.service';
import { ProductType } from '@kokusai/smacere-shared/api';
import { BundleCartService, BundleType } from '../../../../services/bundle-cart.service';
import { I18n } from 'aws-amplify';

@Component({
  selector: 'app-card-select',
  templateUrl: './card-select.component.html',
  styleUrls: ['./card-select.component.scss'],
})
export class CardSelectComponent implements OnInit {
  private Order: Order = null;
  isSmacereClubMember;

  ProductType = ProductType;

  constructor(
    private storage: GlobalStorage,
    private paperService: PaperService,
    private cardService: CardService,
    private route: Router,
    private location: Location,
    private authService: AuthService,
    private bundleCartService: BundleCartService
  ) {}

  Cards: Card[] = [];
  Papers: Paper[] = [];

  selectedCard?: Card = null;
  selectedPaper?: Paper = null;

  ngOnInit(): void {
    this.bundleCartService.initStep(BundleType.Card, 'order');

    this.Order = this.storage.Order;
    this.Cards = this.cardService.gets();
    if (this.Order && this.Order.CardOrder.Product.id !== '') {
      this.selectedCard = this.Order.CardOrder.Product;
    }

    this.Papers = this.paperService.gets();
    if (this.Order && this.Order.CardOrder.Product.id !== '') {
      this.selectedPaper = this.Order.CardOrder.Paper;
    }

    this.isSmacereClubMember = this.authService.isSmacereClubMember;
  }

  OnSelectCoverChange(card: Card): void {
    this.selectedCard = card;
  }

  OnSelectCardChange(paper: Paper): void {
    this.selectedPaper = paper;
  }

  next(): void {
    if (this.selectedCard && this.selectedPaper) {
      if (
        this.Order.CardOrder.Product.id !== this.selectedCard.id ||
        this.Order.CardOrder.Paper.id !== this.selectedPaper.id
      ) {
        this.Order.CardPresent = undefined;
      }

      this.Order.CardOrder.Product = this.selectedCard;
      this.Order.CardOrder.Paper = this.selectedPaper;
      this.Order.CardOrder.quantity = 1;
      this.storage.Order = this.Order;

      this.route.navigate([this.storage.Funeral.funeralId, 'order', 'cards', 'order']);
    } else if (!this.selectedCard) {
      Swal.fire({
        icon: 'warning',
        title: '弔電台紙が未選択です。',
        text: '弔電台紙一覧より、弔電台紙をご選択ください。',
      });
    } else if (!this.selectedPaper) {
      Swal.fire({
        icon: 'warning',
        title: '弔電用紙が未選択です。',
        text: 'ご希望の弔電台紙、用紙をお選びください。',
      });
    } else {
      this.route.navigate([this.storage.Funeral.funeralId, 'order', 'cards', 'order']);
    }
  }

  back(): void {
    this.location.back();
  }

  get returnButtonLabel(): string {
    return this.bundleCartService.isEdit() ? I18n.get('goToApplicationConfirmation') : I18n.get('return');
  }
}
