import { PaymentMethodType } from 'src/types';

export class PaymentActive {
  constructor(type: PaymentMethodType, title: string, isActive: boolean) {
    this.type = type;
    this.title = title;
    this.isActive = isActive;
  }
  type: PaymentMethodType;
  title: string;
  isActive: boolean;
}
