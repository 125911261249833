import { Component, OnInit } from '@angular/core';
import { GlobalStorage } from 'src/app/global/storage';

@Component({
  selector: 'app-memory-slides',
  templateUrl: './memory-slides.component.html',
  styleUrls: ['./memory-slides.component.scss'],
})
export class MemorySlidesComponent implements OnInit {
  public Slides: any;
  constructor(private storage: GlobalStorage) {
    this.Slides = this.storage.Funeral.memorialPictures;
  }

  ngOnInit(): void {}
}
