import { PaymentMethodType } from 'src/types';

export class Payment {
  public id: string;
  public funeralId: string;
  public cartId: string;
  public email: string;
  public orderId: string;
  public type: PaymentMethodType;
}
