export * from './flowers.component';
export * from './flowers-home/flowers-home.component';
export * from './flowers-select/flowers-select.component';
export * from './flowers-order/flowers-order.component';
export * from './flowers-present/flowers-present.component';

import { FlowersComponent } from './flowers.component';
import { FlowersHomeComponent } from './flowers-home/flowers-home.component';
import { FlowersSelectComponent } from './flowers-select/flowers-select.component';
import { FlowersOrderComponent } from './flowers-order/flowers-order.component';
import { FlowersPresentComponent } from './flowers-present/flowers-present.component';

export const FlowersModule = [
  FlowersComponent,
  FlowersHomeComponent,
  FlowersSelectComponent,
  FlowersOrderComponent,
  FlowersPresentComponent,
];
