import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AccessLevel, LambdaAddMemorialShareInput, S3Object } from '@kokusai/smacere-shared/api';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
// import awsmobile from 'src/aws/aws-settings';

@Component({
  selector: 'app-edit-memorial-share-modal',
  templateUrl: './edit-memorial-share-modal.component.html',
  styleUrls: ['./edit-memorial-share-modal.component.scss'],
})
export class EditMemorialShareModalComponent implements OnInit {
  isSubmitted: boolean = false;
  imageIndex: number;
  images;
  accessLevel: AccessLevel;
  firstName: string;
  firstNameKana: string;
  lastName: string;
  lastNameKana: string;
  description: string;
  media: S3Object;
  public image: {
    thumbnail: string;
    input: LambdaAddMemorialShareInput;
  };

  constructor(private modalService: NgbModal) {}

  ngOnInit(): void {
    this.lastName = this.image.input.lastName;
    this.firstName = this.image.input.firstName;
    this.lastNameKana = this.image.input.lastNameKana;
    this.firstNameKana = this.image.input.firstNameKana;
    this.description = this.image.input.description;
    this.accessLevel = this.image.input.accessLevel;
    // this.media = {
    //   __typename: 'S3Object',
    //   bucket: awsmobile.aws_user_files_s3_bucket,
    //   region: awsmobile.aws_user_files_s3_bucket_region,
    //   key: this.image.input.media,
    // };
  }

  change(form: NgForm) {
    this.isSubmitted = true;
    if (form.invalid) {
      return;
    }
    this.image.input.lastName = this.lastName;
    this.image.input.firstName = this.firstName;
    this.image.input.lastNameKana = this.lastNameKana;
    this.image.input.firstNameKana = this.firstNameKana;
    this.image.input.description = this.description;
    this.image.input.accessLevel = this.accessLevel;
    this.modalService.dismissAll();
  }

  delete(index: number) {
    this.images.splice(index, 1);
    this.modalService.dismissAll();
  }

  closeModal() {
    this.modalService.dismissAll();
  }
}
