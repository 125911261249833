export * from './checkout.component';
export * from './checkout-home/checkout-home.component';
export * from './checkout-billing/checkout-billing.component';
export * from './checkout-payment/checkout-payment.component';

import { CheckoutComponent } from './checkout.component';
import { CheckoutHomeComponent } from './checkout-home/checkout-home.component';
import { CheckoutBillingComponent } from './checkout-billing/checkout-billing.component';
import { CheckoutPaymentComponent } from './checkout-payment/checkout-payment.component';

export const CheckoutModule = [
  CheckoutComponent,
  CheckoutHomeComponent,
  CheckoutBillingComponent,
  CheckoutPaymentComponent,
];
