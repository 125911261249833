import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-footer-sct',
  templateUrl: './footer-sct.component.html',
  styleUrls: ['./footer-sct.component.scss'],
})
export class FooterSCTComponent implements OnInit {
  displayForCeremony = environment.tenant === 'ceremony';
  displayForJoin = environment.tenant === 'join';

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit(): void {}
}
