import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { GlobalStorage } from 'src/app/global/storage';
import { Food, MoneyGift, Order, Present } from 'src/models';
import { environment } from 'src/environments/environment';
import { BundleCartService, BundleType } from '../../../../services/bundle-cart.service';

@Component({
  selector: 'app-gift-money-present-select',
  templateUrl: './money-gift-present-selection.component.html',
  styleUrls: ['./money-gift-present-selection.component.scss'],
})
export class MoneyGiftPresentSelectionComponent implements OnInit {
  constructor(
    private storage: GlobalStorage,
    private route: Router,
    private location: Location,
    private bundleCartService: BundleCartService
  ) {}

  private Order: Order = null;

  SelectedMoneyGift?: MoneyGift = null;
  selectedMoneyPresent: Present = null;
  selectedFood: Food = null;
  hasFood = false;
  public isEdit: boolean = false;

  ngOnInit(): void {
    this.bundleCartService.checkStep(BundleType.GiftMoney, 'present');

    this.Order = this.storage.Order;
    if (this.Order.MoneyGiftOrder && this.Order.MoneyGiftOrder.Product?.id !== '') {
      this.SelectedMoneyGift = this.Order.MoneyGiftOrder.Product;
      this.selectedFood = this.Order.MoneyFood;
      this.selectedMoneyPresent = this.Order.MoneyPresent;
    }
    this.isEdit = this.bundleCartService.isEdit();
  }

  get HasFood() {
    return this.hasFood;
  }

  set HasFood(value: boolean) {
    this.hasFood = value;
  }

  get getMoneyGiftAmount(): number {
    return this.SelectedMoneyGift?.price;
  }

  get MoneyGiftAmountExists(): boolean {
    return this.SelectedMoneyGift?.price != null;
  }

  get DisplayMoneyPresent(): boolean {
    return this.MoneyGiftAmountExists && this.storage.Funeral.displayMoneyPresent;
  }

  get DisplayFood(): boolean {
    return this.MoneyGiftAmountExists && this.storage.Funeral.displayFood;
  }

  // This was to handle Join Corporate site for a single event.  Maybe can be removed.
  get IsCorporate(): boolean {
    return environment.corporate;
  }

  OnFoodSelectionChanged(value: boolean) {
    // setTimeout fixed problem with "Expression has changed after it was checked"
    setTimeout(() => {
      this.HasFood = value;
    });
  }

  private async saveToStorage(): Promise<boolean> {
    if (!this.SelectedMoneyGift) {
      await Swal.fire({
        icon: 'error',
        title: '香典金額が未選択です',
        text: '香典金額をご選択または、ご入力ください',
      });
      return false;
    }

    if (!this.selectedMoneyPresent && this.storage.Funeral.displayMoneyPresent) {
      await Swal.fire({
        icon: 'warning',
        title: '香典返礼品が未選択です',
        text: '返礼品一覧より、ご選択ください',
      });
      return false;
    }

    if (!this.selectedFood && this.storage.Funeral.displayFood && this.HasFood) {
      await Swal.fire({
        icon: 'warning',
        title: 'ご会食が未選択です',
        text: 'ご会食一覧より、ご選択ください',
      });
      return false;
    }

    this.Order.MoneyPresent = this.selectedMoneyPresent;
    this.Order.MoneyFood = this.selectedFood;
    this.storage.Order = this.Order;

    return true;
  }

  async saveAction(): Promise<void> {
    if (await this.saveToStorage()) {
      await this.bundleCartService.saveGiftMoneyFromStorage();
      await this.bundleCartService.goToNextStep(BundleType.GiftMoney);
    }
  }

  async addAction(): Promise<void> {
    if (await this.saveToStorage()) {
      await this.bundleCartService.saveGiftMoneyFromStorage();
      await this.bundleCartService.goToAdd(BundleType.GiftMoney);
    }
  }

  back() {
    this.location.back();
  }
}
