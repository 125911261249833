export class OrderSteps {
  constructor() {
    this.isGuestBookStepCompleted = false;
    this.isFlowerStepCompleted = false;
    this.isCardStepCompleted = false;
    this.isMoneyGiftStepCompleted = false;
    this.isOrderConfirmationCompleted = false;
    this.isOrderConfirmationActive = false;
  }
  public isGuestBookStepCompleted: boolean;
  public isFlowerStepCompleted: boolean;
  public isCardStepCompleted: boolean;
  public isMoneyGiftStepCompleted: boolean;
  public isOrderConfirmationCompleted: boolean;
  public isOrderConfirmationActive: boolean;
}
