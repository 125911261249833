<!-- <div class="text-center">
  <video
    id="video"
    autoplay="true"
    hls
    class="img-fluid"
    vtMedia
    [object]="memorialMovie"
    controls="true"
    controlsList="nodownload"
    oncontextmenu="return false;"
  ></video>
</div> -->
<div class="text-center">
  <video
    id="video"
    #video
    autoplay="true"
    preload="auto"
    playsinline
    class="img-fluid"
    vtMedia
    [object]="MemorialMovie"
    controls="true"
    controlsList="nodownload"
    oncontextmenu="return false;"
  ></video>
</div>
