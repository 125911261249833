import { BaseProductOrder } from './BaseProductOrder';
import { Paper } from './Paper';
import { Card } from './Card';
import { Address } from './Address';

export class CardOrder extends BaseProductOrder<Card> {
  constructor() {
    super();
    this.cardDestinationLastName = '';
    this.cardDestinationFirstName = '';
    this.cardSenderCompany = '';
    this.cardSenderPosition = '';
    this.cardSenderLastName = '';
    this.cardSenderLastNameKana = '';
    this.cardSenderFirstName = '';
    this.cardSenderFirstNameKana = '';
    this.attributes = { customName: '' };
    this.Product = new Card();
    this.Paper = new Paper();
    this.cardSenderPosition = '';
    this.IsDestinationSame = true;
    this.isCustomerAddressSame = true;
    this.useMaidenName = false;
    this.ShippingAddress = new Address();
    this.CustomerAddress = new Address();
  }

  cardDestinationLastName: string; // 宛先　姓
  cardDestinationFirstName: string; // 宛先　名
  cardSenderCompany: string; // 差出人 会社・団体名
  cardSenderPosition: string; // 差出人 役職
  cardSenderLastName: string; //差出人 姓
  cardSenderLastNameKana: string; //差出人 姓
  cardSenderFirstName: string; //差出人 名 カナ
  cardSenderFirstNameKana: string; //差出人 名 カナ
  cardMessage: string;
  Paper: Paper;
  IsDestinationSame: boolean;
  isCustomerAddressSame: boolean;
  useMaidenName: boolean;
  ShippingAddress?: Address | null;
  CustomerAddress?: Address | null;
}
