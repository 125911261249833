import { Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { PresentsService } from 'src/services/present.service';

import { Present } from 'src/models/Present';

import { ProductType } from '@kokusai/smacere-shared/api';

import { Sorts } from 'src/app/core/utilities/sorts';
import { GlobalStorage } from 'src/app/global/storage';

@Component({
  selector: 'app-gift-money-present',
  templateUrl: './money-gift-present.component.html',
  styleUrls: ['./money-gift-present.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MoneyGiftPresentComponent),
      multi: true,
    },
  ],
})
export class MoneyGiftPresentComponent implements ControlValueAccessor {
  @Input()
  set Sum(value: number) {
    if (value !== null && value !== undefined) {
      this.presentService
        .gets(ProductType.MoneyGift, value, this.storage.Funeral.funeralDate)
        .subscribe((presents: Present[]) => {
          this.presents = presents.sort((a, b) => {
            return Sorts.byJapanAlpha(a, b, 'name');
          });
          this.selectedMoneyPresent = this.doesPresentExist() ? this.selectedMoneyPresent : null;
        });
    }
  }

  presents: Present[] = [];
  selectedMoneyPresent: Present = null;

  constructor(private presentService: PresentsService, private storage: GlobalStorage) {}

  onTouched(): void {}
  onChange: any = () => {};

  writeValue(present: Present): void {
    this.selectedMoneyPresent = present;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  OnSelectionChange(present: Present) {
    this.writeValue(present);
    this.onChange(present);
  }

  private doesPresentExist(): boolean {
    let arr: Present[] = [];
    if (this.selectedMoneyPresent) {
      arr = this.presents.filter((present) => {
        return present.id === this.selectedMoneyPresent.id;
      });
    }

    return arr.length > 0;
  }
}
