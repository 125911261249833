import { Component, OnInit, TemplateRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalStorage } from 'src/app/global/storage';
import { APIService, CondolenceVideo, AccessLevel, ProductType } from '@kokusai/smacere-shared/api';
import { Funeral } from 'src/models';
import { Location } from '@angular/common';
import { Format, Sort, SortDirection } from '@kokusai/utilities';

@Component({
  selector: 'app-condolence-video-home',
  templateUrl: './condolence-video-home.component.html',
  styleUrls: ['./condolence-video-home.component.scss'],
})
export class CondolenceVideoHomeComponent implements OnInit {
  Funeral: Funeral = {} as Funeral;
  condolenceVideos: Array<any>;
  currentItemIndex: number = 0;

  constructor(
    private modalService: NgbModal,
    private apiService: APIService,
    private storage: GlobalStorage,
    private location: Location
  ) {
    this.Funeral = this.storage.Funeral;
  }

  async ngOnInit(): Promise<void> {
    const result = await this.apiService.CondolenceVideoByFuneralId(this.Funeral.funeralId, null, {
      approved: { eq: true },
      accessLevel: { eq: AccessLevel.Public },
    });

    this.condolenceVideos = result.items.sort(Sort.byDate('createdAt', SortDirection.ASC));

    for (let item of this.condolenceVideos) {
      const orderItem = await this.apiService.OrderItemByOrderId(item.orderId);
      const condolenceVideo = orderItem.items?.find((video) => video.productType === ProductType.CondolenceVideo);
      item.lastName = condolenceVideo?.attributes?.cardSenderLastName;
      item.lastNameKana = condolenceVideo?.attributes?.cardSenderLastNameKana;
      item.firstName = condolenceVideo?.attributes?.cardSenderFirstName;
      item.firstNameKana = condolenceVideo?.attributes?.cardSenderFirstNameKana;
      item.cardSenderName = Format.fullName(
        condolenceVideo?.attributes?.cardSenderFirstName,
        condolenceVideo?.attributes?.cardSenderLastName
      );
      item.cardMessage = condolenceVideo?.attributes?.cardMessage;
      item.company = condolenceVideo?.attributes?.cardSenderCompany;
      item.position = condolenceVideo?.attributes?.cardSenderPosition;
      item.customName = condolenceVideo?.attributes?.customName;
      item.cardDestinationName = Format.fullName(
        condolenceVideo?.attributes.cardDestinationFirstName,
        condolenceVideo?.attributes.cardDestinationLastName
      );
    }
  }

  onReturn(): void {
    this.location.back();
  }

  openModal(template: TemplateRef<any>, index) {
    this.currentItemIndex = index;
    this.modalService.open(template, { centered: true, size: 'xl' });
  }

  nextImage(): void {
    this.currentItemIndex = this.currentItemIndex + 1;
  }

  previousImage(): void {
    this.currentItemIndex = this.currentItemIndex - 1;
  }

  get showNextImageButton() {
    return this.currentItemIndex < this.condolenceVideos.length - 1;
  }

  get showPreviousImageButton() {
    return this.currentItemIndex > 0;
  }
}
