<div class="container">
  <video
    *ngIf="displayRecording; else noRecording"
    id="video"
    #video
    autoplay="false"
    controls="true"
    playsinline
    vtMedia
    [object]="recordingMedia"
  ></video>
  <ng-template #noRecording>
    <div class="mt-2 text-center">
      <p class="stream-info">{{ 'streamInfo' | i18n }}</p>
      <div
        class="btn-group btn-group-toggle btn-stream"
        ngbRadioGroup
        name="playStreamUrl"
        [(ngModel)]="streamingUrl"
        *ngIf="isStreamNotEmpty"
      >
        <label
          ngbButtonLabel
          class="btn-light col-12 col-sm-5 col-lg-3 mr-md-2"
          *ngFor="let item of streamButtons; trackBy: trackByMethod"
        >
          <input ngbButton id="{{ 'stream-item-' + item.index }}" type="radio" [value]="item.url" />
          <span>{{ item.index }}</span>
          {{ item.text }}
        </label>
      </div>
    </div>

    <video
      id="video"
      #video
      autoplay="true"
      controls="true"
      class="img-fluid"
      hls
      [src]="streamingUrl"
      controlsList="nodownload"
      oncontextmenu="return false;"
    ></video>
  </ng-template>

  <div class="mt-4 w-100">
    <button id="stream-return-button" class="btn btn-back btn-secondary" (click)="goBack()">
      < {{ 'return' | i18n }}
    </button>
  </div>
</div>
