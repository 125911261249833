import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FuneralService } from 'src/services/funeral.service';

@Component({
  selector: 'app-empty',
  templateUrl: './funeral-missing.component.html',
  styleUrls: ['./funeral-missing.component.scss'],
})
export class FuneralMissingComponent implements OnInit {
  constructor(private router: Router, private funeralService: FuneralService) {}

  public funeralId: string;
  public idNotFound: boolean = false;

  public ngOnInit(): void {}

  public Check(): void {
    this.funeralService.IsPresent(this.funeralId).subscribe((isPresent: boolean) => {
      if (isPresent) {
        this.router.navigate(['/', this.funeralId]);
      } else {
        this.idNotFound = true;
      }
    });
  }
}
