<div class="guest-book-form">
  <div class="loading-indicator" *ngIf="loading">
    <div class="spinner-border spinner-border-lg" role="status"></div>
  </div>
  <h3 class="guest-book-form-header">名刺の画像を利用して情報入力</h3>
  <div class="guest-book-form-contents">
    <p>名刺を撮影して頂き、画像をアップロードして頂きますと画像の情報を取り込み、自動入力いたします。</p>
    <!-- upload button container -->
    <div class="upload-button-container">
      <button class="upload-button" (click)="fileInput.click()">
        <span class="upload-button-title">
          {{ 'uploadCardButton' | i18n }}
        </span>
        <img class="upload-button-img" src="assets/svg/upload.svg" />
        <input #fileInput style="display: none" type="file" accept=".png, .jpg, image/jpeg" (change)="onChange($event)"/>
      </button>
      <div class="upload-button-remark-container">
        <img class="upload-button-remark-img" src="assets/svg/card-icon.svg" alt="" width="30" height="30" />
        <span>{{ 'dragCardToUpload' | i18n }}</span>
      </div>
    </div>
  </div>
  <div class="guest-book-form-footer">
    <button class="footer-button">ホルダーから画像を選択</button>
    <span class="footer-remark">注）レイアウトや画像によっては情報が 正しく取得できない場合がございます</span>
  </div>
</div>
