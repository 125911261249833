import { Component } from '@angular/core';

@Component({
  selector: 'Timer',
  styleUrls: ['./timer.component.scss'],
  template: ` <div class="wrapper">
    <div class="pie spinner"></div>
    <div class="pie filler"></div>
    <div class="mask"></div>
  </div>`,
})
export class TimerComponent {}
