import { AddressObjectInput, AddressType } from '@kokusai/smacere-shared/api';

export class Address implements AddressObjectInput {
  constructor() {
    this.firstName = '';
    this.lastName = '';
    this.firstNameKana = '';
    this.lastNameKana = '';
    this.customName = '';
    this.lastNameKana = '';
    this.maidenName = '';
    this.maidenNameKana = '';
    this.postalCode = '';
    this.prefecture = '';
    this.city = '';
    this.street = '';
    this.building = '';
    this.company = '';
    this.position = '';
    this.telephone = '';
    this.email = '';
    this.birthday = null;
    this.relationship = '';
  }

  index?: number | null;
  type?: AddressType | null;
  postalCode?: string | null;
  country?: string | null;
  prefecture?: string | null;
  city?: string | null;
  street?: string | null;
  building?: string | null;
  company?: string | null;
  position?: string | null;
  telephone?: string | null;
  firstName?: string | null;
  firstNameKana?: string | null;
  lastName?: string | null;
  lastNameKana?: string | null;
  customName?: string | null;
  maidenName?: string | null;
  maidenNameKana?: string | null;
  email?: string | null;
  confirmEmail?: string | null;
  paymentMethod?: string | null;
  birthday?: string | null;
  relationship?: string | null;
}
