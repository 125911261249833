<div id="flower-present" class="no-flex" [@inOutAnimation]>
  <div class="container">
    <h4 class="page-header">{{ 'flowerPresentHeader' | i18n }}</h4>
    <h5 class="text-center">
      {{ 'flowerPresentSubHeader' | i18n }}
    </h5>
    <h5 class="text-center">
      <u>{{ 'shippingWarning' | i18n }}</u>
    </h5>
  </div>
  <div class="grey-wrapper bg-grey">
    <div class="container">
      <ul class="product-list present row">
        <li
          *ngFor="let item of Presents; let i = index"
          class="col-6 col-md-4 mb-2 d-flex flex-column justify-content-between"
          [ngClass]="{ 'product-active': item.id == selectedPresent?.id }"
        >
          <label for="product-{{ i }}">
            <img class="img-fluid" vtMedia [object]="item.picture" />
            <p class="product-title">
              {{ item.name }}
            </p>
          </label>
          <div class="choice">
            <RadioButton
              id="product-{{ i }}"
              name="present"
              [value]="item.id"
              [checked]="item.id == selectedPresent?.id"
              (change)="OSelectChange(item)"
            ></RadioButton>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <!-- buttons -->
  <div class="buttons-container">
    <button id="return-button" class="nav-button return-button" (click)="back()">
      {{ 'return' | i18n }}
    </button>
    <button id="add-button" class="nav-button add-button" (click)="addAction()" *ngIf="!isEdit">
      {{ 'addNewOrder' | i18n }}
    </button>
    <button id="next-button" class="nav-button next-button" (click)="saveAction()">
      {{ 'decideAndNext' | i18n }}
    </button>
  </div>
  <!-- <div class="container">
    <div class="btn-toolbar justify-content-between mt-2 mb-5" role="toolbar">
      <button id="flower-present-return-button" class="btn btn-outline-secondary" (click)="back()">
        {{ 'return' | i18n }}
      </button>
      <button id="flower-present-add-button" class="btn btn-secondary" (click)="addAction()" *ngIf="!isEdit">
        {{ 'addNewOrder' | i18n }}
      </button>
      <button id="flower-present-decide-and-next-button" class="btn btn-secondary" (click)="saveAction()">
        {{ 'decideAndNext' | i18n }}
      </button>
    </div>
  </div> -->
</div>
