import { Component, OnInit } from '@angular/core';
import { Funeral } from 'src/models';
import { GlobalStorage } from 'src/app/global/storage';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { APIService, MemorialShare, AccessLevel } from '@kokusai/smacere-shared/api';
import { Sort, SortDirection } from '@kokusai/utilities';
@Component({
  selector: 'app-memorial-share-home',
  templateUrl: './memorial-share-home.component.html',
  styleUrls: ['./memorial-share-home.component.scss'],
})
export class MemorialShareHomeComponent implements OnInit {
  Funeral: Funeral = {} as Funeral;
  memorialShareAlbum: Array<MemorialShare>;

  constructor(
    private storage: GlobalStorage,
    private location: Location,
    private router: Router,
    private apiService: APIService
  ) {
    this.Funeral = this.storage.Funeral;
  }

  ngOnInit(): void {
    this.apiService
      .MemorialShareByFuneralId(this.Funeral.funeralId, null, {
        approved: { eq: true },
        accessLevel: { eq: AccessLevel.Public },
      })
      .then((result) => {
        this.memorialShareAlbum = result.items.sort(Sort.byDate('createdAt', SortDirection.ASC));
      });
  }

  get funeralEndDate() {
    return this.Funeral.funeralCloseDate || '';
  }

  upload(): void {
    this.router.navigate(['/', this.Funeral.funeralId, 'memorial-share', 'upload']);
  }

  back(): void {
    this.location.back();
  }
}
