<div class="transparent-background"></div>
<div class="condolence-video-page">
  <div class="condolence-video-contents">
    <div class="condolence-video-header">
      <span class="title">スマート弔電</span>
    </div>
    <div class="condolence-video-container">
      <app-photo-album [album]="condolenceVideos" type="video"></app-photo-album>
    </div>
  </div>
  <div class="condolence-video-footer">
    <button id="return" class="return-button" (click)="onReturn()">
      {{ 'return' | i18n }}
    </button>
  </div>
</div>
