import { Directive, ElementRef, HostListener } from '@angular/core';

import * as HLS from 'hls.js';

@Directive({
  selector: '[hls]',
})
export class VideoHLSDirective {
  private hls: any;

  @HostListener('loadstart', ['$event'])
  loadstart(e: any) {
    const video = e.target;
    if (video.src.indexOf('blob') === -1 && video.src !== video.baseURI && video.src.indexOf('.mp4') === -1) {
      this.InitVideo(video);
    }
  }

  constructor(el: ElementRef) {}

  private InitVideo(video: any) {
    if (this.hls) {
      this.hls.destroy();
    }
    this.hls = new HLS({
      startLevel: 2,
      capLevelToPlayerSize: true,
    });
    if (HLS.isSupported()) {
      this.hls.loadSource(video.src);
      this.hls.attachMedia(video);
      this.hls.on(HLS.Events.MANIFEST_PARSED, () => {
        video.play();
      });
    }
  }
}
