export const merge = (mainObj: object, subObj: object) => {
  if (mainObj && subObj) {
    if (Object.keys(mainObj).length === 0) {
      return subObj;
    } else if (Object.keys(subObj).length === 0) {
      return mainObj;
    } else {
      const obj = { ...mainObj };
      for (const key in obj) {
        if (subObj.hasOwnProperty(key)) {
          if (typeof obj[key] === 'object' && typeof subObj[key] === 'object') {
            obj[key] = merge(obj[key], subObj[key]);
          } else {
            obj[key] = subObj[key];
          }
        }
      }
      for (const key in subObj) {
        if (!mainObj.hasOwnProperty(key)) {
          obj[key] = subObj[key];
        }
      }
      return obj;
    }
  } else {
    return null;
  }
};
