import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { PaymentType } from '@kokusai/smacere-shared/api';
import { GlobalStorage } from 'src/app/global/storage';
import { Funeral } from 'src/models/Funeral';
@Component({
  selector: 'app-payment-method',
  templateUrl: './payment-method.component.html',
  styleUrls: ['./payment-method.component.scss'],
})
export class PaymentMethodComponent implements OnInit, OnChanges {
  selectedMainPaymentMethod: PaymentType.paypal | 'other';
  selectedSubPaymentMethod: PaymentType;

  PaymentType = PaymentType;
  @Input() paymentMethod: PaymentType;
  @Output() paymentMethodChange: EventEmitter<PaymentType> = new EventEmitter<PaymentType>();
  @Input() disableOtherPaymentMethods: boolean = false;

  enabledMethods: PaymentType[] = [PaymentType.paypal, PaymentType.bank_transfer, PaymentType.cash];
  funeral: Funeral;

  constructor(private storage: GlobalStorage) {
    this.funeral = this.storage.Funeral;
  }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.paymentMethod.currentValue === PaymentType.paypal) {
      this.selectedMainPaymentMethod = PaymentType.paypal;
    } else {
      this.selectedMainPaymentMethod = 'other';
      this.selectedSubPaymentMethod = changes.paymentMethod.currentValue;
    }
  }

  updatePaymentMethod(): void {
    this.paymentMethod =
      this.selectedMainPaymentMethod === PaymentType.paypal ? PaymentType.paypal : this.selectedSubPaymentMethod;
    this.paymentMethodChange.emit(this.paymentMethod);
  }

  get disableSubPaymentMethods() {
    return (
      typeof this.selectedMainPaymentMethod === 'undefined' || this.selectedMainPaymentMethod === PaymentType.paypal
    );
  }

  get displayOtherPaymentMethods(): boolean {
    return this.funeral?.allowedPaymentTypes?.length > 0;
  }

  get displayBankPaymentMethod(): boolean {
    return (
      this.funeral.allowedPaymentTypes?.includes(PaymentType.bank_transfer) &&
      this.enabledMethods.includes(PaymentType.bank_transfer)
    );
  }

  get displayConvenienceStorePaymentMethod(): boolean {
    return (
      this.funeral.allowedPaymentTypes?.includes(PaymentType.convenience_store) &&
      this.enabledMethods.includes(PaymentType.convenience_store)
    );
  }

  get displayCashPaymentMethod(): boolean {
    return (
      this.funeral.allowedPaymentTypes?.includes(PaymentType.cash) && this.enabledMethods.includes(PaymentType.cash)
    );
  }
}
