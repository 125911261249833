import { Component, OnInit } from '@angular/core';
import { DecimalPipe, Location } from '@angular/common';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { GlobalStorage } from 'src/app/global/storage';
import { MoneyGiftService } from 'src/services';
import { Present, Order, Food, MoneyGift } from 'src/models';
import { environment } from 'src/environments/environment';
import { APIService, ItemAttributeInput, LookupType } from '@kokusai/smacere-shared/api';
import { DisplayElementsService } from 'src/app/services/display-elements.service';
import { BundleCartService, BundleType } from '../../../../services/bundle-cart.service';
import { I18n } from 'aws-amplify';

type CustomMoneyGift = {
  text: string;
  value: number;
};
const CUSTOM_MONEYGIFT_DEFAULT_VALUE = 0;

@Component({
  selector: 'app-gift-money-select',
  templateUrl: './money-gift-selection.component.html',
  styleUrls: ['./money-gift-selection.component.scss'],
})
export class MoneyGiftSelectionComponent implements OnInit {
  constructor(
    private storage: GlobalStorage,
    private moneyGiftService: MoneyGiftService,
    private route: Router,
    private location: Location,
    private decimalPipe: DecimalPipe,
    private apiService: APIService,
    private displayElementsService: DisplayElementsService,
    private bundleCartService: BundleCartService
  ) {}

  private Order: Order = null;

  moneyGift: MoneyGift[] = [];
  selectedMoneyGift?: MoneyGift = null;
  customMoneyGift: MoneyGift = null;
  customMoneyGiftList: CustomMoneyGift[] = [];
  customMoneyGiftDefaultValue = { text: '選択してください', value: 0 };
  totalAmount?: number = null;
  InputPresentSum: number = 0; // TODO: What is this? Rename?
  selectedMoneyPresent: Present = null;
  selectedFood: Food = null;
  environment = environment;
  attributes: ItemAttributeInput;
  moneyGiftRelationshipData: string[];
  moneyGiftYearsKnownData: number[] = [];

  MIN_AMOUNT: number = 100000 + environment?.moneyGiftIncrementAmount;
  MAX_AMOUNT: number = environment?.moneyGiftMaxAmount;
  STEP: number = environment.moneyGiftIncrementAmount;

  ngOnInit(): void {
    this.bundleCartService.initStep(BundleType.GiftMoney, 'order');

    this.Order = this.storage.Order;
    this.moneyGiftService.gets().subscribe((moneyGifts: MoneyGift[]) => {
      this.moneyGift = moneyGifts;
      this.InputPresentSum = this.moneyGift[this.moneyGift.length - 1].price; // TODO: What the hell is InputPresentSum???
      if (this.Order.MoneyGiftOrder && this.Order.MoneyGiftOrder.Product?.id !== '') {
        if (this.Order.MoneyGiftOrder.Product?.price > 1) {
          this.selectedMoneyGift = this.Order.MoneyGiftOrder.Product;
          this.customMoneyGiftDefaultValue = this.getDefaultCustomValue(this.Order.MoneyGiftOrder.Product);
        } else if (this.Order.MoneyGiftOrder.Product) {
          this.totalAmount = this.Order.MoneyGiftOrder.Product.price * this.Order.MoneyGiftOrder.quantity;
        } else {
          this.totalAmount = 0;
        }
        this.selectedFood = this.Order.MoneyFood;
        this.selectedMoneyPresent = this.Order.MoneyPresent;
      }
    });

    this.apiService.LookupByType(LookupType.Relationship).then((result) => {
      this.moneyGiftRelationshipData = result.items.map((item) => item.message);
    });

    this.initAttributes();
    this.initMessageData();
    this.initCustomMoneyGiftList();
  }

  getDefaultCustomValue(product: MoneyGift) {
    if (product.id === 'kd-0') {
      return { text: this.formatPrice(product.price), value: product.price };
    } else {
      return { text: '選択してください', value: 0 };
    }
  }

  initCustomMoneyGiftList() {
    for (let price = this.MIN_AMOUNT; price <= this.MAX_AMOUNT; price += this.STEP) {
      this.customMoneyGiftList.push({ text: this.formatPrice(price), value: price });
    }
  }

  formatPrice(price: number) {
    return new Intl.NumberFormat('ja-JP').format(price);
  }

  goToMemorialShare(): void {
    if (this.selectedMoneyGift) {
      this.Order.MoneyGiftOrder.Product = this.selectedMoneyGift;
    }
    this.Order.MoneyGiftOrder.attributes = this.attributes;
    this.Order.MoneyPresent = this.selectedMoneyPresent;
    this.Order.MoneyFood = this.selectedFood;
    this.storage.Order = this.Order;

    this.route.navigate([this.storage.Funeral.funeralId, 'order', 'gift-money', 'memorial-share', 'upload']);
  }

  initAttributes(): void {
    if (!!this.Order.MoneyGiftOrder.attributes) {
      this.attributes = this.Order.MoneyGiftOrder.attributes;
    } else {
      this.attributes = {
        moneyGiftMessage: '',
        moneyGiftRecipient: 'family-only',
        moneyGiftRelationship: '',
        moneyGiftYearsKnown: null,
      };
    }
  }

  initMessageData(): void {
    this.moneyGiftRelationshipData = ['友人', '母', '父'];
    for (let i = 1; i < 100; i++) {
      this.moneyGiftYearsKnownData.push(i);
    }
  }

  showButtonPrice(item: MoneyGift) {
    return this.decimalPipe.transform(item.price, '1.0-0') + ' 円';
  }

  choosePrice(item: MoneyGift): void {
    this.selectedMoneyGift = item;
    this.totalAmount = null;
    this.selectedMoneyPresent = null;
    this.selectedFood = null;
  }

  chooseCustomPrice(selected: CustomMoneyGift) {
    if (selected.value === CUSTOM_MONEYGIFT_DEFAULT_VALUE) {
      if (!this.selectedMoneyGift) {
        return;
      }
      if (!this.selectedMoneyGift.id) {
        return;
      }
      this.totalAmount = null;
      this.selectedMoneyPresent = null;
      this.selectedFood = null;
      this.selectedMoneyGift = null;
    } else {
      this.totalAmount = null;
      this.selectedMoneyPresent = null;
      this.selectedFood = null;
      //TODO: remove hardcode in the future when BE fixed or FE can retrive correct id for dropdown
      this.selectedMoneyGift = {
        id: 'kd-0',
        sku: 'kd-0',
        name: 'お香典　150,000円以上',
        picture: null,
        price: selected.value,
      };
    }
  }

  save(): void {
    if (this.selectedMoneyGift || this.totalAmount) {
      if (this.selectedMoneyGift) {
        this.Order.MoneyGiftOrder.Product = this.selectedMoneyGift;
        this.Order.MoneyGiftOrder.quantity = 1;
      } else {
        this.Order.MoneyGiftOrder.Product = this.moneyGift[0];
        this.Order.MoneyGiftOrder.quantity = this.totalAmount; // TODO: Since Magento is not used, we need to remove this amount is quanity
      }

      this.Order.MoneyGiftOrder.attributes = this.attributes;
      this.Order.MoneyPresent = this.selectedMoneyPresent;
      this.Order.MoneyFood = this.selectedFood;
      this.storage.Order = this.Order;

      const steps = this.storage.OrderSteps;
      steps.isMoneyGiftStepCompleted = true;
      this.storage.OrderSteps = steps;
      this.route.navigate([this.storage.Funeral.funeralId, 'order', 'gift-money', 'order']);
    } else {
      Swal.fire({
        icon: 'error',
        title: '香典金額が未選択です',
        text: '香典金額をご選択または、ご入力ください',
      });
      return;
    }
  }

  get displayMemorialShare() {
    return this.displayElementsService.displayMemorialShare;
  }

  back(): void {
    this.location.back();
  }

  getTotalPrice(): number {
    if (!this.selectedMoneyGift || !this.selectedMoneyGift.price) {
      return 0;
    }
    return this.selectedMoneyGift.price;
  }

  get returnButtonLabel(): string {
    return this.bundleCartService.isEdit() ? I18n.get('goToApplicationConfirmation') : I18n.get('return');
  }
}
