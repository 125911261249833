import { Component, OnInit } from '@angular/core';
import { GlobalStorage } from 'src/app/global/storage';
import { MediaObjectInput } from '@kokusai/smacere-shared/api';

@Component({
  selector: 'app-memory-video',
  templateUrl: './memory-video.component.html',
  styleUrls: ['./memory-video.component.scss'],
})
export class MemoryVideoComponent {
  public MemorialMovie: MediaObjectInput = {};
  constructor(private storage: GlobalStorage) {
    this.MemorialMovie = this.storage.Funeral.memorialMovie;
  }
}
