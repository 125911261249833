import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { MediaObject, MediaObjectInput } from '@kokusai/smacere-shared/api';
import { Path } from '../core/utilities';

@Directive({
  selector: '[vtMedia]',
})
export class ImageDirective implements OnInit {
  @Input()
  object: MediaObjectInput | MediaObject;

  @Input()
  defaultUrl: string;

  constructor(private element: ElementRef) {
    this.element.nativeElement.onerror = () => {
      if (
        this.element.nativeElement.src.indexOf(this.defaultUrl) === -1 &&
        this.defaultUrl != null &&
        this.defaultUrl !== undefined
      ) {
        this.element.nativeElement.src = this.defaultUrl;
      }
    };
  }

  ngOnInit(): void {
    this.init(this.object);
  }

  init(object: MediaObjectInput | MediaObject): void {
    if (object && (object.url || object.file)) {
      const src = Path.getS3Path(object);
      this.element.nativeElement.src = src;
    } else if (this.defaultUrl !== null && this.defaultUrl !== undefined) {
      this.element.nativeElement.src = this.defaultUrl;
    }
  }
}
