<footer *ngIf="environment.showFooter">
  <ul>
    <li><a href="javascript:void(0);" (click)="OpenHPlModal()">個人情報の取り扱いについて</a></li>
    <li>/</li>
    <li><a href="javascript:void(0);" (click)="OpenSCTModal()">特定商取引法に基づく表示</a></li>
    <li>/</li>
    <li><a href="javascript:void(0);" (click)="OpenTOSModal()">利用規約</a></li>
    <li>/</li>
    <li *ngIf="isTenantCeremony">
      <a href="https://www.sougi.info/smart-ceremony/customer" target="_blank">お問合わせ</a>
    </li>
    <li *ngIf="isTenantCeremony">/</li>
    <li><a href="javascript:void(0);" (click)="OpenCOMModal()">推奨環境</a></li>
  </ul>
</footer>
