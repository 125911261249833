<div class="memorial-share-upload-container">
  <!-- header -->
  <div class="memorial-share-upload-header-container">
    <h5 class="memorial-share-upload-header">{{ 'memorialShare' | i18n }}</h5>
    <span>{{ 'pleaseUploadImagesFromBelow' | i18n }}</span>
  </div>
  <!-- contents -->
  <div class="memorial-share-contents">
    <div class="contents-header">
      <span>{{ 'memorialShareIsFreeService' | i18n }}</span>
    </div>
    <!-- upload-form -->
    <div class="upload-form">
      <div class="drag-zone" (appDropZone)="onDrop($event)">
        <div>
          <button (click)="fileInput.click()" class="drag-zone-button">
            <span class="drag-zone-button-title">{{ 'uploadButton' | i18n }}</span>
            <img class="drag-zone-button-image" alt="Icon" src="assets/svg/upload.svg" width="32" height="32" />
          </button>
          <input #fileInput type="file" class="d-none" (change)="onChange($event)" accept="video/*,image/*" multiple />
        </div>
        <div class="drag-zone-title">
          <img class="drag-zone-title-icon" src="assets/svg/image-icon-sqr.svg" alt="Icon" width="25" height="25" />
          <span class="drag-zone-title-text">{{ 'dragImageToUpload' | i18n }}</span>
        </div>
      </div>
      <div>
        <button (click)="fileInput.click()" class="upload-form-button">
          {{ 'selectAnImageFromTheHolder' | i18n }}
        </button>
      </div>
    </div>
    <!-- uploaded images -->
    <div class="uploaded-image-container" *ngFor="let file of files; let index = index">
      <div class="uploaded-image-wrapper">
        <img class="uploaded-image" [src]="file.thumbnail" alt="Thumbnail" *ngIf="file.thumbnail !== null" />
        <div class="uploaded-file-container">
          {{ 'fileName' | i18n }}：<span>{{ file.file?.name }}</span>
          <div class="progress-container">
            <progress class="progress-bar" [value]="file.progress" max="100"></progress>
            <button class="cancel-button" (click)="deleteFile(index)">
              {{ 'cancel' | i18n }}
            </button>
          </div>
          {{ 'fileSize' | i18n }}：<span>{{ file.file?.size }} B</span>
        </div>
      </div>
      <div class="comment-container">
        <label class="comment-label" [for]="'comment-' + index">{{ 'comment' | i18n }}</label>
        <textarea
          [id]="'comment-' + index"
          [name]="'comment-' + index"
          class="form-control comment-input"
          rows="3"
          [(ngModel)]="file.description"
        ></textarea>
      </div>

      <!-- access level -->
      <div class="access-container">
        <label [for]="'access-level-' + index" class="access-label">{{ 'access' | i18n }} :</label>
        <div class="radio-button-container">
          <div class="form-check form-check-inline" style="margin-right: 3em">
            <input
              class="form-check-input"
              [id]="'access-level-' + index + '-private'"
              [name]="'access-level-' + index"
              type="radio"
              value="Private"
              [(ngModel)]="file.accessLevel"
            />
            <label [for]="'access-level-' + index + '-private'" class="form-check-label">
              {{ 'privateAccess' | i18n }}
            </label>
          </div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              [id]="'access-level-' + index + '-public'"
              [name]="'access-level-' + index"
              type="radio"
              value="Public"
              [(ngModel)]="file.accessLevel"
            />
            <label [for]="'access-level-' + index + '-public'" class="form-check-label">
              {{ 'publicAccess' | i18n }}
            </label>
          </div>
        </div>
      </div>
    </div>
    <!-- name -->
    <div class="row p-3 name-container">
      <div class="col">
        <form #userForm="ngForm">
          <div class="form-row d-flex align-items-center">
            <label class="col-12 col-xl-2 col-md-4 mb-2">
              {{ 'name' | i18n }}<span class="required">{{ 'required' | i18n }}</span>
            </label>
            <div class="col-12 col-sm-6 col-xl-5 col-md-4 mb-2">
              <input
                id="lastName"
                name="lastName"
                #lastNameRef="ngModel"
                class="form-control"
                [(ngModel)]="lastName"
                placeholder="姓"
                [class]="{ 'is-invalid': lastNameRef.invalid && (!lastNameRef.untouched || isSubmitted) }"
                required
              />
              <div
                class="validate-message text-danger"
                *ngIf="lastNameRef.errors?.required && (!lastNameRef.untouched || isSubmitted)"
              >
                {{ 'lastNameIsRequiredMsg' | i18n }}
              </div>
            </div>
            <div class="col-12 col-sm-6 col-xl-5 col-md-4 mb-2">
              <input
                id="firstName"
                name="firstName"
                #firstNameRef="ngModel"
                class="form-control"
                [class]="{ 'is-invalid': firstNameRef.invalid && (!firstNameRef.untouched || isSubmitted) }"
                [(ngModel)]="firstName"
                placeholder="名"
                required
              />
              <div
                class="validate-message text-danger"
                *ngIf="firstNameRef.errors?.required && (!firstNameRef.untouched || isSubmitted)"
              >
                {{ 'firstNameIsRequiredMsg' | i18n }}
              </div>
            </div>
          </div>
          <div class="form-row d-flex align-items-center">
            <label for="nameKana" class="col-12 col-xl-2 col-md-4 mb-2">
              {{ 'nameKana' | i18n }}<span class="required">{{ 'required' | i18n }}</span>
            </label>
            <div class="col-12 col-sm-6 col-xl-5 col-md-4 mb-2">
              <input
                id="lastNameKana"
                name="lastNameKana"
                #lastNameKanaRef="ngModel"
                class="form-control"
                pattern="(?=.*?[\u30A1-\u30FC])[\u30A1-\u30FC\s]*"
                [class]="{ 'is-invalid': lastNameKanaRef.invalid && (!lastNameKanaRef.untouched || isSubmitted) }"
                [(ngModel)]="lastNameKana"
                placeholder=""
                required
              />
              <div
                class="validate-message text-danger"
                *ngIf="lastNameKanaRef.errors?.required && (!lastNameKanaRef.untouched || isSubmitted)"
              >
                {{ 'lastNameKanaIsRequiredMsg' | i18n }}
              </div>
              <div
                class="validate-message text-danger"
                *ngIf="lastNameKanaRef.errors?.pattern && (!lastNameKanaRef.untouched || isSubmitted)"
              >
                {{ 'nameKanaPatternError' | i18n }}
              </div>
            </div>
            <div class="col-12 col-sm-6 col-xl-5 col-md-4 mb-2">
              <input
                id="firstNameKana"
                name="firstNameKana"
                #firstNameKanaRef="ngModel"
                class="form-control"
                pattern="(?=.*?[\u30A1-\u30FC])[\u30A1-\u30FC\s]*"
                [class]="{ 'is-invalid': firstNameKanaRef.invalid && (!firstNameKanaRef.untouched || isSubmitted) }"
                [(ngModel)]="firstNameKana"
                placeholder=""
                required
              />
              <div
                class="validate-message text-danger"
                *ngIf="firstNameKanaRef.errors?.required && (!firstNameKanaRef.untouched || isSubmitted)"
              >
                {{ 'firstNameKanaIsRequiredMsg' | i18n }}
              </div>
              <div
                class="validate-message text-danger"
                *ngIf="firstNameKanaRef.errors?.pattern && (!firstNameKanaRef.untouched || isSubmitted)"
              >
                {{ 'nameKanaPatternError' | i18n }}
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <!-- add to the list button -->
    <div class="add-to-list-button-container">
      <button class="add-to-list-button" (click)="addImages(userForm)" [disabled]="files.length === 0">
        <span class="add-to-list-button-title">
          <i class="fa fa-arrow-circle-down add-to-list-button-icon"></i>
          <span>{{ 'addToTheListOfSubmissionTargets' | i18n }}</span>
        </span>
      </button>
    </div>
    <!-- list of submissions -->
    <div class="list-of-submissions-container">
      <div class="list-of-submissions-header">
        <h6>{{ 'listOfSubmissions' | i18n }}</h6>
        <div class="list-of-submissions-notes">
          <ul class="notes">
            <li>{{ 'toDeleteImagesClickTheXButton' | i18n }}</li>
            <li>{{ 'toUpdateAnImageDoubleClickOnTheImage' | i18n }}</li>
          </ul>
          <button class="delete-all-button" (click)="deleteAll()" [disabled]="images.length === 0">
            {{ 'deleteAllImages' | i18n }}
          </button>
        </div>
      </div>
      <div class="list-of-submissions-contents">
        <div *ngFor="let image of images; let index = index" class="submissions-item">
          <button class="close-button" (click)="deleteImage(index)">&times;</button>
          <img
            class="item-image"
            [src]="image.thumbnail"
            alt="Thumbnail"
            *ngIf="image.thumbnail !== null"
            (click)="editImage(image, index)"
          />
          <span>{{ image.input.lastName }}　{{ image.input.firstName }}</span>
          <span class="item-description">{{ image.input.description }}</span>
        </div>
      </div>
    </div>
    <!-- user agreement -->
    <div class="user-agreement-container">
      <p class="user-agreement-title">画像・動画投稿につきましては、下記内容をご確認頂きご了承をお願いいたします。</p>
      <ol class="user-agreement-list">
        <li>本サービスの利用規約をご確認頂いたうえで画像・動画の投稿をお願いします。</li>
        <li>
          投稿した画像・動画は、個々に正しく表示・再生されることをご確認下さい。
          <br />
          ※正しく表示・再生されない画像・動画については削除し、投稿をおやめ下さい。
        </li>
        <li>画像の形式は、JPEG でお願いします。</li>
        <li>画像のサイズは、L版（1,047✕1,524(150万画素相当)）を推奨いたします。</li>
        <li>動画の映像時間につきましては、15分以内でお願いします。</li>
        <li>動画の形式は、MOV,MP4 でお願いします。</li>
        <li>
          動画の仕様などは、HD/30fpsを推奨いたします。
          <br />
          ※推奨形式の動画でも、本サービスにて動画をご利用頂けない場合がございます。
        </li>
        <li>スマートセレモニー運営会社にて、適切な画像・動画の仕様に変換させて頂く場合がございます。</li>
        <li>
          画像・動画の内容につきましては、葬家又はスマートセレモニー運営会社にて確認させて頂き、
          <br />
          葬儀に不適切と判断した場合には公開を取り止めさせて頂きます。
          <br />
          その際、葬家又はスマートセレモニー運営会社からご投稿者様へのご連絡は行いません。
          <br />
          ※葬家又はスマートセレモニー運営会社の確認環境にて、内容確認できないなどの事象も含みます。
        </li>
        <li>画像・動画公開までには、数日のお時間を頂く場合がございます。</li>
        <li>
          香典受付の際に、投稿された画像・動画につきましては、香典などの決済処理を完了する必要がございます。
          受付を途中でやめられた場合、画像・動画の投稿はキャンセルといたします。
        </li>
        <li>動画または画像以外に、お名前、コメントなども公開情報に含まれますのでご注意ください。</li>
      </ol>
      <div class="d-flex justify-content-center">
        <div class="form-check form-check-inline agreement-checkbox-container">
          <input id="agreement-checkbox" class="form-check-input" type="checkbox" [(ngModel)]="isAgreedToUserTerms" />
          <label for="agreement-checkbox" class="form-check-label">
            <strong>{{ 'iAgreeToTheAboveAndSubmitTheImage' | i18n }}</strong>
          </label>
        </div>
      </div>
    </div>
  </div>
  <!-- buttons -->
  <div class="buttons-container">
    <button id="return-button" class="nav-button return-button" (click)="back()">
      {{ 'return' | i18n }}
    </button>
    <button id="next-button" class="nav-button memorial-button" (click)="save()">
      <span *ngIf="!saving">{{ 'saveToAlbum' | i18n }}</span>
      <i *ngIf="saving" class="fa fa-spinner fa-spin"></i>
    </button>
  </div>

  <!-- footer -->
  <!-- <div class="memorial-share-footer">
    <button class="return-button-outline-rect footer-return-button" (click)="back()">
      {{ 'return' | i18n }}
    </button>
    <button class="next-button-rect" (click)="save()">
      <span *ngIf="!saving">{{ 'saveToAlbum' | i18n }}</span>
      <i *ngIf="saving" class="fa fa-spinner fa-spin"></i>
    </button>
  </div> -->
</div>
