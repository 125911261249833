import { Component, OnInit, ViewChild } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { merge, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';
import { NgbModal, NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { DictionaryService } from 'src/services';
import { GlobalStorage } from 'src/app/global/storage';
import { Dictionary, FlowerOrder } from 'src/models';
import { FlowerNameSampleComponent } from '../flower-name-sample/flower-name-sample.component';
import { AddressType } from '@kokusai/smacere-shared/api';
import { BundleCartService, BundleType } from '../../../../services/bundle-cart.service';
import { AddressComponent } from '../../../../components/address/address.component';
import { ViewType } from 'src/app/components/address/types/view-type.type';

@Component({
  selector: 'app-flowers-order',
  templateUrl: './flowers-order.component.html',
  styleUrls: ['./flowers-order.component.scss'],
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [style({ opacity: 0 }), animate('1s ease-out', style({ opacity: 1 }))]),
      transition(':leave', [style({ opacity: 1 }), animate('1s ease-in', style({ opacity: 0 }))]),
    ]),
  ],
})
export class FlowersOrderComponent implements OnInit {
  @ViewChild('instance', { static: true }) instance: NgbTypeahead;
  focus$ = new Subject<string>();
  click$ = new Subject<string>();
  FlowerOrder: FlowerOrder = null;
  companyTitles: string[] = [];
  AddressType = AddressType;

  isSubmit: boolean = false;
  public hasPresents: boolean = false;
  public isEdit: boolean = false;
  ViewType = ViewType;
  @ViewChild('flowerOrderForm') flowerOrderForm: NgForm;
  @ViewChild('shippingForm') shippingForm: AddressComponent;

  constructor(
    private storage: GlobalStorage,
    private dictionaryService: DictionaryService,
    private modalService: NgbModal,
    private route: Router,
    private location: Location,
    private bundleCartService: BundleCartService
  ) {}

  ngOnInit(): void {
    this.bundleCartService.checkStep(BundleType.Flower, 'order', 'present');

    const isFlowerOrderShippingAddress = Object.values(this.storage.Order.FlowerOrder?.ShippingAddress).some((item) =>
      Boolean(item)
    );
    const isOrderShippingAddress = Object.values(this.storage.Order.ShippingAddress).some((item) => Boolean(item));
    if (isFlowerOrderShippingAddress) {
      this.FlowerOrder = {} as FlowerOrder;
      this.FlowerOrder.ShippingAddress = this.storage.Order.FlowerOrder?.ShippingAddress;
    } else if (isOrderShippingAddress) {
      this.storage.Order.FlowerOrder.ShippingAddress = { ...this.storage.Order.ShippingAddress };
    } else {
      this.storage.Order.FlowerOrder.ShippingAddress = { ...this.storage.Order.CustomerAddress };
    }
    this.FlowerOrder = this.storage.Order.FlowerOrder;

    // TODO: to be enabled once the client decides to use this prefilled flower name
    // this.initializeFlowerName();

    if (this.FlowerOrder.ShippingAddress) {
      this.dictionaryService.getCompanyTitles().then((items: Dictionary[]) => {
        this.companyTitles = items.map((x) => x.title);
      });
    }
    this.isEdit = this.bundleCartService.isEdit();
    this.hasPresents = this.storage.Funeral.displayFlowerPresent;
  }

  initializeFlowerName() {
    if (this.FlowerOrder.flowerName === '') {
      this.FlowerOrder.flowerName = `${this.storage.Order.CustomerAddress?.lastName || ''}　${
        this.storage.Order.CustomerAddress?.firstName || ''
      }
${this.storage.Order.CustomerAddress?.company || ''}
${this.storage.Order.CustomerAddress?.position || ''}`;
    }
  }

  Search(value: Observable<string>) {
    const debouncedText$ = value.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance.isPopupOpen()));
    const inputFocus$ = this.focus$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map((term) =>
        (term === ''
          ? this.companyTitles
          : this.companyTitles.filter((v) => v.toLowerCase().indexOf(term.toLowerCase()) > -1)
        ).slice(0, this.companyTitles.length)
      )
    );
  }

  capitalize(value: string) {
    return value[0].toUpperCase() + value.slice(1);
  }

  private saveToStorage(): boolean {
    this.isSubmit = true;
    if (this.flowerOrderForm.invalid) {
      for (const [key, control] of Object.entries(this.flowerOrderForm.controls)) {
        if (control.invalid) {
          document.getElementById(key).focus();
          return false;
        }
      }
      return false;
    }

    // check shipping form
    if (!this.FlowerOrder.IsDestinationSame) {
      if (this.shippingForm.userForm.invalid) {
        for (const [key, control] of Object.entries(this.shippingForm.userForm.controls)) {
          if (control.invalid) {
            document.getElementById(`shipping${this.capitalize(key)}`)?.focus();
            return false;
          }
        }
        return false;
      }
      for (const [key, control] of Object.entries(this.shippingForm.userForm.controls)) {
        this.FlowerOrder.ShippingAddress[key] = key === 'postalCode' ? control.value.postalCode : control.value;
      }
    }

    const order = this.storage.Order;
    order.FlowerOrder = this.FlowerOrder;
    this.storage.Order = order;

    return true;
  }

  async nextAction(): Promise<void> {
    if (!this.saveToStorage()) {
      return;
    }

    if (this.hasPresents) {
      await this.route.navigate([this.storage.Funeral.funeralId, 'order', 'flowers', 'presents']);
    } else {
      await this.bundleCartService.saveFlowerFromStorage();
      await this.bundleCartService.goToNextStep(BundleType.Flower);
    }
  }

  async addAction(): Promise<void> {
    if (this.saveToStorage()) {
      await this.bundleCartService.saveFlowerFromStorage();
      await this.bundleCartService.goToAdd(BundleType.Flower);
    }
  }

  back(): void {
    this.location.back();
  }

  OpenFlowerNameSampleModal() {
    this.modalService.open(FlowerNameSampleComponent, {
      windowClass: 'common-modal',
    });
  }
}
