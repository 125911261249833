import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Path } from 'src/app/core/utilities';
import { ReceptionCloseService, SetFontSizeService } from 'src/services';
import { GlobalStorage } from 'src/app/global/storage';
import { Funeral, OrderSteps } from 'src/models';
import { StepType } from 'src/types';
import moment from 'moment';
import { FuneralStatusType } from 'src/types/funeral-status.types';
import { environment } from 'src/environments/environment';
import { BundleCartService } from '../../services/bundle-cart.service';
import { MemorialType } from '@kokusai/smacere-shared/api';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss'],
})
export class WelcomeComponent implements OnInit, OnDestroy {
  @Output()
  onNextClickEvent: EventEmitter<any> = new EventEmitter<any>();

  Funeral: Funeral = {} as Funeral;
  overnightFuneralStartTime: string;
  overnightFuneralEndTime: string;
  funeralStartTime: string;
  funeralEndTime: string;
  // isFuneralClosed: boolean;
  OrderSteps: OrderSteps;
  backgroundImage: string;
  backgroundImageMobile: string;
  fontSize: number;
  viewWidth: number;
  // hideStreamingButton: boolean;
  SPECIAL_ENFORCEMENT_NO: number = 2108000096;

  constructor(
    private storage: GlobalStorage,
    private receptionCloseService: ReceptionCloseService,
    private router: Router,
    private setFontSizeService: SetFontSizeService,
    private bundleCartService: BundleCartService
  ) {
    this.Funeral = this.storage.Funeral;
    this.OrderSteps = this.storage.OrderSteps;
    this.backgroundImage = Path.getPath('silver-lining-video.gif');
    this.backgroundImageMobile = Path.getPath('silver-lining-video.png');
  }

  ngOnInit(): void {
    this.bundleCartService.clear();
    if (!this.checkDate(this.Funeral.funeralDate) || this.Funeral.statusCode === FuneralStatusType.Cancel) {
      this.router.navigate([this.storage.Funeral.funeralId, 'no-reception']);
    }

    this.setFontSize();
    window.addEventListener('resize', this.setFontSize);

    if (this.Funeral.overnightFuneralStartTime) {
      this.overnightFuneralStartTime = '2020/01/01 ' + this.Funeral.overnightFuneralStartTime;
    } else {
      this.overnightFuneralEndTime = null;
      this.overnightFuneralStartTime = null;
    }

    if (this.Funeral.overnightFuneralEndTime) {
      this.overnightFuneralEndTime = '2020/01/01 ' + this.Funeral.overnightFuneralEndTime;
    } else {
      this.overnightFuneralEndTime = null;
    }

    if (this.Funeral.funeralStartTime) {
      this.funeralStartTime = '2020/01/01 ' + this.Funeral.funeralStartTime;
    } else {
      this.funeralEndTime = null;
      this.funeralStartTime = null;
    }

    if (this.Funeral.funeralEndTime) {
      this.funeralEndTime = '2020/01/01 ' + this.Funeral.funeralEndTime;
    } else {
      this.funeralEndTime = null;
    }

    // this.isFuneralClosed = this.IsCloseOrder();
    this.OrderSteps.isOrderConfirmationActive = false;
    this.storage.OrderSteps = this.OrderSteps;

    // this.hideStreamingButton = this.Funeral.displayMemorial === 'None' && !this.Funeral.displayStreaming;

    // if (this.isFuneralClosed && this.hideStreamingButton) {
    if (!this.displayOrderButton && !this.displayMediaButton) {
      this.router.navigate([this.storage.Funeral.funeralId, 'no-reception']);
    }
  }

  get displayFuneralLocation(): boolean {
    if (this.Funeral.enforcementNo === this.SPECIAL_ENFORCEMENT_NO) {
      return false;
    }
    return environment.modules?.displayFuneralLocation;
  }

  get displayFuneralDate(): boolean {
    if (this.Funeral.enforcementNo === this.SPECIAL_ENFORCEMENT_NO) {
      return false;
    }
    return environment.modules?.displayFuneralDate && this.Funeral.funeralDate.length > 0;
  }

  get displayOvernightFuneralDate(): boolean {
    if (this.Funeral.enforcementNo === this.SPECIAL_ENFORCEMENT_NO) {
      return false;
    }
    return (
      environment.modules?.displayFuneralDate &&
      this.Funeral.overnightFuneralDate &&
      this.Funeral.overnightFuneralDate.length > 0
    );
  }

  ngOnDestroy(): void {
    window.removeEventListener('resize', this.setFontSize);
  }

  setFontSize = (): void => {
    if (!this.setFontSizeService) {
      return;
    }

    this.fontSize = this.setFontSizeService.setFontSize(
      this.Funeral.deceasedName,
      this.Funeral.deceasedNamePrefix,
      this.Funeral.deceasedNameSuffix,
      this.Funeral.deceasedNamePostfix,
      this.viewWidth,
      2
    );
  };

  onNextClick(): void {
    this.onNextClickEvent.emit();
  }

  onGuestClick(): void {
    this.onNextClickEvent.emit();
  }

  GoToPaymentCheck(): void {
    this.router.navigate([this.storage.Funeral.funeralId, 'payment-check'], {
      queryParams: {
        openExternalBrowser: '1',
      },
    });
  }

  get flowerIsClose() {
    return this.receptionCloseService.getReceptionStatus(this.storage.Funeral, StepType.Flower).isFuneralClosed;
  }

  get cardIsClose() {
    return this.receptionCloseService.getReceptionStatus(this.storage.Funeral, StepType.Card).isFuneralClosed;
  }

  get condolenceVideoIsClose() {
    return this.receptionCloseService.getReceptionStatus(this.storage.Funeral, StepType.CondolenceVideo)
      .isFuneralClosed;
  }

  get moneyIsClose() {
    return this.receptionCloseService.getReceptionStatus(this.storage.Funeral, StepType.MoneyGift).isFuneralClosed;
  }

  get env(): string {
    return environment.env;
  }

  // private IsCloseOrder(): boolean {
  //   const isCloseFlower = !this.Funeral.displayFlower ? true : this.flowerIsClose;
  //   const isCloseCard = !this.Funeral.displayCard ? true : this.cardIsClose;
  //   const isCloseCondolenceVideo = !this.Funeral.displayCondolenceVideos ? true : this.condolenceVideoIsClose;
  //   const isCloseMoneyGift = !this.Funeral.displayMoney ? true : this.moneyIsClose;

  //   return isCloseFlower && isCloseCard && isCloseCondolenceVideo && isCloseMoneyGift;
  // }

  private checkDate(date: string): boolean {
    if (date) {
      const now = moment();
      const duration = moment.duration(now.diff(moment(date))).asDays();
      return duration <= 8;
    } else {
      return false;
    }
  }

  get displayOrderButton() {
    const displayFlower = this.Funeral.displayFlower && !this.flowerIsClose;
    const displayCard = this.Funeral.displayCard && !this.cardIsClose;
    const displayCondolenceVideos = this.Funeral.displayCondolenceVideos && !this.condolenceVideoIsClose;
    const displayMoneyGift = this.Funeral.displayMoney && !this.moneyIsClose;

    return displayFlower || displayCard || displayCondolenceVideos || displayMoneyGift;
  }

  get displayMediaButton() {
    return (
      this.Funeral.displayStreaming ||
      this.Funeral.displayMemorialShare ||
      this.Funeral.displayCondolenceVideos ||
      this.Funeral.displayMemorial !== MemorialType.None
    );
  }
}
