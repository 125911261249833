import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import moment from 'moment';

import { BaseService } from './base.service';

import { APIService, GetFuneralQuery, OnUpdateFuneralByIdSubscription, ProductType } from '@kokusai/smacere-shared/api';
import { ProductStorageService } from './product-storage.service';

import { FlowerTypes, FuneralStatusType } from 'src/types';
import { Flower, Food, Funeral } from 'src/models';
import { API, graphqlOperation } from 'aws-amplify';
import { Stream } from 'src/models/streams';
import { DateUtils } from 'src/app/core/utilities';

@Injectable()
export class FuneralService extends BaseService {
  /**
   *
   */
  constructor(http: HttpClient, apiService: APIService, productStorage: ProductStorageService) {
    super(http, apiService, productStorage);
  }

  get(id: string): Observable<Funeral> {
    return new Observable<Funeral>((subscriber) => {
      this.api.GetFuneral(id).then((result: GetFuneralQuery) => {
        if (!result) {
          subscriber.next(null);
          return;
        }

        const funeral = this.mapToFuneral(result);

        subscriber.next(funeral);
      });
    });
  }

  mapToFuneral(item: any): Funeral {
    const data: GetFuneralQuery = item;
    let deathDate = '';

    if (data.deathDate) {
      const date = moment(data.deathDate).toDate();
      deathDate = DateUtils.convertToJapaneseEra(date);
    }

    // 供花データ取得
    const flowerSelections: Flower[] = data.flowerSelections.items
      .map((flowerSelect) => {
        const flower = this.productStorage.getById(flowerSelect.flowerTypeId, ProductType.Flower);
        if (flower === null) {
          return null;
        }

        return new Flower({
          id: flower.id,
          sku: flower.sku,
          flowerStyle: flower.attributes.flowerStyle,
          picture: flower.picture,
          name: flower.name,
          description: flower.description,
          price: flower.price,
          flowerWidth: flower.attributes.flowerWidth,
          flowerHeight: flower.attributes.flowerHeight,
        });
      })
      .filter((value) => value !== null);

    let flowerTypes: FlowerTypes = null;
    if (flowerSelections && flowerSelections.length) {
      flowerSelections.sort((a, b) => {
        if (a.price < b.price) {
          return -1;
        }
        if (a.price > b.price) {
          return 1;
        }
        return 0;
      });

      const japaneseFlowers = flowerSelections.filter((flower) => {
        return flower.flowerStyle === FlowerTypes.Japanese;
      });

      const westernFlowers = flowerSelections.filter((flower) => {
        return flower.flowerStyle === FlowerTypes.Western;
      });

      flowerTypes =
        japaneseFlowers.length && westernFlowers.length
          ? FlowerTypes.Both
          : japaneseFlowers.length
          ? FlowerTypes.Japanese
          : FlowerTypes.Western;
    }

    // 会食
    const foodSelections: Food[] = data.foodSelections.items
      .map((foodSelection) => {
        const food = this.productStorage.getById(foodSelection.foodTypeId, ProductType.Food);
        const money = this.productStorage.getById(foodSelection.giftMoneyId, ProductType.MoneyGift);
        if (food === null || money === null) {
          return null;
        }

        return new Food({
          id: food.id,
          sku: food.sku,
          name: food.name,
          picture: food.picture,
          price: 0,
          PDFPath: '',
          foodAmount: money.price,
          cost: food.cost,
        });
      })
      .filter((value) => value !== null);

    const funeral = new Funeral({
      allowedPaymentTypes: data?.allowedPaymentTypes,
      statusCode: FuneralStatusType[data?.statusCode],
      funeralId: data?.id,
      streamingPassword: data?.streamingPassword,
      enforcementNo: data?.enforcementNo,
      funeralLocationAltName: data?.funeralLocationAltName,
      FuneralHallName: data?.funeralLocation?.funeralHallName,
      FuneralHallPhone: data?.funeralLocation?.funeralHallPhone,
      FuneralHallRoom: data?.funeralLocation?.funeralHallRoom,
      funeralName: data?.funeralName,
      deceasedPhoto: data?.deceasedPhoto,
      deceasedName: data?.deceasedName,
      funeralDate: data?.funeralDate,
      funeralStartTime: data?.funeralStartTime,
      funeralEndTime: data?.funeralEndTime,
      overnightFuneralDate: data?.overnightFuneralDate,
      overnightFuneralStartTime: data?.overnightFuneralStartTime,
      overnightFuneralEndTime: data?.overnightFuneralEndTime,
      deceasedAge: data?.deceasedAge,
      deathDate: deathDate,
      mourningName: data?.mourningName,
      FlowerType: flowerTypes,
      flowerSelections: flowerSelections,
      foodSelections: foodSelections,
      displayFlower: data?.displayFlower,
      displayFlowerPresent: data?.displayFlowerPresent,
      displayCard: data?.displayCard,
      displayCardPresent: data?.displayCardPresent,
      displayMoney: data?.displayMoney,
      displayFood: data?.displayFood,
      displayMoneyPresent: data?.displayMoneyPresent,
      displayStreaming: data?.displayStreaming,
      displayMemorial: data?.displayMemorial,
      memorialMovie: data?.memorialMovie,
      thankYouVideo: data?.thankYouVideo,
      memorialPictures: data?.memorialPictures?.sort((a, b) => (a.order > b.order ? 1 : -1)),
      displayMemorialShare: data?.displayMemorialShare,
      displayCondolenceVideos: data?.displayCondolenceVideos,
      streamingUrls: data.streamingUrls?.map((item) => this.mapStream(item)),
      deceasedNamePrefix: data?.deceasedNamePrefix ?? '故',
      deceasedNameSuffix: data?.deceasedNameSuffix ?? '儀',
      deceasedNamePostfix: data?.deceasedNamePostfix ?? '',
      overnightFuneralLabel: data?.overnightFuneralLabel ?? '通夜式',
      funeralLabel: data?.funeralLabel?.replace('・', '<br/>・'),
      mourningEmails: data?.mourningEmails,
      owner: data?.owner,
      welcomeMessage: data?.welcomeMessage,
      companyName: data?.companyName,
      companyTitle: data?.companyTitle,
      funeralRecording: data?.funeralRecording,
      overnightFuneralRecording: data?.overnightFuneralRecording,
      funeralCloseDate: data?.funeralCloseDate,
    });

    return funeral;
  }

  private mapStream(data: any): Stream {
    return {
      url: data.streamingUrl,
      date: data.streamingDate,
      time: data.streamingTime,
      isOnline: data.streamingStatus === 'CHANNEL_RUNNING',
      isDeleted: false, // data.streamingStatus == 'CHANNEL_DELETED', // this means it's not live, not to hide the video
      type: data.streamingType,
    } as Stream;
  }

  IsPresent(id: string): Observable<boolean> {
    return new Observable<boolean>((subscribe) => {
      this.api.GetFuneral(id).then((funeral) => {
        subscribe.next(funeral != null);
      });
    });
  }

  onFuneralUpdate(id: string): Observable<Funeral> {
    return new Observable<Funeral>((subscribe) => {
      const query = `subscription OnUpdateFuneralById($id: ID!) {
        onUpdateFuneralById(id: $id) {
          id
        }
      }`;

      const response = API.graphql(graphqlOperation(query, { id: id })) as any;

      response.subscribe((data: any) => {
        const funeralResponse: OnUpdateFuneralByIdSubscription = data.value.data.onUpdateFuneralById;
        this.get(funeralResponse.id).subscribe((funeral) => {
          subscribe.next(funeral);
        });
      });
    });
  }
}
