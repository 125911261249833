<amplify-authenticator *ngIf="authState !== 'signedin'">
  <amplify-sign-in slot="sign-in">
    <div slot="header-subtitle" class="sub-header">スマセレクラブ会員様は下記よりログインをお願いします。</div>
    <div slot="secondary-footer-content"></div>
  </amplify-sign-in>
</amplify-authenticator>

<div *ngIf="authState === 'signedin' && user" class="sign-out-form">
  <span class="sign-out-header">スマセレクラブ会員認証</span>
  <span class="sign-out-sub-header underline">スマセレクラブ会員様の認証を完了いたしました。</span>
  <span class="sign-out-sub-header"
    >当受付を完了されますと、スマセレクラブより履歴として ご確認いただくことができます。</span
  >
  <span class="sign-out-text">スマセレクラブ会員認証を解除される場合は、 下記ボタンを押下してください</span>
  <amplify-sign-out></amplify-sign-out>
</div>

<!-- <div class="sign-out-form" *ngIf="false">
  <span class="sign-out-header">スマセレクラブ会員認証</span>
  <span class="sign-out-sub-header underline">スマセレクラブ会員様の認証を完了いたしました。</span>
  <span class="sign-out-sub-header"
    >当受付を完了されますと、スマセレクラブより履歴として ご確認いただくことができます。</span
  >
  <span class="sign-out-text">スマセレクラブ会員認証を解除される場合は、 下記ボタンを押下してください</span>
  <button class="sign-out-button" (click)="signOut()">サインアウト</button>
</div> -->

<!-- <div class="log-in-form" *ngIf="false">
  <h6 class="log-in-form-header">メールアドレスを利用してスマセレクラブ認証</h6>
  <div class="log-in-header-contents">
    <p>スマセレクラブにご登録のメールアドレス、パスワードにて認証を行います。</p>
    <form class="member-form">
      <div class="form-row">
        <label class="form-label" for="email">メールアドレス</label>
        <input id="email" name="email" class="form-input" type="email" />
      </div>
      <div class="form-row">
        <label class="form-label" for="password">パスワード</label>
        <input id="password" name="password" class="form-input" type="password" />
      </div>
    </form>
    <button class="button amazon-button">ログイン</button>
  </div>
</div> -->
