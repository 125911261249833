<div [@inOutAnimation]>
  <div class="bg-grey pt-2">
    <div class="container">
      <div class="col-12 col-md-10 ml-auto mr-auto">
        <h4 class="page-header font-mincho">
          {{ 'detailsOfPaymentInfo' | i18n }}
        </h4>
      </div>
      <form #orderForm="ngForm" [mustMatch]="['Email', 'ConfirmEmail']" novalidate>
        <div>
          <div class="col-12 col-md-10 ml-auto mr-auto">
            <div
              *ngIf="displayUseDifferentBillingAddress"
              class="billing-address-select form-group row mb-2"
              style="font-size: small"
            >
              <label for="payer-information" class="label">ご決済者情報</label>
              <div id="payer-information" class="payer-info">
                <input
                  type="checkbox"
                  id="useDifferentBillingAddress"
                  name="useDifferentBillingAddress"
                  [(ngModel)]="Order.IsBillingDifferent"
                />
                <label class="checkbox-label m-0 pl-2" for="useDifferentBillingAddress"
                  >注文者とは別名義で決済をおこなう</label
                >
              </div>
            </div>
            <!-- billing address -->
            <app-address
              [type]="addressType.billing"
              [viewType]="ViewType.subFullAddress"
              [readonly]="!Order.IsBillingDifferent"
              #billingForm
            ></app-address>

            <!-- <div class="billing-address">
              <div class="form-group row">
                <div class="col-12 col-md-6">
                  <label for="LastName">
                    {{ 'billingName' | i18n }}
                    <span class="require">{{ 'required' | i18n }}</span>
                  </label>
                  <input
                    id="LastName"
                    name="LastName"
                    #LastName="ngModel"
                    type="text"
                    class="form-control"
                    [class]="{ 'is-invalid': LastName.invalid && (!LastName.untouched || isSubmit) }"
                    [(ngModel)]="Order.BillingAddress.lastName"
                    placeholder="姓"
                    maxlength="15"
                    required
                  />
                  <div
                    class="validate-message text-danger"
                    *ngIf="
                      (LastName.errors?.required && (!LastName.untouched || isSubmit)) ||
                      (FirstName.invalid && (!FirstName.untouched || isSubmit))
                    "
                  >
                    {{ 'billingNameIsRequiredMsg' | i18n }}
                  </div>
                </div>
                <div class="col-12 col-md-6 mt-2 mt-md-0">
                  <label for="FirstName">&nbsp;</label>
                  <input
                    id="FirstName"
                    name="FirstName"
                    #FirstName="ngModel"
                    type="text"
                    class="form-control"
                    [class]="{
                      'is-invalid': FirstName.invalid && (!FirstName.untouched || isSubmit)
                    }"
                    [(ngModel)]="Order.BillingAddress.firstName"
                    placeholder="名"
                    maxlength="15"
                    required
                  />
                </div>
              </div>
              <div class="form-group row">
                <div class="col-12 col-md-6 mt-2 mt-md-0">
                  <label for="CompanyName">{{ 'companyName2' | i18n }} </label>
                  <input
                    id="CompanyName"
                    name="CompanyName"
                    type="text"
                    class="form-control"
                    [(ngModel)]="Order.BillingAddress.company"
                    maxlength="20"
                  />
                </div>
                <div class="col-12 col-md-6 mt-2 mt-md-0">
                  <label for="Relationship">{{ 'relationship' | i18n }}</label>
                  <div>
                    <kendo-combobox
                      id="Relationship"
                      name="Relationship"
                      #Relationship="ngModel"
                      class="form-control"
                      MaxLength="20"
                      [allowCustom]="true"
                      [data]="Relationships"
                      [(ngModel)]="Order.Relationship"
                    >
                    </kendo-combobox>
                    <div
                      class="validate-message text-danger"
                      *ngIf="Relationship.errors?.maxLengthError && (!Relationship.untouched || isSubmit)"
                    >
                      {{ 'relationshipMaxLengthMessage' | i18n }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group information-address">
                <label for="BillingAddressPostalCode"
                  >{{ 'address' | i18n }}<span class="require">{{ 'required' | i18n }}</span></label
                >
                <div class="row form-group align-items-center ml-2 mr-1">
                  <label class="col-1 mb-0" for="BillingAddressPostalCode"> 〒 </label>
                  <PostalCode
                    id="BillingAddressPostalCode"
                    name="BillingAddressPostalCode"
                    #BillingAddressPostalCode="ngModel"
                    [prefectures]="Prefectures"
                    class="col-9 col-md-4"
                    myclass="form-control"
                    [(ngModel)]="Order.BillingAddress"
                    [IsInvalid]="{
                      'is-invalid':
                        BillingAddressPostalCode.invalid && (!BillingAddressPostalCode.untouched || isSubmit)
                    }"
                    tabindex="0"
                    required
                  >
                  </PostalCode>
                  <div
                    class="validate-message text-danger col-9 offset-1"
                    *ngIf="BillingAddressPostalCode.invalid && (!BillingAddressPostalCode.untouched || isSubmit)"
                  >
                    {{ 'postalCodeIsInvalidMsg' | i18n }}
                  </div>
                </div>
                <div class="row form-group align-items-center ml-2 mr-1">
                  <label for="BillingPrefecture" class="col-4 col-md-3 mb-0">
                    {{ 'prefecture' | i18n }}
                  </label>
                  <ng-select
                    id="BillingPrefecture"
                    name="BillingPrefecture"
                    #BillingPrefecture="ngModel"
                    [items]="Prefectures"
                    bindValue="code"
                    bindLabel="name"
                    [(ngModel)]="Order.BillingAddress.prefecture"
                    class="col-8 col-md-4 p-0"
                    placeholder="【選択してください】"
                    tabindex="0"
                    required
                    [class]="{
                      'is-invalid': BillingPrefecture.invalid && (!BillingPrefecture.untouched || isSubmit)
                    }"
                  >
                  </ng-select>
                  <div
                    class="validate-message text-danger col-8 offset-4 offset-md-3 p-0"
                    *ngIf="BillingPrefecture.invalid && (!BillingPrefecture.untouched || isSubmit)"
                  >
                    {{ 'prefectureIsInvalidMsg' | i18n }}
                  </div>
                </div>
                <div class="row form-group align-items-center ml-2 mr-1">
                  <label for="City" class="col-4 col-md-3 mb-0">{{ 'city' | i18n }}</label>
                  <input
                    id="City"
                    name="City"
                    #City="ngModel"
                    type="text"
                    class="form-control col-8"
                    [(ngModel)]="Order.BillingAddress.city"
                    [class]="{
                      'is-invalid': City.invalid && (!City.untouched || isSubmit)
                    }"
                    maxlength="15"
                    required
                  />
                  <div
                    class="validate-message text-danger col-8 offset-4 offset-md-3 p-0"
                    *ngIf="City.errors?.required && (!City.untouched || isSubmit)"
                  >
                    {{ 'cityIsRequiredMsg' | i18n }}
                  </div>
                </div>
                <div class="row form-group align-items-center ml-2 mr-1">
                  <label for="Street" class="col-4 col-md-3 mb-0">
                    {{ 'streetNumber' | i18n }}
                  </label>
                  <input
                    id="Street"
                    name="Street"
                    #Street="ngModel"
                    type="text"
                    class="form-control col-8"
                    [class]="{
                      'is-invalid': Street.invalid && (!Street.untouched || isSubmit)
                    }"
                    [(ngModel)]="Order.BillingAddress.street"
                    maxlength="20"
                    required
                  />
                  <div
                    class="validate-message text-danger col-8 offset-4 offset-md-3 p-0"
                    *ngIf="Street.errors?.required && (!Street.untouched || isSubmit)"
                  >
                    {{ 'streetNumberIsRequiredMsg' | i18n }}
                  </div>
                </div>

                <div class="row form-group align-items-center ml-2 mr-1">
                  <label for="BuildingName" class="col-4 col-md-3 mb-0"> {{ 'buildingNumber' | i18n }}</label>
                  <input
                    id="BuildingName"
                    name="BuildingName"
                    #BuildingName="ngModel"
                    type="text"
                    class="form-control col-8"
                    [(ngModel)]="Order.BillingAddress.building"
                    maxlength="40"
                  />
                </div>
              </div>
              <div class="form-group">
                <label for="Phone"
                  >{{ 'phone' | i18n }}<span class="require">{{ 'required' | i18n }}</span></label
                >
                <input
                  phoneValidator
                  id="Phone"
                  name="Phone"
                  pattern="^[0-9]{2,5}[-]?[0-9]{1,4}[-]?[0-9]{3,4}$"
                  #Phone="ngModel"
                  type="tel"
                  class="form-control"
                  [class]="{
                    'is-invalid': Phone.invalid && (!Phone.untouched || isSubmit)
                  }"
                  [(ngModel)]="Order.BillingAddress.telephone"
                  placeholder="例) 080-1234-5678"
                  maxlength="13"
                  minlength="10"
                  required
                />
                <div
                  class="validate-message text-danger"
                  *ngIf="Phone.errors?.required && (!Phone.untouched || isSubmit)"
                >
                  {{ 'phoneIsRequiredMsg' | i18n }}
                </div>
                <div
                  class="validate-message text-danger"
                  *ngIf="Phone.errors?.pattern && (!Phone.untouched || isSubmit)"
                >
                  {{ 'phonePatternErrorMsg' | i18n }}
                </div>
                <div
                  class="validate-message text-danger"
                  *ngIf="Phone.errors?.phoneValidationError && (!Phone.untouched || isSubmit)"
                >
                  Max length exceeded!
                </div>
              </div>
              <span class="caution d-block mb-2 font-weight-bold">
                <small>
                  {{ 'informationWillBeProvidedToMournerMsg' | i18n }}
                </small>
              </span>
              <div class="form-group">
                <label for="Email">
                  {{ 'email' | i18n }}
                  <span class="require">{{ 'required' | i18n }}</span>
                </label>
                <input
                  id="Email"
                  name="Email"
                  pattern="^(?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*|(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\[\x01-\x09\x0b\x0c\x0e-\x7f])*&quot;)@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-zA-Z0-9-]*[a-zA-Z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$"
                  #Email="ngModel"
                  type="email"
                  class="form-control"
                  [class]="{
                    'is-invalid': Email.invalid && (!Email.untouched || isSubmit)
                  }"
                  [(ngModel)]="Order.BillingAddress.email"
                  placeholder="例）abc@xxx.co.jp"
                  required
                />
                <div
                  class="validate-message text-danger"
                  *ngIf="Email.errors?.required && (!Email.untouched || isSubmit)"
                >
                  {{ 'emailIsRequiredMsg' | i18n }}
                </div>
                <div
                  class="validate-message text-danger"
                  *ngIf="Email.errors?.pattern && (!Email.untouched || isSubmit)"
                >
                  {{ 'emailPatternErrorMsg' | i18n }}
                </div>
              </div>
              <div class="form-group">
                <label for="ConfirmEmail">
                  {{ 'confirmEmail' | i18n }}
                  <span class="require">{{ 'required' | i18n }}</span>
                </label>
                <input
                  id="ConfirmEmail"
                  name="ConfirmEmail"
                  pattern=".+@.+"
                  #ConfirmEmail="ngModel"
                  type="email"
                  class="form-control"
                  [class]="{ 'is-invalid': ConfirmEmail.invalid && (!ConfirmEmail.untouched || isSubmit) }"
                  [(ngModel)]="Order.BillingAddress.confirmEmail"
                  placeholder="例）abc@xxx.co.jp"
                  required
                />
                <div
                  class="validate-message text-danger"
                  *ngIf="ConfirmEmail.errors?.required && (!ConfirmEmail.untouched || isSubmit)"
                >
                  {{ 'emailIsRequiredMsg' | i18n }}
                </div>
                <div
                  class="validate-message text-danger"
                  *ngIf="ConfirmEmail.errors?.pattern && (!ConfirmEmail.untouched || isSubmit)"
                >
                  {{ 'emailPatternErrorMsg' | i18n }}
                </div>
                <div
                  class="validate-message text-danger"
                  *ngIf="ConfirmEmail.errors?.mustMatch && (!ConfirmEmail.untouched || isSubmit)"
                >
                  {{ 'confirmEmailMatchErrorMsg' | i18n }}
                </div>
              </div>
              <div class="form-group">
                <div class="row align-items-center">
                  <span class="col-auto">{{ 'birthday' | i18n }}</span>
                  <birth-date
                    id="birthday"
                    name="birthday"
                    [(ngModel)]="Order.birthday"
                    #birthday="ngModel"
                    tabindex="0"
                  ></birth-date>
                </div>
                <div class="validate-message text-danger" *ngIf="birthday.invalid && (!birthday.untouched || isSubmit)">
                  {{ 'birthdayIsInvalidMsg' | i18n }}
                </div>
              </div>
            </div> -->

            <!-- <div class="shipping-address-select">
              <p class="destination-title">{{ 'destinationTitle' | i18n }}</p>
              <div class="form-group align-items-center ml-2 mr-1">
                <input
                  class="form-check-input"
                  type="radio"
                  name="DestinationSame"
                  id="DestinationSameYes"
                  [value]="true"
                  [(ngModel)]="Order.IsDestinationSame"
                />
                <label class="form-check-label" for="DestinationSameYes">
                  {{ 'destinationSameYes' | i18n }}
                </label>
              </div>
              <div class="form-group align-items-center ml-2 mr-1">
                <input
                  class="form-check-input"
                  type="radio"
                  name="DestinationSame"
                  id="DestinationSameNo"
                  [value]="false"
                  [(ngModel)]="Order.IsDestinationSame"
                />
                <label class="form-check-label" for="DestinationSameNo">{{ 'destinationSameNo' | i18n }}</label>
              </div>
            </div> -->

            <!-- shipping address -->
            <!-- <app-address
              #shippingForm
              [style.display]="Order.IsDestinationSame ? 'none' : 'initial'"
              [type]="addressType.shipping"
              viewType="shortAddress"
            ></app-address> -->

            <!-- <div class="form-group information-address" *ngIf="!Order.IsDestinationSame">
              <label for="ShippingAddressPostalCode"
                >{{ 'address' | i18n }}<span class="require">{{ 'required' | i18n }}</span></label
              >
              <div class="row form-group align-items-center ml-2 mr-1">
                <label class="col-1 mb-0" for="ShippingAddressPostalCode"> 〒 </label>
                <PostalCode
                  id="ShippingAddressPostalCode"
                  name="ShippingAddressPostalCode"
                  #ShippingAddressPostalCode="ngModel"
                  [prefectures]="Prefectures"
                  [(ngModel)]="Order.ShippingAddress"
                  class="col-9 col-md-4"
                  [IsInvalid]="{
                    'is-invalid':
                      ShippingAddressPostalCode.invalid && (!ShippingAddressPostalCode.untouched || isSubmit)
                  }"
                  tabindex="0"
                  required
                >
                </PostalCode>
                <div
                  class="validate-message text-danger col-9 offset-1"
                  *ngIf="ShippingAddressPostalCode.invalid && (!ShippingAddressPostalCode.untouched || isSubmit)"
                >
                  {{ 'postalCodeIsInvalidMsg' | i18n }}
                </div>
              </div>
              <div class="row form-group align-items-center ml-2 mr-1">
                <label for="DestinationPrefecture" class="col-4 col-md-3 mb-0">
                  {{ 'prefecture' | i18n }}
                </label>
                <ng-select
                  id="DestinationPrefecture"
                  name="DestinationPrefecture"
                  #DestinationPrefecture="ngModel"
                  [items]="Prefectures"
                  bindValue="code"
                  bindLabel="name"
                  [(ngModel)]="Order.ShippingAddress.prefecture"
                  class="col-8 col-md-4 p-0"
                  placeholder="【選択してください】"
                  tabindex="0"
                  required
                  [class]="{
                    'is-invalid': DestinationPrefecture.invalid && (!DestinationPrefecture.untouched || isSubmit)
                  }"
                >
                </ng-select>
                <div
                  class="validate-message text-danger col-8 offset-4 offset-md-3 p-0"
                  *ngIf="DestinationPrefecture.invalid && (!DestinationPrefecture.untouched || isSubmit)"
                >
                  {{ 'prefectureIsInvalidMsg' | i18n }}
                </div>
              </div>
              <div class="row form-group align-items-center ml-2 mr-1">
                <label for="DestinationCity" class="col-4 col-md-3 mb-0">
                  {{ 'city' | i18n }}
                </label>
                <input
                  id="DestinationCity"
                  name="DestinationCity"
                  #DestinationCity="ngModel"
                  type="text"
                  class="form-control col-8"
                  [(ngModel)]="Order.ShippingAddress.city"
                  [class]="{
                    'is-invalid': DestinationCity.invalid && (!DestinationCity.untouched || isSubmit)
                  }"
                  maxlength="15"
                  required
                />
                <div
                  class="validate-message text-danger col-8 offset-4 offset-md-3 p-0"
                  *ngIf="DestinationCity.errors?.required && (!DestinationCity.untouched || isSubmit)"
                >
                  {{ 'cityIsRequiredMsg' | i18n }}
                </div>
              </div>
              <div class="row form-group align-items-center ml-2 mr-1">
                <label for="DestinationStreet" class="col-4 col-md-3 mb-0">
                  {{ 'streetNumber' | i18n }}
                </label>
                <input
                  id="DestinationStreet"
                  name="DestinationStreet"
                  #DestinationStreet="ngModel"
                  type="text"
                  class="form-control col-8"
                  [class]="{
                    'is-invalid': DestinationStreet.invalid && (!DestinationStreet.untouched || isSubmit)
                  }"
                  [(ngModel)]="Order.ShippingAddress.street"
                  maxlength="20"
                  required
                />
                <div
                  class="validate-message text-danger col-8 offset-4 offset-md-3 p-0"
                  *ngIf="DestinationStreet.errors?.required && (!DestinationStreet.untouched || isSubmit)"
                >
                  {{ 'streetNumberIsRequiredMsg' | i18n }}
                </div>
              </div>

              <div class="row form-group align-items-center ml-2 mr-1">
                <label for="DestinationBuildingName" class="col-4 col-md-3 mb-0">
                  {{ 'buildingNumber' | i18n }}
                </label>
                <input
                  id="DestinationBuildingName"
                  name="DestinationBuildingName"
                  #DestinationBuildingName="ngModel"
                  type="text"
                  class="form-control col-8"
                  [(ngModel)]="Order.ShippingAddress.building"
                  maxlength="40"
                />
              </div>
              <div class="row form-group align-items-center ml-2 mr-1">
                <label for="LastName" class="col-4 col-md-3 mb-0">
                  {{ 'recipientsName' | i18n }}
                  <span class="require">{{ 'required' | i18n }}</span>
                </label>
                <input
                  id="SLastName"
                  name="SLastName"
                  #SLastName="ngModel"
                  type="text"
                  class="form-control col-4 mr-1"
                  [class]="{
                    'is-invalid': SLastName.invalid && (!SLastName.untouched || isSubmit)
                  }"
                  [(ngModel)]="Order.ShippingAddress.lastName"
                  placeholder="姓"
                  maxlength="15"
                  required
                />
                <input
                  id="SFirstName"
                  name="SFirstName"
                  #SFirstName="ngModel"
                  type="text"
                  class="form-control col-4"
                  [class]="{
                    'is-invalid': SFirstName.invalid && (!SFirstName.untouched || isSubmit)
                  }"
                  [(ngModel)]="Order.ShippingAddress.firstName"
                  placeholder="名"
                  maxlength="15"
                  required
                />
                <div
                  class="validate-message text-danger col-8 offset-4 offset-md-3 p-0"
                  *ngIf="
                    (SLastName.errors?.required && (!SLastName.untouched || isSubmit)) ||
                    (SFirstName.errors?.required && (!SFirstName.untouched || isSubmit))
                  "
                >
                  {{ 'recipientsNameIsRequiredMsg' | i18n }}
                </div>
              </div>
              <div class="row form-group align-items-center ml-2 mr-1">
                <label for="SPhone"
                  >{{ 'phone' | i18n }}<span class="require">{{ 'required' | i18n }}</span></label
                >
                <input
                  phoneValidator
                  id="SPhone"
                  name="SPhone"
                  pattern="^[0-9]{2,5}[-]?[0-9]{1,4}[-]?[0-9]{3,4}$"
                  #SPhone="ngModel"
                  type="tel"
                  class="form-control"
                  [class]="{
                    'is-invalid': SPhone.invalid && (!SPhone.untouched || isSubmit)
                  }"
                  [(ngModel)]="Order.ShippingAddress.telephone"
                  placeholder="例) 080-1234-5678"
                  maxlength="13"
                  minlength="10"
                  required
                />
                <div
                  class="validate-message text-danger"
                  *ngIf="SPhone.errors?.required && (!SPhone.untouched || isSubmit)"
                >
                  {{ 'phoneIsRequiredMsg' | i18n }}
                </div>
                <div
                  class="validate-message text-danger"
                  *ngIf="SPhone.errors?.phoneValidationError && (!SPhone.untouched || isSubmit)"
                >
                  Max length exceeded!
                </div>
                <div
                  class="validate-message text-danger"
                  *ngIf="SPhone.errors?.pattern && (!SPhone.untouched || isSubmit)"
                >
                  {{ 'phonePatternErrorMsg' | i18n }}
                </div>
              </div>
            </div> -->
          </div>

          <div class="payment-method col-12 col-md-10 ml-auto mr-auto font-small" *ngIf="!hidePaymentMethodsSection">
            <p class="destination-title pl-3 font-bold">お支払い方法のご選択</p>
            <app-payment-method
              [(paymentMethod)]="Order.PaymentMethod"
              [disableOtherPaymentMethods]="disableOtherPaymentMethods"
            ></app-payment-method>
          </div>
          <div class="text-center mt-4 hpi-confirm">
            <span class="position-relative">
              <input
                type="checkbox"
                id="isDisclaimerConfirmed"
                name="isDisclaimerConfirmed"
                [(ngModel)]="Order.DisclaimerConfirmed"
              />
              <label class="checkbox-label" for="isDisclaimerConfirmed"></label>
              <label class="mx-4" for="isDisclaimerConfirmed">
                <a href="javascript:void(0);" (click)="OpenHPlModal()">
                  {{ 'handlingPersonalInformation' | i18n }}
                </a>
                {{ 'agreeAndCheck' | i18n }}
              </label>
            </span>
          </div>
          <div class="footer-remarks-container">
            <span class="remark">
              <span class="counter">注1)　</span>
              ご注文、ご決済内容のメールは、ご決済者様情報に入力されているメールアドレスへ送付させて頂きます。
            </span>
            <span class="remark">
              <span class="counter">注2)　</span
              >クレジットカード決済時に使用いたします個人情報は、ご決済者様情報の内容を使用しております。ご家族名義のクレジットカードをご利用になられる際には、ご注意ください。
            </span>
            <span class="remark">
              <span class="counter">注3)　</span>メールアドレスは、ドメイン「{{
                tenantEmail
              }}」からのメールが受信できるものにしてください
            </span>
          </div>
        </div>
      </form>
    </div>
  </div>

  <!-- buttons -->
  <div class="buttons-container">
    <button id="return-button" class="nav-button return-button" (click)="BackToOrderDetails()">
      {{ 'return' | i18n }}
    </button>
    <button id="next-button" class="nav-button next-button" (click)="createCart()" [disabled]="isAddingToCart">
      <span class="spinner-border spinner-border-sm" role="status" *ngIf="isAddingToCart"></span>
      {{ 'nextBtn' | i18n }}
    </button>
  </div>

  <!-- <div class="container">
    <div class="btn-toolbar justify-content-between mt-2 mb-5" role="toolbar">
      <button id="checkout-return-button" class="btn btn-outline-secondary" (click)="BackToOrderDetails()">
        {{ 'return' | i18n }}
      </button>
      <button id="checkout-next-button" class="btn btn-secondary" (click)="createCart()" [disabled]="isAddingToCart">
        <span class="spinner-border spinner-border-sm" role="status" *ngIf="isAddingToCart"></span>
        {{ 'nextBtn' | i18n }}
      </button>
    </div>
  </div> -->
</div>
