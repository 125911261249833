import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { FuneralService } from 'src/services';
import { GlobalStorage } from '../global/storage';
import { Observable } from 'rxjs';
import { ReceptionCloseService, ProductService } from 'src/services';

@Injectable({
  providedIn: 'root',
})
export class FuneralGuard implements CanActivate {
  constructor(
    private router: Router,
    private funeralService: FuneralService,
    private productService: ProductService,
    private storage: GlobalStorage,
    private receptionCloseService: ReceptionCloseService
  ) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const id = next.params.id;
    const path = state.url.split('/');
    return new Observable<boolean>((subscribe) => {
      this.productService.gets(id).then((products) => {
        this.storage.Products = products;
        this.funeralService.get(id).subscribe((funeral) => {
          if (funeral != null) {
            this.storage.Funeral = funeral;

            // 対象の葬儀がcloseしている場合は　/:id 移動
            if (path.pop() !== id && this.receptionCloseService.getReceptionStatus(funeral).isFuneralClosed) {
              this.router.navigate(['/', id]);
            }

            subscribe.next(true);
          } else {
            this.router.navigate(['/']);
            subscribe.next(false);
          }
        });
      });
    });
  }
}
