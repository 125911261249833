import Amplify, { Auth, Hub } from 'aws-amplify';
import { BehaviorSubject } from 'rxjs';

export class AuthService {
  public isLoggedIn = new BehaviorSubject<boolean>(false);
  private USER_AUTH_TYPE = 'AMAZON_COGNITO_USER_POOLS';
  private GUEST_AUTH_TYPE = 'API_KEY';

  constructor() {
    Auth.currentUserInfo().then(
      (data) => this.isLoggedIn.next(data !== null),
      () => {}
    );

    this.isLoggedIn.subscribe((isLoggedIn) => {
      const currentConfig: any = Amplify.configure();
      const authenticationType = isLoggedIn ? this.USER_AUTH_TYPE : this.GUEST_AUTH_TYPE;
      if (currentConfig.aws_appsync_authenticationType !== authenticationType) {
        Amplify.configure({
          aws_appsync_authenticationType: authenticationType,
        });
      }
    });

    Hub.listen('auth', (data) => {
      switch (data.payload.event) {
        case 'signIn':
        case 'tokenRefresh':
          this.isLoggedIn.next(true);
          break;

        case 'signOut':
        case 'signIn_failure':
        case 'tokenRefresh_failure':
          this.isLoggedIn.next(false);
          break;

        default:
          return;
      }
    });
  }

  get isSmacereClubMember() {
    return this.isLoggedIn;
  }

  async signOut() {
    try {
      await Auth.signOut();
    } catch (error) {
      console.log('error signing out: ', error);
    }
  }
}
