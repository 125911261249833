import { merge } from './merge';

export const MergeTranslation = (mainObj: object, subObj: object, language: string) => {
  if (mainObj && subObj && language) {
    if (mainObj.hasOwnProperty(language) && subObj.hasOwnProperty(language)) {
      const result = { [language]: merge(mainObj[language], subObj[language]) };
      return result;
    } else if (mainObj.hasOwnProperty(language) === false && subObj.hasOwnProperty(language)) {
      return subObj;
    } else if (subObj.hasOwnProperty(language) === false && mainObj.hasOwnProperty(language)) {
      return mainObj;
    } else {
      return null;
    }
  } else {
    return null;
  }
};
