import { Pipe, PipeTransform } from '@angular/core';
import { I18n } from '@aws-amplify/core';

@Pipe({
  name: 'i18n',
  pure: true,
})
export class I18nPipe implements PipeTransform {
  transform(value: string) {
    return I18n.get(value);
  }
}
