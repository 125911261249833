import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { Funeral } from 'src/models/Funeral';
import { isValidDateObj } from 'src/utils/dateUtils';
import { StepType } from 'src/types';
import { environment } from 'src/environments/environment';

@Injectable()
export class ReceptionCloseService extends BaseService {
  public getReceptionStatus(funeral: Funeral, type: StepType = null) {
    let result: { [key: string]: any } = {
      isFuneralClosed: false,
      funeralCloseDateTime: null,
    };

    const nowDateTime = new Date();
    let funeralCloseDateTime = null;

    // 基準日を取得
    switch (type) {
      case StepType.Flower:
        funeralCloseDateTime = new Date(
          funeral.overnightFuneralDate ? funeral.overnightFuneralDate : funeral.funeralDate
        );
        break;
      default:
        funeralCloseDateTime = new Date(funeral.funeralDate);
        break;
    }

    // 基準日を取得できたら時間設定処理に進む
    if (isValidDateObj(funeralCloseDateTime)) {
      switch (type) {
        case StepType.Flower:
          if (!funeral.overnightFuneralDate) {
            // 一日葬：葬儀日前日の18:00
            funeralCloseDateTime.setDate(funeralCloseDateTime.getDate() - 1);
            funeralCloseDateTime.setHours(18);
            funeralCloseDateTime.setMinutes(0);
          } else {
            // それ以外：通夜当日の12:00
            funeralCloseDateTime.setHours(12);
            funeralCloseDateTime.setMinutes(0);
          }
          break;
        case StepType.MoneyGift:
          funeralCloseDateTime.setDate(funeralCloseDateTime.getDate() + environment.moneyDaysOff);
          funeralCloseDateTime.setHours(23);
          funeralCloseDateTime.setMinutes(59);
          funeralCloseDateTime.setSeconds(59);
          break;
        case StepType.Card:
          if (funeral.funeralStartTime) {
            const hms = funeral.funeralStartTime.split(':');
            funeralCloseDateTime.setHours(Number(hms[0]));
            funeralCloseDateTime.setMinutes(Number(hms[1]));
          }
          break;
        case StepType.CondolenceVideo:
          if (funeral.funeralStartTime) {
            const hms = funeral.funeralStartTime.split(':');
            funeralCloseDateTime.setHours(Number(hms[0]));
            funeralCloseDateTime.setMinutes(Number(hms[1]));
          }
          break;
        default:
          funeralCloseDateTime.setDate(funeralCloseDateTime.getDate() + environment.funeralDaysOff);
          funeralCloseDateTime.setHours(23);
          funeralCloseDateTime.setMinutes(59);
          funeralCloseDateTime.setSeconds(59);
          break;
      }

      result = {
        isFuneralClosed: nowDateTime > funeralCloseDateTime,
        funeralCloseDateTime: funeralCloseDateTime,
      };
    }

    return result;
  }
}
