import { FlowerTypes, FuneralStatusType } from 'src/types';
import { MediaObjectInput, MemorialType, PaymentType } from '@kokusai/smacere-shared/api';
import { Flower, Food } from 'src/models';
import { Stream } from './streams';

export class Funeral {
  constructor(params: Funeral = {} as Funeral) {
    let {
      allowedPaymentTypes = [],
      statusCode = FuneralStatusType.Edit,
      funeralId = '',
      streamingPassword: StreamingPassword = '',
      enforcementNo = 0,
      funeralName = '',
      funeralDate = '',
      funeralStartTime = '',
      funeralEndTime = '',
      deceasedPhoto = {},
      deceasedName = '',
      overnightFuneralDate = '',
      overnightFuneralStartTime = '',
      overnightFuneralEndTime = '',
      deceasedAge = 0,
      deathDate = '',
      mourningName = '',
      funeralLocationAltName = '',
      FuneralHallName = '',
      FuneralHallPhone = '',
      FuneralHallRoom = '',
      FlowerType = FlowerTypes.Both,
      displayFlower = true,
      displayFlowerPresent = true,
      displayCard = true,
      displayCardPresent = true,
      displayMoney = true,
      displayFood = true,
      displayMemorialShare = false,
      displayCondolenceVideos = false,
      displayMoneyPresent: IsPresentChoice = true,
      displayStreaming = true,
      displayMemorial = MemorialType.Video,
      memorialMovie: MemorialMovie = {},
      thankYouVideo: ThankYouVideo = {},
      memorialPictures = [],
      flowerSelections = [],
      foodSelections = [],
      streamingUrls = [],
      deceasedNamePrefix = '故',
      deceasedNameSuffix = '儀',
      deceasedNamePostfix = '合同葬',
      overnightFuneralLabel = '通夜式',
      funeralLabel = '葬儀・<br/>告別式',
      mourningEmails = [],
      owner = '',
      welcomeMessage = '',
      companyName = '',
      companyTitle = '',
      funeralRecording = null,
      overnightFuneralRecording = null,
      funeralCloseDate = '',
    } = params;

    this.allowedPaymentTypes = allowedPaymentTypes;
    this.statusCode = statusCode;
    this.funeralId = funeralId;
    this.streamingPassword = StreamingPassword;
    this.enforcementNo = enforcementNo;
    this.funeralName = funeralName;
    this.funeralDate = funeralDate;
    this.funeralStartTime = funeralStartTime;
    this.funeralEndTime = funeralEndTime;
    this.deceasedPhoto = deceasedPhoto;
    this.deceasedName = deceasedName;
    this.overnightFuneralDate = overnightFuneralDate;
    this.overnightFuneralStartTime = overnightFuneralStartTime;
    this.overnightFuneralEndTime = overnightFuneralEndTime;
    this.deceasedAge = deceasedAge;
    this.deathDate = deathDate;
    this.mourningName = mourningName;
    this.funeralLocationAltName = funeralLocationAltName;
    this.FuneralHallName = FuneralHallName;
    this.FuneralHallPhone = FuneralHallPhone;
    this.FuneralHallRoom = FuneralHallRoom;
    this.FlowerType = FlowerType;
    this.displayFlower = displayFlower;
    this.displayFlowerPresent = displayFlowerPresent;
    this.displayCard = displayCard;
    this.displayCardPresent = displayCardPresent;
    this.displayMoney = displayMoney;
    this.displayFood = displayFood;
    this.displayMemorialShare = displayMemorialShare;
    this.displayCondolenceVideos = displayCondolenceVideos;
    this.displayMoneyPresent = IsPresentChoice;
    this.displayStreaming = displayStreaming;
    this.displayMemorial = displayMemorial;
    this.memorialMovie = MemorialMovie;
    this.thankYouVideo = ThankYouVideo;
    this.memorialPictures = memorialPictures;
    this.flowerSelections = flowerSelections;
    this.foodSelections = foodSelections;
    this.streamingUrls = streamingUrls;
    this.deceasedNamePrefix = deceasedNamePrefix;
    this.deceasedNameSuffix = deceasedNameSuffix;
    this.deceasedNamePostfix = deceasedNamePostfix;
    this.overnightFuneralLabel = overnightFuneralLabel;
    this.funeralLabel = funeralLabel;
    this.mourningEmails = mourningEmails;
    this.owner = owner;
    this.welcomeMessage = welcomeMessage;
    this.companyName = companyName;
    this.companyTitle = companyTitle;
    this.funeralRecording = funeralRecording;
    this.overnightFuneralRecording = overnightFuneralRecording;
    this.funeralCloseDate = funeralCloseDate;
  }

  allowedPaymentTypes: Array<PaymentType | null> | null;
  statusCode: FuneralStatusType;
  funeralId: string; //  TODO: Change to just id?
  streamingPassword: string;
  enforcementNo: number;
  funeralName: string;
  funeralDate: string;
  funeralStartTime: string;
  funeralEndTime: string;
  deceasedPhoto: MediaObjectInput;
  deceasedName: string;
  overnightFuneralDate: string;
  overnightFuneralStartTime: string;
  overnightFuneralEndTime: string;
  deceasedAge: number;
  deathDate: string;
  mourningName: string;
  funeralLocationAltName: string;
  FuneralHallName: string;
  FuneralHallPhone: string;
  FuneralHallRoom: string;
  FlowerType: FlowerTypes;
  flowerSelections: Flower[];
  foodSelections: Food[];
  displayFlower: boolean;
  displayFlowerPresent: boolean;
  displayCard: boolean;
  displayMemorialShare: boolean;
  displayCondolenceVideos: boolean;
  displayCardPresent: boolean;
  displayFood: boolean;
  displayMoneyPresent: boolean;
  displayStreaming: boolean;
  displayMemorial: MemorialType;
  displayMoney: boolean;
  thankYouVideo: MediaObjectInput;
  memorialMovie: MediaObjectInput;
  memorialPictures: MediaObjectInput[];
  streamingUrls: Stream[];
  deceasedNamePrefix: string;
  deceasedNameSuffix: string;
  deceasedNamePostfix: string;
  overnightFuneralLabel: string;
  funeralLabel: string;
  mourningEmails: string[];
  owner: string;
  welcomeMessage: string;
  companyName: string;
  companyTitle: string;
  funeralRecording: MediaObjectInput | null;
  overnightFuneralRecording: MediaObjectInput | null;
  funeralCloseDate: string | null;
}
