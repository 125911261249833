import { Injectable } from '@angular/core';

import { BaseService } from './base.service';

import { LookupType } from '@kokusai/smacere-shared/api';

import { Dictionary } from 'src/models/Dictionary';

@Injectable()
export class DictionaryService extends BaseService {
  getCompanyTitles(): Promise<Dictionary[]> {
    return this.api.LookupByType(LookupType.CompanyTitle).then((result) => {
      return result.items
        .sort((a, b) => (a.sortOrder > b.sortOrder ? 1 : -1))
        .map((lookup) => {
          const dic = new Dictionary();
          dic.id = lookup.id;
          dic.title = lookup.message;
          return dic;
        });
    });
  }
}
