import { Component, forwardRef, Input, ViewChild, OnInit, ChangeDetectorRef } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NgModel,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import moment from 'moment';

@Component({
  selector: 'birth-date',
  styleUrls: ['birth-date.component.scss'],
  templateUrl: 'birth-date.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BirthDateComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => BirthDateComponent),
      multi: true,
    },
  ],
})
export class BirthDateComponent implements ControlValueAccessor, Validator, OnInit {
  @ViewChild('yearSelect') ngSelect: NgSelectComponent;
  @ViewChild('yearRef', { static: false }) yearRef: NgModel;
  @ViewChild('monthRef', { static: false }) monthRef: NgModel;
  @ViewChild('dayRef', { static: false }) dayRef: NgModel;

  @Input()
  value: string;

  @Input()
  readonly: boolean = false;

  years: string[] = [];
  months: string[] = [];
  days: string[] = [];

  year: string;
  month: string;
  day: string;

  isValid: boolean = true;
  dateRegex: any = /^(19|20)\d\d[- /.](0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])$/g;

  constructor(private cdRef: ChangeDetectorRef) {
    this.initYears();
    this.initMonth();
  }

  ngOnInit() {
    if (this.years && this.months) {
      const year: number = Number.parseInt(this.year, 10);
      const month: number = Number.parseInt(this.month, 10);
      this.initDays(year, month);
    }
  }

  private isLeapYear(year: number, month: number): boolean {
    return new Date(year, month - 1, 29).getDate() === 29;
  }

  private isMount31(year: number, month: number): boolean {
    return new Date(year, month - 1, 31).getDate() === 31;
  }

  private initYears(): void {
    const date = new Date();
    const currentYear = date.getFullYear();

    const minAge: number = 0;
    const maxAge: number = 110;
    const minYear: number = this.calcYear(currentYear, maxAge);
    const maxYear: number = this.calcYear(currentYear, minAge);

    for (let i = maxYear; i >= minYear; i--) {
      this.years.push(i.toString());
    }
  }

  private initMonth(): void {
    for (let i = 1; i <= 12; i++) {
      if (i < 10) {
        this.months.push(`0${i}`);
      } else {
        this.months.push(i.toString());
      }
    }
  }

  private initDays(year: number, month: number) {
    const startDay = 1;
    let endDay = 0;

    if (month === 2) {
      endDay = this.isLeapYear(year, month) ? 29 : 28;
    } else {
      endDay = this.isMount31(year, month) ? 31 : 30;
    }

    this.days = [];
    this.day = null;

    for (let i = startDay; i <= endDay; i++) {
      if (i < 10) {
        this.days.push('0' + i);
      } else {
        this.days.push(i.toString());
      }
    }
  }

  private calcYear(currentYear: number, age: number): number {
    return currentYear - age;
  }

  private replaceAt(value: string, index: number, replacement: string): string {
    return `${value.substr(0, index)}${replacement}${value.substr(index + replacement.length)}`;
  }

  onChange: any = () => {};
  onTouched: any = () => {};

  onOpen(): void {
    this.year = this.year ? this.year : '2000';
    setTimeout(() => {
      if (this.ngSelect.dropdownPanel == null || this.ngSelect.dropdownPanel === undefined) {
        this.onOpen();
      } else {
        this.ngSelect.dropdownPanel.scrollTo(this.ngSelect.selectedItems[0]);
      }
    }, 10);
    this.onYearChange();
  }

  writeValue(value: string): void {
    if (value == null) {
      this.value = '0000-00-00';
    } else {
      this.value = value;

      this.isValid = this.value.match(this.dateRegex)?.length > 0 && moment(value).isValid();

      if (this.isValid) {
        const data = this.value.split('-');
        this.year = data[0];
        this.month = data[1];
        this.day = data[2];
      }
    }
    this.refreshData();
  }

  refreshData() {
    this.yearRef.control.setValue(this.year);
    this.monthRef.control.setValue(this.month);
    this.month && this.monthRef.control.enable();
    this.dayRef.control.setValue(this.day);
    this.day && this.dayRef.control.enable();

    this.cdRef.detectChanges();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  validate(control: AbstractControl): ValidationErrors {
    if (this.value !== '0000-00-00' && !this.isValid) {
      control.setErrors({ notValid: true });
      return { invalid_format: true };
    }
  }

  onYearChange(): void {
    this.value = this.replaceAt(this.value, 0, this.year);
    this.writeValue(this.value);
    this.onChange(this.value);
  }

  onMonthChange(): void {
    const year: number = Number.parseInt(this.year, 10);
    const month: number = Number.parseInt(this.month, 10);
    this.initDays(year, month);

    this.value = this.replaceAt(this.value, 5, this.month);
    this.writeValue(this.value);
    this.onChange(this.value);
  }

  onDayChange(): void {
    this.value = this.replaceAt(this.value, 8, this.day);
    this.writeValue(this.value);
    this.onChange(this.value);
  }

  clearYear() {
    this.value = null;
    this.year = null;
    this.month = null;
    this.day = null;
  }

  clearMonth() {
    this.month = null;
    this.day = null;
  }
}
