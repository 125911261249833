import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { animate, style, transition, trigger } from '@angular/animations';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { GlobalStorage } from 'src/app/global/storage';
import { AddressService } from 'src/services';
import { Address, Funeral, Order } from 'src/models';
import { StepType } from 'src/types';
import { FooterHPIComponent } from 'src/app/shared/footer/footer-hpi/footer-hpi.component';
import { AddressType, APIService, LookupType, PaymentType } from '@kokusai/smacere-shared/api';
import { AddressComponent } from '../../../../components/address/address.component';
import { BundleCartService, BundleType } from '../../../../services/bundle-cart.service';
import { environment } from 'src/environments/environment';
import { ViewType } from 'src/app/components/address/types/view-type.type';
import { AuthService } from 'src/app/services/auth.service';
@Component({
  selector: 'app-checkout-billing',
  templateUrl: './checkout-billing.component.html',
  styleUrls: ['./checkout-billing.component.scss'],
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [style({ opacity: 0 }), animate('1s ease-out', style({ opacity: 1 }))]),
      transition(':leave', [style({ opacity: 1 }), animate('1s ease-in', style({ opacity: 0 }))]),
    ]),
  ],
})
export class CheckoutBillingComponent implements OnInit {
  Order: Order = null;
  funeral: Funeral;
  Years: number[] = [];
  Relationships: string[] = [];
  Prefectures: string[] = [];
  displayProcessing: boolean = false;
  funeralId: string = '';
  addressType = AddressType;
  selectedMainPaymentMethod: string = 'credit-card';
  selectedSubPaymentMethod: string = 'master-visa-card';
  isSubmit: boolean = false;
  isAddingToCart: boolean = false;
  ViewType = ViewType;
  isSmacereClubMember;

  @ViewChild('billingForm') billingForm: AddressComponent;

  constructor(
    private storage: GlobalStorage,
    private addressService: AddressService,
    private modalService: NgbModal,
    private router: Router,
    private apiService: APIService,
    private bundleCartService: BundleCartService,
    private authService: AuthService
  ) {
    this.Order = this.storage.Order;
    this.funeral = this.storage.Funeral;
    this.funeralId = this.storage.Funeral.funeralId;

    if (!this.Order.BillingAddress) {
      this.Order.BillingAddress = new Address();
    }
    if (!this.Order.ShippingAddress) {
      this.Order.ShippingAddress = new Address();
    }
  }

  async ngOnInit(): Promise<void> {
    await this.bundleCartService.emptyCartGuard();

    this.addressService.GetPrefectures().subscribe((prefectures) => {
      this.Prefectures = prefectures;
    });

    this.apiService.LookupByType(LookupType.Relationship).then((result) => {
      this.Relationships = result.items.map((item) => item.message);
    });

    // set credit card as the default payment method
    if (!this.Order.PaymentMethod) {
      this.Order.PaymentMethod = PaymentType.paypal;
    }

    this.authService.isSmacereClubMember.subscribe((isLoggedIn) => {
      this.isSmacereClubMember = isLoggedIn;
      if (isLoggedIn) {
        this.Order.IsBillingDifferent = false;
      }
    });
  }

  OpenHPlModal() {
    this.modalService.open(FooterHPIComponent, { windowClass: 'common-modal' });
  }

  BackToOrderDetails() {
    this.router.navigate([this.storage.Funeral.funeralId, 'order', 'confirm']);
  }

  capitalize(value: string) {
    return value[0].toUpperCase() + value.slice(1);
  }

  async createCart() {
    this.isSubmit = true;

    if (this.hidePaymentMethodsSection) {
      this.Order.PaymentMethod = PaymentType.paypal;
    }

    if (typeof this.Order.PaymentMethod === 'undefined') {
      await Swal.fire({
        icon: 'error',
        title: 'Please select payment method',
      });
      return;
    }

    // if (form.invalid) {
    //   const controls: string[] = Object.keys(form.controls);
    //   for (const key of controls) {
    //     if (form.controls[key].invalid) {
    //       document.getElementById(key)?.focus();
    //       return;
    //     }
    //   }
    //   return;
    // }

    // check billing form
    const controls = Object.entries(this.billingForm.userForm.controls);
    if (this.billingForm.userForm.invalid) {
      for (const [key, control] of controls) {
        if (control.invalid) {
          document.getElementById(`billing${this.capitalize(key)}`)?.focus();
          return;
        }
      }
      return;
    }
    for (const [key, control] of controls) {
      this.Order.BillingAddress[key] = key === 'postalCode' ? control.value.postalCode : control.value;
    }

    if (!this.Order.DisclaimerConfirmed) {
      await Swal.fire({
        icon: 'error',
        title: '個人情報の取り扱いに同意してください',
      });
      return;
    }

    this.isAddingToCart = true;
    this.storage.Order = this.Order;
    try {
      this.Order.Id = null;
      this.Order.CartId = await this.bundleCartService.createCart(this.funeralId);
      this.storage.Order = this.Order;
      await this.router.navigate([this.storage.Funeral.funeralId, 'order', 'checkout', 'payment']);
    } catch (errorMessage) {
      await Swal.fire({
        icon: 'error',
        title: errorMessage,
        text: '',
      });
    } finally {
      this.isAddingToCart = false;
    }
  }

  get disableOtherPaymentMethods(): boolean {
    return this.bundleCartService.getBundlesOfType(BundleType.GiftMoney).length > 0;
  }

  get tenantEmail() {
    return environment.tenantEmail;
  }

  get displayUseDifferentBillingAddress(): boolean {
    return !this.isSmacereClubMember;
  }

  get hidePaymentMethodsSection(): boolean {
    const otherPaymentMethodsDisabled =
      this.funeral?.allowedPaymentTypes === null || this.funeral?.allowedPaymentTypes?.length === 0;
    const cartHasMoneyGift = this.disableOtherPaymentMethods;
    return otherPaymentMethodsDisabled || cartHasMoneyGift;
  }
}
