export * from './card.component';
export * from './card-present/card-present.component';
export * from './card-preview/card-preview.component';
export * from './card-message/card-message.component';
export * from './card-home/card-home.component';
export * from './card-select/card-select.component';
export * from './card-order/card-order.component';
export * from './card-order-details/card-order-details.component';
export * from './card-main/card-main.component';

import { CardComponent } from './card.component';
import { CardPresentComponent } from './card-present/card-present.component';
import { CardPreviewComponent } from './card-preview/card-preview.component';
import { CardMessageComponent } from './card-message/card-message.component';
import { CardHomeComponent } from './card-home/card-home.component';
import { CardSelectComponent } from './card-select/card-select.component';
import { CardOrderComponent } from './card-order/card-order.component';
import { CardOrderDetailsComponent } from './card-order-details/card-order-details.component';
import { CardMainComponent } from './card-main/card-main.component';

export const CardModule = [
  CardComponent,
  CardHomeComponent,
  CardSelectComponent,
  CardOrderComponent,
  CardMessageComponent,
  CardOrderDetailsComponent,
  CardPreviewComponent,
  CardPresentComponent,
  CardMainComponent,
];
