import { BaseProductOrder } from './BaseProductOrder';
import { Flower } from './Flower';
import { Address } from './Address';

export class FlowerOrder extends BaseProductOrder<Flower> {
  constructor() {
    super();

    this.flowerName = '';
    this.Product = new Flower();
    this.flowerRemark = '';
    this.IsDestinationSame = true;
    this.ShippingAddress = new Address();
  }

  flowerName: string;
  flowerRemark: string;
  IsDestinationSame: boolean;
  ShippingAddress: Address;
}
