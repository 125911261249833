<div class="money-gift-order-container">
  <div class="money-gift-contents">
    <div class="money-gift-header">
      <img
        class="money-gift-image"
        src="{{ 'kouden_manner.png' | path }}"
        alt="money-gift-thumbnail"
        width="163"
        height="120"
      />
      <div class="money-gift-amount">
        <p class="m-0">
          <span>{{ 'moneyGiftTotalAmount' | i18n }}　</span>
          <span>{{ MoneyGiftOrder.amount | number }}円</span>
        </p>
        <span>{{ 'noConsumptionTaxOnTheIncense' | i18n }}</span>
      </div>
    </div>
    <div class="money-gift-form">
      <!-- radio buttons container -->
      <div class="radio-button-container">
        <label class="radio-button-label" for="SameOrderInformation">{{ 'nameInformation' | i18n }}</label>
        <div class="radio-buttons">
          <div class="form-group">
            <input
              type="radio"
              name="SameOrderInformation"
              id="SameOrderInformationYes"
              class="mr-2"
              [value]="true"
              [(ngModel)]="MoneyGiftOrder.isCustomerAddressSame"
              (ngModelChange)="handleIsCustomerAddressSame($event)"
            />
            <label for="SameOrderInformationYes"> {{ 'enterCustomerInformation' | i18n }} </label>
            <div class="mb-0 ml-3">
              <input
                type="checkbox"
                name="UseMaidenName"
                id="UseMaidenName"
                class="mr-2"
                [(ngModel)]="MoneyGiftOrder.useMaidenName"
                (ngModelChange)="handleUseMaidenNameChange($event)"
                [disabled]="!MoneyGiftOrder.isCustomerAddressSame || !Order.BillingAddress.maidenName"
              />
              <label for="SameOrderInformationYes" class="m-0"> {{ 'userMaidenName' | i18n }} </label>
            </div>
          </div>
          <div class="form-group mb-0">
            <input
              type="radio"
              name="SameOrderInformation"
              id="SameOrderInformationNo"
              class="mr-2"
              [value]="false"
              [(ngModel)]="MoneyGiftOrder.isCustomerAddressSame"
              (ngModelChange)="handleIsCustomerAddressSame($event)"
            />
            <label for="SameOrderInformationNo" class="m-0">{{ 'enterOtherInformation' | i18n }}</label>
          </div>
        </div>
      </div>
      <!-- customer address form container -->
      <div>
        <app-address
          #customerForm
          [type]="AddressType.customer"
          itemType="moneyGift"
          [viewType]="ViewType.moneyGiftAddress"
          [readonly]="MoneyGiftOrder.isCustomerAddressSame"
          [specialName]="false"
          [nameRemark]="true"
          [useMaidenName]="MoneyGiftOrder.useMaidenName"
        ></app-address>
      </div>
      <!-- shipping address -->
      <div class="shipping-radio-button-container" *ngIf="hasPresents">
        <p>{{ 'returnOfIncenseAndDeliveryOfReturnedGoods' | i18n }}</p>
        <!-- address same as Order.CustomerAddress -->
        <div class="form-group">
          <input
            type="radio"
            name="DestinationSame"
            id="sameAsOrderAddress"
            value="shipping"
            [(ngModel)]="MoneyGiftOrder.IsDestinationSame"
          />
          <label for="sameAsOrderAddress"> {{ 'sameAsOrderAddress' | i18n }} </label>
        </div>
        <!-- address same as OrderItem.CustomerAddress -->
        <!-- TODO: make this radio button get the address from OrderItem.CustomerAddress -->
        <div class="form-group">
          <input
            type="radio"
            name="DestinationSame"
            id="sameAsOrderItemAddress"
            value="customer"
            [(ngModel)]="MoneyGiftOrder.IsDestinationSame"
          />
          <label for="sameAsOrderItemAddress"> {{ 'sameAsOrderItemAddress' | i18n }} </label>
        </div>
        <!-- custom address -->
        <div class="form-group">
          <input
            type="radio"
            name="DestinationSame"
            id="differentAddressFromTheAbove"
            [value]="false"
            [(ngModel)]="MoneyGiftOrder.IsDestinationSame"
          />
          <label for="differentAddressFromTheAbove">{{ 'differentAddressFromTheAbove' | i18n }}</label>
        </div>
      </div>
      <!-- shipping address form container -->
      <div>
        <app-address
          #shippingForm
          myStyle="padding-left: 1em"
          [style.display]="MoneyGiftOrder.IsDestinationSame ? 'none' : 'initial'"
          [type]="AddressType.shipping"
          itemType="moneyGift"
          [viewType]="ViewType.shortAddress"
        ></app-address>
      </div>
    </div>
  </div>

  <!-- buttons -->
  <div class="buttons-container">
    <button id="return-button" class="nav-button return-button" (click)="back()">
      {{ 'return' | i18n }}
    </button>
    <button id="add-button" class="nav-button add-button" (click)="addAction()" *ngIf="!hasPresents && !isEdit">
      {{ 'addNewOrder' | i18n }}
    </button>
    <button id="next-button" class="nav-button next-button" (click)="nextAction()">
      {{ 'decideAndNext' | i18n }}
    </button>
  </div>

  <!-- footer -->
  <!-- <div class="money-gift-footer">
    <button id="return-button" class="return-button-outline-rect" (click)="back()">
      {{ 'return' | i18n }}
    </button>
    <button id="add-button" class="btn btn-secondary" (click)="addAction()" *ngIf="!hasPresents && !isEdit">
      {{ 'addNewOrder' | i18n }}
    </button>
    <button id="next-button" class="next-button-rect" (click)="nextAction()">
      {{ 'decideAndNext' | i18n }}
    </button>
  </div> -->
</div>
