import { environment } from 'src/environments/environment';
import { Component } from '@angular/core';
@Component({
  selector: 'env',
  styles: ['.env{ position: fixed; font-size: 12px; left: 10px; bottom: 10px; color: red }'],
  template: `<div class="env" *ngIf="Environment">{{ Environment }}</div>`,
})
export class EnvComponent {
  public Environment: string;

  constructor() {}
  ngOnInit(): void {
    this.Environment = environment.env;
  }
}
