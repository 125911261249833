<div class="bg-grey pt-1 condolence-video-order-container">
  <div class="container mt-4">
    <div class="row justify-content-between pb-3">
      <div class="col-12 col-md-4 col-lg-5">
        <ul class="product-details">
          <li>
            <div class="row">
              <div class="col-11">
                {{ 'deceasedNamePrefix' | i18n }}
                {{ Funeral.deceasedName }} {{ 'nameSuffix' | i18n }}
              </div>
            </div>
          </li>
          <li class="mb-4">
            <div class="row">
              <div class="col-11">
                {{ 'mourning' | i18n }}
                {{ Funeral.mourningName }} {{ 'nameSuffix' | i18n }}
              </div>
            </div>
          </li>
          <li>
            <img
              class="col-md-12 col-lg-8 w-100 p-0 form-group"
              [src]="CondolenceVideoOrder.Thumbnail"
              alt="condolence-video"
            />
          </li>
          <li>
            <div class="row">
              <div class="col-12 condolence-video-order-label">{{ 'condolenceVideo' | i18n }}</div>
            </div>
          </li>
          <li>
            <div class="row">
              <div class="col-3 pr-0 condolence-video-order-label">{{ 'price' | i18n }}</div>
              <div
                class="col-9 condolence-video-order-product-price"
                *ngIf="isSmacereClubMember | async; else noDiscount"
              >
                {{ discountedPrice | priceWithTax }}<small>（{{ 'taxIncluded' | i18n }}）</small>
              </div>
              <ng-template #noDiscount>
                <div class="col-9 condolence-video-order-product-price">
                  {{ CondolenceVideoOrder.Product?.price | priceWithTax }}<small>（{{ 'taxIncluded' | i18n }}）</small>
                </div>
              </ng-template>
            </div>
          </li>
        </ul>
      </div>
      <!-- form container -->
      <div class="col-12 col-md-8 col-lg-7">
        <form #cardOrderForm="ngForm" novalidate>
          <!-- card destination name -->
          <div class="form-group">
            <div class="row">
              <label for="DestinationLastName" class="ml-3">
                {{ 'destinationName' | i18n }}
                <span class="require">{{ 'required' | i18n }}</span>
              </label>
              <span class="caution">
                <small>{{ 'oldFontsCantBeUsedMsg' | i18n }}</small>
              </span>
              <div class="col-md-6 mb-3 mb-md-0">
                <input
                  id="DestinationLastName"
                  name="DestinationLastName"
                  #DestinationLastName="ngModel"
                  type="text"
                  class="form-control"
                  [class]="{
                    'is-invalid': DestinationLastName.invalid && (!DestinationLastName.untouched || isSubmit)
                  }"
                  [(ngModel)]="CondolenceVideoOrder.DestinationLastName"
                  placeholder="姓"
                  required
                  maxlength="15"
                />
                <div
                  class="validate-message text-danger"
                  *ngIf="
                    (DestinationLastName.errors?.required && (!DestinationLastName.untouched || isSubmit)) ||
                    (DestinationFirstName.invalid && (!DestinationFirstName.untouched || isSubmit))
                  "
                >
                  {{ 'destinationIsRequiredMsg' | i18n }}
                </div>
              </div>
              <div class="col-md-6">
                <input
                  type="text"
                  id="DestinationFirstName"
                  name="DestinationFirstName"
                  #DestinationFirstName="ngModel"
                  class="form-control"
                  maxlength="15"
                  [class]="{
                    'is-invalid': DestinationFirstName.invalid && (!DestinationFirstName.untouched || isSubmit)
                  }"
                  [(ngModel)]="CondolenceVideoOrder.DestinationFirstName"
                  placeholder="名"
                  required
                />
              </div>
            </div>
          </div>
          <!-- card message -->
          <div class="form-group">
            <label for="condolenceVideoComment">
              {{ 'comment' | i18n }}
            </label>
            <textarea
              id="CondolenceVideoComment"
              name="CondolenceVideoComment"
              #CondolenceVideoComment="ngModel"
              rows="10"
              [maxlength]="cardMessageMaxLength"
              class="form-control"
              style.resize="none"
              [(ngModel)]="CondolenceVideoOrder.Comment"
            ></textarea>
            <small class="total-left">
              <strong>{{ 'condolenceCharsLeft' | i18n }}</strong>
              {{ CondolenceSymbolsTotalLeft }}
            </small>
          </div>
          <!-- radio buttons container -->
          <div class="radio-buttons-container">
            <p class="radio-buttons-title">{{ 'senderInformation' | i18n }}</p>
            <div class="radio-buttons">
              <div class="form-group">
                <input
                  type="radio"
                  name="SameOrderInformation"
                  id="SameOrderInformationYes"
                  class="mr-2"
                  [value]="true"
                  [(ngModel)]="CondolenceVideoOrder.isCustomerAddressSame"
                  (ngModelChange)="handleIsCustomerAddressSame($event)"
                />
                <label for="SameOrderInformationYes"> {{ 'enterCustomerInformation' | i18n }} </label>
                <div class="mb-0 ml-3">
                  <input
                    type="checkbox"
                    name="UseMaidenName"
                    id="UseMaidenName"
                    class="mr-2"
                    [(ngModel)]="CondolenceVideoOrder.useMaidenName"
                    (ngModelChange)="handleUseMaidenNameChange($event)"
                    [disabled]="disableUseMaidenName"
                  />
                  <label for="SameOrderInformationYes" class="m-0"> {{ 'userMaidenName' | i18n }} </label>
                </div>
              </div>
              <div class="form-group m-0">
                <input
                  type="radio"
                  name="SameOrderInformation"
                  id="SameOrderInformationNo"
                  class="mr-2"
                  [value]="false"
                  [(ngModel)]="CondolenceVideoOrder.isCustomerAddressSame"
                  (ngModelChange)="handleIsCustomerAddressSame($event)"
                />
                <label for="SameOrderInformationNo" class="m-0">{{ 'enterTheAboveInformation' | i18n }}</label>
              </div>
            </div>
            <!-- customer address form container -->
            <app-address
              #customerForm
              [type]="AddressType.customer"
              itemType="condolenceVideo"
              [labelOnTop]="true"
              [viewType]="ViewType.nameOnly"
              [nameRemark]="true"
              [readonly]="CondolenceVideoOrder.isCustomerAddressSame"
              [isCustomerAddressSame]="CondolenceVideoOrder.isCustomerAddressSame"
              [useMaidenName]="CondolenceVideoOrder.useMaidenName"
            ></app-address>
            <!-- custom name -->
            <div class="form-group row mb-2">
              <label for="customName" class="d-flex flex-column col-md-12">
                <p class="d-flex m-0 flex-row">
                  <span class="mr-3">{{ 'customName' | i18n }}</span>
                  <!-- <span class="require m-0">{{ 'customNameIsRequired' | i18n }}</span> -->
                </p>
                <span class="custom-name-remark"> {{ 'pleaseEnterInKana' | i18n }} </span>
              </label>
              <div class="col-md-12">
                <textarea
                  id="customName"
                  name="customName"
                  class="form-control"
                  style="font-size: 14px"
                  rows="9"
                  placeholder="
                  例1 連盟）   田中 一郎（タナカ イチロウ）
                  　　　　　　　鈴木 二郎（スズキ ジロウ）

                  例2 一同）　〇〇〇会社　営業部一同（〇〇〇ガイシャ　エイギョウブイチドウ）
                  "
                  [(ngModel)]="CondolenceVideoOrder.attributes.customName"
                  [readonly]="CondolenceVideoOrder.isCustomerAddressSame"
                ></textarea>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>

    <!-- remark: condolence video does not have presents that is why it does not have shipping address -->
    <!-- shipping address -->
    <!-- <div class="shipping-address-container" *ngIf="hasPresents">
      <p>{{ 'condolenceShippingAddress' | i18n }}</p>
      <div class="form-group">
        <input
          type="radio"
          name="DestinationSame"
          id="DestinationSameYes"
          [value]="true"
          [(ngModel)]="CondolenceVideoOrder.IsDestinationSame"
        />
        <label for="DestinationSameYes"> {{ 'destinationSameFlowerYes' | i18n }} </label>
      </div>
      <div class="form-group">
        <input
          type="radio"
          name="DestinationSame"
          id="DestinationSameNo"
          [value]="false"
          [(ngModel)]="CondolenceVideoOrder.IsDestinationSame"
        />
        <label for="DestinationSameNo">{{ 'destinationSameNo' | i18n }}</label>
      </div>
    </div> -->

    <!-- remark: condolence video does not have presents that is why it does not have shipping address -->
    <!-- shipping address form container -->
    <!-- <app-address
      #shippingForm
      myStyle="padding-left: 3em"
      [style.display]="CondolenceVideoOrder.IsDestinationSame ? 'none' : 'initial'"
      [type]="AddressType.shipping"
      itemType="condolenceVideo"
      viewType="shortAddress"
      [labelOnTop]="false"
    ></app-address> -->
  </div>
  <!-- buttons -->
  <div class="buttons-container">
    <button id="return-button" class="nav-button return-button" (click)="back()">
      <!-- {{ 'return' | i18n }} -->
      {{ returnButtonLabel }}
    </button>
    <button id="next-button" class="nav-button next-button" (click)="next()">
      {{ 'decideAndNext' | i18n }}
    </button>
  </div>
</div>
<!--
<div class="container pt-3">
  <div class="row btn-toolbar justify-content-between mt-2 mb-5 mx-1" role="toolbar">
    <button id="condolence-video-order-return-button" class="btn btn-outline-secondary col-md-4" (click)="back()">
      {{ 'return' | i18n }}
    </button>
    <button id="condolence-video-order-save-button" class="btn btn-secondary col-md-4" (click)="next()">
      {{ 'saveOrderButton' | i18n }}
    </button>
  </div>
</div> -->
