<div id="flowers-select" class="no-flex" [@inOutAnimation]>
  <div class="container">
    <h4 class="page-header">{{ 'flowerOffering' | i18n }}</h4>
    <h5 class="text-center">{{ 'selectFlowerToApply' | i18n }}</h5>
    <div class="row justify-content-center mb-5" *ngIf="IsSelectTypeVisible">
      <div
        class="btn-group col-12 col-md-6 btn-group-toggle flower-type-toggle"
        ngbRadioGroup
        name="radioBasic"
        [(ngModel)]="StyleType"
      >
        <label for="japaneseStyle" ngbButtonLabel class="btn-outline-secondary btn-japan mr-md-5 mr-2">
          <input ngbButton id="japaneseStyle" type="radio" value="Japanese" /> {{ 'japaneseStyle' | i18n }}
        </label>
        <label for="westernStyle" ngbButtonLabel class="btn-outline-secondary btn-west">
          <input ngbButton id="westernStyle" type="radio" value="Western" /> {{ 'westernStyle' | i18n }}
        </label>
      </div>
    </div>
  </div>
  <div class="grey-wrapper bg-grey">
    <div class="container">
      <ul class="product-list row form-group">
        <li
          z="formArray"
          *ngFor="let item of flowers; let i = index"
          class="col-6 col-md-4 mb-2"
          [ngClass]="{ 'product-active': item.id == selectedFlower?.id }"
        >
          <label for="product_{{ i }}">
            <img class="img-fluid" vtMedia [object]="item.picture" />
            <div class="product-title">
              {{ item.name }}
            </div>
            <div class="price row font-medium text-left ml-3" *ngIf="isSmacereClubMember | async; else notMember">
              <span class="col-md-4 p-0 font-small">{{ 'normalPrice' | i18n }}</span>
              <span class="col-md-8 p-0">{{ item.price | priceWithTax }}({{ 'taxIncluded' | i18n }})</span>
            </div>
            <ng-template #notMember>
              <div class="price row font-medium text-center">
                <span class="col-md-12 p-0">{{ item.price | priceWithTax }}({{ 'taxIncluded' | i18n }})</span>
              </div>
            </ng-template>
            <div
              class="priceAfterDiscount row font-small text-danger text-left ml-3 align-items-center"
              *ngIf="isSmacereClubMember | async"
            >
              <span class="col-md-4 p-0 d-flex flex-column font-xx-small">
                <span>{{ 'smacereClub' | i18n }}</span>
                <span>{{ 'memberPrice' | i18n }}</span>
              </span>
              <span class="col-md-8 p-0">
                <app-discounted-price [price]="item.price" [type]="ProductType.Flower"></app-discounted-price>({{
                  'taxIncluded' | i18n
                }})
              </span>
            </div>
          </label>
          <RadioButton
            id="product_{{ i }}"
            name="flower"
            [value]="item.id"
            [checked]="item.id == selectedFlower?.id"
            (change)="onSelectionChange(item)"
          ></RadioButton>
        </li>
      </ul>
    </div>
  </div>
  <!-- buttons -->
  <div class="buttons-container">
    <button id="return-button" class="nav-button return-button" (click)="back()">
      <!-- {{ 'return' | i18n }} -->
      {{ returnButtonLabel }}
    </button>
    <button id="next-button" class="nav-button next-button" (click)="next()">
      {{ 'decideAndNext' | i18n }}
    </button>
  </div>
  <!-- <div class="container">
    <div class="btn-toolbar justify-content-between mt-2 mb-5" role="toolbar">
      <button id="flower-select-return-button" class="btn btn-outline-secondary" (click)="back()">
        {{ 'return' | i18n }}
      </button>
      <button id="flower-select-decide-and-next-button" class="btn btn-secondary" type="button" (click)="next()">
        {{ 'decideAndNext' | i18n }}
      </button>
    </div>
  </div> -->
</div>
