import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';
import { APIService, Product } from '@kokusai/smacere-shared/api';
import { ProductStorageService } from './product-storage.service';
import { AmplifyAppSync } from '@kokusai/utilities';
import moment from 'moment';
import { GlobalStorage } from 'src/app/global/storage';

@Injectable({
  providedIn: 'root',
})
export class ProductService extends BaseService {
  constructor(
    http: HttpClient,
    apiService: APIService,
    productStorage: ProductStorageService,
    private storage: GlobalStorage
  ) {
    super(http, apiService, productStorage);
  }

  public async gets(funeralId: string): Promise<Array<Product>> {
    // TODO: This should be funeralDate instead . c622 1.2
    // const today = moment().format('YYYY-MM-DD');

    const funeral = await this.api.GetFuneral(funeralId);
    if (funeral) {
      const funeralDate = funeral?.funeralDate;
      return AmplifyAppSync.listAll(this.api.ListProducts, {
        and: [
          {
            or: [
              { fromDate: { attributeExists: false } },
              { fromDate: { eq: null } },
              { fromDate: { le: funeralDate } },
            ],
          },
          { or: [{ toDate: { attributeExists: false } }, { toDate: { eq: null } }, { toDate: { ge: funeralDate } }] },
          { isDeleted: { ne: true } },
        ],
      });
    }
  }

  public get(id: string): Promise<any> {
    return this.api.GetProduct(id);
  }
}
