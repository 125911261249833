import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { GlobalStorage } from 'src/app/global/storage';
import { PresentsService } from 'src/services';
import { CondolenceVideoOrder } from 'src/models';
import { Funeral, ProductType } from '@kokusai/smacere-shared/api';
import { BundleCartService, BundleType } from '../../../services/bundle-cart.service';
import { DiscountsService } from 'src/app/services/discounts.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-condolence-video-order-details',
  templateUrl: './condolence-video-order-details.component.html',
  styleUrls: ['./condolence-video-order-details.component.scss'],
})
export class CondolenceVideoOrderDetailsComponent implements OnInit {
  constructor(
    private storage: GlobalStorage,
    private presentsService: PresentsService,
    private router: Router,
    private location: Location,
    private bundleCartService: BundleCartService,
    private discountsService: DiscountsService,
    private authService: AuthService
  ) {}

  CondolenceVideoOrder: CondolenceVideoOrder;
  Funeral: Funeral;
  public hasPresents: boolean = false;
  public isEdit: boolean = false;
  isSmacereClubMember;

  async ngOnInit(): Promise<void> {
    this.bundleCartService.checkStep(BundleType.CondolenceVideo, 'order-details', 'present');

    this.Funeral = await this.storage.FuneralModel;
    this.CondolenceVideoOrder = this.storage.Order.CondolenceVideoOrder;
    window.scrollTo(0, 0);

    // if (this.Funeral.displayCardPresent) {
    //   this.hasPresents = true;
    //   this.presentsService
    //     .gets(ProductType.Card, this.CondolenceVideoOrder.Product.price, this.Funeral.funeralDate)
    //     .subscribe((presents) => (this.hasPresents = presents.length > 0));
    // }
    this.isEdit = this.bundleCartService.isEdit();

    this.isSmacereClubMember = this.authService.isSmacereClubMember;
  }

  public async nextAction(): Promise<void> {
    if (this.hasPresents) {
      await this.router.navigate([this.storage.Funeral.funeralId, 'order', 'condolence-video', 'presents']);
      return;
    }

    await this.bundleCartService.saveCondolenceVideoFromStorage();
    await this.bundleCartService.goToNextStep(BundleType.CondolenceVideo);
  }

  public async addAction(): Promise<void> {
    await this.bundleCartService.saveCondolenceVideoFromStorage();
    await this.bundleCartService.goToAdd(BundleType.Card);
  }

  back(): void {
    this.location.back();
  }

  get discountedPrice() {
    return this.discountsService.getDiscountedPrice(
      this.CondolenceVideoOrder.Product?.price,
      ProductType.CondolenceVideo
    );
  }
}
