import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[imgCheck]',
})
export class ImgCheckDirective {
  @Input()
  public defaultUrl: string;
  constructor(private element: ElementRef) {
    this.element.nativeElement.onerror = () => {
      if (this.element.nativeElement.src.indexOf(this.defaultUrl) === -1) {
        this.element.nativeElement.src = this.defaultUrl;
      }
    };
  }
}
