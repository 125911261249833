<div class="bg-grey pt-1">
  <div class="container mt-4">
    <div class="row justify-content-between">
      <div class="col-12 col-md-4 col-lg-5">
        <ul class="product-details">
          <li>
            <div class="row">
              <div class="col-11">
                {{ 'deceasedNamePrefix' | i18n }}
                {{ Funeral.deceasedName }} {{ 'nameSuffix' | i18n }}
              </div>
            </div>
          </li>
          <li class="mb-4">
            <div class="row">
              <div class="col-11">
                {{ 'mourning' | i18n }}
                {{ Funeral.mourningName }} {{ 'nameSuffix' | i18n }}
              </div>
            </div>
          </li>
          <li>
            <img class="col-12 col-md-5 p-0 form-group" vtMedia [object]="CardOrder.Product.picture" />
          </li>
          <li>
            <div class="row">
              <div class="col-3 card-order-label">{{ 'materialType' | i18n }}</div>
              <div class="col-9 card-order-material-type">
                {{ 'condolenceMaterialType' | i18n }}({{ CardOrder.Product.cardMaterialType }})
              </div>
            </div>
          </li>
          <li>
            <div class="row">
              <div class="col-3 card-order-label"></div>
              <div class="col-9 p-0">
                <small class="card-order-product-name">
                  <span>{{ '【' + CardOrder.Product.name }}</span>
                  <span *ngIf="CardOrder.Product.nameKana; else noNameKana">{{
                    '＜' + CardOrder.Product.nameKana + '＞】'
                  }}</span>
                  <ng-template #noNameKana>】</ng-template>
                </small>
              </div>
            </div>
          </li>
          <li>
            <div class="row">
              <div class="col-3 card-order-label"></div>
              <div class="col-9">
                <small>{{ CardOrder.Product.cardOpenSide }}</small>
              </div>
            </div>
          </li>
          <li>
            <div class="row">
              <div class="col-3 card-order-label">{{ 'price' | i18n }}</div>
              <div class="col-9 card-order-product-price">
                {{ CardOrder.Product.price | priceWithTax }}<small>({{ 'taxIncluded' | i18n }})</small>
              </div>
            </div>
          </li>
        </ul>
        <ul class="product-details">
          <li class="text-left">
            <img class="col-12 col-md-4 p-0 form-group" vtMedia [object]="CardOrder.Paper.picture" />
          </li>
          <li>
            <div class="row">
              <div class="col-3 card-order-label">{{ 'paperName' | i18n }}</div>
              <div class="col-9 card-order-card-name">
                {{ CardOrder.Paper.name }}
              </div>
            </div>
          </li>
        </ul>
      </div>
      <!-- form container -->
      <div class="col-12 col-md-8 col-lg-7">
        <form #cardOrderForm="ngForm" novalidate>
          <!-- card destination name -->
          <div class="form-group">
            <div class="row">
              <label for="DestinationLastName" class="ml-3">
                {{ 'destinationName' | i18n }}
                <span class="require">{{ 'required' | i18n }}</span>
              </label>
              <span class="caution">
                <small>{{ 'oldFontsCantBeUsedMsg' | i18n }}</small>
              </span>
              <div class="col-md-6 mb-3 mb-md-0">
                <input
                  id="DestinationLastName"
                  name="DestinationLastName"
                  #DestinationLastName="ngModel"
                  type="text"
                  class="form-control"
                  [class]="{
                    'is-invalid': DestinationLastName.invalid && (!DestinationLastName.untouched || isSubmit)
                  }"
                  [(ngModel)]="CardOrder.cardDestinationLastName"
                  placeholder="姓"
                  required
                  maxlength="15"
                />
                <div
                  class="validate-message text-danger"
                  *ngIf="
                    (DestinationLastName.errors?.required && (!DestinationLastName.untouched || isSubmit)) ||
                    (DestinationFirstName.invalid && (!DestinationFirstName.untouched || isSubmit))
                  "
                >
                  {{ 'destinationIsRequiredMsg' | i18n }}
                </div>
              </div>
              <div class="col-md-6">
                <input
                  type="text"
                  id="DestinationFirstName"
                  name="DestinationFirstName"
                  #DestinationFirstName="ngModel"
                  class="form-control"
                  maxlength="15"
                  [class]="{
                    'is-invalid': DestinationFirstName.invalid && (!DestinationFirstName.untouched || isSubmit)
                  }"
                  [(ngModel)]="CardOrder.cardDestinationFirstName"
                  placeholder="名"
                  required
                />
              </div>
            </div>
          </div>
          <!-- card message -->
          <div class="form-group">
            <label for="condolenceInput">
              {{ 'funeralAddressInput' | i18n }}
              <span class="require">{{ 'required' | i18n }}</span>
            </label>
            <button
              id="choose-template-button"
              class="btn btn-primary btn-popup side-round-button"
              type="button"
              (click)="OpenCardMessageModal()"
            >
              {{ 'chooseTemplateButton' | i18n }} >
            </button>
            <textarea
              id="CondolenceInput"
              name="CondolenceInput"
              #CondolenceInput="ngModel"
              rows="10"
              [maxlength]="cardMessageMaxLength"
              class="form-control"
              [class]="{
                'is-invalid': CondolenceInput.invalid && (!CondolenceInput.untouched || isSubmit)
              }"
              style="resize: none"
              [(ngModel)]="CardOrder.cardMessage"
              required
            ></textarea>
            <div
              class="validate-message text-danger"
              *ngIf="CondolenceInput.errors?.required && (!CondolenceInput.untouched || isSubmit)"
            >
              {{ 'funeralAddressInputIsRequired' | i18n }}
            </div>
            <div class="total-left">
              <strong>{{ 'condolenceCharsLeft' | i18n }}</strong>
              {{ CondolenceSymbolsTotalLeft }}
            </div>
          </div>
          <!-- radio buttons container -->
          <div class="customer-form-container">
            <p class="customer-form-title">{{ 'senderInformation' | i18n }}</p>
            <div class="radio-container">
              <div class="form-group">
                <input
                  type="radio"
                  name="SameOrderInformation"
                  id="SameOrderInformationYes"
                  class="mr-2"
                  [value]="true"
                  [(ngModel)]="CardOrder.isCustomerAddressSame"
                  (ngModelChange)="handleIsCustomerAddressSame($event)"
                />
                <label for="SameOrderInformationYes"> {{ 'enterCustomerInformation' | i18n }} </label>
                <div class="mb-0 ml-3">
                  <input
                    type="checkbox"
                    name="UseMaidenName"
                    id="UseMaidenName"
                    class="mr-2"
                    [(ngModel)]="CardOrder.useMaidenName"
                    (ngModelChange)="handleUseMaidenNameChange($event)"
                    [disabled]="!CardOrder.isCustomerAddressSame || !Order.BillingAddress.maidenName"
                  />
                  <label for="SameOrderInformationYes" class="m-0"> {{ 'userMaidenName' | i18n }} </label>
                </div>
              </div>
              <div class="form-group">
                <input
                  type="radio"
                  name="SameOrderInformation"
                  id="SameOrderInformationNo"
                  class="mr-2"
                  [value]="false"
                  [(ngModel)]="CardOrder.isCustomerAddressSame"
                  (ngModelChange)="handleIsCustomerAddressSame($event)"
                />
                <label for="SameOrderInformationNo" class="m-0">{{ 'enterOtherInformation' | i18n }}</label>
              </div>
            </div>
            <!-- customer address form container -->
            <app-address
              #customerForm
              [type]="AddressType.customer"
              itemType="card"
              [labelOnTop]="true"
              [viewType]="ViewType.nameOnly"
              [nameRemark]="true"
              [readonly]="CardOrder.isCustomerAddressSame"
              [isCustomerAddressSame]="CardOrder.isCustomerAddressSame"
              [useMaidenName]="CardOrder.useMaidenName"
            ></app-address>
            <!-- custom name -->
            <div class="form-group row mb-2 custom-name-container">
              <label for="customName" class="d-flex flex-column col-md-12">
                <p class="d-flex flex-md-row flex-column m-0">
                  <span class="mr-3">{{ 'customName' | i18n }}</span>
                  <!-- <span class="require m-0">{{ 'customNameIsRequired' | i18n }}</span> -->
                </p>
                <span class="custom-name-remark"> {{ 'pleaseEnterInKana' | i18n }} </span>
              </label>
              <div class="col-md-12">
                <textarea
                  id="customName"
                  name="customName"
                  class="form-control"
                  rows="9"
                  placeholder="
      例1 連盟）   田中 一郎（タナカ イチロウ）
      　　　　　　　鈴木 二郎（スズキ ジロウ）

      例2 一同）　〇〇〇会社　営業部一同（〇〇〇ガイシャ　エイギョウブイチドウ）
      "
                  [(ngModel)]="CardOrder.attributes.customName"
                  [readonly]="CardOrder.isCustomerAddressSame"
                ></textarea>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <!-- shipping address -->
    <div class="shipping-form-container" *ngIf="hasPresents">
      <p>{{ 'condolenceShippingAddress' | i18n }}</p>
      <div class="form-group">
        <input
          type="radio"
          name="DestinationSame"
          id="DestinationSameYes"
          [value]="true"
          [(ngModel)]="CardOrder.IsDestinationSame"
        />
        <label for="DestinationSameYes"> {{ 'destinationSameFlowerYes' | i18n }} </label>
      </div>
      <div class="form-group">
        <input
          type="radio"
          name="DestinationSame"
          id="DestinationSameNo"
          [value]="false"
          [(ngModel)]="CardOrder.IsDestinationSame"
        />
        <label for="DestinationSameNo">{{ 'destinationSameNo' | i18n }}</label>
      </div>
    </div>
    <!-- shipping address form container -->
    <app-address
      #shippingForm
      [myClass]="'padding-left'"
      [style.display]="CardOrder.IsDestinationSame ? 'none' : 'initial'"
      [type]="AddressType.shipping"
      itemType="card"
      [viewType]="ViewType.shortAddress"
      [labelOnTop]="false"
    ></app-address>
  </div>
  <!-- buttons -->
  <div class="buttons-container">
    <button id="return-button" class="nav-button return-button" (click)="back()">
      {{ 'return' | i18n }}
    </button>
    <button id="next-button" class="nav-button next-button" (click)="next()">
      {{ 'decideAndNext' | i18n }}
    </button>
  </div>
</div>
<!-- <div class="container pt-3">
  <div class="row btn-toolbar justify-content-between mt-2 mb-5 mx-1" role="toolbar">
    <button id="card-order-return-button" class="btn btn-outline-secondary col-md-4" (click)="back()">
      {{ 'return' | i18n }}
    </button>
    <button id="card-order-save-button" class="btn btn-secondary col-md-4" (click)="next()">
      {{ 'saveOrderButton' | i18n }}
    </button>
  </div>
</div> -->
