import { Pipe, PipeTransform } from '@angular/core';
import { Format } from '@kokusai/utilities';

@Pipe({
  name: 'address',
  pure: true,
})
export class AddressPipe implements PipeTransform {
  transform(value: any, options: Parameters<typeof Format['address']>[1]): string {
    return Format.address(value, options ?? {});
  }
}
