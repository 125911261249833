import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, ValidationErrors } from '@angular/forms';
import { ComboBoxComponent } from '@progress/kendo-angular-dropdowns';

@Directive({
  selector: '[MaxLength]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: MaxLengthDirective,
      multi: true,
    },
  ],
})
export class MaxLengthDirective implements Validator {
  private target: ComboBoxComponent;
  private maxLength: number;

  @Input()
  set MaxLength(value: number) {
    this.maxLength = value;
  }
  get MaxLength() {
    return this.maxLength;
  }

  constructor(private element: ComboBoxComponent) {
    this.target = this.element;
  }

  validate(): ValidationErrors {
    const length: number = this.target.text.length;
    if (length > this.maxLength) {
      return { maxLengthError: true };
    } else {
      return null;
    }
  }
}
