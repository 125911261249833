import { Component, OnInit } from '@angular/core';
import { Order } from 'src/models';
import { GlobalStorage } from 'src/app/global/storage';
import { BundleCartService } from '../../../services/bundle-cart.service';

@Component({
  selector: 'app-customer-summary',
  templateUrl: './customer-summary.component.html',
  styleUrls: ['./customer-summary.component.scss'],
})
export class CustomerSummaryComponent implements OnInit {
  order: Order;

  constructor(private storage: GlobalStorage, private bundleCartService: BundleCartService) {}

  ngOnInit(): void {
    this.order = this.storage.Order;
  }

  async edit(): Promise<void> {
    await this.bundleCartService.goToCustomerDetails();
  }
}
