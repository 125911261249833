import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'japanTime',
  pure: true,
})
export class JapanTimePipe implements PipeTransform {
  transform(value: Date): string {
    const timeName = value.getHours() >= 12 ? '午後' : '午前';
    const date = moment(value);
    if (date.hour() === 12 && timeName === '午後') {
      return '正午';
    }

    return `${timeName}${date.format('hh:mm')}`;
  }
}
