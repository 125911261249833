import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalStorage } from 'src/app/global/storage';
import { Order, Funeral } from 'src/models';
import { StepType } from 'src/types';
import { ReceptionCloseService } from 'src/services';

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss'],
})
export class OrderComponent implements OnInit {
  constructor(
    private storage: GlobalStorage,
    private router: Router,
    private receptionCloseService: ReceptionCloseService
  ) {}

  public Funeral: Funeral;

  ngOnInit(): void {
    if (!this.storage.Order) {
      this.storage.Order = new Order();
    }
    this.Funeral = this.storage.Funeral;
    if (this.IsCloseOrder()) {
      this.router.navigate([this.Funeral.funeralId]);
    }
  }

  private IsCloseOrder(): boolean {
    const IsCloseFlower = !this.Funeral.displayFlower
      ? true
      : this.receptionCloseService.getReceptionStatus(this.storage.Funeral, StepType.Flower).isFuneralClosed;
    const IsCloseCard = !this.Funeral.displayCard
      ? true
      : this.receptionCloseService.getReceptionStatus(this.storage.Funeral, StepType.Card).isFuneralClosed;
    const IsCloseCondolenceVideo = !this.Funeral.displayCondolenceVideos
      ? true
      : this.receptionCloseService.getReceptionStatus(this.storage.Funeral, StepType.CondolenceVideo).isFuneralClosed;
    const IsCloseMoneyGift = !this.Funeral.displayMoney
      ? true
      : this.receptionCloseService.getReceptionStatus(this.storage.Funeral, StepType.MoneyGift).isFuneralClosed;

    return IsCloseFlower && IsCloseCard && IsCloseCondolenceVideo && IsCloseMoneyGift;
  }
}
