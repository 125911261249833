import { Injectable } from '@angular/core';

import { BaseService } from './base.service';

import { LookupType } from '@kokusai/smacere-shared/api';

// TODO: Should this be moved to dictionary service?
@Injectable()
export class CardRelationshipService extends BaseService {
  gets(): Promise<string[]> {
    return this.api.LookupByType(LookupType.CardRelationship).then((result) => {
      return result.items.sort((a, b) => (a.sortOrder > b.sortOrder ? 1 : -1)).map((item) => item.group);
    });
  }

  getsBy(group: string): Promise<string[]> {
    return this.api.ListLookups({ group: { eq: group } }, 500).then((result) => {
      return result.items.sort((a, b) => (a.sortOrder > b.sortOrder ? 1 : -1)).map((item) => item.message);
    });
  }
}
