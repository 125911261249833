<div class="log-in-form">
  <h6 class="log-in-form-header">他サービスIDを利用して情報入力</h6>
  <div class="log-in-header-contents">
    <p>
      SNSなどの他サービスとの連携のお手続きを頂きますと、 他サービスでご登録頂いていおります情報を自動入力いたします。
    </p>
    <button class="button amazon-button"><img src="/assets/svg/amazon.svg" alt="" /><span>でログイン</span></button>
    <button class="button line-button"><img src="/assets/svg/line.svg" alt="" /><span>LINE でログイン</span></button>
    <button class="button yahoo-button">
      <img src="/assets/svg/yahoo.svg" alt="" /><span>Yahoo! JAPAN IDでログイン</span>
    </button>
  </div>
</div>
