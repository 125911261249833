import { Injectable } from '@angular/core';

import { BaseService } from './base.service';

import { CardMessage } from 'src/models/CardMessage';

import { LookupType } from '@kokusai/smacere-shared/api';

@Injectable()
export class CardMessageService extends BaseService {
  // TODO: Shouldn't this be moved to dictionary service?
  gets(): Promise<CardMessage[]> {
    return this.api.LookupByType(LookupType.CardMessage).then((result) => {
      return result.items
        .sort((a, b) => (a.sortOrder > b.sortOrder ? 1 : -1))
        .map((item) => new CardMessage(item.message));
    });
  }
}
