<section id="user-screen" class="align-items-center justify-content-center">
  <div class="deceased-card p-1 pt-5 p-md-0 pt-md-0 font-mincho">
    <div class="deceased-title-container">
      <p class="deceased-name-container font-mincho" [style.fontSize]="fontSize + 'px'">
        <span *ngIf="Funeral.deceasedNamePrefix" class="deceased-name-title">
          {{ Funeral.deceasedNamePrefix + '&#12288;' }}
        </span>
        <span *ngIf="Funeral.deceasedName" class="deceased-name">{{ Funeral.deceasedName }}</span>
        <span *ngIf="Funeral.deceasedNameSuffix" class="deceased-name-title">
          {{ '&#12288;' + Funeral.deceasedNameSuffix }}
        </span>
        <span *ngIf="Funeral.deceasedNamePostfix" class="deceased-name-postfix">
          {{ '&#12288;' + Funeral?.deceasedNamePostfix }}
        </span>
      </p>
      <p class="deceased-age">
        <span class="mr-3">{{ Funeral.deathDate }}{{ 'death' | i18n }}</span>
        <span>({{ 'agePrefix' | i18n }}{{ Funeral.deceasedAge }}{{ 'age' | i18n }})</span>
      </p>
    </div>

    <div class="photo">
      <img vtMedia [object]="Funeral.deceasedPhoto" defaultUrl="/assets/img/deceased/default.jpg" class="img-fluid" />
    </div>

    <div class="card-body">
      <p>{{ Funeral.deathDate }}に、{{ 'agePrefix' | i18n }}{{ Funeral.deceasedAge }}{{ 'diedAtAge' | i18n }}</p>
      <p>
        {{ 'gratitudeForKindnessMsg' | i18n }}
      </p>
    </div>

    <div class="mourning-name">
      <p class="mourning-name-title">{{ 'mourning' | i18n }}</p>
      <p class="mourning-name-value">{{ Funeral.mourningName }}</p>
    </div>

    <div class="skip-button">
      <!-- next button -->
      <button id="deceased-person-next-button" class="btn btn-light next-button" (click)="skipClick()">
        {{ 'next' | i18n }}
        <span class="button-right-arrow"><i class="fa fa-angle-right"></i></span>
      </button>
      <!-- return button -->
      <button id="deceased-person-return-button" class="btn btn-light return-button" (click)="returnClick()">
        <span class="button-left-arrow"><i class="fa fa-angle-left"></i></span>
        {{ 'return' | i18n }}
      </button>
    </div>
  </div>
</section>
