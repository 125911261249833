<div class="modal-header no-border">
  <a href="javascript:void(0);" class="modal-close-icon" (click)="activeModal.close()"></a>
  <h4 class="modal-title" id="modal-basic-title">【推奨環境について】</h4>
</div>
<div class="com-body modal-body">
  <p>スマートセレモニーを快適にご利用いただくために、以下の環境でのご利用を推奨いたします。</p>
  <p>またJavaScript、Cookieが使用できる状態でご利用ください。</p>
  <br />
  <p class="title_counter">【PC】推奨OS</p>
  <p>Windows（メーカーサポート対象のバージョン）</p>
  <p>Mac　OS　X（メーカーサポート対象のバージョン）</p>
  <br />
  <p class="title_counter">【スマートフォン】推奨ＯＳ</p>
  <p>iOS（最新版）</p>
  <p>Android（最新版）</p>
  <br />
  <p class="title_counter">【PC】推奨ブラウザ</p>
  <p>Google Chrome（最新版）</p>
  <p>Microsoft Edge（最新版）</p>
  <p>Firefox（最新版）</p>
  <p>Safari（最新版）</p>
  <br />
  <p class="title_counter">【スマートフォン】推奨ブラウザ</p>
  <p>Google Chrome（最新版）</p>
  <p>Safari （最新版）</p>
  <br />
  <p>※Internet Explorerには対応しておりません。ご了承ください。</p>
  <p>※フィーチャーフォンには対応しておりません。ご了承ください。</p>
</div>
