<div class="d-flex">
  <div class="order-logo">
    <a [routerLink]="['/', Funeral.funeralId]">
      <img src="{{ 'logo.png' | path }}" class="logo-md img-fluid" />
      <img src="{{ 'logo_mobile.png' | path }}" class="logo-sm img-fluid md-hi" />
    </a>
  </div>
  <app-order-header class="w-100"></app-order-header>
</div>
<div class="order-conteiner">
  <router-outlet></router-outlet>
</div>
<app-footer class="order-footer"></app-footer>
