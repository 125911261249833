export * from './memorial-share.component';
export * from './edit-memorial-share-modal/edit-memorial-share-modal.component';
export * from './memorial-share-home/memorial-share-home.component';
export * from './memorial-share-photo-album/memorial-share-photo-album.component';
export * from './memorial-share-upload/memorial-share-upload.component';

import { MemorialShareComponent } from './memorial-share.component';
import { EditMemorialShareModalComponent } from './edit-memorial-share-modal/edit-memorial-share-modal.component';
import { MemorialShareHomeComponent } from './memorial-share-home/memorial-share-home.component';
import { MemorialSharePhotoAlbumComponent } from './memorial-share-photo-album/memorial-share-photo-album.component';
import { MemorialShareUploadComponent } from './memorial-share-upload/memorial-share-upload.component';

export const CondolenceVideoModule = [
  MemorialShareComponent,
  EditMemorialShareModalComponent,
  MemorialShareHomeComponent,
  MemorialSharePhotoAlbumComponent,
  MemorialShareUploadComponent,
];
