<div class="container mt-4">
  <div class="row">
    <div class="col-12 col-md-4 d-none d-md-block">
      <ul class="product-details">
        <li>
          <img class="col-12 col-md-5 p-0 mb-2" vtMedia [object]="CardOrder?.Product?.picture" />
        </li>
        <li>
          <div class="row">
            <div class="col-4">{{ 'materialType' | i18n }}</div>
            <div class="col-8">{{ CardOrder?.Product?.cardMaterialType }}</div>
          </div>
        </li>
        <li>
          <div class="row">
            <div class="col-4"></div>
            <div class="col-8">
              <small class="order-product-name">
                <span>{{ '【' + CardOrder?.Product?.name }}</span>
                <span *ngIf="CardOrder?.Product?.nameKana; else noNameKana">{{
                  '＜' + CardOrder?.Product?.nameKana + '＞】'
                }}</span>
                <ng-template #noNameKana>】</ng-template>
              </small>
              <!-- <small>【{{ CardOrder?.Product?.name }}＜{{ CardOrder?.Product?.nameKana }}＞】</small> -->
            </div>
          </div>
        </li>
        <li>
          <div class="row">
            <div class="col-4"></div>
            <div class="col-8">
              <small>{{ CardOrder?.Product?.cardOpenSide }}</small>
            </div>
          </div>
        </li>
        <li class="mb-3">
          <div class="row">
            <div class="col-4">{{ 'price' | i18n }}</div>
            <div class="col-8">
              {{ CardOrder?.Product?.price | priceWithTax }}
              <small>（{{ 'taxIncluded' | i18n }}）</small>
            </div>
          </div>
        </li>
        <li><img class="col-12 col-md-4 p-0 mb-2" vtMedia [object]="CardOrder?.Paper?.picture" /></li>
        <li>
          <div class="row">
            <div class="col-4">{{ 'paperName' | i18n }}</div>
            <div class="col-8">
              <small>{{ CardOrder?.Paper?.name }}</small>
            </div>
          </div>
        </li>
      </ul>
    </div>

    <div class="col-md-8 col-md-5">
      <ul class="product-details">
        <li class="row mb-3">
          <div class="col-12 col-md-12">
            {{ 'deceasedNamePrefix' | i18n }}　{{ Funeral?.deceasedName }}　{{ 'nameSuffix' | i18n }}
          </div>
        </li>
        <li class="row mb-3">
          <div class="col-12 col-md-12">
            {{ 'mourning' | i18n }}　{{ Funeral?.mourningName }}　{{ 'nameSuffix' | i18n }}
          </div>
        </li>
        <li class="d-block d-md-none">
          <img class="md-2 p-0 img-fluid" vtMedia [object]="CardOrder?.Product?.picture" />
        </li>
        <li class="row">
          <div class="col-6 col-md-4">{{ 'productNameOne' | i18n }}</div>
          <div class="col-6 col-md-8">
            {{ CardOrder?.Product?.cardMaterialType }}
            <small class="order-product-name">
              <span>{{ '【' + CardOrder?.Product?.name }}</span>
              <span *ngIf="CardOrder?.Product?.nameKana; else noNameKana">{{
                '＜' + CardOrder?.Product?.nameKana + '＞】'
              }}</span>
              <ng-template #noNameKana>】</ng-template>
            </small>
            <!-- <small
              >【{{ CardOrder?.Product?.name }}＜{{ CardOrder?.Product?.nameKana }}＞】{{
                CardOrder?.Product?.cardOpenSide
              }}</small
            > -->
          </div>
        </li>
        <li class="row">
          <div class="col-6 col-md-4">{{ 'price' | i18n }}</div>
          <div class="col-6 col-md-8">
            {{ CardOrder?.Product?.price | priceWithTax }}
            <small>（{{ 'taxIncluded' | i18n }}）</small>
          </div>
        </li>
        <li class="d-block d-md-none">
          <img class="md-2 p-0 img-fluid" vtMedia [object]="CardOrder?.Paper?.picture" />
        </li>
        <li class="row">
          <div class="col-6 col-md-4 mb-3">{{ 'productNameTwo' | i18n }}</div>
          <div class="col-6 col-md-8">{{ CardOrder?.Paper?.name }}</div>
        </li>
        <li class="row">
          <div class="col-6 col-md-4">{{ 'destinationName' | i18n }}</div>
          <div class="col-6 col-md-8">
            {{ CardOrder?.cardDestinationLastName }} {{ CardOrder?.cardDestinationFirstName }}
          </div>
        </li>

        <li class="row">
          <div class="col-6 col-md-4">{{ 'companyName' | i18n }}</div>
          <div class="col-6 col-md-8">{{ CardOrder?.cardSenderCompany }}</div>
        </li>

        <li class="row">
          <div class="col-6 col-md-4">{{ 'senderPosition' | i18n }}</div>
          <div class="col-6 col-md-8">{{ CardOrder?.cardSenderPosition }}</div>
        </li>

        <li class="row mb-3">
          <div class="col-6 col-md-4">{{ 'senderName' | i18n }}</div>
          <div class="col-6 col-md-8">{{ CardOrder?.cardSenderLastName }} {{ CardOrder?.cardSenderFirstName }}</div>
        </li>

        <li class="row mb-3">
          <div class="col-6 col-md-4">{{ 'customName' | i18n }}</div>
          <div class="col-6 col-md-8">{{ CardOrder?.attributes.customName }}</div>
        </li>

        <li class="row">
          <div class="col-12">
            {{ 'funeralAddress' | i18n }}
          </div>
          <div class="col-12 text-break">
            {{ CardOrder?.cardMessage }}
          </div>
        </li>
      </ul>
      <!-- TODO: Show when come good time :) -->
      <div class="preview col-12" *ngIf="false">
        <button id="previewButton" class="btn btn-primary side-round-button" (click)="OpenCardPreviewModal()">
          {{ 'preview' | i18n }}
        </button>
        <br />
        <span class="caution">
          <small>{{ 'checkContentMsg' | i18n }}</small>
        </span>
      </div>
    </div>
  </div>

  <!-- buttons -->
  <div class="buttons-container">
    <button id="return-button" class="nav-button return-button" (click)="back()">
      {{ 'return' | i18n }}
    </button>
    <button id="add-button" class="nav-button add-button" (click)="addAction()" *ngIf="!hasPresents && !isEdit">
      {{ 'addNewOrder' | i18n }}
    </button>
    <button id="next-button" class="nav-button next-button" (click)="nextAction()">
      {{ 'decideAndNext' | i18n }}
    </button>
  </div>

  <!-- <div class="btn-toolbar justify-content-between mt-2 mb-5" role="toolbar">
    <button id="card-order-details-return-button" class="btn btn-outline-secondary" (click)="back()">
      {{ 'return' | i18n }}
    </button>
    <button
      id="card-order-details-add-button"
      class="btn btn-secondary"
      (click)="addAction()"
      *ngIf="!hasPresents && !isEdit"
    >
      {{ 'addNewOrder' | i18n }}
    </button>
    <button id="card-order-details-decide-button" class="btn btn-secondary" (click)="nextAction()">
      {{ 'decideButton' | i18n }}
    </button>
  </div> -->
</div>
