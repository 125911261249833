import { Pipe, PipeTransform } from '@angular/core';
import { Format } from '@kokusai/utilities';

@Pipe({
  name: 'fullName',
  pure: true,
})
export class FullNamePipe implements PipeTransform {
  transform(value: any): string {
    return Format.fullName(value);
  }
}
