import { Component, OnInit } from '@angular/core';
import { SummarySection } from '../../summary/cart-summary.component';
import { BundleCartService, BundleType } from '../../../../services/bundle-cart.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-flowers-summary',
  templateUrl: './flowers-summary.component.html',
  styleUrls: ['./flowers-summary.component.scss'],
})
export class FlowersSummaryComponent implements OnInit {
  public SummarySection = SummarySection;

  constructor(private location: Location, protected bundleCartService: BundleCartService) {}

  ngOnInit(): void {
    this.bundleCartService.resetCurrentStep();
  }

  async back(): Promise<void> {
    this.location.back();
  }

  async next(): Promise<void> {
    await this.bundleCartService.goToNextStep(BundleType.Flower, false);
  }
}
