<div class="modal-header no-border">
  <a href="javascript:void(0);" class="modal-close-icon" (click)="activeModal.close()"></a>
  <h4 class="modal-title" id="modal-basic-title">【Web上のお申し込みにおける個人情報の取扱いについて】</h4>
</div>
<div class="hpi-body modal-body">
  <p class="title_counter">個人情報の適切な保護と管理</p>
  <p>お客様に最適なサービスの提供を図るために、お客様の個人情報の正当な取扱いと保護に努めます。</p>
  <p>
    当社は個人情報の重要性を理解し、個人情報を正確かつ安全に取り扱うことにより、お客様からお預かりした個人情報を漏洩などから守り、その保護に万全を期してまいります。
  </p>
  <p>
    個人情報の取り扱いに関して、すべてのお客様に安心していただき、かつ信頼をお寄せいただくために、当社において業務に従事する者は、個人情報保護に関するコンプライアンス・プログラム（法令遵守実践規定：JISQ15001：<span
      *ngIf="displayForCeremony"
      >2006</span
    ><span *ngIf="displayForJoin">2017</span>に準じる）に基づく社内研修を受講しております。
  </p>
  <p class="title_counter">個人情報の利用目的</p>
  <p>お客様の個人情報は、葬儀施行に係る弊社からの資料・サービス・商品の提供、イベントのご案内などに利用いたします。</p>
  <p>
    お客様がご自身の個人情報を弊社に提供されるか否かは、お客様のご判断によりますが、もしご提供されない場合には、適切なサービス等が提供できないことがありますので予めご了承ください。
  </p>
  <p class="title_counter">個人情報の第三者への提供</p>
  <p>お客様の個人情報は、以下に掲げる場合以外に、事前にご本人の同意無く第三者に提供することはありません。</p>
  <ul>
    <li>法令に基づく場合</li>
    <li>人の生命、身体又は財産の保護のために必要がある場合であって、本人の同意を得る事が困難であるとき</li>
    <li>
      公衆衛生の向上又は児童の健全な育成の推進のために特に必要がある場合であって、本人の同意を得る事が困難であるとき
    </li>
    <li>
      国の機関若しくは地方公共団体又はその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、本人の同意を得ることによって当該事務の遂行に支障を及ぼすおそれがあるとき
    </li>
    <li>
      統計的情報を提供する目的で、個々の個人情報を集積しまたは分析し、個人の識別や特定ができない形式に加工して、その統計データを、開示する場合
    </li>
  </ul>
  <p class="title_counter">個人情報の取扱業務の委託</p>
  <p>
    機微情報を含む個人情報の取扱業務の全部または一部を外部に業務委託する場合があります。その際、弊社は、個人情報を適切に保護できる管理体制を敷き実行していることを条件として委託先を厳選したうえで、機密保持契約を委託先と締結し、お客様の個人情報を厳密に管理させます。
  </p>
  <p class="title_counter">お客様からの使用停止、削除等の申し込みへの応諾</p>
  <p>
    お客様は、弊社に対してご自身の個人情報の開示等（利用目的の通知、開示、内容の訂正・追加・削除、利用の停止または消去、第三者への提供の停止）に関して、当社問合わせ窓口に申し出ることができます。その際、弊社はお客様ご本人を確認させていただいたうえで、合理的な期間内に対応いたします。
  </p>
  <p>なお、個人情報に関する弊社問合わせ先は、次の通りです。</p>
  <div *ngIf="displayForCeremony">
    <p>株式会社埼玉冠婚葬祭センター　個人情報問合せ窓口</p>
    <p>TEL:048-822-5047(平日9:00～17:00)</p>
    <p>〒330-0061　さいたま市浦和区常盤9丁目4番13号</p>
  </div>
  <div *ngIf="displayForJoin">
    <p>株式会社ジョイン 　個人情報問合せ窓口</p>
    <p>〒990-2447　山形県山形市元木1丁目13番25号</p>
    <p>TEL（023）633-7733（受付時間　9：00～17：30※）</p>
    <p>※土、日曜日、祝日、年末年始、ゴールデンウィーク、お盆期間は</p>
    <p>翌営業日以降の対応とさせていただきます。</p>
  </div>
</div>
