import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-footer-com',
  templateUrl: './footer-com.component.html',
  styleUrls: ['./footer-com.component.scss'],
})
export class FooterCOMComponent implements OnInit {
  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit(): void {}
}
