import { Injectable } from '@angular/core';

import { GlobalStorage } from 'src/app/global/storage';

import { ProductType, Product } from '@kokusai/smacere-shared/api';

@Injectable({
  providedIn: 'root',
})
export class ProductStorageService {
  constructor(private storage: GlobalStorage) {}

  public gets(): Product[] {
    return this.storage.Products;
  }

  public getsByType(type: ProductType) {
    return this.gets().filter((item) => item.productType === type);
  }

  public getById(id: string, type: ProductType) {
    return this.gets().find((item) => item.id === id && item.productType === type) ?? null;
  }
}
