import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { NgForm } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
// import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { GlobalStorage } from 'src/app/global/storage';
import { DictionaryService } from 'src/services';
import { CardOrder, Dictionary, Funeral, Order } from 'src/models';
import { CardMessageComponent } from '../card-message/card-message.component';
import { AddressType } from '@kokusai/smacere-shared/api';
import { AddressComponent } from '../../../../components/address/address.component';
import { BundleCartService, BundleType } from '../../../../services/bundle-cart.service';
import { ViewType } from 'src/app/components/address/types/view-type.type';

@Component({
  selector: 'app-card-order',
  templateUrl: './card-order.component.html',
  styleUrls: ['./card-order.component.scss'],
})
export class CardOrderComponent implements OnInit {
  // @ViewChild('instance', { static: true }) instance: NgbTypeahead;
  // focus$ = new Subject<string>();
  // click$ = new Subject<string>();

  constructor(
    private modalService: NgbModal,
    private dictionaryService: DictionaryService,
    private storage: GlobalStorage,
    private route: Router,
    private location: Location,
    private bundleCartService: BundleCartService
  ) {}

  public hasPresents: boolean = false;
  Funeral: Funeral;
  CardOrder: CardOrder;
  companyTitles: string[] = [];
  cardMessageMaxLength = 255;
  AddressType = AddressType;
  isSubmit = false;
  Order: Order;
  ViewType = ViewType;
  @ViewChild('cardOrderForm') cardOrderForm: NgForm;
  @ViewChild('customerForm') customerForm: AddressComponent;
  @ViewChild('shippingForm') shippingForm: AddressComponent;

  get CondolenceSymbolsTotalLeft() {
    return this.CardOrder.cardMessage?.length > 0
      ? this.cardMessageMaxLength - this.CardOrder.cardMessage.length
      : this.cardMessageMaxLength;
  }

  OpenCardMessageModal() {
    this.modalService.open(CardMessageComponent, { windowClass: 'common-modal' }).result.then((result) => {
      this.CardOrder.cardMessage = result;
    });
  }

  ngOnInit(): void {
    this.bundleCartService.checkStep(BundleType.Card, 'order', 'order-details');

    this.Funeral = this.storage.Funeral;
    this.hasPresents = this.storage.Funeral.displayCardPresent;

    const isCardOrderCustomerAddress = Object.values(this.storage.Order.CardOrder.CustomerAddress).some((item) =>
      Boolean(item)
    );
    if (isCardOrderCustomerAddress) {
      this.CardOrder = {} as CardOrder;
      this.CardOrder.CustomerAddress = { ...this.storage.Order.CardOrder.CustomerAddress };
    } else {
      this.storage.Order.CardOrder.CustomerAddress = { ...this.storage.Order.CustomerAddress };
    }

    const isCardOrderShippingAddress = Object.values(this.storage.Order.CardOrder.ShippingAddress).some((item) =>
      Boolean(item)
    );
    const isOrderShippingAddress = Object.values(this.storage.Order.ShippingAddress).some((item) => Boolean(item));
    if (isCardOrderShippingAddress) {
      this.CardOrder = {} as CardOrder;
      this.CardOrder.ShippingAddress = { ...this.storage.Order.CardOrder.ShippingAddress };
    } else if (isOrderShippingAddress) {
      this.storage.Order.CardOrder.ShippingAddress = { ...this.storage.Order.ShippingAddress };
    } else {
      this.storage.Order.CardOrder.ShippingAddress = { ...this.storage.Order.CustomerAddress };
    }

    this.CardOrder = this.storage.Order.CardOrder;
    this.Order = this.storage.Order;
    this.dictionaryService.getCompanyTitles().then((items: Dictionary[]) => {
      this.companyTitles = items.map((x) => x.title);
    });
    window.scrollTo(0, 0);
  }

  capitalize(value: string) {
    return value[0].toUpperCase() + value.slice(1);
  }

  async next(): Promise<void> {
    this.isSubmit = true;

    if (this.cardOrderForm.invalid) {
      for (const [key, control] of Object.entries(this.cardOrderForm.controls)) {
        if (control.invalid) {
          document.getElementById(key).focus();
          return;
        }
      }
      return;
    }

    // validate customer form
    if (!this.CardOrder.isCustomerAddressSame && this.customerForm.userForm.invalid) {
      for (const [key, control] of Object.entries(this.customerForm.userForm.controls)) {
        if (control.invalid) {
          document.getElementById(`customer${this.capitalize(key)}`).focus();
          return;
        }
      }
      return;
    }
    for (const [key, control] of Object.entries(this.customerForm.userForm.controls)) {
      this.CardOrder.CustomerAddress[key] = key === 'postalCode' ? control.value.postalCode : control.value;
    }

    this.CardOrder.cardSenderFirstName = this.customerForm.userForm.controls.firstName.value;
    this.CardOrder.cardSenderFirstNameKana = this.customerForm.userForm.controls.firstNameKana.value;
    this.CardOrder.cardSenderLastName = this.CardOrder.useMaidenName
      ? this.customerForm.userForm.controls.maidenName.value
      : this.customerForm.userForm.controls.lastName.value;
    this.CardOrder.cardSenderLastNameKana = this.CardOrder.useMaidenName
      ? this.customerForm.userForm.controls.maidenNameKana.value
      : this.customerForm.userForm.controls.lastNameKana.value;
    this.CardOrder.cardSenderPosition = this.customerForm.userForm.controls.position.value;
    this.CardOrder.cardSenderCompany = this.customerForm.userForm.controls.company.value;

    // validate shipping form
    if (!this.CardOrder.IsDestinationSame && this.shippingForm.userForm.invalid) {
      for (const [key, control] of Object.entries(this.shippingForm.userForm.controls)) {
        if (control.invalid) {
          document.getElementById(`shipping${this.capitalize(key)}`).focus();
          return;
        }
      }
      return;
    }
    for (const [key, control] of Object.entries(this.shippingForm.userForm.controls)) {
      this.CardOrder.ShippingAddress[key] = key === 'postalCode' ? control.value.postalCode : control.value;
    }

    const order = this.storage.Order;
    order.CardOrder = this.CardOrder;

    this.storage.Order = order;

    await this.route.navigate([this.storage.Funeral.funeralId, 'order', 'cards', 'order-details']);
  }

  back(): void {
    this.location.back();
  }

  handleIsCustomerAddressSame(event) {
    if (!event) {
      this.CardOrder.useMaidenName = false;
      this.customerForm.useMaidenName = false;
    }
  }

  handleUseMaidenNameChange(useMaidenName: boolean) {
    if (this.Order.BillingAddress.maidenName) {
      this.customerForm.useMaidenName = useMaidenName;
    }
  }
}
