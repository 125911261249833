import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-memorial-share-photo-album',
  templateUrl: './memorial-share-photo-album.component.html',
  styleUrls: ['./memorial-share-photo-album.component.scss'],
})
export class MemorialSharePhotoAlbumComponent implements OnInit {
  @Input('album') memorialShareAlbum;
  totalPages: number = 0;
  currentPage: number = 1;
  currentItemIndex: number = 0;
  imagesPerPage: number = 4;
  mobileView: boolean = false;
  currentPagesMobile;
  constructor(private modalService: NgbModal) {}

  ngOnInit(): void {
    this.checkView();
    window.addEventListener('resize', this.checkView);
  }

  checkView(): void {
    if (this.memorialShareAlbum.length > 0) {
      if (window.innerWidth > 768) {
        this.mobileView = false;
        this.totalPages = Math.ceil(this.memorialShareAlbum.length / this.imagesPerPage);
      } else {
        this.mobileView = true;
        this.totalPages = 1;
      }
    }
  }

  nextPage(): void {
    this.currentPage = this.currentPage + 1;
  }

  previousPage(): void {
    this.currentPage = this.currentPage - 1;
  }

  get currentPages() {
    let start: number;
    let end: number;
    if (!this.mobileView) {
      start = this.currentPage * this.imagesPerPage - this.imagesPerPage;
      end = this.currentPage * this.imagesPerPage;
    } else {
      start = 0;
      end = this.memorialShareAlbum.length;
    }
    return this.memorialShareAlbum.slice(start, end);
  }

  get showNextPageButton() {
    return this.currentPage < this.totalPages ? 'visible' : 'hidden';
  }

  get showPreviousPageButton() {
    return this.currentPage > 1 ? 'visible' : 'hidden';
  }

  openModal(template: TemplateRef<any>, item) {
    this.currentItemIndex = this.memorialShareAlbum?.indexOf(item);
    this.modalService.open(template, { centered: true, size: 'xl' });
    this.currentPagesMobile = this.memorialShareAlbum?.slice(this.currentItemIndex);
  }

  nextImage(): void {
    this.currentItemIndex = this.currentItemIndex + 1;
  }

  previousImage(): void {
    this.currentItemIndex = this.currentItemIndex - 1;
  }

  get showNextImageButton() {
    return this.currentItemIndex < this.memorialShareAlbum.length - 1;
  }

  get showPreviousImageButton() {
    return this.currentItemIndex > 0;
  }
}
