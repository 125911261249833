import { Component, Input, OnInit } from '@angular/core';
import { Bundle, BundleCartService, BundleType } from '../../../services/bundle-cart.service';
import { ScreenType, StepType } from '../../../../types';
import Swal from 'sweetalert2';
import { GlobalStorage } from '../../../global/storage';
import { ReceptionCloseService } from '../../../../services';
import { Funeral, Order } from '../../../../models';

export enum SummarySection {
  Card = 'card',
  Flower = 'flower',
  GiftMoney = 'gift-money',
}

@Component({
  selector: 'app-cart-summary',
  templateUrl: './cart-summary.component.html',
  styleUrls: ['./cart-summary.component.scss'],
})
export class CartSummaryComponent implements OnInit {
  @Input() public section: SummarySection;
  public displaySections: SummarySection[];
  public SummarySection = SummarySection;
  public BundleType = BundleType;
  public ScreenType = ScreenType;

  public Order: Order;
  funeral: Funeral;
  public isFlowerStepClosed = false;
  public isCardStepClosed = false;
  public isMoneyGiftStepClosed = false;
  public totals: { total: number; tax: number; totalBeforeDiscount?: number; discount?: number };

  constructor(
    private storage: GlobalStorage,
    private receptionCloseService: ReceptionCloseService,
    protected bundleCartService: BundleCartService
  ) {}

  ngOnInit(): void {
    this.bundleCartService.totalsChanges.subscribe(this.updateTotals.bind(this));
    this.updateTotals(this.bundleCartService.getTotals());

    if (typeof this.section === 'undefined') {
      this.displaySections = [SummarySection.Flower, SummarySection.Card, SummarySection.GiftMoney];
    } else {
      this.displaySections = [this.section];
    }

    this.Order = this.storage.Order;

    this.funeral = this.storage.Funeral;
    this.isFlowerStepClosed = this.receptionCloseService.getReceptionStatus(
      this.funeral,
      StepType.Flower
    ).isFuneralClosed;
    this.isCardStepClosed = this.receptionCloseService.getReceptionStatus(this.funeral, StepType.Card).isFuneralClosed;
    this.isMoneyGiftStepClosed = this.receptionCloseService.getReceptionStatus(
      this.funeral,
      StepType.MoneyGift
    ).isFuneralClosed;
  }

  public addBundle(type: SummarySection) {
    switch (type) {
      case SummarySection.Card:
        return this.bundleCartService.goToAdd(BundleType.Card);
      case SummarySection.Flower:
        return this.bundleCartService.goToAdd(BundleType.Flower);
      case SummarySection.GiftMoney:
        return this.bundleCartService.goToAdd(BundleType.GiftMoney);
    }
  }

  public editBundle(bundle: Bundle, step) {
    return this.bundleCartService.goToEdit(bundle, step);
  }

  public async removeBundle(bundle: Bundle) {
    let title;
    switch (bundle.type) {
      case BundleType.Card:
      case BundleType.CondolenceVideo:
        title = '弔電';
        break;
      case BundleType.Flower:
        title = '供花';
        break;
      case BundleType.GiftMoney:
        title = '香典';
        break;
    }

    const result = await Swal.fire({
      icon: 'warning',
      title: title + 'お申込みの取消',
      text: title + 'のご注文を取り消しても宜しいですか？',
      showCancelButton: true,
      confirmButtonText: 'OK',
      cancelButtonText: 'キャンセル',
    });

    if (result.value) {
      this.bundleCartService.removeBundle(bundle);
    }
  }

  public getBundleTypes(type: SummarySection): BundleType[] {
    switch (type) {
      case SummarySection.Card:
        return [BundleType.Card, BundleType.CondolenceVideo];
      case SummarySection.Flower:
        return [BundleType.Flower];
      case SummarySection.GiftMoney:
        return [BundleType.GiftMoney];
    }
  }

  public getBundles(type: SummarySection) {
    const types = this.getBundleTypes(type);
    return this.bundleCartService.getBundlesOfType(...types);
  }

  private updateTotals(totals) {
    if (typeof this.section !== 'undefined') {
      const bundleTypes = this.getBundleTypes(this.section);
      this.totals = this.bundleCartService.calculateTotals(bundleTypes);
    } else {
      this.totals = totals;
    }
  }

  get displayMoneyGiftDisclaimer() {
    const cartIncludesMoney = this.bundleCartService.getBundlesOfType(BundleType.GiftMoney).length > 0;
    const displaySectionsIncludesMoney = this.displaySections.includes(SummarySection.GiftMoney);
    return cartIncludesMoney && displaySectionsIncludesMoney;
  }
}
