import { Component } from '@angular/core';
import { Path } from 'src/app/core/utilities';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
})
export class NotFoundComponent {
  backgroundImage: string;

  constructor() {
    this.backgroundImage = Path.getPath('reception_over.jpg');
  }
}
