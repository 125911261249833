export const DefaultLabels = {};

export const Labels = {
  customer: {
    none: {
      name: 'お名前（フリガナ）',
    },
    card: {
      // required: '差出人　または、連盟、一同　必須',
      name: '差出人　氏名（フリガナ）',
      company: '差出人 会社・団体名',
      position: '差出人 役職',
    },
    condolenceVideo: {
      name: '差出人　氏名（フリガナ）',
      company: '差出人 会社・団体名',
      position: '差出人 役職',
    },
    moneyGift: {
      // required: 'ご芳名　または、連盟、一同　必須',
      name: 'ご芳名（フリガナ）',
      company: '会社・団体名',
    },
  },
  shipping: {
    none: {
      name: '宛名',
    },
    flower: {
      name: '宛名',
    },
    card: {
      name: '宛名',
    },
    moneyGift: {
      name: '宛名',
      company: '建物名・部屋番号・会社名',
    },
  },
  billing: {},
};
