<div class="condolence-video-order-container">
  <div class="sections-container flex-row">
    <div class="order-left-section flex-column">
      <div class="name-container flex-column">
        <!-- deceasedName -->
        <span class="deceasedName">
          {{ 'deceasedNamePrefix' | i18n }}　{{ Funeral?.deceasedName }}　{{ 'nameSuffix' | i18n }}
        </span>
        <!-- mourningName -->
        <span class="mourningName">
          {{ 'mourning' | i18n }}　{{ Funeral?.mourningName }}　{{ 'nameSuffix' | i18n }}
        </span>
      </div>
      <img class="order-image" [src]="CondolenceVideoOrder?.Thumbnail" alt="condolence-video" />
      <span class="condolence-video">{{ 'condolenceVideo' | i18n }}</span>
      <span class="price" *ngIf="isSmacereClubMember | async; else noDiscount"
        >{{ discountedPrice | priceWithTax }}<small>（{{ 'taxIncluded' | i18n }}）</small></span
      >
      <ng-template #noDiscount>
        <span class="price"
          >{{ CondolenceVideoOrder?.Product?.price | priceWithTax }}<small>（{{ 'taxIncluded' | i18n }}）</small></span
        >
      </ng-template>
    </div>
    <div class="order-right-section flex-column">
      <div class="info-container flex-column">
        <!-- deceasedName -->
        <span class="deceasedName">
          {{ 'deceasedNamePrefix' | i18n }}　{{ Funeral?.deceasedName }}　{{ 'nameSuffix' | i18n }}
        </span>
        <!-- mourningName -->
        <span class="mourningName">
          {{ 'mourning' | i18n }}　{{ Funeral?.mourningName }}　{{ 'nameSuffix' | i18n }}
        </span>
        <!-- productNameOne -->
        <div class="details-item">
          <span class="label">{{ 'productNameOne' | i18n }}</span>
          <span class="value">{{ 'condolenceVideo' | i18n }}</span>
        </div>
        <!-- productPrice -->
        <div class="details-item">
          <span class="label">{{ 'price' | i18n }}</span>
          <span class="value">
            <span *ngIf="isSmacereClubMember | async; else noDiscount">{{ discountedPrice | priceWithTax }}</span>
            <ng-template #noDiscount>
              <span>{{ CondolenceVideoOrder?.Product?.price | priceWithTax }}</span>
            </ng-template>
            <span>（{{ 'taxIncluded' | i18n }}）</span>
          </span>
        </div>
      </div>
      <div class="info-2-container flex-column">
        <!-- destinationName -->
        <div class="details-item">
          <span class="label">{{ 'destinationName' | i18n }}</span>
          <span class="value"
            >{{ CondolenceVideoOrder?.DestinationLastName }} {{ CondolenceVideoOrder?.DestinationFirstName }}</span
          >
        </div>
        <!-- companyName -->
        <div class="details-item">
          <span class="label">{{ 'companyName' | i18n }}</span>
          <span class="value">{{ CondolenceVideoOrder?.SenderCompany }}</span>
        </div>
        <!-- senderPosition -->
        <div class="details-item">
          <span class="label">{{ 'senderPosition' | i18n }}</span>
          <span class="value">{{ CondolenceVideoOrder?.SenderPosition }}</span>
        </div>
        <!-- senderName -->
        <div class="details-item">
          <span class="label">{{ 'senderName' | i18n }}</span>
          <span class="value"
            >{{ CondolenceVideoOrder?.SenderLastName }} {{ CondolenceVideoOrder?.SenderFirstName }}</span
          >
        </div>
        <!-- customName -->
        <div class="details-item">
          <span class="label">{{ 'customName' | i18n }}</span>
          <span class="value">{{ CondolenceVideoOrder?.attributes.customName }}</span>
        </div>
      </div>
      <!-- comment -->
      <div class="details-item comment-container">
        <span class="label">
          {{ 'comment' | i18n }}
        </span>
        <span class="value">
          {{ CondolenceVideoOrder?.Comment }}
        </span>
      </div>
    </div>
  </div>

  <!-- buttons -->
  <div class="buttons-container">
    <button id="return-button" class="nav-button return-button" (click)="back()">
      {{ 'return' | i18n }}
    </button>
    <button id="add-button" class="nav-button add-button" (click)="addAction()" *ngIf="!hasPresents && !isEdit">
      {{ 'addNewOrder' | i18n }}
    </button>
    <button id="next-button" class="nav-button next-button" (click)="nextAction()">
      {{ 'decideAndNext' | i18n }}
    </button>
  </div>

  <!-- <div class="order-footer flex-row">
    <button id="return-button" class="return-button-outline-rect" (click)="back()">
      {{ 'return' | i18n }}
    </button>
    <button id="add-button" class="btn btn-secondary" (click)="addAction()" *ngIf="!hasPresents && !isEdit">
      {{ 'addNewOrder' | i18n }}
    </button>
    <button id="decide-button" class="next-button-rect" (click)="nextAction()">
      {{ 'decideAndNext' | i18n }}
    </button>
  </div> -->
</div>
