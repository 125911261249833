<div class="card-main-page">
  <!-- first section -->
  <div class="condolence-container">
    <div class="condolence-header">
      <h3>{{ 'condolence' | i18n }}</h3>
    </div>
    <div class="condolence-wrapper">
      <div class="condolence-wrapper-header">
        <span class="big-note">{{ 'thereAreTwoTypesOfCondolenceMessage' | i18n }}</span>
        <span class="small-note">{{ 'byUsingCondolenceVideoMessage' | i18n }}</span>
      </div>
      <div class="condolence-contents">
        <!-- card -->
        <div class="select-card condolence-item" *ngIf="displayCard">
          <span class="condolence-item-title">{{ 'condolenceCard' | i18n }}</span>
          <div class="card-item" (click)="goToCardSelection()"></div>
          <button class="condolence-item-button" (click)="goToCardSelection()">
            {{ 'condolenceCardButton' | i18n }}
          </button>
        </div>
        <!-- condolence video -->
        <div *ngIf="displayCondolenceVideos" class="select-condolence-video condolence-item">
          <span class="condolence-item-title">{{ 'condolenceVideo' | i18n }}</span>
          <div class="condolence-video-item" (click)="goToCondolenceVideo()">
            <img class="play" src="assets/svg/play.svg" width="75" height="75" alt="" />
          </div>
          <button class="condolence-item-button" (click)="goToCondolenceVideo()">
            {{ 'condolenceVideoButton' | i18n }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- footer -->
  <div class="memorial-share-footer">
    <button class="return-button-outline-rect return-button" (click)="back()">{{ 'return' | i18n }}</button>
  </div>
</div>
