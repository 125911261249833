import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalStorage } from 'src/app/global/storage';

import { FooterHPIComponent } from './footer-hpi/footer-hpi.component';
import { FooterSCTComponent } from './footer-sct/footer-sct.component';
import { FooterTOSComponent } from './footer-tos/footer-tos.component';
import { FooterCOMComponent } from './footer-com/footer-com.component';

import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  environment = environment;
  isTenantCeremony = environment.tenant === 'ceremony';
  constructor(private modalService: NgbModal, private storage: GlobalStorage) {}

  ngOnInit(): void {}

  // 個人情報の取り扱い
  public OpenHPlModal() {
    this.modalService.open(FooterHPIComponent, { windowClass: 'common-modal' });
  }

  // 特定商取引法
  public OpenSCTModal() {
    this.modalService.open(FooterSCTComponent, { windowClass: 'common-modal' });
  }

  // 利用規約
  public OpenTOSModal() {
    this.modalService.open(FooterTOSComponent, { windowClass: 'common-modal' });
  }

  // Compatibility
  public OpenCOMModal() {
    this.modalService.open(FooterCOMComponent, { windowClass: 'common-modal' });
  }
}
