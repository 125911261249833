import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { trigger, transition, style, animate } from '@angular/animations';
import Swal from 'sweetalert2';
import { GlobalStorage } from 'src/app/global/storage';
import { Order, Funeral, Flower } from 'src/models';
import { FlowerTypes } from 'src/types';
import { AuthService } from 'src/app/services/auth.service';
import { BehaviorSubject } from 'rxjs';
import { ProductType } from '@kokusai/smacere-shared/api';
import { BundleCartService, BundleType } from '../../../../services/bundle-cart.service';
import { I18n } from 'aws-amplify';

@Component({
  selector: 'app-flowers-select',
  templateUrl: './flowers-select.component.html',
  styleUrls: ['./flowers-select.component.scss'],
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [style({ opacity: 0 }), animate('1s ease-out', style({ opacity: 1 }))]),
      transition(':leave', [style({ opacity: 1 }), animate('1s ease-in', style({ opacity: 0 }))]),
    ]),
  ],
})
export class FlowersSelectComponent implements OnInit {
  private Order: Order = null;
  Funeral: Funeral = null;
  flowers: Flower[] = [];
  private styleType: string;
  selectedFlower?: Flower = null;
  isSmacereClubMember: BehaviorSubject<boolean>;

  ProductType = ProductType;

  constructor(
    private storage: GlobalStorage,
    private route: Router,
    private location: Location,
    private authService: AuthService,
    private bundleCartService: BundleCartService
  ) {}

  ngOnInit(): void {
    this.bundleCartService.initStep(BundleType.Flower, 'order');

    this.Funeral = this.storage.Funeral;
    this.Order = this.storage.Order;
    if (this.Funeral.FlowerType) {
      this.StyleType = this.Funeral.FlowerType === FlowerTypes.Both ? FlowerTypes.Japanese : this.Funeral.FlowerType;
    } else {
      this.StyleType = this.Order.FlowerOrder.Product.flowerStyle;
    }

    if (this.Order && this.Order.FlowerOrder.Product.id !== '') {
      this.selectedFlower = this.Order.FlowerOrder.Product;
    }

    this.isSmacereClubMember = this.authService.isSmacereClubMember;
  }

  //#region flowerStyle
  get StyleType() {
    return this.styleType;
  }
  set StyleType(value: string) {
    this.styleType = value;
    this.GetFlowers(this.styleType);
  }

  //#endregion

  //#region selectedFlower

  //#endregion

  //#region

  get IsSelectTypeVisible(): boolean {
    return this.Funeral.FlowerType === FlowerTypes.Both;
  }

  //#endregion

  private GetFlowers(styleType: string) {
    const selectionFlowers: Flower[] = this.Funeral.flowerSelections;
    this.flowers = selectionFlowers.filter((flower: Flower) => flower.flowerStyle === styleType);
  }

  onSelectionChange(flower: Flower) {
    this.selectedFlower = flower;
  }

  next(): void {
    if (this.selectedFlower) {
      if (this.Order.FlowerOrder.Product.id !== this.selectedFlower.id) {
        this.Order.FlowerPresent = undefined;
      }

      this.Order.FlowerOrder.Product = this.selectedFlower;
      this.Order.FlowerOrder.quantity = 1;
      this.storage.Order = this.Order;
      this.route.navigate([this.Funeral.funeralId, 'order', 'flowers', 'order']);
    } else if (!this.selectedFlower) {
      Swal.fire({
        icon: 'warning',
        title: '供花が選択されていません',
        text: '供花商品を１つお選びください',
      });
    } else {
    }
  }

  back(): void {
    this.location.back();
  }

  get returnButtonLabel(): string {
    return this.bundleCartService.isEdit() ? I18n.get('goToApplicationConfirmation') : I18n.get('return');
  }
}
