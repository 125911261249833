import { Product } from 'src/models/Product';
import { environment } from 'src/environments/environment';
export class BaseProductOrder<T extends Product> {
  constructor() {
    this.quantity = 1;
  }

  public Product: T;
  public quantity: number;
  public attributes?: any;

  public get amount() {
    return this.Product.price * this.quantity;
  }

  public get taxAmount() {
    return this.amount * (environment.taxRate / 100);
  }

  public get SumWithTax() {
    return this.amount + this.taxAmount;
  }
}
