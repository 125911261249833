<ngb-carousel>
  <ng-template ngbSlide *ngFor="let slide of Slides">
    <div class="slide-body">
      <img
        vtMedia
        [object]="slide"
        alt=""
        draggable="false"
        (contextmenu)="(false)"
        class="img-fluid rounded mx-auto d-block"
      />
    </div>
  </ng-template>
</ngb-carousel>
