import { Pipe, PipeTransform } from '@angular/core';
import { Path } from '../core/utilities';

@Pipe({
  name: 'path',
  pure: true,
})
export class PathPipe implements PipeTransform {
  transform(fileName: string): string {
    return Path.getPath(fileName);
  }
}
