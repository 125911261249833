import { Product } from './Product';
import { FlowerTypes } from 'src/types';
export class Flower extends Product {
  constructor(params: Flower = {} as Flower) {
    super(params);
    let { flowerStyle = FlowerTypes.Japanese, flowerHeight = 0, flowerWidth = 0, description = '' } = params; //Default value;

    this.flowerStyle = flowerStyle;
    this.flowerHeight = flowerHeight;
    this.flowerWidth = flowerWidth;
    this.description = description;
  }

  flowerStyle: string;
  flowerHeight?: number;
  flowerWidth?: number;
  description: string;
}
