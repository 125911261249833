<div class="thankyou-wrapper">
  <div class="logo">
    <a [routerLink]="['/', this.funeralid]">
      <img src="{{ 'logo.png' | path }}" class="logo-md img-fluid" />
      <img src="{{ 'logo_mobile.png' | path }}" class="logo-sm img-fluid md-hi" />
    </a>
  </div>
  <div class="tanks-images" [hidden]="!isThankYouVideoEmpty"></div>
  <div class="thankyou-video-wrapper" [hidden]="isThankYouVideoEmpty">
    <div class="thankyou-video">
      <video
        id="video"
        #video
        autoplay="false"
        preload="auto"
        playsinline
        (ended)="OnThankYouVideoEnd()"
        vtMedia
        [object]="thankYouVideo"
      ></video>
    </div>
  </div>

  <!-- <button
    id="play-video-button"
    class="btn btn-light play"
    (click)="PlayVideo()"
    *ngIf="displayPlayButton && !isThankYouVideoEmpty"
  ></button> -->
  <button id="thankyou-next-button" class="btn btn-light skip" *ngIf="displayMediaButton" (click)="Skip()">
    {{ 'next' | i18n }}
  </button>
</div>
<app-footer class="footer-fix"></app-footer>
