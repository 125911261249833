import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { GlobalStorage } from 'src/app/global/storage';
import { MemorialType } from '@kokusai/smacere-shared/api';

@Component({
  selector: 'app-memorial',
  templateUrl: './memorial.component.html',
  styleUrls: ['./memorial.component.scss'],
})
export class MemorialComponent implements OnInit {
  public displayMemorial: any;
  public MemorialType = MemorialType;

  constructor(private globalStorage: GlobalStorage, private router: Router, private route: ActivatedRoute) {
    this.displayMemorial = this.globalStorage.Funeral.displayMemorial;
  }

  ngOnInit(): void {}

  goBack(): void {
    this.router.navigate(['../'], { relativeTo: this.route });
  }
}
