import { Component, OnInit } from '@angular/core';
import { Funeral } from 'src/models';
import { GlobalStorage } from 'src/app/global/storage';
import { environment } from 'src/environments/environment';
import { DisplayElementsService } from 'src/app/services/display-elements.service';

@Component({
  selector: 'app-media-header',
  templateUrl: './media-header.component.html',
  styleUrls: ['./media-header.component.scss'],
})
export class MediaHeaderComponent implements OnInit {
  Funeral: Funeral = {} as Funeral;
  width: string = '0';
  environment = environment;

  constructor(private storage: GlobalStorage, private displayElementsService: DisplayElementsService) {
    this.Funeral = this.storage.Funeral;
  }

  ngOnInit(): void {}

  openSideMenu() {
    this.width = '100';
  }

  closeSideMenu() {
    this.width = '0';
  }

  get displayCondolenceVideos() {
    return this.displayElementsService.displayCondolenceVideos;
  }

  get displayMemorialShare() {
    return this.displayElementsService.displayMemorialShare;
  }
}
