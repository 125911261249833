import { Component, OnInit } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';
@Component({
  selector: 'app-memorial-share',
  templateUrl: './memorial-share.component.html',
  styleUrls: ['./memorial-share.component.scss'],
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [style({ opacity: 0 }), animate('1s ease-out', style({ opacity: 1 }))]),
      transition(':leave', [style({ opacity: 1 }), animate('1s ease-in', style({ opacity: 0 }))]),
    ]),
  ],
})
export class MemorialShareComponent implements OnInit {
  constructor() {}

  public ngOnInit(): void {}
}
