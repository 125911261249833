import { Component } from '@angular/core';
import { Path } from 'src/app/core/utilities';

@Component({
  selector: 'app-close',
  templateUrl: './funeral-closed.component.html',
  styleUrls: ['./funeral-closed.component.scss'],
})
export class FuneralClosedComponent {
  backgroundImage: string;

  constructor() {
    this.backgroundImage = Path.getPath('reception_over.jpg');
  }
}
