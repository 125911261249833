import { Component, NgZone, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { animate, style, transition, trigger } from '@angular/animations';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { environment } from 'src/environments/environment';
import { GlobalStorage } from 'src/app/global/storage';
import { AddressService, PaymentService } from 'src/services';
import { Address, IPaymentMethod, Order, OrderSteps, Payment } from 'src/models';
import { PaypalOrder } from 'src/models/paypal';
import { PaymentMethodType, StepType } from 'src/types';
import { FooterHPIComponent } from 'src/app/shared/footer/footer-hpi/footer-hpi.component';
import {
  AddressType,
  APIService,
  CreateEventLogInput,
  EventSourceType,
  LambdaOrderAddressInput,
  LookupType,
  PaymentType,
} from '@kokusai/smacere-shared/api';
import { BundleCartService } from '../../../../services/bundle-cart.service';
import { I18n } from '@aws-amplify/core';

@Component({
  selector: 'app-checkout-home',
  templateUrl: './checkout-home.component.html',
  styleUrls: ['./checkout-home.component.scss'],
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [style({ opacity: 0 }), animate('1s ease-out', style({ opacity: 1 }))]),
      transition(':leave', [style({ opacity: 1 }), animate('1s ease-in', style({ opacity: 0 }))]),
    ]),
  ],
})
export class CheckoutHomeComponent implements OnInit {
  Order: Order = null;
  Years: number[] = [];
  Relationships: string[] = [];
  displayPaymentInput: boolean = true;
  displayBackButton: boolean = false;
  isDisclaimerConfirmed: boolean = false;
  displayProcessing: boolean = false;
  PaypalOrder: PaypalOrder = null;
  PaymentMethodType = PaymentMethodType;
  PaymentMethod: PaymentMethodType = environment.payment.paymentDefault;
  PaymentMethods: IPaymentMethod[] = [];
  orderPromise = null;
  funeralId: string = '';
  addressType = AddressType;
  selectedMainPaymentMethod: string = 'credit-card';
  selectedSubPaymentMethod: string = 'master-visa-card';
  isSubmit: boolean = false;

  constructor(
    private storage: GlobalStorage,
    private addressService: AddressService,
    private paymentService: PaymentService,
    private zone: NgZone,
    private modalService: NgbModal,
    private router: Router,
    private apiService: APIService,
    private bundleCartService: BundleCartService
  ) {
    this.Order = this.storage.Order;
    this.funeralId = this.storage.Funeral.funeralId;

    if (!this.Order.BillingAddress) {
      this.Order.BillingAddress = new Address();
    }
    if (!this.Order.ShippingAddress) {
      this.Order.ShippingAddress = new Address();
    }

    this.PaymentMethods = [];
  }

  Prefectures: string[] = [];

  async ngOnInit(): Promise<void> {
    await this.bundleCartService.emptyCartGuard();

    this.addressService.GetPrefectures().subscribe((prefectures) => {
      this.Prefectures = prefectures;
    });

    this.apiService.LookupByType(LookupType.Relationship).then((result) => {
      this.Relationships = result.items.map((item) => item.message);
    });

    window.scrollTo(0, 0);

    environment.payment.paymentMethods.forEach((item) => {
      if (item.isActive) {
        this.PaymentMethods.push({
          name: item.title,
          value: PaymentMethodType.Paypal,
        });
      }
    });
  }

  private logEvent(
    source: string,
    sourceType: EventSourceType,
    eventType: string,
    details: any,
    resourceId?: string,
    funeralId?: string,
    orderId?: string
  ) {
    const eventLog: CreateEventLogInput = {
      tenant: environment.tenant,
      source,
      sourceType,
      eventType,
      details: JSON.stringify(details),
    };
    if (typeof resourceId !== 'undefined') {
      eventLog.resourceId = resourceId;
    }
    if (typeof funeralId !== 'undefined') {
      eventLog.funeralId = funeralId;
    }
    if (typeof orderId !== 'undefined') {
      eventLog.orderId = orderId;
    }

    // noinspection JSIgnoredPromiseFromCall
    this.apiService.CreateEventLog(eventLog).catch((e) => {
      console.error(e);
    });
  }

  // noinspection JSMethodCanBeStatic
  private getOrderAddress(address: Address) {
    return {
      country: 'JP',
      postalCode: address.postalCode,
      prefecture: address.prefecture,
      city: address.city,
      street: address.street,
      building: address.building,
      company: address.company,
      firstName: address.firstName,
      lastName: address.lastName,
      firstNameKana: address.firstNameKana,
      lastNameKana: address.lastNameKana,
      maidenName: address.maidenName,
      maidenNameKana: address.maidenNameKana,
      telephone: address.telephone,
      email: address.email,
    } as LambdaOrderAddressInput;
  }

  OnPayPalCreateOrder = async () => {
    this.logEvent('Checkout', EventSourceType.Client, 'PaymentInit', {}, this.Order.CartId, this.funeralId);

    try {
      if (this.orderPromise === null) {
        this.orderPromise = this.CreateOrder();
      }
      return await this.orderPromise;
    } finally {
      this.orderPromise = null;
    }
    // tslint:disable-next-line:semicolon
  };

  checkCallResponse(response: { statusCode: number }, allowedStatuses: number[] = [200]) {
    if (!allowedStatuses.includes(response.statusCode)) {
      throw new Error('Invalid response from API with status code ' + response.statusCode);
    }
  }

  async CreateOrder() {
    const billingAddress = this.getOrderAddress(this.Order.BillingAddress);
    const shippingAddress = this.getShipping(this.Order.IsDestinationSame);

    if (this.Order.Id == null) {
      const result = await this.apiService.PlaceOrder(
        this.Order.CartId,
        billingAddress,
        shippingAddress,
        this.Order.BillingAddress.email,
        PaymentType.paypal,
        this.Order.CustomerAddress.relationship,
        this.Order.birthday
      );
      this.checkCallResponse(result, [200, 402]);
      this.Order.Id = result.orderId;
    }

    const initPaymentResponse = await this.apiService.InitPayment(this.Order.Id, billingAddress, shippingAddress);
    this.checkCallResponse(initPaymentResponse);
    return JSON.parse(initPaymentResponse.response);
  }

  // TODO: need refactoring
  OnPayPalApproved = async (data: any) => {
    this.logEvent(
      'Checkout',
      EventSourceType.Client,
      'PaymentApproved',
      data,
      this.Order.CartId,
      this.funeralId,
      this.Order.Id
    );

    this.displayProcessing = true;
    this.apiService
      .CapturePayment(this.Order.Id, data.orderID)
      .then(this.OrderSuccessful)
      .catch((err: any) => {
        Swal.fire({
          icon: 'error',
          title: '決済エラー',
          text: '決済処理に失敗しました。カード情報および限度額を再度ご確認ください。',
        }).then(() => this.Back());

        console.error(err);
      })
      .finally(() => {
        this.displayProcessing = false;
      });
    // tslint:disable-next-line:semicolon
  };

  OnPayPalCancel = () => {
    this.logEvent(
      'Checkout',
      EventSourceType.Client,
      'PaymentCanceled',
      {},
      this.Order.CartId,
      this.funeralId,
      this.Order.Id
    );

    this.displayProcessing = false;
    Swal.fire({
      icon: 'warning',
      title: 'お支払いキャンセル',
      text: 'お支払いを完了できませんでした',
    }).then(() => this.Back());
    // tslint:disable-next-line:semicolon
  };

  OnPayPalError = (e: any) => {
    this.logEvent(
      'Checkout',
      EventSourceType.Client,
      'PaymentError',
      e,
      this.Order.CartId,
      this.funeralId,
      this.Order.Id
    );

    const err: string = e.toString().replace('Error: ', ''); // Fix what return PayPal, he return error how broken object
    let message: string;
    let isGoToConfirmPage: boolean = false;
    this.displayProcessing = false;

    switch (err) {
      case '400':
        message = 'Invalid cartId or cart contains invalid products';
        isGoToConfirmPage = true;
        break;
      case '402':
        message = `Order has been already created for this cartId but the payment haven't been captured yet`;
        isGoToConfirmPage = true;
        break;
      case '404':
        message = 'Cart with given cartId not found';
        isGoToConfirmPage = true;
        break;
      case '410':
        message = 'Invalid cartId or cart contains invalid products';
        isGoToConfirmPage = true;
        break;
      case '500':
        message = 'All other unexpected errors';
        isGoToConfirmPage = true;
        break;
      default:
        message = 'PayPal error';
        break;
    }

    Swal.fire({
      icon: 'error',
      title: 'Create order error',
      text: message,
    }).then(() => {
      if (isGoToConfirmPage) {
        this.router.navigate([this.funeralId, 'order', 'confirm']);
        return;
      }
    });
    // tslint:disable-next-line:semicolon
  };

  OrderSuccessful = () => {
    this.displayProcessing = false;

    const payment = new Payment();
    payment.cartId = this.Order.CartId;
    payment.funeralId = this.funeralId;
    payment.email = this.Order.BillingAddress.email;
    payment.orderId = this.Order.Id;
    payment.type = PaymentMethodType.Paypal;

    // TODO: No longer needed to save payment here because it's saved server side
    // TODO: Since this happens after capture was successful (MONEY TAKEN OUT FROM CLIENT)
    // We must retry this at least 3 times until we successfully save the data in the DB
    this.paymentService.SaveComplete(payment).subscribe((result) => {
      Swal.fire({
        icon: 'success',
        title: I18n.get('yourOrderHasBeenCompleted'),
        text: I18n.get('weHaveSentConfirmationEmail'),
      }).then(() => {
        this.Order = null;
        this.Order = new Order();
        this.Order.IsComplete = true;
        this.storage.OrderSteps = new OrderSteps();
        this.storage.Order = this.Order;
        this.storage.Payment = result;
        this.router.navigate([this.storage.Funeral.funeralId, 'thankyou']);
      });
    });
    // tslint:disable-next-line
  };

  private getShipping(isDestinationSame: boolean) {
    if (isDestinationSame) {
      return this.getOrderAddress(this.Order.BillingAddress);
    } else {
      return this.getOrderAddress(this.Order.ShippingAddress);
    }
  }

  OnInformationClick(form: NgForm) {
    this.isSubmit = true;
    if (form.invalid) {
      for (const [key, control] of Object.entries(form.controls)) {
        if (control.invalid) {
          document.getElementById(key)?.focus();
          return;
        }
      }
      return;
    } else {
      if (!this.isDisclaimerConfirmed) {
        Swal.fire({
          icon: 'error',
          title: '個人情報の取り扱いに同意してください',
        });
        return;
      }
    }

    window.scroll(0, 0);
    this.displayPaymentInput = false;
    this.displayBackButton = true;
    this.storage.Order = this.Order;
  }

  BackToOrderDetails() {
    this.router.navigate([this.storage.Funeral.funeralId, 'order', 'confirm']);
  }

  Back() {
    window.scroll(0, 0);
    this.displayPaymentInput = true;
    this.displayBackButton = false;
  }

  // 個人情報の取り扱い
  OpenHPlModal() {
    this.modalService.open(FooterHPIComponent, { windowClass: 'common-modal' });
  }
}
