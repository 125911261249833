import { EventEmitter, Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { APIService, Discount, ModelSortDirection, ProductType } from '@kokusai/smacere-shared/api';
import { environment } from '../../environments/environment';
import moment from 'moment';
import { GlobalStorage } from '../global/storage';

@Injectable({
  providedIn: 'root',
})
export class DiscountsService {
  public readonly changes: EventEmitter<void> = new EventEmitter<void>();
  private discounts: Map<ProductType, number> = new Map<ProductType, number>();
  private availableDiscounts: Discount[] = [];

  constructor(private authService: AuthService, private apiService: APIService, storage: GlobalStorage) {
    this.authService.isLoggedIn.subscribe(() => this.updateDiscounts());

    // TODO: This should be funeralDate instead c627 1.2
    // const today = moment().format('YYYY-MM-DD');

    const funeralDate = storage.Funeral?.funeralDate;
    this.apiService
      .ListDiscountsByTenant(environment.tenant, undefined, {
        and: [
          {
            or: [
              { fromDate: { attributeExists: false } },
              { fromDate: { eq: null } },
              { fromDate: { le: funeralDate } },
            ],
          },
          { or: [{ toDate: { attributeExists: false } }, { toDate: { eq: null } }, { toDate: { ge: funeralDate } }] },
        ],
      })
      .then((result) => {
        this.availableDiscounts = result.items;
        this.updateDiscounts();
      });
  }

  private updateDiscounts() {
    this.discounts.clear();
    if (this.authService.isLoggedIn.value) {
      for (const discount of this.availableDiscounts) {
        this.discounts.set(discount.productType, discount.discountValue);
      }
    }
    this.changes.emit();
  }

  public getDiscountedPrice(price: number, type: ProductType): number {
    if (!this.discounts.has(type)) {
      return price;
    }

    return Math.floor((price / 100) * (100 - this.discounts.get(type)));
  }
}
