export class EventStep {
  constructor(stepIndex: number, params: any) {
    this._stepIndex = stepIndex;
    this._params = params;
  }

  //#region StepIndex

  private _stepIndex: number;

  public get StepIndex(): number {
    return this._stepIndex;
  }

  public set StepIndex(value: number) {
    this._stepIndex = value;
  }

  //#endregion

  //#region Params

  private _params: any;

  public get Params(): any {
    return this._params;
  }

  public set Params(value: any) {
    this._params = value;
  }

  //#endregion
}
