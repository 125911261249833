import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';
import { APIService } from '@kokusai/smacere-shared/api';
import { ProductStorageService } from './product-storage.service';

@Injectable()
export class BaseService {
  constructor(protected http: HttpClient, protected api: APIService, protected productStorage: ProductStorageService) {}

  protected httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  protected handleError<T>(title = 'Server Error', operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure

      Swal.fire({
        icon: 'error',
        title: title,
        text: operation,
      });

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
