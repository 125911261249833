import { Component } from '@angular/core';
import { GlobalStorage } from '../global/storage';
@Component({
  selector: 'FreeDial',
  styleUrls: ['./free-dial.component.scss'],
  template: `<p>
    お問い合わせは、{{ FuneralHallName }}フリーダイヤル{{ FuneralHallPhone ? FuneralHallPhone : '0120-41-1147' }}
  </p>`,
})
export class FreeDialComponent {
  public FuneralHallName: string;
  public FuneralHallPhone: string;
  public funeralHalls: Array<any>;

  constructor(private storage: GlobalStorage) {}
  ngOnInit(): void {
    this.FuneralHallName = this.storage.Funeral.FuneralHallName; // TODO: This and below should be Funeral.funeralLocation.FuneralHallName
    this.FuneralHallPhone = this.storage.Funeral.FuneralHallPhone.replace('(+81)', '')
      .replace('+81-', '0')
      .replace('+81', '0');
  }
}
