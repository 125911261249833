<section id="empty">
  <div class="col-12 col-md-8 col-lg-6 col-xl-6 p-0 m-auto">
    <div class="p-3 text-center">
      <img class="img-fluid img-logo" src="{{ 'logo.png' | path }}" alt="セレモニー" />
    </div>
    <div class="form-goup p-2">
      <input
        id="Code"
        name="Code"
        #code="ngModel"
        type="text"
        maxlength="40"
        [(ngModel)]="inputValue"
        class="form-control col-12 m-auto"
        placeholder="{{ inputPlaceholderText }}"
        [class]="{
          'is-invalid': code.invalid && (!code.untouched || isSubmit)
        }"
        required
      />
      <div class="validate-message text-danger" *ngIf="code.errors?.required && (!code.untouched || isSubmit)">
        {{ requiredValidationMsg | i18n }}
      </div>
    </div>
    <div class="p-3 text-center">
      <button id="payment-check-submit-button" class="btn btn-primary" [disabled]="code.invalid" (click)="check()">
        {{ 'goToMediaPage' | i18n }}
      </button>
      <button id="payment-check-return-button" class="btn btn-secondary ml-2" (click)="back()">
        {{ 'return' | i18n }}
      </button>
    </div>
  </div>
</section>
