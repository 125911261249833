import { Component } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';
import { GlobalStorage } from 'src/app/global/storage';
import { ReceptionCloseService } from 'src/services';
import { StepType } from 'src/types';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss'],
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [style({ opacity: 0 }), animate('1s ease-out', style({ opacity: 1 }))]),
      transition(':leave', [style({ opacity: 1 }), animate('1s ease-in', style({ opacity: 0 }))]),
    ]),
  ],
})
export class CheckoutComponent {
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private storage: GlobalStorage,
    private receptionCloseService: ReceptionCloseService
  ) {}

  public ngOnInit(): void {}

  public ngAfterViewChecked(): void {
    const receptionStatus = this.receptionCloseService.getReceptionStatus(this.storage.Funeral, StepType.Card);
    const { snapshot } = this.activatedRoute;

    if (snapshot.children[0].routeConfig.path.length && receptionStatus.isFuneralClosed) {
      this.router.navigate(['../cards'], { relativeTo: this.activatedRoute });
    }
  }
}
