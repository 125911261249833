import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import API from '@aws-amplify/api';

export type BusinessCardApiDataFormat = {
  address?: {
    street_number?: string;
    locality?: string;
    street_name?: string;
    postal_code?: string;
    country?: string;
    broad_region?: string;
    narrow_region?: string;
    sublocality?: string;
  };
  company?: string;
  email?: string;
  phoneNumber?: string;
  position?: string;
};

@Component({
  selector: 'app-card-upload-form',
  templateUrl: './card-upload-form.component.html',
  styleUrls: ['./card-upload-form.component.scss'],
})
export class CardUploadFormComponent implements OnInit {
  @Output()
  public data = new EventEmitter<BusinessCardApiDataFormat>();
  public loading = false;

  constructor() {}

  ngOnInit(): void {}

  public onChange(event) {
    this.loading = true;
    const reader = new FileReader();
    reader.onload = () => {
      API.post('BusinessCard', '/scan', {
        response: true,
        body: {
          file: (reader.result as string).split(';').pop().split(',').pop(),
        },
      }).then((result) => {
        this.loading = false;
        this.data.emit(result.data);
      });
    };

    const input = event.target as HTMLInputElement;
    const file = input.files.item(0);
    reader.readAsDataURL(file);
  }
}
