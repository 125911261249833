export * from './money-gift.component';
export * from './money-gift-home/money-gift-home.component';
export * from './money-gift-select/money-gift-selection.component';
export * from './money-gift-present/money-gift-present.component';
export * from './money-gift-food/money-gift-food.component';
export * from './money-gift-present-select/money-gift-present-selection.component';
export * from './money-gift-order/money-gift-order.component';

import { MoneyGiftComponent } from './money-gift.component';
import { MoneyGiftHomeComponent } from './money-gift-home/money-gift-home.component';
import { MoneyGiftSelectionComponent } from './money-gift-select/money-gift-selection.component';
import { MoneyGiftPresentComponent } from './money-gift-present/money-gift-present.component';
import { MoneyGiftFoodComponent } from './money-gift-food/money-gift-food.component';
import { MoneyGiftPresentSelectionComponent } from './money-gift-present-select/money-gift-present-selection.component';
import { MoneyGiftOrderComponent } from './money-gift-order/money-gift-order.component';
export const MoneyGiftModule = [
  MoneyGiftComponent,
  MoneyGiftHomeComponent,
  MoneyGiftSelectionComponent,
  MoneyGiftPresentComponent,
  MoneyGiftFoodComponent,
  MoneyGiftPresentSelectionComponent,
  MoneyGiftOrderComponent,
];
