import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { environment } from 'src/environments/environment';

declare var paypal;

@Component({
  selector: 'paypal-button',
  template: ` <div #paypal></div> `,
})
export class PaypalButtonComponent implements OnInit {
  @ViewChild('paypal', { static: true })
  public paypalElement: ElementRef;

  @Input()
  public OnCreateOrder: Function;

  @Input()
  public OnApproved: Function;

  @Input()
  public onCancel: Function;

  @Input()
  public onError: Function;

  @Input()
  public onClick: Function;

  public ngOnInit(): void {
    let button = paypal.Buttons({
      env: environment.payment.payPalType,
      fundingSource: paypal.FUNDING.CARD,
      locale: 'ja_JP',
      commit: true, // This will add the transaction amount to the PayPal button
      style: {
        shape: 'rect',
        color: 'white',
        layout: 'vertical',
        label: 'checkout',
        size: 'medium',
        height: 40,
      },
      createOrder: this.OnCreateOrder,
      onApprove: this.OnApproved,
      onCancel: this.onCancel,
      onError: this.onError,
      onClick: this.onClick,
    });
    if (button.isEligible()) {
      button.render(this.paypalElement.nativeElement);
    }
  }
}
