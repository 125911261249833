<img class="img-fluid mt-2 mb-3 w-100" src="{{ 'condolence_money.jpg' | path }}" alt="香典" style="margin: auto" />
<div class="container">
  <div class="row mt-mb-3 align-items-end">
    <div class="col-12 col-md-4 ml-auto">
      <h1>
        {{ 'incense' | i18n }}
      </h1>
    </div>
    <div class="col-12 col-4 col-md-7 text-center mr-auto font-mincho">
      <h4 class="close-time">
        {{ 'applicationDeadline' | i18n }}
        {{ funeralCloseDateTime | date: 'M月d日' }} {{ funeralCloseDateTime | japanTime }}{{ 'until' | i18n }}
      </h4>
    </div>
  </div>
  <div class="row align-items-center justify-content-center p-3 p-md-0 mt-md-4 mb-md-5">
    <button
      id="incense-reception-button"
      class="btn btn-wizard btn-secondary mr-md-5 col-12 col-md-4 mb-3"
      (click)="next()"
      [disabled]="isFuneralClosed"
    >
      {{ (!isFuneralClosed ? 'moneyGiftReceptionButton' : 'receptionFinished') | i18n }}
    </button>
    <button
      *ngIf="displaySkipButton"
      id="gift-money-home-skip-button"
      class="btn btn-wizard btn-outline-secondary col-12 col-md-4 mb-3"
      type="button"
      (click)="skip()"
    >
      {{ 'processWithoutApplyingMsg' | i18n }}
    </button>
    <FreeDial *ngIf="isFuneralClosed"></FreeDial>
  </div>
</div>
