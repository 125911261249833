import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { GlobalStorage } from './global/storage';
import { DeceasedModule } from './pages/deceased';
import { Services } from '../services';
import { AppComponent } from './app.component';
import { SplashScreenComponent } from './pages/splash-screen/splash-screen.component';
import { HomeComponent } from './pages/home/home.component';
import { WelcomeComponent } from './pages/welcome/welcome.component';
import { FuneralMissingComponent } from './pages/funeral-missing/funeral-missing.component';
import { PaymentCheckComponent } from './pages/payment-check/payment-check.component';
import { TimerComponent } from './components/timer.component';
import { FreeDialComponent } from './components/free-dial.component';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { CreditCardDirectivesModule } from 'angular-cc-library';
import { NgxGoogleAnalyticsModule } from 'ngx-google-analytics';
import { NgSelectModule } from '@ng-select/ng-select';
import { GoogleMapsModule } from '@angular/google-maps';
import { NguCarouselModule } from '@ngu/carousel';
// Amplify API requirement
import { AmplifyUIAngularModule } from '@aws-amplify/ui-angular';
import { ImgCheckDirective } from './directives/img-check.directive';
import { DropZoneDirective } from './directives/drop-zone-directive';
import { MediaModules } from './pages/media';
import { MediaComponent } from './pages/media/media.component';
import { VideoHLSDirective } from './directives/video-hls.directive';
import { FuneralClosedComponent } from './pages/funeral-closed/funeral-closed.component';
import { OrderHeaderComponent } from './shared/order-header/order-header.component';
import { OrderComponent } from './pages/order/order.component';
import { FlowersModule } from './pages/order/flowers';
import { RadioButtonComponent } from 'src/app/components/radio-button.component';
import { CardModule } from './pages/order/card';
import { CondolenceVideoModule } from './pages/condolence-video';
import { FilterPipe } from './pipes/filter.pipe';
import { MoneyGiftModule } from './pages/order/money-gift';
import { MustMatchDirective } from './directives/validators/must-match.directive';
import { ZoomImageDirective, ZoomBodyComponent } from './directives/zoom-image.directive';
import { ShowPDFDirective, ShowPDFBodyComponent } from './directives/show-pdf.directive';
import { CheckoutModule, CheckoutComponent } from './pages/order/checkout';
import { PostalCodeComponent } from './components/postal-code.component';
import { PaypalButtonComponent } from './components/paypal-button.component';
import { FooterComponent } from './shared/footer/footer.component';
import { ConfirmComponent } from './pages/order/confirm';
import { ThankYouComponent } from './pages/thank-you/thank-you.component';
import { EnvComponent } from './components/env.component';
import { environment } from 'src/environments/environment';
import { JapanTimePipe } from './pipes/japan-time.pipe';
import { PayPalGuideComponent } from './pages/order/checkout/paypal-guide/paypal-guide.component';
import { PaypalGuideWindowComponent } from './pages/order/checkout/paypal-guide/paypal-guide-window/paypal-guide-window.component';
import { BirthDateComponent } from './components/birth-date/birth-date.component';
import { PathPipe } from './pipes/path.pipe';
import { ImageDirective } from './directives/image.directive';
import { I18nPipe } from './pipes/i18n.pipe';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { MaxLengthDirective } from './directives/max-length.directive';
import { PhoneValidatorDirective } from './directives/phone-validator.directive';
import { PriceWithTaxPipe } from './pipes/price-with-tax.pipe';
import { DecimalPipe } from '@angular/common';
import { MoneyGiftPresentSelectionComponent } from './pages/order/money-gift';
import { MemorialShareComponent } from './pages/memorial-share/memorial-share.component';
import { MemorialShareUploadComponent } from './pages/memorial-share/memorial-share-upload/memorial-share-upload.component';
import { MediaHeaderComponent } from './components/media-header/media-header.component';
import { MemorialSharePhotoAlbumComponent } from './pages/memorial-share/memorial-share-photo-album/memorial-share-photo-album.component';
import { GuestBookComponent } from './pages/order/guest-book/guest-book.component';
import { AddressComponent } from './components/address/address.component';
import { LogInFormComponent } from './components/log-in-form/log-in-form.component';
import { CardUploadFormComponent } from './components/card-upload-form/card-upload-form.component';
import { EditMemorialShareModalComponent } from './pages/memorial-share/edit-memorial-share-modal/edit-memorial-share-modal.component';
import { MemberLoginFormComponent } from './components/member-login-form/member-login-form.component';
import { PaymentMethodComponent } from './components/payment-method/payment-method.component';
import { PhotoAlbumComponent } from './components/photo-album/photo-album.component';
import { APIService } from '@kokusai/smacere-shared/api';
import { MoneyGiftOrderComponent } from './pages/order/money-gift/money-gift-order/money-gift-order.component';
import { MemorialShareHomeComponent } from './pages/memorial-share/memorial-share-home/memorial-share-home.component';
import { CondolenceVideoOrderDetailsComponent } from './pages/condolence-video/condolence-video-order-details/condolence-video-order-details.component';
import { AuthService } from './services/auth.service';
import { Amplify, Auth } from 'aws-amplify';
import awsconfig from '../aws/aws-settings';
import { MessageService } from '@progress/kendo-angular-l10n';
import { KendoMessageService } from 'src/app/services/kendo-message.service';
import { DiscountedPriceComponent } from './components/discounted-price/discounted-price.component';
import { FullNamePipe } from './pipes/full-name.pipe';
import { AddressPipe } from './pipes/address.pipe';
import { PhoneNumberPipe } from './pipes/phone-number.pipe';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { FlowerNameSampleComponent } from './pages/order/flowers/flower-name-sample/flower-name-sample.component';
import { CartSummaryComponent } from './pages/order/summary/cart-summary.component';
import { CardSummaryComponent } from './pages/order/card/card-summary/card-summary.component';
import { FlowersSummaryComponent } from './pages/order/flowers/flowers-summary/flowers-summary.component';
import { MoneyGiftSummaryComponent } from './pages/order/money-gift/money-gift-summary/money-gift-summary.component';
import { UAParser } from 'ua-parser-js';
import { IsS3ObjectVideoPipe } from './pipes/is-s3-object-video.pipe';
import { GetS3UrlPipe } from './pipes/get-s3-url.pipe';
import { CustomerSummaryComponent } from './pages/order/customer-summary/customer-summary.component';
import { FooterCOMComponent } from './shared/footer/footer-com/footer-com.component';
import { FooterHPIComponent } from './shared/footer/footer-hpi/footer-hpi.component';
import { FooterSCTComponent } from './shared/footer/footer-sct/footer-sct.component';
import { FooterSmacereTOSComponent } from './shared/footer/footer-smacere-tos/footer-smacere-tos.component';
import { FooterTOSComponent } from './shared/footer/footer-tos/footer-tos.component';

Amplify.configure(awsconfig);

const ua = UAParser(navigator.userAgent);
Auth.configure({
  clientMetadata: {
    clientId: '8z7x2jy55kxie0eo8sov4mjxy',
    browser: ua.browser.name,
    platform: ua.os.name,
  },
});
@NgModule({
  declarations: [
    AppComponent,
    ConfirmComponent,
    CheckoutComponent,
    FooterComponent,
    SplashScreenComponent,
    HomeComponent,
    WelcomeComponent,
    DeceasedModule,
    FuneralMissingComponent,
    PaymentCheckComponent,
    TimerComponent,
    FreeDialComponent,
    ImgCheckDirective,
    DropZoneDirective,
    MediaComponent,
    MediaModules,
    VideoHLSDirective,
    FuneralClosedComponent,
    OrderHeaderComponent,
    OrderComponent,
    FlowersModule,
    RadioButtonComponent,
    CardModule,
    CondolenceVideoModule,
    FilterPipe,
    MoneyGiftModule,
    MustMatchDirective,
    ZoomImageDirective,
    ZoomBodyComponent,
    ShowPDFDirective,
    ShowPDFBodyComponent,
    CheckoutModule,
    PostalCodeComponent,
    PaypalButtonComponent,
    RadioButtonComponent,
    ThankYouComponent,
    EnvComponent,
    JapanTimePipe,
    PayPalGuideComponent,
    PaypalGuideWindowComponent,
    BirthDateComponent,
    PathPipe,
    ImageDirective,
    I18nPipe,
    MaxLengthDirective,
    PhoneValidatorDirective,
    PriceWithTaxPipe,
    MoneyGiftPresentSelectionComponent,
    MemorialShareComponent,
    MemorialShareUploadComponent,
    MediaHeaderComponent,
    MemorialSharePhotoAlbumComponent,
    GuestBookComponent,
    AddressComponent,
    LogInFormComponent,
    CardUploadFormComponent,
    EditMemorialShareModalComponent,
    MemberLoginFormComponent,
    PaymentMethodComponent,
    PhotoAlbumComponent,
    MoneyGiftOrderComponent,
    MemorialShareHomeComponent,
    CondolenceVideoOrderDetailsComponent,
    DiscountedPriceComponent,
    FullNamePipe,
    AddressPipe,
    PhoneNumberPipe,
    NotFoundComponent,
    FlowerNameSampleComponent,
    CartSummaryComponent,
    CardSummaryComponent,
    FlowersSummaryComponent,
    MoneyGiftSummaryComponent,
    IsS3ObjectVideoPipe,
    GetS3UrlPipe,
    CustomerSummaryComponent,
    FooterCOMComponent,
    FooterHPIComponent,
    FooterSCTComponent,
    FooterSmacereTOSComponent,
    FooterTOSComponent,
  ],
  imports: [
    AmplifyUIAngularModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    BrowserModule,
    NgSelectModule,
    NgbModule,
    GoogleMapsModule,
    CreditCardDirectivesModule,
    SweetAlert2Module.forRoot(),
    NgxGoogleAnalyticsModule.forRoot(environment.google.googleAnalytics),
    NguCarouselModule,
    DropDownsModule,
  ],
  providers: [
    { provide: APIService, useValue: new APIService() },
    { provide: AuthService, useValue: new AuthService() },
    { provide: MessageService, useClass: KendoMessageService },
    Services,
    GlobalStorage,
    DecimalPipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
