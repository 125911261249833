import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalStorage } from 'src/app/global/storage';
import { PresentsService } from 'src/services';
import { CardOrder } from 'src/models';
import { Funeral, ProductType } from '@kokusai/smacere-shared/api';
import { CardPreviewComponent } from '../card-preview/card-preview.component';
import { BundleCartService, BundleType } from '../../../../services/bundle-cart.service';
@Component({
  selector: 'app-card-order-details',
  templateUrl: './card-order-details.component.html',
  styleUrls: ['./card-order-details.component.scss'],
})
export class CardOrderDetailsComponent implements OnInit {
  constructor(
    private storage: GlobalStorage,
    private presentsService: PresentsService,
    private modalService: NgbModal,
    private route: Router,
    private location: Location,
    private bundleCartService: BundleCartService
  ) {}

  CardOrder: CardOrder;
  Funeral: Funeral;

  public hasPresents: boolean = false;
  public isEdit: boolean = false;

  async ngOnInit(): Promise<void> {
    this.bundleCartService.checkStep(BundleType.Card, 'order-details', 'present');

    this.Funeral = await this.storage.FuneralModel;
    this.CardOrder = this.storage.Order.CardOrder;
    window.scrollTo(0, 0);
    if (this.storage.Funeral.displayCardPresent) {
      this.hasPresents = true;
      this.presentsService
        .gets(ProductType.Card, this.CardOrder.Product.price, this.Funeral.funeralDate)
        .subscribe((presents) => (this.hasPresents = presents.length > 0));
    }
    this.isEdit = this.bundleCartService.isEdit();
  }

  OpenCardPreviewModal(): void {
    const modal = this.modalService.open(CardPreviewComponent, {
      windowClass: 'card-message-preview-modal',
    });

    modal.componentInstance.Order = this.CardOrder;
  }

  public async nextAction(): Promise<void> {
    if (this.hasPresents) {
      await this.route.navigate([this.storage.Funeral.funeralId, 'order', 'cards', 'presents']);
    } else {
      await this.bundleCartService.saveCardFromStorage();
      await this.bundleCartService.goToNextStep(BundleType.Card);
    }
  }

  public async addAction(): Promise<void> {
    await this.bundleCartService.saveCardFromStorage();
    await this.bundleCartService.goToAdd(BundleType.Card);
  }

  back(): void {
    this.location.back();
  }
}
