import { Pipe, PipeTransform } from '@angular/core';
import { S3Object } from '@kokusai/smacere-shared/types/api';
import { AWS } from '@kokusai/utilities';
@Pipe({
  name: 'isS3ObjectVideo',
})
export class IsS3ObjectVideoPipe implements PipeTransform {
  transform(media: S3Object): boolean {
    const extension = AWS.getS3Url(media)?.slice(-3);
    const videoExtensions = ['avi', 'mov', 'mp4'];
    return videoExtensions.includes(extension.toLowerCase());
  }
}
