<div class="row mr-3 ml-3">
  <div class="col-12 col-md-auto p-0 mb-1 mb-md-0">
    <div class="d-flex align-items-center">
      <ng-select
        #yearSelect
        id="years"
        #yearRef="ngModel"
        [items]="years"
        [searchable]="false"
        [clearable]="false"
        [clearable]="true"
        class="select-years"
        [(ngModel)]="year"
        (change)="onYearChange()"
        (open)="onOpen()"
        (clear)="clearYear()"
        [readonly]="readonly"
      ></ng-select>
      <strong>年</strong>
    </div>
  </div>
  <div class="col-12 col-md-auto p-0 p-0 mb-1 mb-md-0">
    <div class="d-flex align-items-center">
      <ng-select
        id="months"
        #monthRef="ngModel"
        [items]="months"
        [searchable]="false"
        [clearable]="true"
        class="select-months"
        [disabled]="year == null"
        [(ngModel)]="month"
        (change)="onMonthChange()"
        (clear)="clearMonth()"
        [readonly]="readonly"
      ></ng-select>
      <strong>月</strong>
    </div>
  </div>
  <div class="col-12 col-md-auto p-0 p-0 mb-1 mb-md-0">
    <div class="d-flex align-items-center">
      <ng-select
        id="days"
        #dayRef="ngModel"
        [items]="days"
        [searchable]="false"
        [clearable]="true"
        class="select-days"
        [disabled]="month == null"
        [(ngModel)]="day"
        (change)="onDayChange()"
        [readonly]="readonly"
      ></ng-select>
      <strong>日</strong>
    </div>
  </div>
</div>
