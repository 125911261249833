<div class="transparent-background"></div>
<div class="memorial-share-page">
  <app-media-header></app-media-header>
  <div class="memorial-share-contents">
    <div class="memorial-share-info-container">
      <div class="memorial-share-info">
        <div class="memorial-share-image" *ngIf="Funeral?.deceasedPhoto">
          <img [src]="Funeral?.deceasedPhoto.file | getS3Url" alt="deceasedPhoto" />
        </div>
        <div class="memorial-share-info-contents small-font">
          <div class="name-container">
            <span>{{ 'prefix' | i18n }} </span> <span class="name">　{{ Funeral?.deceasedName }}　</span
            ><span>{{ 'nameSuffix' | i18n }}</span>
            <div class="title">{{ 'memorialShare' | i18n }}</div>
          </div>
          <div>
            <span>{{ 'endDate' | i18n }}</span
            >　{{ funeralEndDate }}
          </div>
          <div>
            <span>{{ 'numberOfPhotos' | i18n }}</span
            >　{{ memorialShareAlbum?.length }}<span>{{ 'photosCounter' | i18n }}</span>
          </div>
        </div>
      </div>
      <div class="upload-button-container">
        <div class="upload-button-title">
          <img class="upload-button-img" src="assets/svg/image-icon.svg" />
          <span>{{ 'uploadButtonHeader' | i18n }}</span>
        </div>
        <button class="upload-button" (click)="upload()">
          {{ 'uploadButton' | i18n }}
        </button>
      </div>
    </div>
    <!-- memorial-share-album -->
    <div class="memorial-share-album" *ngIf="memorialShareAlbum?.length > 0">
      <!-- TODO: delete once we decide not to use the photo album -->
      <!-- <app-memorial-share-photo-album [album]="memorialShareAlbum"></app-memorial-share-photo-album> -->
      <app-photo-album [album]="memorialShareAlbum" type="image"></app-photo-album>
    </div>
  </div>
  <div class="memorial-share-footer">
    <button class="return-button" (click)="back()">{{ 'return' | i18n }}</button>
  </div>
</div>
<app-footer class="footer-fix"></app-footer>
