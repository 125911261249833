import { Injectable } from '@angular/core';
import { CreatePaymentInput, APIService } from '@kokusai/smacere-shared/api';
import { Payment } from 'src/models';
import { Observable } from 'rxjs';

@Injectable()
export class PaymentService {
  constructor(private apiService: APIService) {}
  public SaveComplete(data: Payment): Observable<any> {
    return new Observable((subscribe) => {
      const payment = {
        funeralId: data.funeralId,
        cartId: data.cartId,
        orderId: data.orderId,
        paymentType: data.type,
        email: data.email,
      } as CreatePaymentInput;

      this.apiService.CreatePayment(payment).then((result) => {
        data.id = result.id;
        subscribe.next(data);
        subscribe.complete();
      });
    });
  }

  public checkByEmail(funeralId: string, email: string): Observable<Payment> {
    return new Observable((subscribe) => {
      this.apiService
        .PaymentByEmailByFuneralId(email, { eq: funeralId })
        .then((result) => {
          const item = result.items[0];
          const payment = new Payment();
          Object.keys(item).forEach((key) => {
            payment[key] = item[key];
          });
          subscribe.next(payment);
        })
        .catch(() => subscribe.error())
        .finally(() => subscribe.complete());
    });
  }
}
