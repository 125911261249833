<div id="stream">
  <div class="transparent-background"></div>
  <app-media-header></app-media-header>
  <p class="deceased-name-container font-mincho" [style.fontSize]="fontSize + 'px'">
    <span *ngIf="Funeral.deceasedNamePrefix" class="deceased-title">{{ Funeral.deceasedNamePrefix + '&#12288;' }}</span>
    <span *ngIf="Funeral.deceasedName" class="deceased-name">{{ Funeral.deceasedName }}</span
    >　
    <span *ngIf="Funeral.deceasedNameSuffix" class="deceased-title">{{ '&#12288;' + Funeral.deceasedNameSuffix }}</span>
    <span *ngIf="Funeral.deceasedNamePostfix" class="deceased-postfix">{{
      '&#12288;' + Funeral.deceasedNamePostfix
    }}</span>
  </p>
  <router-outlet></router-outlet>
  <div class="caution container mt-3 mt-md-5 mb-5">
    <p class="live-stream-title">{{ 'liveStreamingUseForEveryone' | i18n }}</p>
    <ul>
      <li>
        {{ 'pleaseRefreshMsg' | i18n }}
      </li>
      <li>
        {{ 'receptionCloseStart' | i18n }}
        <strong>{{ funeralCloseDateTime | date: 'M月d日' }}</strong>
        {{ 'receptionCloseEnd' | i18n }}
      </li>
    </ul>
  </div>
</div>
<app-footer class="footer-fix important"></app-footer>
