export class Permissions {
  constructor() {
    this.isName = true;
    this.isKana = true;
    this.isMaidenName = true;
    this.customName = false;
    this.isRelationship = true;
    this.isCompany = true;
    this.isPosition = true;
    this.isAddress = true;
    this.isTelephone = true;
    this.isEmail = true;
    this.isConfirmEmail = true;
    this.isBirthday = true;
    this.showNotice = true;
  }
  isName?: boolean | null;
  isKana?: boolean | null;
  isMaidenName?: boolean | null;
  customName?: boolean | null;
  isRelationship?: boolean | null;
  isCompany?: boolean | null;
  isPosition?: boolean | null;
  isAddress?: boolean | null;
  isTelephone?: boolean | null;
  isEmail?: boolean | null;
  isConfirmEmail?: boolean | null;
  isBirthday?: boolean | null;
  showNotice?: boolean | null;
}
