import { Product } from './Product';

export class Paper extends Product {
  constructor(params: Paper = {} as Paper) {
    super(params);
    let { paperSize = '' } = params; //Default value;

    this.paperSize = paperSize;
  }

  paperSize: string;
}
