import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { DiscountsService } from '../../services/discounts.service';
import { Product, ProductType } from '@kokusai/smacere-shared/api';

@Component({
  selector: 'app-discounted-price',
  templateUrl: './discounted-price.component.html',
  styleUrls: ['./discounted-price.component.scss'],
})
export class DiscountedPriceComponent implements OnInit, OnChanges {
  public value: number = null;
  @Input() price: number;
  @Input() type: ProductType;
  @Input() product: Product;
  @Input() symbol: string;
  @Input() position: string;

  constructor(private discountsService: DiscountsService) {}

  public ngOnInit(): void {
    this.updateValue();
    this.discountsService.changes.subscribe(() => {
      this.updateValue();
    });
  }

  public ngOnChanges(): void {
    this.updateValue();
  }

  private updateValue() {
    if (this.product) {
      this.value = this.discountsService.getDiscountedPrice(this.product.price, this.product.productType);
    } else if (this.price && this.type) {
      this.value = this.discountsService.getDiscountedPrice(this.price, this.type);
    } else {
      this.value = null;
    }
  }
}
