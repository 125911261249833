import { environment } from '../environments/environment';
import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Path } from 'src/app/core/utilities';

@Injectable({
  providedIn: 'root',
})
export class HeadService {
  constructor(@Inject(DOCUMENT) private doc) {
    this.AddPayPal();
    this.AddGoogle();
    this.AddFiveIco();
  }

  private AddGoogle = (): void => {
    const url =
      'https://maps.googleapis.com/maps/api/js?key=' +
      environment.google.googleKey +
      '&types=geocode&language=ja&region=JP&libraries=places';
    this.AddScript(url);
  };

  private AddPayPal = (): void => {
    const url =
      'https://www.paypal.com/sdk/js?client-id=' +
      environment.payment.payPalKey +
      '&currency=JPY&locale=ja_JP&intent=capture&components=buttons,funding-eligibility&integration-date=2018-11-07';

    this.AddScript(url);
  };

  private AddScript = (link: string) => {
    const script: HTMLScriptElement = this.doc.createElement('script');
    script.setAttribute('src', link);
    this.doc.body.appendChild(script);
  };

  private AddFiveIco = (): void => {
    const fiveIcoPath = Path.getPath('favicon.png');
    const link: HTMLLinkElement = this.doc.createElement('link');
    link.setAttribute('rel', 'shortcut icon');
    link.setAttribute('type', 'image/png');
    link.setAttribute('href', fiveIcoPath);
    this.doc.head.appendChild(link);
  };
}
