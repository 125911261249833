import { Component, Input, Output, EventEmitter, forwardRef, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

import { Sorts } from 'src/app/core/utilities/sorts';

import { GlobalStorage } from 'src/app/global/storage';

import { Funeral, Food } from 'src/models';

@Component({
  selector: 'app-gift-money-food',
  templateUrl: './money-gift-food.component.html',
  styleUrls: ['./money-gift-food.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MoneyGiftFoodComponent),
      multi: true,
    },
  ],
})
export class MoneyGiftFoodComponent implements AfterViewInit, ControlValueAccessor {
  @Output()
  onChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input()
  set sum(value: number) {
    if (value) {
      const amount = value > 100000 ? 100000 : value;
      this.foods = this.funeral.foodSelections
        .sort((a, b) => {
          return Sorts.byJapanAlpha(a, b, 'name');
        })
        .filter((food) => {
          return food.foodAmount === amount && food.cost > 0;
        });

      this.onChanged.emit(this.foods.length > 0);
    }
  }

  funeral: Funeral;
  foods: Food[] = [];
  selectedFood: Food = null;

  constructor(private storage: GlobalStorage, private changeDetectionRef: ChangeDetectorRef) {
    this.funeral = this.storage.Funeral;
  }

  ngAfterViewInit(): void {
    this.changeDetectionRef.detectChanges();
    if (this.foods.length > 0) {
      if (this.selectedFood == null) {
        this.onSelectionChange(this.foods[0]);
        this.changeDetectionRef.detectChanges();
      }
    }
  }

  onTouched(): void {}

  onChange: any = () => {};

  get showSelectionCheckbox() {
    return this.foods.length > 1;
  }

  writeValue(food: Food): void {
    this.selectedFood = food;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  onSelectionChange(food: Food): void {
    this.writeValue(food);
    this.onChange(food);
  }
}
