import { Injectable } from '@angular/core';
import { nanoid } from 'nanoid';
import { Storage } from 'aws-amplify';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FilesService {
  constructor() {}

  uploadFile(file: File, path?: string): { progress: BehaviorSubject<number>; result: Promise<string> } {
    const filePath = [];
    const date = new Date();
    if (typeof path === 'string' && path.length > 0) {
      filePath.push(path);
    }
    filePath.push([date.getUTCFullYear(), date.getUTCMonth() + 1, date.getUTCDate()].join('-'));

    const id = nanoid();
    const extension = file.name.split('.').pop();
    filePath.push(`${id}.${extension}`);

    const progress = new BehaviorSubject<number>(0);
    // noinspection JSUnusedGlobalSymbols
    return {
      progress,
      result: Storage.put(filePath.join('/'), file, {
        level: 'public',
        customPrefix: {
          public: 'uploads/',
        },
        progressCallback: (value) => {
          progress.next(Math.min(Math.round((value.loaded / value.total) * 100), 100));
          if (value.loaded === value.total) {
            progress.complete();
          }
        },
      }).then((uploadResult: { key: string }) => uploadResult.key),
    };
  }

  getThumbnail(file: File, timeout: number = 1000): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const timeoutHandle = setTimeout(reject, timeout);
      let element: HTMLImageElement | HTMLVideoElement;
      const onloadHandler = () => {
        clearTimeout(timeoutHandle);
        const result = this.renderThumbnail(element);
        if (result !== null) {
          resolve(result);
        } else {
          reject();
        }
      };
      if (file.type.startsWith('image/')) {
        element = document.createElement('img');
        element.addEventListener('load', onloadHandler);
      } else if (file.type.startsWith('video/')) {
        element = document.createElement('video');
        element.muted = true;
        element.addEventListener('loadedmetadata', () => {
          (element as HTMLVideoElement).currentTime = 0;
        });
        element.addEventListener('timeupdate', onloadHandler);
      }
      element.src = URL.createObjectURL(file);
    });
  }

  // noinspection JSMethodCanBeStatic
  private renderThumbnail(element: HTMLImageElement | HTMLVideoElement): string {
    let sw;
    let sh;
    if (element instanceof HTMLImageElement) {
      sw = element.naturalWidth;
      sh = element.naturalHeight;
    } else if (element instanceof HTMLVideoElement) {
      sw = element.videoWidth;
      sh = element.videoHeight;
    } else {
      return null;
    }

    let dw = sw;
    let dh = sh;
    if (sw > 400) {
      dh = Math.floor((400 / sw) * sh);
      dw = 400;
    }

    const canvas = document.createElement('canvas');
    canvas.width = dw;
    canvas.height = dh;
    canvas.getContext('2d').drawImage(element, 0, 0, sw, sh, 0, 0, dw, dh);
    return canvas.toDataURL();
  }
}
