export * from './condolence-video.component';
export * from './condolence-video-home/condolence-video-home.component';
export * from './condolence-video-upload/condolence-video-upload.component';
export * from './condolence-video-order/condolence-video-order.component';
export * from './condolence-video-order-details/condolence-video-order-details.component';
export * from './condolence-video-present/condolence-video-present.component';

import { CondolenceVideoComponent } from './condolence-video.component';
import { CondolenceVideoHomeComponent } from './condolence-video-home/condolence-video-home.component';
import { CondolenceVideoUploadComponent } from './condolence-video-upload/condolence-video-upload.component';
import { CondolenceVideoOrderComponent } from './condolence-video-order/condolence-video-order.component';
import { CondolenceVideoOrderDetailsComponent } from './condolence-video-order-details/condolence-video-order-details.component';
import { CondolenceVideoPresentComponent } from './condolence-video-present/condolence-video-present.component';

export const CondolenceVideoModule = [
  CondolenceVideoComponent,
  CondolenceVideoHomeComponent,
  CondolenceVideoUploadComponent,
  CondolenceVideoOrderComponent,
  CondolenceVideoOrderDetailsComponent,
  CondolenceVideoPresentComponent,
];
