import { Directive, HostListener, Input, Component } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  template: `<div [innerHTML]="innerHtml"></div>`,
})
export class ShowPDFBodyComponent {
  public innerHtml: SafeHtml;
}

@Directive({
  selector: '[showPDF]',
})
export class ShowPDFDirective {
  @Input()
  public PDFPath: string;
  public innerHtml: SafeHtml;

  constructor(private modalService: NgbModal, private sanitizer: DomSanitizer) {}

  @HostListener('click') OnClick() {
    const modal = this.modalService.open(ShowPDFBodyComponent, {
      windowClass: 'pdf-modal',
    });

    // const width = 	window.innerWidth * 0.85;
    const height = window.innerHeight * 0.85;

    this.innerHtml = this.sanitizer.bypassSecurityTrustHtml(
      "<object data='" +
        this.PDFPath +
        "' type='application/pdf' width='100%' height='" +
        height +
        "'>" +
        '<p>お使いのブラウザではこちらに表示されません。お手数ですが下記リンクをクリックしてご確認ください。</p>' +
        "<p><a href='" +
        this.PDFPath +
        "' target='_blank'>別ウィンドウが開きます。</a>" +
        '</object>'
    );
    modal.componentInstance.innerHtml = this.innerHtml;
    return false;
  }
}
