<div class="modal-header no-border">
  <a href="javascript:void(0);" class="modal-close-icon" (click)="activeModal.close()"></a>
  <h4 class="modal-title" id="modal-basic-title">スマセレクラブ会員規約</h4>
</div>
<div class="tos-body modal-body">
  <p>
    <span *ngIf="displayForCeremony">株式会社埼玉冠婚葬祭センター</span
    ><span *ngIf="displayForJoin">株式会社ジョイン</span
    >（以下「当社」といいます。）は、スマセレクラブ（以下「本サービス」といいます。）の会員規約（以下「本会員規約」と言います）を定め、本会員規約に基づき本サービスを提供します。本サービスを利用するためには、本会員規約および「WEBサービスに関するプライバシーポリシー」（以下「本プライバシーポリシー」といいます。）」に同意していただく必要があります。
  </p>
  <p>
    <span
      >本サービスを利用することで、当社が提供する関連サービスのスマートセレモニーにて商品の申込手続きを簡略化でき、申込商品の履歴を本サービス上で閲覧することが出来ます。スマートセレモニー利用時には、「スマートセレモニー利用規約」を遵守していただく必要があります。</span
    >
  </p>
  <!-- #1 -->
  <p class="clause"><strong>第１条（スマセレクラブ会員）</strong></p>
  <p>1．当社は、本会員規約および本プライバシーポリシーに同意し、本サービス上より会員登録をされた方を会員とします。</p>
  <p>2．会員は個人利用のみを認めるものとし、団体・組織での登録を認めません。</p>
  <p>3．会員は自己の責任においてＩＤ・パスワードを管理し、これを第三者に貸与、譲渡、売買してはならないものとします。</p>
  <p>
    4．会員は、本サービス上より退会の手続きがおこなえます。会員は、退会することで、いつでも本サービスの利用を終了することができます。
  </p>
  <!-- #2 -->
  <p class="clause"><strong>第2条（利用料）</strong></p>
  <p>
    1．
    本サービスの登録および利用料は無料とします。ただし、本サービスを利用するために必要な通信機器等（以下「端末」といいます。）の設備、インターネット接続にかかわる通信費用等は会員が負担するものとします。
  </p>
  <!-- #3 -->
  <p class="clause"><strong>第3条（会員情報の取得、利用目的等）</strong></p>
  <p>1．会員が本サービス上に登録した会員情報は、当社にて、本プライバシーポリシーに則り管理および利用いたします。</p>
  <p>
    2．本サービス上では、クレジットカードに関する情報は保存いたしません。その為、スマートセレモニーにて商品をお申込み頂く際には、お申込みの都度、クレジットカード情報を入力して頂く必要がございます。
  </p>
  <!-- #4 -->
  <p class="clause"><strong>第4条（ログイン）</strong></p>
  <p>
    会員が、当社が定める方法でログインされた場合、当社はその行為を会員の行為であるとみなします。第三者により不正に会員に代わりログインが行われ、その行為により会員が不利益および損害を被った場合、当社は一切の責任を負いません。また同様の行為により、当社が損害を被った場合、会員にその損害を請求するものとします。
  </p>
  <!-- #5 -->
  <p class="clause"><strong>第5条（反社会的勢力の排除）</strong></p>
  <p>
    当社は以下の事項に該当する方の利用を認めません。会員が以下の条項に該当することが判明した場合、アクセスを拒否し、ログインを遮断する場合があります。
  </p>
  <ul>
    <li>自らまたは第三者を利用して、当社に対し、暴力的行為、詐術、脅迫的言辞、業務妨害行為などの行為をした者。</li>
    <li>
      暴力団、暴力団員（暴力団員でなくなった時から5年を経過しない者を含みます。）、暴力団準構成員、暴力団関係企業・その他の反社会的勢力（以下、あわせて「反社会的勢力」といいます。）である者。
    </li>
    <li>反社会的勢力に対して資金等を提供し、または便宜を供与するなどの関与をしている者。</li>
    <li>反社会的勢力を不当に利用している者。</li>
    <li>その他反社会的勢力と社会的に非難されるべき関係を有している者及び当社がご利用を不適当と認めた者。</li>
  </ul>

  <!-- #6 -->
  <p class="clause"><strong>第6条（登録情報の変更）</strong></p>
  <p>1． 会員が登録した会員情報に変更が生じた場合、速やかに本サービス上より会員情報を変更するものとします。</p>
  <p>
    2．
    会員が会員情報の変更を行わなかったことにより、なんらかの不利益および損害を被った場合、当社は一切の責任を負いません。また同様の行為により、当社が損害を被った場合、会員にその損害を請求するものとします。
  </p>

  <!-- #7 -->
  <p class="clause"><strong>第7条（本サービスの情報）</strong></p>
  <p>当社は、本サービスにおいて提供する本サービスの内容（機能・仕様等）を予告なく変更する場合があります。</p>

  <!-- #8 -->
  <p class="clause"><strong>第8条（本サービスの停止・終了）</strong></p>
  <p>
    当社が必要と認める場合、当社はなんらの通知を行うことなく、本サービスの全部または一部の提供を中止（一時的なものを含む。）または終了することがあります。
  </p>
  <!-- #9 -->
  <p class="clause"><strong>第9条（著作権等）</strong></p>
  <p>
    本サービスで表示される各種情報等に関する知的財産権等（著作権、特許権、実用新案権、意匠権、商標権等を含みますがこれらに限定されません。以下同じ。）は、当社に帰属します。
  </p>
  <!-- #10 -->
  <p class="clause"><strong>第10条（禁止事項）</strong></p>
  <p>1．会員は、本サービスを利用するに際し、次に掲げる行為を行わないものとします。</p>
  <ul>
    <li>
      本サービスに使用されている画像、データ、情報等のすべてについて、その有償無償や形態の如何を問わず、事前に当社から書面による承諾を得ることなく、複製、転載、再配布等する行為。
    </li>
    <li>当社のサービス、当社の配信する広告または当社のサイト上で提供されているサービス・広告等を妨害する行為。</li>
    <li>当社の知的財産権等、肖像権、プライバシーの権利、名誉、その他の権利または利益を侵害する行為。</li>
    <li>本サービスの提供に支障をきたす行為。</li>
    <li>法令または公序良俗に反する行為、犯罪行為に関連する行為、犯罪につながると客観的に判断される行為。</li>
    <li>本サービスを、個人的な利用範囲を超えて、商業または営利目的において利用する行為。</li>
    <li>第三者に成りすます行為。</li>
    <li>当社のネットワークまたはシステム等に不正アクセスし、または不正なアクセスを試みる行為。</li>
    <li>その他、当社が合理的な理由に基づき不適切と判断する行為。</li>
  </ul>
  <p>
    2．会員は本サービスを利用した営業活動を行うことができません。会員は、本サービスを利用した営業活動によって当社が損害を被った場合、その損害を賠償しなければなりません。
  </p>
  <p>
    3．会員は、本サービスの利用権限、その他の利用契約上の地位を第三者に譲渡、贈与もしくは移転できず、また、これを担保として提供することができません。
  </p>
  <!-- #11 -->
  <p class="clause"><strong>第11条（免責）</strong></p>
  <p>
    1．当社は、本サービスに不具合、障害等の瑕疵がないこと、および本サービスが中断なく稼動することをなんら保証しません。また、特定の目的に対する適応性、知的財産権等その他の権利の侵害等の不存在に対してもなんら保証しません。当社は、本サービスにいかなる不備があってもそれを回復、訂正等する義務を負いません。
  </p>
  <p>
    2．当社は、会員が本サービスを使用すること、または、使用できなかったことによって損害、トラブル等が生じた場合であっても、いかなる責任も負いません。
  </p>
  <p>
    3．当社は、本サービス上に掲載される情報等について、明示または黙示を問わず、その正確性、完全性、最新性、および品質等についてなんら保証しません。また、当社は、本サービスに表示される情報等およびその変更、更新等に関連して、会員に生じた一切の損害、トラブルに関していかなる責任も負いません。
  </p>
  <p>
    4．当社は、自然災害、ディードス(DDOS)攻撃、クラウド事業者、IDC障害、電気通信事業者の回線障害またはそれに準ずる不可抗力により本サービスが提供できない場合であっても、一切の責任を負いません。
  </p>
  <p>5．当社は、本サービスの仕様に関するご質問には一切お答えできません。</p>

  <!-- #12 -->
  <p class="clause"><strong>第12条（本会員規約等の変更）</strong></p>
  <p>
    本会員規約の内容は、当社が必要と判断した場合には、予告なしに変更される場合があります。会員は本サービスをご利用の際には、その都度本会員規約にご同意いただく必要があります。
  </p>

  <!-- #13 -->
  <p class="clause"><strong>第13条（利用停止、損害賠償）</strong></p>
  <p>
    1．
    会員が本会員規約に定める事項に違反した場合、当社はなんらの通知を行うことなく直ちにログインを遮断もしくは、強制的に退会させることができるものとします。また、会員が本会員規約に違反した結果、当社が損害を被った場合、会員はその損害を負担するものとします。
  </p>
  <p>
    2．
    その他当社が適当と判断した場合は、なんらの通知を行うことなく本サービスへのログインを遮断もしくは、強制的に退会させることができるものとします。
  </p>

  <!-- #14 -->
  <p class="clause"><strong>第14条（委託および再委託）</strong></p>
  <p>
    当社は本サービスの提供業務の全部または一部を第三者に委託することができます。また、受託者は受託業務をさらに第三者に再委託することができます。
  </p>

  <!-- #15 -->
  <p class="clause"><strong>第15条（法令等の遵守）</strong></p>
  <p>
    会員は、本サービスの使用にあたって、会員規約に加え、関連する法律、政令、省令、条例、規則および命令等を遵守するものとします。
  </p>

  <!-- #16 -->
  <p class="clause"><strong>第16条（準拠法および裁判管轄）</strong></p>
  <p>
    本会員規約は日本法に準拠します。また、当社と会員との間で紛争が生じた場合、さいたま地方裁判所を第一審の専属的合意管轄裁判所とします。
  </p>

  <!-- date footer -->
  <p *ngIf="displayForCeremony" class="text-right w-100">2021 年 8 月 1 日　制定</p>
  <p *ngIf="displayForJoin" class="text-right w-100">2021 年 8 月 1 日　制定</p>
</div>
