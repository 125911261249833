// noinspection JSUnusedGlobalSymbols

import { EventEmitter, Injectable } from '@angular/core';
import { GlobalStorage } from '../global/storage';
import {
  AddressObjectInput,
  AddressSameAs,
  APIService,
  CreateCartItemInput,
  ItemAttributeInput,
  Product,
} from '@kokusai/smacere-shared/api';
import {
  CardOrder,
  CondolenceVideoOrder,
  FlowerOrder,
  Food,
  MoneyGiftOrder,
  Order,
  Paper,
  Present,
} from '../../models';
import { Router } from '@angular/router';
import { StepType } from '../../types';
import { environment } from '../../environments/environment';
import { DiscountsService } from './discounts.service';
import { DisplayElementsService } from './display-elements.service';

export enum BundleType {
  Card = 'card',
  Flower = 'flower',
  GiftMoney = 'gift-money',
  CondolenceVideo = 'condolence-video',
}

type Totals = { total: number; totalBeforeDiscount?: number; discount?: number; tax: number };
type BundleItem<T> = {
  storageItem: T;
  cartItem: CreateCartItemInput;
  product: Product;
  totals: Totals;
};
export type Bundle =
  | {
      type: BundleType.Card;
      item: BundleItem<CardOrder>;
      paper: BundleItem<Paper>;
      present?: BundleItem<Present>;
    }
  | {
      type: BundleType.CondolenceVideo;
      item: BundleItem<CondolenceVideoOrder>;
      present?: BundleItem<Present>;
    }
  | {
      type: BundleType.Flower;
      item: BundleItem<FlowerOrder>;
      present?: BundleItem<Present>;
    }
  | {
      type: BundleType.GiftMoney;
      item: BundleItem<MoneyGiftOrder>;
      present?: BundleItem<Present>;
      food?: BundleItem<Food>;
    };

const EDIT_BUNDLE_ID_STORAGE_KEY = 'EditBundleIndex';
const CART_STORAGE_KEY = 'Cart';
const CART_STATE_STORAGE_KEY = 'CartState';

@Injectable({
  providedIn: 'root',
})
export class BundleCartService {
  private order: Order;
  private readonly items: Array<Bundle>;
  private totals: Totals = { total: 0, tax: 0 };
  public readonly totalsChanges: EventEmitter<Totals> = new EventEmitter<Totals>();
  private bundleStepMap = new Map<BundleType, StepType>([
    [BundleType.Card, StepType.Card],
    [BundleType.CondolenceVideo, StepType.CondolenceVideo],
    [BundleType.Flower, StepType.Flower],
    [BundleType.GiftMoney, StepType.MoneyGift],
  ]);
  private currentBundleType: BundleType;
  private unlockedSteps: string[] = [];

  constructor(
    private storage: GlobalStorage,
    private apiService: APIService,
    private router: Router,
    private discountsService: DiscountsService,
    private displayElementsService: DisplayElementsService
  ) {
    try {
      this.items = JSON.parse(sessionStorage.getItem(CART_STORAGE_KEY)) ?? [];
      this.recalculateTotals();
    } catch (e) {
      console.error(e);
      this.items = [];
    }
    this.loadState();

    this.order = this.storage.Order;
    this.storage.OrderChanged.subscribe((order) => (this.order = order));
    this.discountsService.changes.subscribe(() => this.recalculateTotals());
  }

  public clear(): void {
    this.items.length = 0;
    this.saveCart();
    this.clearOrderStorage();
  }

  public getBundles(): Bundle[] {
    return this.items;
  }

  public getBundlesOfType(...types: BundleType[]): Bundle[] {
    return this.items.filter((item) => types.includes(item.type));
  }

  public getCartItems(): CreateCartItemInput[] {
    let index = 1;
    return this.items.reduce((items, bundle) => {
      bundle.item.cartItem.index = index;
      items.push(bundle.item.cartItem);

      if (bundle.present) {
        bundle.present.cartItem.index = index;
        items.push(bundle.present.cartItem);
      }

      if (bundle.type === BundleType.Card && bundle.paper) {
        bundle.paper.cartItem.index = index;
        items.push(bundle.paper.cartItem);
      }

      if (bundle.type === BundleType.GiftMoney && bundle.food) {
        bundle.food.cartItem.index = index;
        items.push(bundle.food.cartItem);
      }

      index++;

      return items;
    }, [] as CreateCartItemInput[]);
  }

  public reset(type?: BundleType): void {
    this.clearOrderItemStorage(type);
    sessionStorage.removeItem(EDIT_BUNDLE_ID_STORAGE_KEY);
  }

  public isEdit() {
    return sessionStorage.getItem(EDIT_BUNDLE_ID_STORAGE_KEY) !== null;
  }

  public async goToAdd(type: BundleType): Promise<void> {
    this.reset();

    const funeral = await this.storage.FuneralModel;
    switch (type) {
      case BundleType.Card:
      case BundleType.CondolenceVideo:
        if (!this.displayElementsService.displayCard && !this.displayElementsService.displayCondolenceVideos) {
          break;
        }

        if (this.displayElementsService.displayCard && this.displayElementsService.displayCondolenceVideos) {
          await this.router.navigate([funeral.id, 'order', StepType.Card, 'main']);
        } else if (this.displayElementsService.displayCard) {
          await this.router.navigate([funeral.id, 'order', StepType.Card, 'products']);
        } else if (this.displayElementsService.displayCondolenceVideos) {
          await this.router.navigate([funeral.id, 'order', StepType.CondolenceVideo, 'upload']);
        }
        return;

      case BundleType.Flower:
        if (!this.displayElementsService.displayFlower) {
          break;
        }

        await this.router.navigate([funeral.id, 'order', StepType.Flower, 'products']);
        return;

      case BundleType.GiftMoney:
        if (!this.displayElementsService.displayMoney) {
          break;
        }

        await this.router.navigate([funeral.id, 'order', StepType.MoneyGift, 'products']);
        return;
    }
  }

  public async goToEdit(bundle: Bundle, step: string = 'products'): Promise<void> {
    const bundleIndex = this.items.indexOf(bundle);
    sessionStorage.setItem(EDIT_BUNDLE_ID_STORAGE_KEY, bundleIndex.toString(10));

    const stepType = this.bundleStepMap.get(bundle.type);
    if (bundle.type === BundleType.Card) {
      this.order.CardOrder = bundle.item.storageItem;
      if (bundle.present) {
        this.order.CardPresent = bundle.present.storageItem;
      }
    } else if (bundle.type === BundleType.CondolenceVideo) {
      this.order.CondolenceVideoOrder = bundle.item.storageItem;
      if (bundle.present) {
        this.order.CondolenceVideoPresent = bundle.present.storageItem;
      }
      if (step === 'products' || step === 'upload') {
        step = 'order';
      }
    } else if (bundle.type === BundleType.Flower) {
      this.order.FlowerOrder = bundle.item.storageItem;
      if (bundle.present) {
        this.order.FlowerPresent = bundle.present.storageItem;
      }
    } else if (bundle.type === BundleType.GiftMoney) {
      this.order.MoneyGiftOrder = bundle.item.storageItem;
      if (bundle.present) {
        this.order.MoneyPresent = bundle.present.storageItem;
      }
      if (bundle.food) {
        this.order.MoneyFood = bundle.food.storageItem;
      }
    }

    this.storage.Order = this.order;

    this.currentBundleType = bundle.type;
    const funeralId = (await this.storage.FuneralModel).id;
    await this.router.navigate([funeralId, 'order', stepType, step]);
  }

  public async goToNextStep(currentStep: BundleType | null = null, summary: boolean = true): Promise<unknown> {
    if (this.storage.OrderSteps.isOrderConfirmationActive) {
      return this.goToSummary();
    }

    const funeral = await this.storage.FuneralModel;

    // tslint:disable:no-switch-case-fall-through
    // noinspection FallThroughInSwitchStatementJS
    switch (currentStep) {
      default:
        if (funeral.displayFlower) {
          return this.router.navigate([funeral.id, 'order', StepType.Flower]);
        }
        summary = false;

      case BundleType.Flower:
        if (summary && this.getBundlesOfType(BundleType.Flower).length >= 2) {
          return this.goToSummary(BundleType.Flower);
        }
        if (funeral.displayCard || funeral.displayCondolenceVideos) {
          return this.router.navigate([funeral.id, 'order', StepType.Card]);
        }
        summary = false;

      case BundleType.Card:
      case BundleType.CondolenceVideo:
        if (summary && this.getBundlesOfType(BundleType.Card, BundleType.CondolenceVideo).length >= 2) {
          return this.goToSummary(BundleType.Card);
        }
        if (funeral.displayMoney) {
          return this.router.navigate([funeral.id, 'order', StepType.MoneyGift]);
        }
        summary = false;

      case BundleType.GiftMoney:
        if (summary && this.getBundlesOfType(BundleType.GiftMoney).length >= 2) {
          return this.goToSummary(BundleType.GiftMoney);
        }
        return this.goToSummary();
    }
    // tslint:enable
  }

  public async goToSummary(type?: BundleType): Promise<unknown> {
    const funeralId = this.storage.Funeral.funeralId;
    if (typeof type === 'undefined') {
      return this.router.navigate([funeralId, 'order', StepType.OrderConfirm]);
    }

    if (type === BundleType.CondolenceVideo) {
      type = BundleType.Card;
    }
    const stepType = this.bundleStepMap.get(type);
    return this.router.navigate([funeralId, 'order', stepType, 'summary']);
  }

  public async goToCustomerDetails(): Promise<void> {
    await this.router.navigate([this.storage.Funeral.funeralId, 'order', StepType.GuestBook]);
  }

  public async hasNextStep(currentStep: StepType = null): Promise<boolean> {
    let result = false;
    const funeral = await this.storage.FuneralModel;

    // tslint:disable:no-switch-case-fall-through
    // noinspection FallThroughInSwitchStatementJS
    switch (currentStep) {
      default:
        result = result || Boolean(funeral.displayFlower);

      case StepType.Flower:
        result = result || Boolean(funeral.displayCard) || Boolean(funeral.displayCondolenceVideos);

      case StepType.Card:
      case StepType.CondolenceVideo:
        result = result || Boolean(funeral.displayMoney);

      case StepType.MoneyGift:
    }
    // tslint:enable

    return result;
  }

  public removeBundle(bundle: Bundle): void {
    const index = this.items.indexOf(bundle);
    if (index > -1) {
      this.items.splice(index, 1);
      this.recalculateTotals();
      this.saveCart();
    }

    this.reset();
  }

  public async saveCardFromStorage(): Promise<void> {
    const bundle: Bundle = {
      type: BundleType.Card,
      item: await this.getBundleItemFromOrder(this.order.CardOrder),
      paper: await this.getBundleItemFromProduct(this.order.CardOrder.Paper),
    };
    if (this.order.CardPresent?.id) {
      bundle.present = await this.getBundleItemFromProduct(this.order.CardPresent);
    }

    this.saveBundle(bundle);

    const orderSteps = this.storage.OrderSteps;
    orderSteps.isCardStepCompleted = true;
    this.storage.OrderSteps = orderSteps;
  }

  public async saveCondolenceVideoFromStorage(): Promise<void> {
    const bundle: Bundle = {
      type: BundleType.CondolenceVideo,
      item: await this.getBundleItemFromOrder(this.order.CondolenceVideoOrder),
    };
    if (this.order.CondolenceVideoPresent?.id) {
      bundle.present = await this.getBundleItemFromProduct(this.order.CondolenceVideoPresent);
    }

    this.saveBundle(bundle);

    const orderSteps = this.storage.OrderSteps;
    orderSteps.isCardStepCompleted = true;
    this.storage.OrderSteps = orderSteps;
  }

  public async saveFlowerFromStorage(): Promise<void> {
    const bundle: Bundle = {
      type: BundleType.Flower,
      item: await this.getBundleItemFromOrder(this.order.FlowerOrder),
    };
    if (this.order.FlowerPresent?.id) {
      bundle.present = await this.getBundleItemFromProduct(this.order.FlowerPresent);
    }

    this.saveBundle(bundle);

    const orderSteps = this.storage.OrderSteps;
    orderSteps.isFlowerStepCompleted = true;
    this.storage.OrderSteps = orderSteps;
  }

  public async saveGiftMoneyFromStorage(): Promise<void> {
    const bundle: Bundle = {
      type: BundleType.GiftMoney,
      item: await this.getBundleItemFromOrder(this.order.MoneyGiftOrder),
    };
    if (this.order.MoneyPresent?.id) {
      bundle.present = await this.getBundleItemFromProduct(this.order.MoneyPresent);
    }
    if (this.order.MoneyFood?.id) {
      bundle.food = await this.getBundleItemFromProduct(this.order.MoneyFood);
    }

    this.saveBundle(bundle);

    const orderSteps = this.storage.OrderSteps;
    orderSteps.isMoneyGiftStepCompleted = true;
    this.storage.OrderSteps = orderSteps;
  }
  private async getBundleItemFromOrder<T extends FlowerOrder | CardOrder | MoneyGiftOrder | CondolenceVideoOrder>(
    item: T
  ): Promise<BundleItem<T>> {
    const attributes: ItemAttributeInput = {};

    if (item instanceof FlowerOrder) {
      attributes.flowerName = item?.flowerName;
      attributes.flowerRemark = item?.flowerRemark;
    } else if (item instanceof CardOrder) {
      attributes.cardMessage = item?.cardMessage;
      attributes.cardSenderFirstName = item?.cardSenderFirstName;
      attributes.cardSenderFirstNameKana = item?.cardSenderFirstNameKana;
      attributes.cardSenderLastName = item?.cardSenderLastName;
      attributes.cardSenderLastNameKana = item?.cardSenderLastNameKana;
      attributes.cardSenderCompany = item?.cardSenderCompany;
      attributes.cardSenderPosition = item?.cardSenderPosition;
      attributes.cardDestinationFirstName = item?.cardDestinationFirstName;
      attributes.cardDestinationLastName = item?.cardDestinationLastName;
      attributes.customName = item?.attributes?.customName;
      attributes.useMaidenName = item?.useMaidenName;
    } else if (item instanceof MoneyGiftOrder) {
      attributes.moneyGiftMessage = item?.attributes.moneyGiftMessage;
      attributes.moneyGiftRecipient = item?.attributes.moneyGiftRecipient;
      attributes.moneyGiftRelationship = item?.attributes.moneyGiftRelationship;
      attributes.moneyGiftYearsKnown = item?.attributes.moneyGiftYearsKnown;
      attributes.customName = item?.attributes.customName;
      attributes.useMaidenName = item?.useMaidenName;
    } else if (item instanceof CondolenceVideoOrder) {
      attributes.condolenceVideo = { accessLevel: item.AccessLevel, media: item.UploadedVideo };
      attributes.cardSenderFirstName = item?.SenderFirstName;
      attributes.cardSenderFirstNameKana = item?.SenderFirstNameKana;
      attributes.cardSenderLastName = item?.SenderLastName;
      attributes.cardSenderLastNameKana = item?.SenderLastNameKana;
      attributes.cardSenderCompany = item?.SenderCompany;
      attributes.cardSenderPosition = item?.SenderPosition;
      attributes.cardDestinationFirstName = item?.DestinationFirstName;
      attributes.cardDestinationLastName = item?.DestinationLastName;
      attributes.cardMessage = item.Comment;
      attributes.customName = item?.attributes?.customName;
      attributes.useMaidenName = item?.useMaidenName;
    }

    const product = await this.apiService.GetProduct(item.Product.id);
    if (product.allowCustomPrice) {
      product.price = item.Product.price;
    }

    return {
      cartItem: this.getCartItem(product, item.quantity, attributes),
      storageItem: item,
      product: product,
      totals: this.getItemTotals(product),
    };
  }

  private async getBundleItemFromProduct<T extends Present | Food>(item: T): Promise<BundleItem<T>> {
    const product = await this.apiService.GetProduct(item.id);
    return {
      cartItem: this.getCartItem(product, 1),
      storageItem: item,
      product: product,
      totals: this.getItemTotals(product),
    };
  }

  // noinspection JSMethodCanBeStatic
  private getCartItem(product: Product, qty: number = 1, attributes?: ItemAttributeInput): CreateCartItemInput {
    return {
      status: 'Active',
      index: 0,
      cartId: null,
      productId: product.id,
      quantity: qty,
      price: product.price,
      attributes: attributes,
    };
  }

  private getItemTotals(product: Product): Totals {
    const beforeDiscountExcludingTax = Math.floor(product.price);
    const afterDiscountExcludingTax = this.discountsService.getDiscountedPrice(
      beforeDiscountExcludingTax,
      product.productType
    );

    const afterDiscountIncludingTax = product.isTaxable
      ? Math.floor((afterDiscountExcludingTax / 100) * (100 + environment.taxRate))
      : afterDiscountExcludingTax;

    const result: Totals = {
      total: afterDiscountIncludingTax,
      tax: afterDiscountIncludingTax - afterDiscountExcludingTax,
    };
    if (afterDiscountExcludingTax !== beforeDiscountExcludingTax) {
      result.totalBeforeDiscount = beforeDiscountExcludingTax;
      result.discount = beforeDiscountExcludingTax - afterDiscountExcludingTax;
    }

    return result;
  }

  private saveBundle(bundle: Bundle): void {
    const index = sessionStorage.getItem(EDIT_BUNDLE_ID_STORAGE_KEY);
    if (index !== null) {
      this.items[Number(index)] = bundle;
    } else {
      this.items.push(bundle);
    }

    this.recalculateTotals();
    this.saveCart();
    this.reset(bundle.type);
  }

  private saveCart(): void {
    sessionStorage.setItem(CART_STORAGE_KEY, JSON.stringify(this.items));
  }

  private clearOrderStorage(): void {
    this.storage.Order = new Order();
  }

  private clearOrderItemStorage(type?: BundleType): void {
    if (typeof type === 'undefined' || type === BundleType.Card) {
      this.order.CardOrder = null;
      this.order.CardPresent = null;
    }

    if (typeof type === 'undefined' || type === BundleType.CondolenceVideo) {
      this.order.CondolenceVideoOrder = null;
      this.order.CondolenceVideoPresent = null;
    }

    if (typeof type === 'undefined' || type === BundleType.Flower) {
      this.order.FlowerOrder = null;
      this.order.FlowerPresent = null;
    }

    if (typeof type === 'undefined' || type === BundleType.GiftMoney) {
      this.order.MoneyGiftOrder = null;
      this.order.MoneyPresent = null;
      this.order.MoneyFood = null;
    }

    this.storage.saveOrder();
  }

  public getTotals(): Totals {
    return this.totals;
  }

  private recalculateTotals(): void {
    const totals = this.calculateTotals();
    Object.assign(this.totals, totals);
    for (const key of Object.keys(this.totals)) {
      if (!totals.hasOwnProperty(key)) {
        this.totals[key] = undefined;
      }
    }
    this.totalsChanges.emit(this.totals);
  }

  public calculateTotals(bundleTypes?: BundleType[]): Totals {
    let totalBeforeDiscountsIncludingTax = 0;
    let totalIncludingTax = 0;
    let totalTax = 0;

    const selectTypes = Array.isArray(bundleTypes) && bundleTypes.length > 0;
    for (const bundle of this.items) {
      let includeBundle = true;
      if (selectTypes && !bundleTypes.includes(bundle.type)) {
        includeBundle = false;
      }
      for (const key of Object.keys(bundle)) {
        if (typeof bundle[key].product !== 'undefined') {
          const itemTotals = this.getItemTotals(bundle[key].product);
          bundle[key].totals = itemTotals;

          if (includeBundle) {
            totalIncludingTax += itemTotals.total;
            totalTax += itemTotals.tax;
            totalBeforeDiscountsIncludingTax += itemTotals.totalBeforeDiscount ?? itemTotals.total;
          }
        }
      }
    }

    const totals: Totals = {
      total: totalIncludingTax,
      tax: totalTax,
    };
    if (totalBeforeDiscountsIncludingTax !== totalIncludingTax) {
      totals.totalBeforeDiscount = totalBeforeDiscountsIncludingTax;
      totals.discount = totalBeforeDiscountsIncludingTax - totalIncludingTax + totalTax;
    }

    return totals;
  }

  public async createCart(funeralId: string): Promise<string> {
    const storageOrder = this.storage.Order;
    const address = this.cleanAddress(storageOrder.CustomerAddress);

    let billingAddress;
    if (storageOrder.IsBillingDifferent) {
      billingAddress = this.cleanAddress(storageOrder.BillingAddress);
    }

    let shippingAddress;
    if (!storageOrder.IsDestinationSame) {
      shippingAddress = this.cleanAddress(storageOrder.ShippingAddress);
    }

    const cart = await this.apiService.CreateCart({ funeralId, address, billingAddress, shippingAddress });

    let index = 1;
    for (const bundle of this.items) {
      let customerAddress;
      if (
        (bundle.type === BundleType.Card ||
          bundle.type === BundleType.GiftMoney ||
          bundle.type === BundleType.CondolenceVideo) &&
        !bundle.item.storageItem.isCustomerAddressSame
      ) {
        customerAddress = this.cleanAddress(bundle.item.storageItem.CustomerAddress);
      }
      await this.createCartItem(bundle.item.cartItem, index, cart.id, customerAddress);

      let itemShippingAddress;
      if (typeof bundle.item.storageItem.IsDestinationSame === 'string') {
        itemShippingAddress = bundle.item.storageItem.IsDestinationSame;
      } else if (!bundle.item.storageItem.IsDestinationSame) {
        itemShippingAddress = this.cleanAddress(bundle.item.storageItem.ShippingAddress);
      }

      if (bundle.present) {
        await this.createCartItem(bundle.present.cartItem, index, cart.id, undefined, itemShippingAddress);
      }

      if (bundle.type === BundleType.Card && bundle.paper) {
        await this.createCartItem(bundle.paper.cartItem, index, cart.id);
      }

      if (bundle.type === BundleType.GiftMoney && bundle.food) {
        await this.createCartItem(bundle.food.cartItem, index, cart.id, undefined, itemShippingAddress);
      }

      index++;
    }

    return cart.id;
  }

  // noinspection JSMethodCanBeStatic
  private cleanAddress(address: object) {
    const result: AddressObjectInput = {};
    for (const property of [
      'index',
      'type',
      'postalCode',
      'country',
      'prefecture',
      'city',
      'street',
      'building',
      'company',
      'position',
      'telephone',
      'firstName',
      'firstNameKana',
      'lastName',
      'lastNameKana',
      'maidenName',
      'maidenNameKana',
      'email',
      'birthday',
    ]) {
      if (typeof address[property] !== 'undefined') {
        result[property] = address[property];
      }
    }
    return result;
  }

  private async createCartItem(
    item: CreateCartItemInput,
    index: number,
    cartId: string,
    customerAddress?: AddressObjectInput,
    shippingAddress?: AddressObjectInput | 'shipping' | 'customer'
  ) {
    item.index = index;
    item.cartId = cartId;
    if (typeof customerAddress !== 'undefined') {
      item.customerAddress = customerAddress;
    }
    if (typeof shippingAddress === 'string') {
      if (shippingAddress === 'shipping') {
        item.shippingAddressSameAs = AddressSameAs.OrderShippingAddress;
      } else if (shippingAddress === 'customer') {
        item.shippingAddressSameAs = AddressSameAs.OrderCustomerAddress;
      }
    } else if (typeof shippingAddress !== 'undefined') {
      item.shippingAddress = shippingAddress;
    }

    await this.apiService.CreateCartItem(item);
  }

  public isEmpty() {
    return this.items.length === 0;
  }

  public async emptyCartGuard() {
    if (!this.isEmpty()) {
      return;
    }

    const funeral = await this.storage.FuneralModel;
    if (funeral.displayFlower) {
      await this.router.navigate([funeral.id, 'order', StepType.Flower]);
    } else if (funeral.displayCard) {
      await this.router.navigate([funeral.id, 'order', StepType.Card]);
    } else if (funeral.displayMoney) {
      await this.router.navigate([funeral.id, 'order', StepType.MoneyGift]);
    }
  }

  public initStep(type: BundleType, nextStep: string) {
    if (this.isEdit()) {
      return;
    }

    this.currentBundleType = type;
    this.unlockedSteps.length = 0;
    this.unlockedSteps.push(nextStep);
    this.saveState();

    if (type === BundleType.Flower && !this.order.FlowerOrder) {
      this.order.FlowerOrder = new FlowerOrder();
      this.order.FlowerPresent = new Present();
    } else if (type === BundleType.Card && !this.order.CardOrder) {
      this.order.CardOrder = new CardOrder();
      this.order.CardPresent = new Present();
    } else if (type === BundleType.CondolenceVideo && !this.order.CondolenceVideoOrder) {
      this.order.CondolenceVideoOrder = new CondolenceVideoOrder();
      this.order.CondolenceVideoPresent = new Present();
    } else if (type === BundleType.GiftMoney && !this.order.MoneyGiftOrder) {
      this.order.MoneyGiftOrder = new MoneyGiftOrder();
      this.order.MoneyPresent = new Present();
      this.order.MoneyFood = new Food();
    }

    this.storage.saveOrder();
  }

  public resetCurrentStep() {
    this.currentBundleType = undefined;
    this.unlockedSteps.length = 0;
    this.saveState();
  }

  public checkStep(type: BundleType, step: string, nextStep?: string): Promise<unknown> {
    const isEdit = this.isEdit();
    if (this.currentBundleType === type && (isEdit || this.unlockedSteps.includes(step))) {
      if (!isEdit && typeof nextStep !== 'undefined') {
        this.unlockedSteps.push(nextStep);
        this.saveState();
      }
      return;
    }

    if (this.storage.OrderSteps.isOrderConfirmationActive) {
      return this.goToSummary();
    } else {
      return this.goToSummary(type);
    }
  }

  private loadState() {
    try {
      const state = JSON.parse(sessionStorage.getItem(CART_STATE_STORAGE_KEY));
      this.currentBundleType = state?.bundleType;
      this.unlockedSteps = state?.steps ?? [];
    } catch (e) {}
  }

  private saveState() {
    if (this.currentBundleType) {
      sessionStorage.setItem(
        CART_STATE_STORAGE_KEY,
        JSON.stringify({
          bundleType: this.currentBundleType,
          steps: this.unlockedSteps,
        })
      );
    } else {
      sessionStorage.removeItem(CART_STATE_STORAGE_KEY);
    }
  }
}
