import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalStorage } from 'src/app/global/storage';
import { MemorialType, StreamingType } from '@kokusai/smacere-shared/api';
import { Funeral } from 'src/models';
import { StreamStatusType } from 'src/types';
import { FuneralService } from 'src/services';
import { environment } from 'src/environments/environment';
import { DisplayElementsService } from 'src/app/services/display-elements.service';

@Component({
  selector: 'app-media-home',
  templateUrl: './media-home.component.html',
  styleUrls: ['./media-home.component.scss'],
})
export class MediaHomeComponent implements OnInit {
  environment = environment;
  Funeral: Funeral = {} as Funeral;
  funeralCloseDateTime: Date;
  MemorialType = MemorialType;
  IsFuneralStreamVisible: boolean = false;
  IsOvernightStreamVisible: boolean = false;
  streamStatus: { [key: string]: StreamStatusType } = {
    overnight: StreamStatusType.Wait,
    funeral: StreamStatusType.Wait,
  }; // ライブ配信中：live　、　配信開始前：wait　、　録画配信または、メモリアルムービー（スライドショー）：view
  IntervalId: any = null;

  StreamType = StreamingType;

  get IsMemoryAlbumVisible(): boolean {
    if (this.Funeral.displayMemorial === this.MemorialType.None) {
      return false;
    }

    switch (this.Funeral.displayMemorial) {
      case this.MemorialType.Slides:
        return this.Funeral.memorialPictures.length > 0;
      case this.MemorialType.Video:
        return this.Funeral.memorialMovie?.url != null;
    }
  }

  constructor(
    private storage: GlobalStorage,
    private funeralService: FuneralService,
    private router: Router,
    private displayElementsService: DisplayElementsService
  ) {
    this.Funeral = this.storage.Funeral;
    this.IsFuneralStreamVisible = this.Funeral.displayStreaming && this.Funeral.funeralDate != null;
    this.IsOvernightStreamVisible = this.Funeral.displayStreaming && this.Funeral.overnightFuneralDate != null;
  }

  async ngOnInit(): Promise<void> {
    this.initStreamStatus();

    this.funeralService.onFuneralUpdate(this.Funeral.funeralId).subscribe((funeral) => {
      this.storage.Funeral = funeral;
      this.Funeral = this.storage.Funeral;
      this.initStreamStatus();
    });
  }

  private isHaveArchive(type: StreamingType): boolean {
    return this.Funeral.streamingUrls.some((item) => item.type === type && !item.isDeleted);
  }

  private isOnline(type: StreamingType): boolean {
    return this.Funeral.streamingUrls.some((item) => item.type === type && item.isOnline);
  }

  private setStreamStatus(type: StreamingType): void {
    if (this.isOnline(type)) {
      this.streamStatus[type] = StreamStatusType.Live;
      return;
    }

    if (
      this.isHaveArchive(type) ||
      (type === StreamingType.funeral && this.Funeral.funeralRecording) ||
      (type === StreamingType.overnight && this.Funeral.overnightFuneralRecording)
    ) {
      this.streamStatus[type] = StreamStatusType.View;
      return;
    }

    this.streamStatus[type] = StreamStatusType.Wait;
  }

  private initStreamStatus(): void {
    if (this.IsOvernightStreamVisible) {
      this.setStreamStatus(StreamingType.overnight);
    }

    if (this.IsFuneralStreamVisible) {
      this.setStreamStatus(StreamingType.funeral);

      if (this.streamStatus[StreamingType.funeral] === StreamStatusType.Wait && !this.IsOvernightStreamVisible) {
        if (this.isHaveArchive(StreamingType.overnight)) {
          this.streamStatus[StreamingType.funeral] = StreamStatusType.View;
        }
      }
    }
  }

  get TimeOvernightWait(): string {
    const result =
      this.Funeral.overnightFuneralDate && this.Funeral.overnightFuneralStartTime
        ? this.GetWait(this.Funeral.overnightFuneralDate, this.Funeral.overnightFuneralStartTime)
        : null;
    return result;
  }

  get TimeFuneralWait(): string {
    const result =
      this.Funeral.funeralDate && this.Funeral.funeralStartTime
        ? this.GetWait(this.Funeral.funeralDate, this.Funeral.funeralStartTime)
        : null;
    return result;
  }

  private getDate(value: string): Date | null {
    if (value === null || value === undefined) {
      return null;
    }

    return new Date(value);
  }

  private setTime(date: Date, time: string): void {
    if (date === null || date === undefined || time === null || time === undefined) {
      return null;
    }
    const timeArray: string[] = time.split(':');
    const hour = Number.parseInt(timeArray[0], 10);
    const minute = Number.parseInt(timeArray[1], 10);
    date.setHours(hour, minute);
  }

  private GetWait(date: string, time: string): string {
    if (date === null || date === undefined) {
      throw new Error('date is missing');
    }

    if (time === null || time === undefined) {
      throw new Error('time is missing');
    }

    const now = new Date().getTime();
    const tempDate = this.getDate(date);
    this.setTime(tempDate, time);

    const overnightDateTime = tempDate.getTime();
    const distance = overnightDateTime - now;
    const hours: any = Math.floor(distance / (1000 * 60 * 60));
    if (hours > 0) {
      return `${hours}時`;
    } else {
      return '';
    }
  }

  get displayMemorialShare(): boolean {
    return this.displayElementsService.displayMemorialShare;
  }

  get displayCondolenceVideos(): boolean {
    return this.displayElementsService.displayCondolenceVideos;
  }

  goToStream(type: StreamingType): void {
    this.router.navigate([this.Funeral.funeralId, 'media', 'stream', type]);
  }

  goToMemorial(): void {
    this.router.navigate([this.Funeral.funeralId, 'media', 'memorial']);
  }

  goToMemorialShare(): void {
    this.router.navigate([this.Funeral.funeralId, 'memorial-share']);
  }

  goToCondolenceVideo(): void {
    this.router.navigate([this.Funeral.funeralId, 'media', 'condolence-video']);
  }
}
