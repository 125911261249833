import { Component, OnInit } from '@angular/core';
import { GlobalStorage } from 'src/app/global/storage';
import { Router } from '@angular/router';

export interface RouteInfo {
  step: string;
  routePaths: string[];
  pageTitle: string;
  pageTitleJp: string;
  displayRoute: boolean;
}

@Component({
  selector: 'app-order-header',
  templateUrl: './order-header.component.html',
  styleUrls: ['./order-header.component.scss'],
})
export class OrderHeaderComponent implements OnInit {
  public Items: RouteInfo[] = [];

  constructor(private route: Router, private storage: GlobalStorage) {}
  ngOnInit(): void {
    const routes: RouteInfo[] = [
      {
        step: 'guest-book',
        routePaths: ['guest-book'],
        pageTitle: 'ご会葬者様<br>情報入力',
        pageTitleJp: '情報入力',
        displayRoute: true,
      },
      {
        step: 'flowers',
        routePaths: ['flowers'],
        pageTitle: '供花',
        pageTitleJp: '供花',
        displayRoute: this.storage.Funeral.displayFlower,
      },
      {
        step: 'cards',
        routePaths: ['cards', 'condolence-video'],
        pageTitle: '弔電',
        pageTitleJp: '弔電',
        displayRoute: this.storage.Funeral.displayCard || this.storage.Funeral.displayCondolenceVideos,
      },
      {
        step: 'gift-money',
        routePaths: ['gift-money'],
        pageTitle: '香典',
        pageTitleJp: '香典',
        displayRoute: this.storage.Funeral.displayMoney,
      },
      {
        step: 'confirm',
        routePaths: ['confirm'],
        pageTitle: 'お申込み内容<br>確認画面',
        pageTitleJp: '確認画面',
        displayRoute: true,
      },
      {
        step: 'check-out',
        routePaths: ['check-out'],
        pageTitle: 'ご決済者様<br>情報入力',
        pageTitleJp: '情報入力',
        displayRoute: true,
      },
    ];

    this.Items = routes.filter((item) => item.displayRoute);
  }

  public IsActive(paths: string[]): boolean {
    return paths.some((value) => this.route.url.indexOf(value) !== -1);
  }

  public IsComplete(route: RouteInfo): boolean {
    const isNotCurrent = !this.IsActive(route.routePaths);
    switch (route.step) {
      case 'guest-book':
        return this.storage.OrderSteps.isGuestBookStepCompleted && isNotCurrent;
      case 'flowers':
        return this.storage.OrderSteps.isFlowerStepCompleted && isNotCurrent;
      case 'cards':
        return this.storage.OrderSteps.isCardStepCompleted && isNotCurrent;
      case 'gift-money':
        return this.storage.OrderSteps.isMoneyGiftStepCompleted && isNotCurrent;
      case 'confirm':
        return this.storage.OrderSteps.isOrderConfirmationCompleted && isNotCurrent;
    }
  }
}
