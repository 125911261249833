<div class="m-lg-4 m-2 edit-memorial-share-container">
  <div class="d-flex justify-content-center mb-4 mx-lg-5 mx-2">
    <img alt="Preview" [src]="images[imageIndex].thumbnail" class="preview-image" />
    <!-- <img *ngIf="!(media | isS3ObjectVideo); else video" alt="Preview" [src]="media | getS3Url" class="preview-image" />
    <ng-template #video>
      <video muted class="video-thumbnail" [src]="media | getS3Url"></video>
    </ng-template> -->
  </div>
  <form class="mx-lg-5 mx-2" #form="ngForm">
    <div class="mx-lg-3 mx-1 mb-2">
      <div class="form-row mb-2">
        <div class="col-lg-3 mb-2 mb-lg-0">
          {{ 'name' | i18n }}<span class="require">{{ 'required' | i18n }}</span>
        </div>
        <div class="col-lg mb-2 mb-lg-0">
          <input
            id="lastName"
            name="lastName"
            class="form-control"
            #lastNameRef="ngModel"
            [(ngModel)]="lastName"
            placeholder="姓"
            [class]="{ 'is-invalid': lastNameRef.invalid && (!lastNameRef.untouched || isSubmitted) }"
            required
          />
          <div
            class="validate-message text-danger"
            *ngIf="lastNameRef.errors?.required && (!lastNameRef.untouched || isSubmitted)"
          >
            {{ 'lastNameIsRequiredMsg' | i18n }}
          </div>
        </div>
        <div class="col-lg mb-2 mb-lg-0">
          <input
            id="firstName"
            name="firstName"
            class="form-control"
            #firstNameRef="ngModel"
            [(ngModel)]="firstName"
            placeholder="名"
            [class]="{ 'is-invalid': firstNameRef.invalid && (!firstNameRef.untouched || isSubmitted) }"
            required
          />
          <div
            class="validate-message text-danger"
            *ngIf="firstNameRef.errors?.required && (!firstNameRef.untouched || isSubmitted)"
          >
            {{ 'firstNameIsRequiredMsg' | i18n }}
          </div>
        </div>
      </div>
      <div class="form-row mb-2">
        <div class="col-lg-3 mb-2 mb-lg-0">
          {{ 'nameKana' | i18n }}<span class="require">{{ 'required' | i18n }}</span>
        </div>
        <div class="col-lg mb-2 mb-lg-0">
          <input
            id="lastNamekana"
            name="lastNamekana"
            class="form-control"
            pattern="(?=.*?[\u30A1-\u30FC])[\u30A1-\u30FC\s]*"
            #lastNameKanaRef="ngModel"
            [(ngModel)]="lastNameKana"
            placeholder=""
            [class]="{ 'is-invalid': lastNameKanaRef.invalid && (!lastNameKanaRef.untouched || isSubmitted) }"
            required
          />
          <div
            class="validate-message text-danger"
            *ngIf="lastNameKanaRef.errors?.required && (!lastNameKanaRef.untouched || isSubmitted)"
          >
            {{ 'lastNameKanaIsRequiredMsg' | i18n }}
          </div>
          <div
            class="validate-message text-danger"
            *ngIf="lastNameKanaRef.errors?.pattern && (!lastNameKanaRef.untouched || isSubmitted)"
          >
            {{ 'nameKanaPatternError' | i18n }}
          </div>
        </div>
        <div class="col-lg mb-2 mb-lg-0">
          <input
            id="firstNamekana"
            name="firstNamekana"
            class="form-control"
            pattern="(?=.*?[\u30A1-\u30FC])[\u30A1-\u30FC\s]*"
            #firstNameKanaRef="ngModel"
            [(ngModel)]="firstNameKana"
            placeholder=""
            [class]="{ 'is-invalid': firstNameKanaRef.invalid && (!firstNameKanaRef.untouched || isSubmitted) }"
            required
          />
          <div
            class="validate-message text-danger"
            *ngIf="firstNameKanaRef.errors?.required && (!firstNameKanaRef.untouched || isSubmitted)"
          >
            {{ 'firstNameKanaIsRequiredMsg' | i18n }}
          </div>
          <div
            class="validate-message text-danger"
            *ngIf="firstNameKanaRef.errors?.pattern && (!firstNameKanaRef.untouched || isSubmitted)"
          >
            {{ 'nameKanaPatternError' | i18n }}
          </div>
        </div>
      </div>
      <div class="form-row mb-4">
        <div class="col-lg-3 mb-2 mb-lg-0">{{ 'comment' | i18n }}</div>
        <div class="col-lg-9 mb-2 mb-lg-0">
          <textarea
            id="comment"
            name="comment"
            rows="5"
            class="form-control comment-input"
            placeholder=""
            [(ngModel)]="description"
          ></textarea>
        </div>
      </div>
    </div>
    <div class="form-row access-container mx-1">
      <div class="col-lg-4 text-lg-center text-left mb-lg-0 mb-1">{{ 'access' | i18n }} :</div>
      <div class="col-lg-8 form-group mb-0">
        <div class="form-check form-check-inline pr-1 pr-lg-5">
          <input
            class="form-check-input"
            id="access-level-edit-private"
            name="access-level-edit"
            type="radio"
            value="Private"
            [(ngModel)]="accessLevel"
          />
          <label for="access-level-edit-private" class="form-check-label">
            {{ 'privateAccess' | i18n }}
          </label>
        </div>
        <div class="form-check form-check-inline">
          <input
            class="form-check-input"
            id="access-level-edit-public"
            name="access-level-edit"
            type="radio"
            value="Public"
            [(ngModel)]="accessLevel"
          />
          <label for="access-level-edit-public" class="form-check-label">
            {{ 'publicAccess' | i18n }}
          </label>
        </div>
      </div>
    </div>
    <hr />
    <div class="row justify-content-between mx-lg-5 mx-2">
      <button
        id="return"
        name="return"
        type="button"
        class="button white-button col-lg-2 col-md-12 mb-2 mb-lg-0"
        (click)="closeModal()"
      >
        {{ 'return' | i18n }}
      </button>
      <button
        id="change"
        name="change"
        type="button"
        class="button grey-button col-lg-2 col-md-12 mb-2 mb-lg-0"
        (click)="change(form)"
      >
        {{ 'change' | i18n }}
      </button>
      <button
        id="delete"
        name="delete"
        type="button"
        class="button grey-button col-lg-2 col-md-12 mb-2 mb-lg-0"
        (click)="delete(imageIndex)"
      >
        {{ 'delete' | i18n }}
      </button>
    </div>
  </form>
</div>
