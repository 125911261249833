import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalStorage } from 'src/app/global/storage';
import { Location } from '@angular/common';
import { MoneyGiftOrder, Order } from 'src/models';
import { AddressType } from '@kokusai/smacere-shared/api';
import { BundleCartService, BundleType } from '../../../../services/bundle-cart.service';
import { AddressComponent } from '../../../../components/address/address.component';
import { ViewType } from 'src/app/components/address/types/view-type.type';

@Component({
  selector: 'app-money-gift-order',
  templateUrl: './money-gift-order.component.html',
  styleUrls: ['./money-gift-order.component.scss'],
})
export class MoneyGiftOrderComponent implements OnInit {
  MoneyGiftOrder: MoneyGiftOrder = null;
  AddressType = AddressType;
  isSubmit: boolean = false;
  public hasPresents: boolean = false;
  public isEdit: boolean = false;
  Order: Order;
  ViewType = ViewType;

  @ViewChild('customerForm') customerForm: AddressComponent;
  @ViewChild('shippingForm') shippingForm: AddressComponent;

  constructor(
    private route: Router,
    private storage: GlobalStorage,
    private location: Location,
    private bundleCartService: BundleCartService
  ) {}

  ngOnInit(): void {
    this.bundleCartService.checkStep(BundleType.GiftMoney, 'order', 'present');

    const isMoneyGiftOrderCustomerAddress = Object.values(
      this.storage.Order.MoneyGiftOrder.CustomerAddress
    ).some((item) => Boolean(item));
    if (isMoneyGiftOrderCustomerAddress) {
      this.MoneyGiftOrder = {} as MoneyGiftOrder;
      this.MoneyGiftOrder.CustomerAddress = { ...this.storage.Order.MoneyGiftOrder.CustomerAddress };
    } else {
      this.storage.Order.MoneyGiftOrder.CustomerAddress = { ...this.storage.Order.CustomerAddress };
    }

    const isMoneyGiftOrderShippingAddress = Object.values(
      this.storage.Order.MoneyGiftOrder.ShippingAddress
    ).some((item) => Boolean(item));
    const isOrderShippingAddress = Object.values(this.storage.Order.ShippingAddress).some((item) => Boolean(item));
    if (isMoneyGiftOrderShippingAddress) {
      this.MoneyGiftOrder = {} as MoneyGiftOrder;
      this.MoneyGiftOrder.ShippingAddress = { ...this.storage.Order.MoneyGiftOrder.ShippingAddress };
    } else if (isOrderShippingAddress) {
      this.storage.Order.MoneyGiftOrder.ShippingAddress = { ...this.storage.Order.ShippingAddress };
    } else {
      this.storage.Order.MoneyGiftOrder.ShippingAddress = { ...this.storage.Order.CustomerAddress };
    }

    this.MoneyGiftOrder = this.storage.Order.MoneyGiftOrder;
    this.isEdit = this.bundleCartService.isEdit();
    this.hasPresents = this.storage.Funeral.displayMoneyPresent || this.storage.Funeral.displayFood;
    this.Order = this.storage.Order;
  }

  capitalize(value: string) {
    return value[0].toUpperCase() + value.slice(1);
  }

  private saveToStorage(): boolean {
    this.isSubmit = true;
    // validate customer form
    if (!this.MoneyGiftOrder.isCustomerAddressSame && this.customerForm.userForm.invalid) {
      for (const [key, control] of Object.entries(this.customerForm.userForm.controls)) {
        if (control.invalid) {
          document.getElementById(`customer${this.capitalize(key)}`).focus();
          return false;
        }
      }
      return false;
    }

    for (const [key, control] of Object.entries(this.customerForm.userForm.controls)) {
      this.MoneyGiftOrder.CustomerAddress[key] = key === 'postalCode' ? control.value.postalCode : control.value;
      if (key === 'customName') {
        this.MoneyGiftOrder.attributes.customName = control.value;
      }
    }

    // validate shipping form
    if (!this.MoneyGiftOrder.IsDestinationSame && this.shippingForm.userForm.invalid) {
      for (const [key, control] of Object.entries(this.shippingForm.userForm.controls)) {
        if (control.invalid) {
          document.getElementById(`shipping${this.capitalize(key)}`).focus();
          return false;
        }
      }
      return false;
    }

    for (const [key, control] of Object.entries(this.shippingForm.userForm.controls)) {
      this.MoneyGiftOrder.ShippingAddress[key] = key === 'postalCode' ? control.value.postalCode : control.value;
    }

    const order = this.storage.Order;
    order.MoneyGiftOrder = this.MoneyGiftOrder;
    this.storage.Order = order;

    return true;
  }

  public async addAction() {
    if (this.saveToStorage()) {
      await this.bundleCartService.saveGiftMoneyFromStorage();
      await this.bundleCartService.goToAdd(BundleType.GiftMoney);
    }
  }

  public async nextAction() {
    if (!this.saveToStorage()) {
      return;
    }

    if (this.hasPresents) {
      await this.route.navigate([this.storage.Funeral.funeralId, 'order', 'gift-money', 'presents']);
    } else {
      await this.bundleCartService.saveGiftMoneyFromStorage();
      await this.bundleCartService.goToNextStep(BundleType.GiftMoney);
    }
  }

  back(): void {
    this.location.back();
  }

  handleIsCustomerAddressSame(event) {
    if (!event) {
      this.MoneyGiftOrder.useMaidenName = false;
      this.customerForm.useMaidenName = false;
    }
  }

  handleUseMaidenNameChange(useMaidenName: boolean) {
    if (this.Order.BillingAddress.maidenName) {
      this.customerForm.useMaidenName = useMaidenName;
    }
  }
}
