<div class="modal-header no-border">
  <a href="javascript:void(0);" class="modal-close-icon" (click)="activeModal.close()"></a>
  <h4 class="modal-title" id="modal-basic-title">
    {{ 'chooseTemplate' | i18n }}
  </h4>
</div>
<div class="card-message-body modal-body">
  <!-- form -->
  <div class="row justify-content-center">
    <!-- relationship with deceased -->
    <div class="row col-12 mb-1">
      <label for="groups" class="title-label col-lg-5 col-md-6">{{ 'relationshipWithDeceased' | i18n }}</label>
      <div class="col-lg-7 col-md-6">
        <ng-select
          id="groups"
          name="groups"
          [items]="groups"
          (change)="setTitles()"
          [(ngModel)]="selectedGroup"
        ></ng-select>
      </div>
    </div>
    <!-- title -->
    <div class="row col-12 mb-1">
      <label for="titles" class="title-label col-lg-5 col-md-6">{{ 'title' | i18n }}</label>
      <div class="col-lg-7 col-md-6">
        <ng-select
          id="titles"
          name="titles"
          [items]="titles"
          (change)="setPhrases()"
          notFoundText="{{ 'titleNotFound' | i18n }}"
          [(ngModel)]="selectedTitle"
        ></ng-select>
      </div>
    </div>
  </div>
  <!-- card cardMessages list -->
  <ul class="card-message-list list-unstyled">
    <li *ngFor="let cardMessage of cardMessages" class="row align-items-center">
      <div class="col-12 col-md-9 card-message-text font-mincho">
        {{ cardMessage.message }}
      </div>
      <div class="col-12 col-md-3">
        <button
          id="choose-button"
          type="button"
          class="btn btn-outline-primary col-12 side-round-button"
          [disabled]="!selectedTitle"
          (click)="activeModal.close(cardMessage.message)"
        >
          {{ 'chooseButton' | i18n }}
        </button>
      </div>
    </li>
  </ul>
</div>
