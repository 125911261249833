<div class="order-header pc-window">
  <ul class="steps-{{ Items.length }}">
    <li
      *ngFor="let item of Items"
      [class]="{
        active: IsActive(item.routePaths),
        complete: IsComplete(item)
      }"
      [innerHTML]="item.pageTitle"
    ></li>
  </ul>
</div>

<div class="order-header sp-window">
  <ul class="steps-{{ Items.length }}">
    <li
      *ngFor="let item of Items"
      [class]="{
        active: IsActive(item.routePaths),
        complete: IsComplete(item)
      }"
    >
      <span>{{ item.pageTitleJp }}</span>
    </li>
  </ul>
</div>
