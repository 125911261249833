import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Guide } from 'src/models';
import { NguCarouselConfig } from '@ngu/carousel';

@Component({
  selector: 'paypal-guide',
  templateUrl: './paypal-guide.component.html',
  styleUrls: ['./paypal-guide.component.scss'],
})
export class PayPalGuideComponent implements OnInit {
  public guides: Guide[] = [];
  public carouselTile: NguCarouselConfig = {
    grid: { xs: 1, sm: 2, md: 3, lg: 4, all: 0 },
    slide: 3,
    speed: 250,
    point: {
      visible: true,
    },
    load: 2,
    velocity: 0,
    touch: true,
    easing: 'cubic-bezier(0, 0, 0.2, 1)',
  };

  constructor(private modalService: NgbModal) {}

  public ngOnInit(): void {
    this.guides = [
      {
        step: 1,
        title: '「デビットカードまたはクレジットカード」ボタンをクリックしてください。',
        imgPath: '/assets/img/paypal_steps/paypal_step_1.jpg',
      },
      {
        step: 2,
        title: '少々お待ちください。',
        imgPath: '/assets/img/paypal_steps/paypal_step_2.jpg',
      },
      {
        step: 3,
        title:
          'ペイパル画面が 日本語表示になります。 "<span class="text-danger">カード</span>"をクリックしてください。',
        imgPath: '/assets/img/paypal_steps/paypal_step_6.jpg',
      },
      {
        step: 4,
        title: 'クレジットカード番号、 有効期限、 セキュリティコードを入力してください。',
        imgPath: '/assets/img/paypal_steps/paypal_step_7.jpg',
      },
      {
        step: 5,
        title: 'カード名義人様の 氏名、住所に 間違いがないことを 確認してください。',
        imgPath: '/assets/img/paypal_steps/paypal_step_8.jpg',
      },
      {
        step: 6,
        title: '連絡先情報の電話番号、 メールアドレスに 間違いがないことを確認してください。',
        imgPath: '/assets/img/paypal_steps/paypal_step_9.jpg',
      },
      {
        step: 7,
        title:
          'ペイパルのアカウントを作成しない場合は、図の赤枠内にチェックを入れて 「続行」ボタンをクリックしてください。',
        imgPath: '/assets/img/paypal_steps/paypal_step_10.jpg',
      },
    ];
  }

  public open(content: any) {
    this.modalService
      .open(content, {
        size: 'lg',
      })
      .result.then((result) => {});
  }
}
