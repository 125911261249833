/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  "aws_project_region": "ap-northeast-1",
  "aws_appsync_graphqlEndpoint": "https://k3epnq6lerdblhax56wy5rronu.appsync-api.ap-northeast-1.amazonaws.com/graphql",
  "aws_appsync_region": "ap-northeast-1",
  "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
  "aws_appsync_apiKey": "da2-7gv32gbwrzfoddybvtgn4rkrza",
  "aws_cloud_logic_custom": [
    {
      "name": "PaymentApi",
      "endpoint": "https://q5syx0fhi1.execute-api.ap-northeast-1.amazonaws.com/dev",
      "region": "ap-northeast-1"
    },
    {
      "name": "BusinessCard",
      "endpoint": "https://7gmwhbxdm9.execute-api.ap-northeast-1.amazonaws.com/dev",
      "region": "ap-northeast-1"
    }
  ],
  "aws_cognito_identity_pool_id": "ap-northeast-1:8f003d11-cf53-491b-bbd1-2a03b56eb65c",
  "aws_cognito_region": "ap-northeast-1",
  "aws_user_pools_id": "ap-northeast-1_BekaATciF",
  "aws_user_pools_web_client_id": "442jea20lkmbth7dfns121tokv",
  "oauth": {},
  "aws_user_files_s3_bucket": "sougiadminstoragedev145938-dev",
  "aws_user_files_s3_bucket_region": "ap-northeast-1"
};

export default awsmobile;
