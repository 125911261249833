import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SetFontSizeService {
  maxFont = 60;
  constructor() {}

  setFontSize(name: string, prefix: string, suffix: string, postfix: string, viewWidth: number, ratio: number) {
    viewWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);

    if (viewWidth < 768) {
      const fullNameLength: number = name.length + prefix.length + suffix.length - 1;
      let fontSize: number;
      if (fullNameLength < 10) {
        fontSize = 7.7;
      } else if (fullNameLength >= 10 && fullNameLength <= 12) {
        fontSize = 5.8;
      } else if (fullNameLength >= 13 && fullNameLength <= 15) {
        fontSize = 6;
      } else if (fullNameLength >= 16) {
        fontSize = 6;
      }

      const size = (fontSize * viewWidth) / 100;
      return size <= this.maxFont ? size : this.maxFont;
    } else {
      return this.setWebFontSize(name, prefix, suffix, postfix, viewWidth, ratio);
    }
  }

  setWebFontSize(name: string, prefix: string, suffix: string, postfix: string, viewWidth: number, ratio: number) {
    const fullNameLength = name.length + prefix.length + suffix.length + postfix.length - 1;
    let fontSize: number;
    let size: number;
    if (fullNameLength < 10) {
      fontSize = 5.2;
    } else if (fullNameLength >= 10 && fullNameLength <= 12) {
      fontSize = 3.9;
    } else if (fullNameLength >= 13 && fullNameLength <= 15) {
      fontSize = 3.5;
    } else if (fullNameLength >= 16) {
      fontSize = 3.5;
    }

    viewWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
    size = (fontSize * viewWidth) / 100;
    if (viewWidth >= 768) {
      return size <= this.maxFont ? size : this.maxFont;
    } else {
      return size * ratio <= this.maxFont ? size * ratio : this.maxFont;
    }
  }
}
