<div class="bg-grey pt-1">
  <div class="header-container">
    <h4 class="header font-mincho">供花お申し込み内容確認画面</h4>
  </div>
  <div class="container mt-4 pb-4">
    <app-cart-summary [section]="SummarySection.Flower"></app-cart-summary>
  </div>
</div>

<!-- buttons -->
<div class="buttons-container">
  <button id="return-button" class="nav-button return-button" (click)="back()">
    {{ 'return' | i18n }}
  </button>
  <button id="next-button" class="nav-button next-button" (click)="next()">
    {{ 'decideAndNext' | i18n }}
  </button>
</div>
<!-- 
<div class="container">
  <div class="btn-toolbar justify-content-between mt-5 mb-5" role="toolbar">
    <button id="gift-money-select-return-button" class="btn btn-outline-secondary" (click)="back()">
      {{ 'return' | i18n }}
    </button>
    <button id="gift-money-select-decide-and-next-button" class="btn btn-secondary" (click)="next()">
      {{ 'decideAndNext' | i18n }}
    </button>
  </div>
</div> -->
