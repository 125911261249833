import { MediaObjectInput } from '@kokusai/smacere-shared/api';

export class Product {
  constructor(params: Product = {} as Product) {
    let { id = '', sku = '', name = '', picture = {}, price = 0 } = params; //Default value;

    this.id = id;
    this.sku = sku;
    this.name = name;
    this.picture = picture;
    this.price = price;
  }

  id: string;
  sku: string;
  name: string;
  picture: MediaObjectInput;
  price: number;
}
