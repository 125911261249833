<div class="presents-container">
  <h4 class="text-center" *ngIf="IsCorporate">
    <br />
    <b>{{ 'presentDeliveryWarning' | i18n }}</b>
  </h4>
  <div id="mone-present-header" *ngIf="DisplayMoneyPresent">
    <h4 class="page-header">{{ 'offeringPresent' | i18n }}</h4>
    <h5 class="text-center">
      {{ 'moneyGiftSelectionMsg' | i18n }}
    </h5>
    <h5 class="text-center">
      <u>{{ 'shippingWarning' | i18n }}</u>
    </h5>
    <h5 class="text-center bold">
      <b
        ><u>{{ 'moneyGiftSelectMsgWarning' | i18n }}</u></b
      >
    </h5>
  </div>
  <div id="money-present-body" *ngIf="DisplayMoneyPresent">
    <app-gift-money-present [Sum]="getMoneyGiftAmount" [(ngModel)]="selectedMoneyPresent"></app-gift-money-present>
  </div>
  <div id="money-food-header" *ngIf="DisplayFood" class="mt-5" [hidden]="!HasFood">
    <h4 class="page-header">{{ 'moneyFood' | i18n }}</h4>
    <h5 class="text-center">
      {{ 'moneyGiftFoodSelectionMsg' | i18n }}
    </h5>
  </div>
  <div id="money-present-body" *ngIf="DisplayFood" [hidden]="!HasFood">
    <app-gift-money-food
      (onChanged)="OnFoodSelectionChanged($event)"
      [(ngModel)]="selectedFood"
      [sum]="getMoneyGiftAmount"
    ></app-gift-money-food>
  </div>
</div>

<!-- buttons -->
<div class="buttons-container">
  <button id="return-button" class="nav-button return-button" (click)="back()">
    {{ 'return' | i18n }}
  </button>
  <button id="add-button" class="nav-button add-button" (click)="addAction()" *ngIf="!isEdit">
    {{ 'addNewOrder' | i18n }}
  </button>
  <button id="next-button" class="nav-button next-button" (click)="saveAction()">
    {{ 'decideAndNext' | i18n }}
  </button>
</div>
<!-- <div class="container">
  <div class="btn-toolbar justify-content-between mt-5 mb-5" role="toolbar">
    <button id="gift-money-select-return-button" class="btn btn-outline-secondary" (click)="back()">
      {{ 'return' | i18n }}
    </button>
    <button id="gift-money-select-add-button" class="btn btn-secondary" (click)="addAction()" *ngIf="!isEdit">
      {{ 'addNewOrder' | i18n }}
    </button>
    <button id="gift-money-select-decide-and-next-button" class="btn btn-secondary" (click)="saveAction()">
      {{ 'decideAndNext' | i18n }}
    </button>
  </div>
</div> -->
