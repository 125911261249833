<ul class="order-list">
  <!-- FlowerOrder -->
  <li *ngFor="let section of displaySections">
    <div class="card">
      <div class="card-body">
        <div class="row">
          <!-- Left sidebar -->
          <div class="col-md-1 p-md-0">
            <div class="right-line py-2 px-sm-2 font-weight-bold title-border-bottom-mobile" [ngSwitch]="section">
              <ng-template [ngSwitchCase]="SummarySection.Flower">{{ 'flowerOffering' | i18n }}</ng-template>
              <ng-template [ngSwitchCase]="SummarySection.Card">{{ 'condolence' | i18n }}</ng-template>
              <ng-template [ngSwitchCase]="SummarySection.GiftMoney">{{ 'incense' | i18n }}</ng-template>
            </div>
          </div>

          <!-- Products list -->
          <div class="col-md-11">
            <ng-container *ngFor="let bundle of getBundles(section)">
              <!-- Main item details -->
              <div class="row m-md-3 pb-md-3">
                <div class="col-md-3 mb-2 mb-md-0">
                  <div [ngSwitch]="bundle.type" class="pb-2 font-weight-bold">
                    <ng-template [ngSwitchCase]="BundleType.Flower">{{ 'flowerOffering' | i18n }}</ng-template>
                    <ng-template [ngSwitchCase]="BundleType.Card">{{ 'materialType' | i18n }}</ng-template>
                    <ng-template [ngSwitchCase]="BundleType.GiftMoney">{{ 'incense' | i18n }}</ng-template>
                  </div>
                  <div class="item-image">
                    <img
                      *ngIf="bundle.type !== BundleType.GiftMoney"
                      vtMedia
                      [object]="bundle.item.product.picture"
                      class="img-fluid"
                    />
                    <img
                      *ngIf="bundle.type === BundleType.GiftMoney"
                      src="{{ 'kouden_manner.png' | path }}"
                      class="img-fluid"
                    />
                  </div>
                </div>
                <div class="col-md-6 mb-2 mb-md-0">
                  <ul class="list-unstyled ml-3">
                    <li *ngIf="bundle.type !== BundleType.GiftMoney" class="row mb-4 font-weight-bold mb-2">
                      <div class="col-4">{{ 'flowerOrderProductName' | i18n }}</div>
                      <div class="col-8" [ngSwitch]="bundle.type">
                        <ng-template [ngSwitchCase]="BundleType.Flower">
                          {{ bundle.item.product.name }}
                        </ng-template>
                        <ng-template [ngSwitchCase]="BundleType.Card">
                          {{ bundle.item.product.attributes.cardMaterialType }}{{ bundle.item.product.name }} ({{
                            bundle.item.product.nameKana
                          }}){{ bundle.item.product.attributes.cardOpenSide }}
                        </ng-template>
                        <ng-template [ngSwitchCase]="BundleType.CondolenceVideo">
                          {{ bundle.item.product.name }}
                        </ng-template>
                      </div>
                    </li>
                    <li
                      *ngIf="bundle.type === BundleType.GiftMoney"
                      class="row mb-4 font-weight-bold item-data-row mb-2"
                    >
                      <div class="col-4 p-md-0">{{ 'moneyGiftTotalAmount' | i18n }}</div>
                      <div class="col-8">{{ bundle.item.cartItem.price | number }}円</div>
                    </li>
                    <li *ngIf="bundle.type === BundleType.Flower" class="row item-data-row mb-2 ml-0">
                      <div class="col-4 p-md-0">{{ 'billingName' | i18n }}</div>
                      <div class="col-8 white-space-pre-wrap">
                        {{ bundle.item.cartItem.attributes.flowerName }}
                      </div>
                    </li>
                    <li *ngIf="bundle.type === BundleType.Flower" class="row item-data-row mb-2 ml-0">
                      <div class="col-4 p-md-0">{{ 'orderRemarks' | i18n }}</div>
                      <div class="col-8 white-space-pre-wrap">
                        {{ bundle.item.cartItem.attributes.flowerRemark }}
                      </div>
                    </li>
                    <li *ngIf="bundle.type === BundleType.GiftMoney" class="row item-data-row mb-2">
                      <div class="col-4 p-md-0">{{ 'moneyGiftRecipient' | i18n }}</div>
                      <div class="col-8 white-space-pre-wrap">
                        {{ bundle.item.cartItem.attributes.moneyGiftRecipient | i18n }}
                      </div>
                    </li>
                    <li *ngIf="bundle.type === BundleType.GiftMoney" class="row item-data-row mb-2">
                      <div class="col-4 p-md-0 font-small">{{ 'moneyGiftRelationship' | i18n }}</div>
                      <div class="col-8 white-space-pre-wrap">
                        {{ bundle.item.cartItem.attributes.moneyGiftRelationship }}
                      </div>
                    </li>
                    <li *ngIf="bundle.type === BundleType.GiftMoney" class="row item-data-row mb-2">
                      <div class="col-4 p-md-0">{{ 'moneyGiftYearsKnown' | i18n }}</div>
                      <div class="col-8 white-space-pre-wrap">
                        {{ bundle.item.cartItem.attributes.moneyGiftYearsKnown }}
                        <span>年</span>
                      </div>
                    </li>
                    <li *ngIf="bundle.type === BundleType.GiftMoney" class="row item-data-row mb-2">
                      <div class="col-4 p-md-0">{{ 'moneyGiftMessage' | i18n }}</div>
                      <div class="col-8 white-space-pre-wrap">
                        {{ bundle.item.cartItem.attributes.moneyGiftMessage }}
                      </div>
                    </li>
                  </ul>
                </div>

                <!-- Main item actions -->
                <div class="col-md-3 mb-2 mb-md-0 position-relative">
                  <button
                    id="flower-order-modify-button"
                    class="btn btn-outline-secondary col-12 col-lg-5 mb-2 mr-2"
                    (click)="editBundle(bundle, ScreenType.Select)"
                  >
                    {{ 'flowerOrderModifyButton' | i18n }}
                  </button>
                  <button
                    id="flower-order-remove-button"
                    class="btn btn-outline-secondary col-12 col-lg-6 mb-2"
                    (click)="removeBundle(bundle)"
                  >
                    {{ 'flowerOrderRemoveButton' | i18n }}
                  </button>
                </div>
              </div>

              <!-- Main item value and tax -->
              <div
                class="sub-order-item d-flex flex-direction-column justify-content-end font-weight-bold item-data-row"
              >
                <div class="col-xl-3 col-lg-4 col-md-5 col-sm-6">
                  <ng-container *ngIf="bundle.item.product.isTaxable; else noTax">
                    <div class="row item-data-row sub-order-sum">
                      <div class="col-4 p-0">{{ 'flowerOrderSum' | i18n }}</div>
                      <div class="col-8 text-right p-0">{{ bundle.item.totals.total | number }}円</div>
                    </div>
                    <!-- product price -->
                    <div class="row item-data-row tax-amount" *ngIf="bundle.item.totals.totalBeforeDiscount">
                      <div class="col-4 p-0">{{ 'productPrice' | i18n }}</div>
                      <div class="col-8 text-right p-0">{{ bundle.item.totals.totalBeforeDiscount | number }}円</div>
                    </div>
                    <!-- discount amount -->
                    <div class="row item-data-row tax-amount" *ngIf="bundle.item.totals.discount">
                      <div class="col-4 p-0">{{ 'discountAmount' | i18n }}</div>
                      <div class="col-8 text-right p-0">{{ bundle.item.totals.discount | number }}円</div>
                    </div>
                    <!-- tax amount -->
                    <div class="row item-data-row tax-amount">
                      <div class="col-4 p-0">{{ 'flowerOrderTax' | i18n }}</div>
                      <div class="col-8 text-right p-0">{{ bundle.item.totals.tax | number }}円</div>
                    </div>
                  </ng-container>
                  <ng-template #noTax>
                    <div class="row">
                      <div class="col-4 p-0">{{ 'flowerOrderSum' | i18n }}</div>
                      <div class="col-8 text-right p-0">{{ bundle.item.cartItem.price | number }}円</div>
                    </div>
                    <div class="row p-0">
                      <div class="col-12 text-right p-0">
                        <span class="font-xx-small">{{ 'noConsumptionTaxOnTheIncense' | i18n }}</span>
                      </div>
                    </div>
                  </ng-template>
                </div>
              </div>

              <!-- CardOrder: Paper -->
              <div *ngIf="bundle.paper" class="row m-md-3 pb-md-3 sub-order-item">
                <div class="col-md-3 mb-2 mb-md-0">
                  <div class="pb-2 font-weight-bold">{{ 'paperName' | i18n }}</div>
                  <div class="item-image">
                    <img vtMedia [object]="bundle.paper.product.picture" class="img-fluid" />
                  </div>
                </div>
                <div class="col-md-6 mb-2 mb-md-0">
                  <ul class="list-unstyled">
                    <li class="row mb-4 font-weight-bold">
                      <div class="col-4">{{ 'flowerOrderProductName' | i18n }}</div>
                      <div class="col-8">
                        {{ bundle.paper.product.name }}
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="col-md-3 mb-2 mb-md-0">
                  <button
                    id="flower-order-modify-button-4"
                    class="btn btn-outline-secondary col-12 col-lg-5 mb-2 mr-2"
                    (click)="editBundle(bundle, ScreenType.Select)"
                  >
                    {{ 'flowerOrderModifyButton' | i18n }}
                  </button>
                </div>
              </div>

              <!-- Present -->
              <div *ngIf="bundle.present" class="row m-md-3 pb-md-5 sub-order-item">
                <div class="col-md-3 mb-2 mb-md-0">
                  <div class="pb-2 font-weight-bold" [ngSwitch]="bundle.type">
                    <ng-template [ngSwitchCase]="BundleType.Flower">{{ 'flowerOrderPresent' | i18n }}</ng-template>
                    <ng-template [ngSwitchCase]="BundleType.Card">{{ 'condolencePresent' | i18n }}</ng-template>
                    <ng-template [ngSwitchCase]="BundleType.GiftMoney">{{ 'moneyPresent' | i18n }}</ng-template>
                  </div>
                  <div class="item-image">
                    <img vtMedia [object]="bundle.present.product.picture" class="img-fluid" />
                  </div>
                </div>
                <div class="col-md-6 mb-2 mb-md-0">
                  <ul class="list-unstyled">
                    <li class="row mb-4 font-weight-bold item-data-row">
                      <div class="col-4 pl-3">{{ 'flowerOrderPresentName' | i18n }}</div>
                      <div class="col-8">
                        {{ bundle.present.product.name }}
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="col-md-3 mb-2 mb-md-0">
                  <button
                    id="flower-order-modify-button-2"
                    class="btn btn-outline-secondary col-12 col-lg-5 mb-2 mr-2"
                    (click)="editBundle(bundle, ScreenType.Present)"
                  >
                    {{ 'flowerOrderModifyButton' | i18n }}
                  </button>
                </div>
              </div>

              <!-- Food -->
              <div *ngIf="bundle.food" class="row m-md-3 pb-md-5 sub-order-item">
                <div class="col-md-3 mb-2 mb-md-0">
                  <div class="p-2 p-md-0">{{ 'moneyFood' | i18n }}</div>
                  <div class="item-image">
                    <img vtMedia [object]="bundle.food.product.picture" class="img-fluid" />
                  </div>
                </div>
                <div class="col-md-6 mb-2 mb-md-0">
                  <ul class="list-unstyled">
                    <li class="row mb-4 font-weight-bold item-data-row">
                      <div class="col-4 pl-3">{{ 'flowerOrderPresentName' | i18n }}</div>
                      <div class="col-8">
                        {{ bundle.food.product.name }}
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="col-md-3 mb-2 mb-md-0">
                  <button
                    class="btn btn-outline-secondary col-12 col-lg-5 mr-2 mb-2"
                    (click)="editBundle(bundle, ScreenType.Present)"
                  >
                    {{ 'flowerOrderModifyButton' | i18n }}
                  </button>
                </div>
              </div>

              <!-- Card Customer address -->
              <div *ngIf="bundle.type === BundleType.Card" class="row m-md-3 pb-md-5 sub-order-item">
                <div class="col-md-9">
                  <!-- cardDestinationName -->
                  <div class="row mb-2">
                    <div class="col-md-4">{{ 'destinationName' | i18n }}</div>
                    <div class="col-md-8">
                      {{
                        bundle.item.storageItem.cardDestinationLastName +
                          ' ' +
                          bundle.item.storageItem.cardDestinationFirstName
                      }}
                    </div>
                  </div>
                  <!-- cardMessage -->
                  <div class="row mb-4">
                    <div class="col-md-4">{{ 'funeralAddressInput' | i18n }}</div>
                    <div class="col-md-8">{{ bundle.item.storageItem.cardMessage }}</div>
                  </div>

                  <ng-container *ngIf="!bundle.item.storageItem.isCustomerAddressSame; else defaultCustomerAddress">
                    <!-- name -->
                    <div class="row mb-2">
                      <div class="col-md-4">{{ 'cardName' | i18n }}</div>
                      <div class="col-md-8" *ngIf="!bundle.item.storageItem.useMaidenName; else useMaidenName">
                        {{ bundle.item.storageItem.CustomerAddress | fullName }}
                      </div>
                      <ng-template #useMaidenName>
                        <div class="col-md-8">
                          {{
                            bundle.item.storageItem.CustomerAddress?.maidenName +
                              ' ' +
                              bundle.item.storageItem.CustomerAddress?.firstName
                          }}
                        </div>
                      </ng-template>
                    </div>
                    <!-- customName -->
                    <div class="row mb-2">
                      <div class="col-md-4">{{ 'customName' | i18n }}</div>
                      <!-- TODO: this is not present in the Address structure available here -->
                      <div class="col-md-8">{{ bundle.item.storageItem.attributes.customName }}</div>
                    </div>
                    <!-- company -->
                    <div class="row mb-2">
                      <div class="col-md-4">{{ 'companyName' | i18n }}</div>
                      <div class="col-md-8">{{ bundle.item.storageItem.CustomerAddress.company }}</div>
                    </div>
                    <!-- position -->
                    <div class="row mb-2">
                      <div class="col-md-4">{{ 'senderPosition' | i18n }}</div>
                      <div class="col-md-8">{{ bundle.item.storageItem.CustomerAddress.position }}</div>
                    </div>
                  </ng-container>
                  <ng-template #defaultCustomerAddress>
                    <!-- name -->
                    <div class="row mb-2">
                      <div class="col-md-4">{{ 'cardName' | i18n }}</div>
                      <div class="col-md-8" *ngIf="!bundle.item.storageItem.useMaidenName; else useMaidenName">
                        {{ Order.CustomerAddress | fullName }}
                      </div>
                      <ng-template #useMaidenName>
                        <div class="col-md-8">
                          {{
                            bundle.item.storageItem.CustomerAddress?.maidenName +
                              ' ' +
                              bundle.item.storageItem.CustomerAddress?.firstName
                          }}
                        </div>
                      </ng-template>
                    </div>
                    <!-- customName -->
                    <div class="row mb-2">
                      <div class="col-md-4">{{ 'customName' | i18n }}</div>
                      <!-- TODO: this is not present in the Address structure available here -->
                      <div class="col-md-8"></div>
                    </div>
                    <!-- company -->
                    <div class="row mb-2">
                      <div class="col-md-4">{{ 'companyName' | i18n }}</div>
                      <div class="col-md-8">{{ Order.CustomerAddress.company }}</div>
                    </div>
                    <!-- position -->
                    <div class="row mb-2">
                      <div class="col-md-4">{{ 'senderPosition' | i18n }}</div>
                      <div class="col-md-8">{{ Order.CustomerAddress.position }}</div>
                    </div>
                  </ng-template>
                </div>
                <!-- customer address update button -->
                <div class="col-md-3 mb-2 mb-md-0">
                  <button
                    class="btn btn-outline-secondary col-12 col-lg-5 mr-2 mb-2"
                    (click)="editBundle(bundle, ScreenType.Order)"
                  >
                    {{ 'flowerOrderModifyButton' | i18n }}
                  </button>
                </div>
              </div>

              <!-- condolence video Customer address -->
              <div *ngIf="bundle.type === BundleType.CondolenceVideo" class="row m-md-3 pb-md-5 sub-order-item">
                <div class="col-md-9">
                  <!-- DestinationName -->
                  <div class="row mb-2">
                    <div class="col-md-4">{{ 'destinationName' | i18n }}</div>
                    <div class="col-md-8">
                      {{
                        bundle.item.storageItem.DestinationLastName + ' ' + bundle.item.storageItem.DestinationFirstName
                      }}
                    </div>
                  </div>
                  <!-- Comment -->
                  <div class="row mb-4">
                    <div class="col-md-4">{{ 'comment' | i18n }}</div>
                    <div class="col-md-8">{{ bundle.item.storageItem.Comment }}</div>
                  </div>

                  <ng-container *ngIf="!bundle.item.storageItem.isCustomerAddressSame; else defaultCustomerAddress">
                    <!-- name -->
                    <div class="row mb-2">
                      <div class="col-md-4">{{ 'cardName' | i18n }}</div>
                      <div class="col-md-8" *ngIf="!bundle.item.storageItem.useMaidenName; else useMaidenName">
                        {{ bundle.item.storageItem.CustomerAddress | fullName }}
                      </div>
                      <ng-template #useMaidenName>
                        <div class="col-md-8">
                          {{
                            bundle.item.storageItem.CustomerAddress?.maidenName +
                              ' ' +
                              bundle.item.storageItem.CustomerAddress?.firstName
                          }}
                        </div>
                      </ng-template>
                    </div>
                    <!-- customName -->
                    <div class="row mb-2">
                      <div class="col-md-4">{{ 'customName' | i18n }}</div>
                      <!-- TODO: this is not present in the Address structure available here -->
                      <div class="col-md-8">{{ bundle.item.storageItem.attributes.customName }}</div>
                    </div>
                    <!-- company -->
                    <div class="row mb-2">
                      <div class="col-md-4">{{ 'companyName' | i18n }}</div>
                      <div class="col-md-8">{{ bundle.item.storageItem.CustomerAddress.company }}</div>
                    </div>
                    <!-- position -->
                    <div class="row mb-2">
                      <div class="col-md-4">{{ 'senderPosition' | i18n }}</div>
                      <div class="col-md-8">{{ bundle.item.storageItem.CustomerAddress.position }}</div>
                    </div>
                  </ng-container>
                  <ng-template #defaultCustomerAddress>
                    <!-- name -->
                    <div class="row mb-2">
                      <div class="col-md-4">{{ 'cardName' | i18n }}</div>
                      <div class="col-md-8" *ngIf="!bundle.item.storageItem.useMaidenName; else useMaidenName">
                        {{ Order.CustomerAddress | fullName }}
                      </div>
                      <ng-template #useMaidenName>
                        <div class="col-md-8">
                          {{
                            bundle.item.storageItem.CustomerAddress?.maidenName +
                              ' ' +
                              bundle.item.storageItem.CustomerAddress?.firstName
                          }}
                        </div>
                      </ng-template>
                    </div>
                    <!-- customName -->
                    <div class="row mb-2">
                      <div class="col-md-4">{{ 'customName' | i18n }}</div>
                      <!-- TODO: this is not present in the Address structure available here -->
                      <div class="col-md-8"></div>
                    </div>
                    <!-- company -->
                    <div class="row mb-2">
                      <div class="col-md-4">{{ 'companyName' | i18n }}</div>
                      <div class="col-md-8">{{ Order.CustomerAddress.company }}</div>
                    </div>
                    <!-- position -->
                    <div class="row mb-2">
                      <div class="col-md-4">{{ 'senderPosition' | i18n }}</div>
                      <div class="col-md-8">{{ Order.CustomerAddress.position }}</div>
                    </div>
                  </ng-template>
                </div>
                <!-- customer address update button -->
                <div class="col-md-3 mb-2 mb-md-0">
                  <button
                    class="btn btn-outline-secondary col-12 col-lg-5 mr-2 mb-2"
                    (click)="editBundle(bundle, ScreenType.Order)"
                  >
                    {{ 'flowerOrderModifyButton' | i18n }}
                  </button>
                </div>
              </div>

              <!-- Money Gift Customer address -->
              <div *ngIf="bundle.type === BundleType.GiftMoney" class="row m-md-3 pb-md-5 sub-order-item">
                <div class="col-md-9">
                  <ng-container *ngIf="!bundle.item.storageItem.isCustomerAddressSame; else defaultCustomerAddress">
                    <!-- name -->
                    <div class="row mb-3">
                      <div class="col-md-4">{{ 'billingName' | i18n }}</div>
                      <div class="col-md-8" *ngIf="!bundle.item.storageItem.useMaidenName; else useMaidenName">
                        {{ bundle.item.storageItem.CustomerAddress | fullName }}
                      </div>
                      <ng-template #useMaidenName>
                        <div class="col-md-8">
                          {{
                            bundle.item.storageItem.CustomerAddress?.maidenName +
                              ' ' +
                              bundle.item.storageItem.CustomerAddress?.firstName
                          }}
                        </div>
                      </ng-template>
                    </div>
                    <!-- customName -->
                    <div class="row mb-5">
                      <div class="col-md-4">{{ 'customName' | i18n }}</div>
                      <!-- TODO: this is not present in the Address structure available here -->
                      <div class="col-md-8">{{ bundle.item.storageItem.attributes.customName }}</div>
                    </div>
                    <!-- relationship -->
                    <div class="row mb-3">
                      <div class="col-md-4">{{ 'relationship' | i18n }}</div>
                      <div class="col-md-8">
                        <!-- TODO: this is not present in the Address structure available here -->
                        {{ bundle.item.storageItem.CustomerAddress.relationship }}
                      </div>
                    </div>
                    <!-- company -->
                    <div class="row mb-3">
                      <div class="col-md-4">{{ 'company' | i18n }}</div>
                      <div class="col-md-8">{{ bundle.item.storageItem.CustomerAddress.company }}</div>
                    </div>
                    <!-- position -->
                    <div class="row mb-3">
                      <div class="col-md-4">{{ 'position' | i18n }}</div>
                      <div class="col-md-8">{{ bundle.item.storageItem.CustomerAddress.position }}</div>
                    </div>
                    <!-- address -->
                    <div class="row mb-4">
                      <div class="col-md-4">{{ 'address' | i18n }}</div>
                      <div class="col-md-8">
                        {{
                          bundle.item.storageItem.CustomerAddress
                            | address
                              : {
                                  omitPostcode: true,
                                  noSpaces: false
                                }
                        }}
                      </div>
                    </div>
                    <!-- telephone -->
                    <div class="row mb-3">
                      <div class="col-md-4">{{ 'telephone' | i18n }}</div>
                      <div class="col-md-8">
                        {{ bundle.item.storageItem.CustomerAddress.telephone | phoneNumber }}
                      </div>
                    </div>
                    <!-- email -->
                    <div class="row mb-3">
                      <div class="col-md-4">E-mail</div>
                      <div class="col-md-8">{{ bundle.item.storageItem.CustomerAddress.email }}</div>
                    </div>
                  </ng-container>
                  <ng-template #defaultCustomerAddress>
                    <!-- name -->
                    <div class="row mb-3">
                      <div class="col-md-4">{{ 'billingName' | i18n }}</div>
                      <div class="col-md-8" *ngIf="!bundle.item.storageItem.useMaidenName; else useMaidenName">
                        {{ Order.CustomerAddress | fullName }}
                      </div>
                      <ng-template #useMaidenName>
                        <div class="col-md-8">
                          {{
                            bundle.item.storageItem.CustomerAddress?.maidenName +
                              ' ' +
                              bundle.item.storageItem.CustomerAddress?.firstName
                          }}
                        </div>
                      </ng-template>
                    </div>
                    <!-- customName -->
                    <div class="row mb-5">
                      <div class="col-md-4">{{ 'customName' | i18n }}</div>
                      <!-- TODO: this is not present in the Address structure available here -->
                      <div class="col-md-8"></div>
                    </div>
                    <!-- relationship -->
                    <div class="row mb-3">
                      <div class="col-md-4">{{ 'relationship' | i18n }}</div>
                      <div class="col-md-8">
                        <!-- TODO: this is not present in the Address structure available here -->
                        {{ Order.CustomerAddress.relationship }}
                      </div>
                    </div>
                    <!-- company -->
                    <div class="row mb-3">
                      <div class="col-md-4">{{ 'company' | i18n }}</div>
                      <div class="col-md-8">{{ Order.CustomerAddress.company }}</div>
                    </div>
                    <!-- position -->
                    <div class="row mb-3">
                      <div class="col-md-4">{{ 'position' | i18n }}</div>
                      <div class="col-md-8">{{ Order.CustomerAddress.position }}</div>
                    </div>
                    <!-- address -->
                    <div class="row mb-4">
                      <div class="col-md-4">{{ 'address' | i18n }}</div>
                      <div class="col-md-8">
                        {{
                          Order.CustomerAddress
                            | address
                              : {
                                  omitPostcode: true,
                                  noSpaces: false
                                }
                        }}
                      </div>
                    </div>
                    <!-- telephone -->
                    <div class="row mb-3">
                      <div class="col-md-4">{{ 'telephone' | i18n }}</div>
                      <div class="col-md-8">{{ Order.CustomerAddress.telephone | phoneNumber }}</div>
                    </div>
                    <!-- email -->
                    <div class="row mb-3">
                      <div class="col-md-4">{{ 'email' | i18n }}</div>
                      <div class="col-md-8">{{ Order.CustomerAddress.email }}</div>
                    </div>
                  </ng-template>
                </div>
                <!-- customer address update button -->
                <div class="col-md-3 mb-2 mb-md-0">
                  <button
                    class="btn btn-outline-secondary col-12 col-lg-5 mr-2 mb-2"
                    (click)="editBundle(bundle, ScreenType.Order)"
                  >
                    {{ 'flowerOrderModifyButton' | i18n }}
                  </button>
                </div>
              </div>

              <!-- Shipping address -->
              <div *ngIf="bundle.present || bundle.food" class="row m-md-3 pb-md-5 sub-order-item">
                <div class="col-md-3">
                  {{ 'shippingAddress' | i18n }}
                </div>
                <div class="col-md-6">
                  <ng-container *ngIf="!bundle.item.storageItem.IsDestinationSame; else sameAddress">
                    <p>
                      {{ bundle.item.storageItem.ShippingAddress | fullName }}
                    </p>
                    <p>
                      {{
                        bundle.item.storageItem.ShippingAddress
                          | address
                            : {
                                omitPostcode: true,
                                noSpaces: false
                              }
                      }}
                    </p>
                    <p>
                      {{ bundle.item.storageItem.ShippingAddress.telephone | phoneNumber }}
                    </p>
                  </ng-container>
                  <ng-template #sameAddress>
                    {{ 'shippingAddressIsSameAsDefault' | i18n }}
                  </ng-template>
                </div>
                <div class="col-md-3">
                  <button
                    (click)="editBundle(bundle, ScreenType.Order)"
                    class="btn btn-outline-secondary col-12 col-lg-5 mb-2 mr-2"
                  >
                    {{ 'flowerOrderModifyButton' | i18n }}
                  </button>
                </div>
              </div>
            </ng-container>

            <!-- Add another button -->
            <div class="row">
              <div class="col text-center" [ngSwitch]="section">
                <button
                  *ngSwitchCase="SummarySection.Flower"
                  id="flower-reception-button"
                  class="btn btn-outline-secondary col-12 col-md-6 mt-3 mt-md-0"
                  (click)="addBundle(section)"
                  [disabled]="isFlowerStepClosed || !funeral.displayFlower"
                >
                  {{ (isFlowerStepClosed ? 'receptionFinished' : 'isCloseFlowerFalse') | i18n }}
                </button>
                <button
                  *ngSwitchCase="SummarySection.Card"
                  id="card-reception-button"
                  class="btn btn-outline-secondary col-12 col-md-6 mt-3 mt-md-0"
                  (click)="addBundle(section)"
                  [disabled]="isCardStepClosed || !funeral.displayCard"
                >
                  {{ (isCardStepClosed ? 'receptionFinished' : 'isCloseCardFalse') | i18n }}
                </button>
                <button
                  *ngSwitchCase="SummarySection.GiftMoney"
                  id="money-gift-reception-button"
                  class="btn btn-outline-secondary col-12 col-md-6 mt-3 mt-md-0"
                  (click)="addBundle(section)"
                  [disabled]="isMoneyGiftStepClosed || !funeral.displayMoney"
                >
                  {{ (isMoneyGiftStepClosed ? 'receptionFinished' : 'isCloseMoneyGiftFalse') | i18n }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </li>
  <li>
    <div class="card order-total border-2">
      <div class="card-body no-padding">
        <div class="row item-center">
          <div class="col-md-1 font-weight-bold p-md-0">
            <div class="right-line py-2 px-sm-2 order-total-title title-border-bottom-mobile">
              {{ 'total' | i18n }}
              <br />
              {{ 'flowerOrderSum' | i18n }}
            </div>
          </div>
          <div class="col-md-11 item-center font-weight-bold">
            <div class="row justify-content-end align-items-center item-center total-price">
              <div class="col-6 col-lg-5 col-xl-5 font-x-large">
                <div class="d-flex flex-row align-items-center order-sum">
                  <small class="col-5 col-md-4 text-left">{{ 'flowerOrderSum' | i18n }}</small
                  ><span class="col-7 col-md-8 text-right">{{ totals?.total | number }}円</span>
                </div>
                <!-- product price -->
                <div class="d-flex flex-row align-items-center font-medium" *ngIf="totals?.totalBeforeDiscount">
                  <small class="col-5 col-md-4 text-left">{{ 'productPrice' | i18n }}</small>
                  <span class="col-7 col-md-8 text-right"> {{ totals?.totalBeforeDiscount | number }}円 </span>
                </div>
                <!-- discount amount -->
                <div class="d-flex flex-row align-items-center font-medium" *ngIf="totals?.discount">
                  <small class="col-5 col-md-4 text-left">{{ 'discountAmount' | i18n }}</small>
                  <span class="col-7 col-md-8 text-right"> {{ totals?.discount | number }}円 </span>
                </div>
                <!-- tax amount -->
                <div class="d-flex flex-row align-items-end font-medium">
                  <small class="col-5 col-md-4 text-left">{{ 'flowerOrderTax' | i18n }}</small
                  ><span class="col-7 col-md-8 text-right">{{ totals?.tax | number }}円</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <span class="caution d-block text-right" *ngIf="displayMoneyGiftDisclaimer"
      ><small>{{ 'noConsumptionTaxOnTheIncense' | i18n }}</small></span
    >
  </li>
</ul>
