import { MoneyGift } from './MoneyGift';
import { BaseProductOrder } from './BaseProductOrder';
import { Address } from './Address';

export class MoneyGiftOrder extends BaseProductOrder<MoneyGift> {
  constructor() {
    super();
    this.IsDestinationSame = 'shipping';
    this.isCustomerAddressSame = true;
    this.useMaidenName = false;
    this.ShippingAddress = new Address();
    this.CustomerAddress = new Address();
  }
  IsDestinationSame: false | 'shipping' | 'customer';
  isCustomerAddressSame: boolean;
  useMaidenName: boolean;
  ShippingAddress?: Address | null;
  CustomerAddress?: Address | null;
}
