import { Translations } from '@aws-amplify/ui-components';
import { globalVariables } from '../global-variables.setting';

const mainUi = {
  [globalVariables.JP_LANGUAGE]: {
    // shared
    normalPrice: '通常価格',
    memberPrice: '会員価格',
    videoPrice: '動画単価',
    productPrice: '本体価格',
    discountAmount: '割引額',
    smacereClub: 'スマセレクラブ',
    // funeral-closed component
    closeScreen: '受付は終了いたしました',
    // death person component
    diedAtAge: '歳にて永眠いたしました。',
    gratitudeForKindnessMsg: 'ここに生前のご厚誼に深謝し、謹んでお知らせ申し上げます。',
    mourning: '喪主',
    next: '次に進む',
    death: '没',
    age: '歳',
    agePrefix: '享年',
    // empty component
    enterFuneralIdPlaceholder: 'ご葬儀番号をご入力ください',
    enterFuneralIdErrorMsg: 'ご入力頂いたご葬儀番号は存在しません',
    goToMediaPage: '入場',
    // media home component
    streamingAvailableFrom: '配信開始',
    watchTheStreaming: '中継を見る',
    streamingStartsSoon: '間後に中継開始',
    playAndWatch: '再生して見る',
    memorialPhoto: 'メモリアルフォト',
    memorialMovie: 'メモリアルムービー',
    liveStreamingUseForEveryone: '※ライブ配信をご利用の皆様へ',
    pleaseRefreshMsg: 'お時間になっても配信が始まらない場合は、画面を更新してください。',
    receptionCloseStart: 'ライブ配信は',
    receptionCloseEnd: 'までご覧いただけます。',
    watch: '見る',
    // not found component
    pageNotFoundMsg: '申し訳ございません。お探しのページは見つかりませんでした。',
    returnToSiteTop: 'サイトトップへ戻る',
    // order component
    customName: '連盟、一同など',
    cardName: '差出人　氏名',
    customNameIsRequired: 'ご芳名　または、連盟、一同',
    pleaseEnterInKana: '※フリガナの入力をお願いいたします',
    pleaseEnterInKana1: '※フリガナの入力を',
    pleaseEnterInKana2: 'お願いいたします',
    condolence: '弔電',
    applicationDeadline: 'お申込み期限',
    until: 'まで',
    applicationIsHere: 'お申込みはこちら',
    receptionFinished: '受付終了',
    processWithoutApplyingMsg: 'お申込みせずに進む方はこちら',
    deceasedNamePrefix: '故人',
    nameSuffix: '様',
    materialType: '台紙',
    condolenceMaterialType: '弔電台紙',
    price: '価格',
    taxExcluded: '税抜',
    paperName: '用紙',
    destinationName: '宛先',
    required: '必須',
    nameIsRequired: '差出人　または、連盟、一同　必須',
    oldFontsCantBeUsedMsg: '※外字、旧字体等は使用できません',
    destinationIsRequiredMsg: '宛先を入力してください',
    companyName: '差出人 会社・団体名',
    companyNameShort: '会社・団体名',
    senderPosition: '差出人 役職',
    senderName: '差出人 氏名',
    senderNameIsRequiredMsg: '差出人 氏名を入力してください',
    funeralAddress: '弔文',
    funeralAddressInput: '弔文入力',
    funeralAddressInputIsRequired: '弔文を入力してください',
    chooseTemplateButton: '定型文選択はこちら',
    chooseTemplate: '定型文選択',
    condolenceCharsLeft: '入力可能な残り文字数:',
    return: '戻る',
    addNewOrder: '追加申し込みは、こちら',
    saveOrderButton: '入力内容確認画面へ',
    productNameOne: '商品名1:',
    productNameTwo: '商品名2:',
    checkContentMsg: '※必ずプレビューにて弔電の内容を確認してから決定を押してください。',
    decideButton: '決定',
    relationshipWithDeceased: '受取人から見た故人との関係',
    title: '敬称',
    titleNotFound: '故人との関係が未入力です',
    chooseButton: '選択',
    addThankYouGift: '弔電返礼品お渡し',
    choosePresentMsg: '弔電をご注文いただいた方へご葬家から返礼品が届きます。下の品物からお一つお選びください',
    decideAndNext: '決定して次へ',
    chooseCondolenceMsg: '下の弔電台紙からお選びください',
    lookInside: '中面をみる',
    condolencePaper: '弔電用紙',
    chooseFromCondolenceMsg: '下の弔電用紙からお選びください',
    paypalProcedure: 'PayPal（ペイパル）操作手順',
    step: 'STEP',
    paypalStepOneMsg: '「デビットカードまたはクレジットカード」ボタンをクリックしてください。',
    paypalStepTwoMsg: '少々お待ちください。',
    paypalStepThreeMsg: 'ペイパル画面が開きます。',
    paypalStepFourFirstMsg: '国選択より ”',
    paypalStepFourMiddleMsg: 'Japan（日本）',
    paypalStepFourFirstLastMsg: '”を選択してください。',
    paypalStepFiveMsg: '少々お待ちください。',
    paypalStepSixFirstMsg: 'ペイパル画面が 日本語表示になります。 ”',
    paypalStepSixMiddleMsg: 'カード',
    paypalStepSixLastMsg: '”を クリックしてください。',
    paypalStepSevenMsg: 'クレジットカード番号、 有効期限、 セキュリティコードを入力してください。',
    paypalStepEightMsg: 'カード名義人様の 氏名、住所に 間違いがないことを確認してください。',
    paypalStepNineMsg: '連絡先情報の電話番号、 メールアドレスに 間違いがないことを確認してください。',
    paypalStepTenMsgFirst: 'ペイパルのアカウントを作成しない場合は「いいえ」を選択してください。',
    paypalStepTenMsgMiddle:
      '「私は18歳以上であることを認め、Paypalのブライバシーステートメントに同意します」をチェックします。',
    paypalStepTenMsgLast: '「続行」ボタンをクリックしてください。',
    seeOnLargeScreenButton: '大画面で見る方はこちら',
    anotherWindowWillOpen: '別ウィンドウが開きます',
    mournerInfo: 'ご注文者様情報',
    enterMournerInfo: 'ご会葬者様情報入力',
    detailsOfPaymentInfo: 'ご決済情報内容',
    billingName: 'ご芳名',
    billingNameIsRequiredMsg: 'ご芳名を入力してください',
    companyName2: '会社名・団体名（役職名）',
    relationship: 'ご関係',
    address: 'ご住所',
    postalCodeIsInvalidMsg: '郵便番号を入力してください',
    prefecture: '都道府県',
    prefectureIsInvalidMsg: '都道府県を入力してください',
    city: '市区町村',
    cityIsRequiredMsg: '市区町村を入力してください',
    streetNumber: '丁目番地',
    streetNumberIsRequiredMsg: '丁目番地を入力してください',
    buildingNumber: '建物名・部屋番号・会社名',
    phone: '電話番号',
    phoneIsRequiredMsg: '電話番号を入力してください',
    phonePatternErrorMsg: '電話番号の形式が正しくありません',
    informationWillBeProvidedToMournerMsg: '※ここまでのご入力情報は、喪主様へご芳名情報として提供いたします',
    email: 'メールアドレス',
    emailIsRequiredMsg: 'メールアドレスを入力してください',
    emailPatternErrorMsg: 'メールの形式が正しくありません',
    confirmEmail: 'メールアドレス確認',
    confirmEmailMatchErrorMsg: 'メールアドレスが一致しません',
    birthday: '生年月日',
    birthdayIsInvalidMsg: '生年月日が一致しません',
    year: '年',
    month: '月',
    day: '日',
    destinationTitle: 'お返し・返礼品配送先',
    destinationSameFlowerYes: '注文者の住所と同じ',
    sameAsOrderAddress: '注文者の住所と同じ',
    destinationSameYes: '上記の住所と同じ',
    destinationSameNo: '上記の住所と別の場所',
    differentAddressFromTheAbove: '上記の住所と別の場所',
    recipientsName: '宛名',
    recipientsNameIsRequiredMsg: '宛名を入力してください',
    clubTitle: 'セレモニークラブ会員登録',
    isRegCeremonyClub: '会員登録する',
    isRegCeremonyClubMsg: 'セレモニークラブの会員に登録していただくと次回からお客様情報の入力が不要です。',
    isRegCeremonyClubMsg2: 'さらに供花・弔電5%OFF!(入会金・年会費・手数料無し)',
    isRegCeremonyClubSubMsg: '※サービスのご提供は７月以降を予定しております。',
    password: 'パスワード',
    confirmPassword: 'パスワード確認',
    handlingPersonalInformation: '個人情報の取り扱い',
    agreeAndCheck: 'をご確認頂き、ご同意の上でチェックしてください',
    nextBtn: '次へ',
    paymentScreen: 'お支払い画面',
    paymentScreenHeaderOne: 'お支払いは下記のPayPal（ペイパル）決済システムからお手続きください。',
    paymentScreenHeaderTwo: 'デビットカード、またはクレジットカードからお選びいただけます。',
    paymentScreenSubHeaderOne:
      '※銀行口座で支払うには、アカウントにログイン後、銀行口座で口座振替設定を行ってください。',
    paymentScreenSubHeaderTwo: '法人の方は銀行口座支払いをご利用いただけません。詳しくは',
    here: 'こちら',
    pleaseWaitMsg: 'しばらくお待ちください',
    applicationContentConfirmationScreen: 'お申込み内容確認画面',
    flowerOffering: '供花',
    isCloseFlowerFalse: '供花を追加する',
    flowerOrderProductName: '商品名',
    orderRemarks: '備考欄',
    flowerOrderModifyButton: '修正',
    flowerOrderRemoveButton: '取り消す',
    flowerOrderSum: '金額',
    flowerOrderTax: '内消費税',
    flowerOrderSumWithTax: '小計',
    flowerOrderPresent: '供花返礼品',
    flowerOrderPresentName: '品名',
    isCloseCardFalse: '弔電を追加する',
    condolencePresent: '弔電返礼品',
    isCloseMoneyGiftFalse: '香典を追加する',
    incense: '香典',
    dynamicPrice: '１０万以上',
    moneyGiftTotalAmount: '香典金額',
    moneyPresent: '香典返礼品',
    moneyFood: 'ご会食',
    total: '合計',
    taxIncluded: '税込',
    noConsumptionTaxOnTheIncense: '※香典に対しての消費税はございません',
    isProcessingTrue: 'ご注文画面に進む',
    isProcessingFalse: '処理中...',
    flowerNameSample: '供花のお札名の一例になります。レイアウトは専門スタッフが責任を持って構成いたします',
    size: '大きさ',
    height: '高さ:約',
    width: '巾:約',
    cm: 'cm',
    orderProductDescription: '使用花材',
    quantity: '数量',
    billName: 'ご芳名（供花の名札）',
    openFlowerName: '名札サンプルはこちら >',
    billNameIsRequiredMsg: 'ご芳名（供花の名札）を入力してください',
    flowerPresentHeader: '供花返礼品お渡し',
    offeringPresent: '返礼品お渡し',
    flowerPresentSubHeader: '供花をご注文いただいた方へご葬家から返礼品が届きます。下の品物からお一つお選びください',
    shippingWarning: '返礼品の発送先は、日本国内に限らさせて頂きます。',
    selectFlowerToApply: 'お申込みしたい供花をお選びください',
    japaneseStyle: '和花',
    westernStyle: '洋花',
    pdfIsHere: 'PDFはこちら >',
    moneyGiftReceptionButton: '香典のお預けはこちら',
    incenseAmount: '香典金額をお選びください',
    moneyGiftSelectionMsg: '香典をお預けいただいた方へご葬家から返礼品が届きます。下の品物からお一つお選びください',
    moneyGiftSelectMsgWarning: ' ', // keep space so it does not display variable name for ceremony
    presentDeliveryWarning: ' ', // keep space so it does not display variable name for ceremony
    moneyGiftFoodSelectionMsg: 'ご葬家からご会葬者様へ、ご会食の代わりにグルメギフトをお届けいたします',
    senderPositionMaxLengthMessage: '役職は25桁以内で入力してください。',
    relationshipMaxLengthMessage: 'ご関係は20桁以内で入力してください。',
    'family-only': '故人様宛て',
    everyone: 'ご遺族様宛て',
    moneyGiftRelationship: '故人様とのご関係',
    moneyGiftYearsKnown: '交友歴',
    moneyGiftMessage: 'メッセージ',
    moneyGiftRecipient: 'メッセージ宛先',
    moneyGiftMessageLabel: 'メッセージをつける方はこちらへご記入ください',
    memorialShare: 'メモリアルシェア',
    memorialShareNote1: '故人様との思い出写真を共有される方はこちら',
    memorialShareNote2: 'お手続きを済まされた後でもご共有いただけます',
    condolenceVideo: '動画弔電',
    condolenceCard: '弔電（台紙）',
    condolenceCardButton: '電文の方はこちら',
    condolenceVideoButton: '動画電文の方はこちら',
    thereAreTwoTypesOfCondolenceMessage: '弔電は、 弔電（台紙） 又は 動画弔電 の２種類からお選びいただけます',
    byUsingCondolenceVideoMessage:
      '弔電に動画を利用することにより、いままで以上に故人やご葬家様へお気持ちをお伝えすることが可能になりました',
    nameInformation: 'ご芳名情報',
    enterCustomerInformation: '注文者の情報を入力',
    userMaidenName: '旧姓を使用する',
    enterTheAboveInformation: '上記の情報を入力',
    enterOtherInformation: '上記以外の情報を入力',
    returnOfIncenseFlowerAndDeliveryOfReturnedGoods: '供花　お返し・返礼品配送先',
    returnOfIncenseAndDeliveryOfReturnedGoods: '香典　お返し・返礼品配送先',
    sameAsOrderItemAddress: 'ご芳名の住所と同じ',
    senderInformation: '差出人情報',
    condolenceShippingAddress: '弔電　お返し・返礼品配送先',
    moreThanTenMan: '10万超～50万円までの方はこちら',
    fiveManIsMinimumStep: '※5万円単位での入力をお願いします。',
    shippingAddressIsSameAsDefault: 'ご配送先が会葬者住所と同じです',
    telephone: '電話番号',
    company: '会社名・団体名',
    shippingAddress: '配送先',
    cash: '当日現金',
    bank_transfer: '銀行振込',
    convenience_store: 'コンビニ',
    goToApplicationConfirmation: '申込内容確認へ',
    // payment component
    paymentCheckEmailPasswordPlaceholder: 'パスコードまたはご登録メールアドレスを入力してください',
    paymentCheckPasswordPlaceholder: 'パスワードを入力して下さい',
    paymentCheckEmailIsRequiredValidationMsg: 'パスコードまたはご登録メールアドレスを入力してください',
    paymentCheckPasswordIsRequiredValidationMsg: 'パスワードを入力してください',
    paymentCheckCheckEmailPasswordNotFoundMsg: 'メールアドレスまたはパスワードが登録されていません',
    paymentCheckCheckPasswordNotFoundMsg: 'パスワードが登録されていません',
    // welcome component
    in: '於',
    SmacereClubMemberIsHere: 'スマセレクラブ会員様はこちら',
    generalIsHere: '一般の会葬者儀はこちら',
    liveStreamIsHere: 'ライブ配信はこちら',
    alreadyAcceptedHere: '(受付済みの方はこちら)',
    reception: '受付',
    mourner: 'ご会葬',
    toSmacereClub: 'スマセレクラブとは',
    receptionIsHere: '受付はこちら',
    // Stream component
    streamInfo: '動画が複数ファイルに分かれている場合は、番号順にご覧ください',
    // memorial share component
    overnightStreaming: '通夜ライブ配信',
    funeralStreaming: '葬儀ライブ配信',
    smartCondolenceMessage: 'スマート弔電・弔辞',
    prefix: '故',
    endDate: '公開終了日',
    numberOfPhotos: '公開写真枚数',
    photosCounter: '枚',
    uploadButtonHeader: '故人様との思い出写真を共有される方はこちら',
    uploadButton: '画像・動画をアップロードする',
    uploadVideoButton: '動画をアップロードする',
    uploadCardButton: '名刺画像をアップロードする',
    pleaseUploadImagesFromBelow: '下記より画像のアップロードをお願いいたします',
    pleaseUploadVideoFromBelow: '下記より動画弔電のアップロードをお願いいたします',
    memorialShareIsFreeService: 'メモリアルシェアは無料のサービスとなります',
    selectAnImageFromTheHolder: 'ホルダーから画像・動画を選択',
    selectAVideoFromTheHolder: 'ホルダーから動画を選択',
    fileSize: 'ファイルサイズ',
    videoDuration: '動画時間',
    playVideo: '動画再生',
    deleteVideo: '動画削除',
    access: '公開範囲',
    publicAccess: 'ご葬家様・ご会葬者様全員に公開',
    privateAccess: 'ご葬家様のみに公開',
    name: 'お名前',
    sender: '差出人',
    addToTheListOfSubmissionTargets: '投稿対象一覧へ追加',
    change: '変更',
    delete: '削除',
    contributedIn: 'にご投稿頂きました',
    // memorial share and condolence video upload component
    selectPhoto: '写真を選択',
    selectVideo: '動画を選択',
    upload: 'アップロード',
    save: '登録',
    dragImageToUpload: '画像・動画をドラッグ＆ドロップするとアップロードします',
    dragVideoToUpload: '動画をドラッグするとアップロードします',
    dragCardToUpload: '名刺画像をドラッグするとアップロードします',
    fileName: 'ファイル名',
    comment: 'コメント',
    cancel: '取消し',
    public: '全員に共有',
    private: 'ご葬家のみ共有',
    saveToAlbum: 'メモリアルシェアへ投稿',
    listOfSubmissions: '投稿対象一覧',
    toDeleteImagesClickTheXButton: '画像・動画を個別削除する場合は、画像右上の「✖」を押下することで削除できます',
    toUpdateAnImageDoubleClickOnTheImage:
      '画像・動画の内容を確認、修正などをおこなう場合は、対象の画像・動画をダブルクリックしてください',
    deleteAllImages: '画像・動画一括削除',
    iAgreeToTheAboveAndSubmitTheImage: '上記内容を承諾し、画像・動画の投稿をいたします。',
    lastNameIsRequiredMsg: 'お名前を入力してください',
    lastNameKanaIsRequiredMsg: 'フリガナを入力してください',
    firstNameIsRequiredMsg: 'お名前を入力してください',
    firstNameKanaIsRequiredMsg: 'フリガナを入力してください',
    nameKanaPatternError: 'フリガナを入力してください',
    // address component
    nameKana: 'フリガナ',
    position: '役職',
    maidenName: '旧姓',
    companyIsRequiredWhenCompanyPositionIsSelected: '会社・団体名を入力してください',
    positionIsRequiredWhenCompanyPositionIsSelected: '役職を選択してください',
    nameRequired: 'お名前を入力してください',
    shippingNameRequired: '宛名を入力してください',
    kanaRequired: 'フリガナを入力してください',
    maidenNameRequired: '旧姓を入力してください',
    // payment method component
    confirmOrderDetails: 'ご注文内容を確定する',
    paymentMethod: 'お支払い方法',
    payWithCreditCard: 'クレジットカードでのお支払い',
    otherPaymentMethods: 'その他のお支払い方法（供花、弔電のみのお申込み時、選択可）',
    bankTransfer: '銀行振込（後払い）',
    pleaseNoteYouWillPayBankTransferFee: '※振込手数料は、別途、お客様にて負担して頂く必要がございます',
    payAtConvenienceStore: 'コンビニ決済（払込票）',
    pleaseNoteYouWillPayTransferFee: '※払込手数料は、別途、お客様にて負担して頂く必要がございます',
    cashOnDelivery: '当日現金払い',
    cashOnDeliveryPaymentOnTheDayOfTheFuneral:
      '※ご葬儀当日、葬儀会場にてご会葬頂いた際、現金にてお支払い頂く方式となります',
    paymentProcess: 'お支払いまでの流れ',
    confirmOrder: '注文確定',
    confirmIdentityByPhone: 'お電話でのご本人様確認',
    sendInvoiceByEmail: '請求書を郵送にて送付',
    payment: 'お支払い',
    // guest book page
    toSmartCeremonyGuests: 'スマートセレモニーご会葬者様へ',
    aConvenientMembershipProgramGuide: '便利でお得な会員制度(スマセレクラブ)のご案内',
    ifYouBecomeAMemberYouWillRecieveDiscount:
      'スマセレクラブにご入会頂けますと、次回以降、各種割引サービスやスマートセレモニーでの受付が簡単、便利におこなえます。',
    membershipFee: '入会金',
    annualFeesAndCharges: '年会費・手数料',
    freeOfCharge: '無料',
    fromThePriceOfCondolenceOffering: '弔電価格より',
    fromThePriceOfFlowerOffering: '供花価格より',
    clickHereForTheSmacereClubWebsite: 'スマセレクラブWebサイトはこちら',
    smacereClubMembershipAgreement: 'スマセレクラブ会員規約',
    smacereClubMembershipRegistration: 'スマセレクラブ会員登録',
    agreeAndRegisterAsAMember: '会員規約に同意し、会員登録する',
    ifYouUseSNSToRegisterYourInformation: '※会員登録を完了するには、スマセレクラブにて、追加手続きが必要となります。',
    ifYouRegisteredYourInformationByCard:
      '※受付完了後に、ご注文者情報のメールアドレスへメールを送付いたしますのでご確認ください',
    smacereCanBeUsedWithoutRegistration:
      'スマートセレモニーは、ＩＤ・パスワードなど、登録不要でご利用いただけるサービスとなります',
    doubleBookingAlertTitle:
      '{previousFuneralName}家は、受付済みの葬家名で、{currentFuneralName}家は現在受付をしようとしている葬家名です',
    doubleBookingAlertMessage:
      '既に受付をおえられておられます、{previousFuneralName}家様のご葬儀日程と{currentFuneralName}家様のご葬儀日程は一部時間帯が重複しております。<br>日程などご確認いただけますと幸いです。<br>{currentFuneralName}家様の受付につきましては、引き続き実施頂くことも可能となります。',
    // other
    cardRegistrationConfirmScreen: '弔電お申し込み内容確認画面',
    moneyGiftRegistrationConfirmScreen: '香典お申し込み内容確認画面',
    orderer: '注文者',
    yourOrderHasBeenCompleted: 'お支払いが完了しました',
    yourOrderHasBeenAccepted: 'ご注文の受付が完了しました',
    weHaveSentConfirmationEmail: 'ご注文内容の確認メールを送信致しましたのでご確認ください',
  },
};

const amplifyUi = {
  [globalVariables.JP_LANGUAGE]: {
    [Translations.BACK_TO_SIGN_IN]: 'ログインに戻る',
    [Translations.CHANGE_PASSWORD_ACTION]: '変更',
    [Translations.CHANGE_PASSWORD]: 'パスワードを変更',
    [Translations.CODE_LABEL]: '検証コード',
    [Translations.CODE_PLACEHOLDER]: 'コードを入力',
    [Translations.CONFIRM_SIGN_UP_CODE_LABEL]: '確認コード',
    [Translations.CONFIRM_SIGN_UP_CODE_PLACEHOLDER]: 'コードを入力',
    [Translations.CONFIRM_SIGN_UP_HEADER_TEXT]: 'サインアップを確認',
    [Translations.CONFIRM_SIGN_UP_LOST_CODE]: 'コードがありませんか？',
    [Translations.CONFIRM_SIGN_UP_RESEND_CODE]: 'コードを再送',
    [Translations.CONFIRM_SIGN_UP_SUBMIT_BUTTON_TEXT]: '確認',
    [Translations.CONFIRM_SMS_CODE]: 'SMSコードを確認',
    [Translations.CONFIRM_TOTP_CODE]: 'TOTPコードを確認',
    [Translations.CONFIRM]: '確認',
    [Translations.CREATE_ACCOUNT_TEXT]: 'アカウントを作成',
    [Translations.EMAIL_LABEL]: 'Eメールアドレス *',
    [Translations.EMAIL_PLACEHOLDER]: 'Eメールアドレスを入力',
    [Translations.FORGOT_PASSWORD_TEXT]: 'パスワードをお忘れですか？',
    [Translations.LESS_THAN_TWO_MFA_VALUES_MESSAGE]: '2つ未満のMFAタイプが利用可能です',
    [Translations.NEW_PASSWORD_LABEL]: '新しいパスワード',
    [Translations.NEW_PASSWORD_PLACEHOLDER]: '新しいパスワードを入力',
    [Translations.NO_ACCOUNT_TEXT]: 'アカウントが未登録ですか？',
    [Translations.PASSWORD_LABEL]: 'パスワード',
    [Translations.PASSWORD_PLACEHOLDER]: 'パスワードを入力',
    [Translations.PHONE_LABEL]: '電話番号 *',
    [Translations.PHONE_PLACEHOLDER]: '(555) 555-1212',
    [Translations.QR_CODE_ALT]: 'QRコード',
    [Translations.RESET_PASSWORD_TEXT]: 'パスワードをリセット',
    [Translations.RESET_YOUR_PASSWORD]: 'パスワードをリセット',
    [Translations.SELECT_MFA_TYPE_HEADER_TEXT]: 'MFAタイプを選択',
    [Translations.SELECT_MFA_TYPE_SUBMIT_BUTTON_TEXT]: '検証',
    [Translations.SEND_CODE]: '検証コードを送信',
    [Translations.SUBMIT]: '送信',
    [Translations.SETUP_TOTP_REQUIRED]: 'TOTPが設定されている必要があります',
    [Translations.SIGN_IN_ACTION]: 'ログイン',
    [Translations.SIGN_IN_HEADER_TEXT]: 'スマセレクラブにログイン',
    [Translations.SIGN_IN_TEXT]: 'ログイン',
    [Translations.SIGN_IN_WITH_AMAZON]: 'Amazonでログイン',
    [Translations.SIGN_IN_WITH_AUTH0]: 'Auth0でログイン',
    [Translations.SIGN_IN_WITH_AWS]: 'AWSでログイン',
    [Translations.SIGN_IN_WITH_FACEBOOK]: 'Facebookでログイン',
    [Translations.SIGN_IN_WITH_GOOGLE]: 'Googleでログイン',
    [Translations.SIGN_OUT]: 'サインアウト',
    [Translations.SIGN_UP_EMAIL_PLACEHOLDER]: 'Eメール',
    [Translations.SIGN_UP_HAVE_ACCOUNT_TEXT]: 'アカウントをお持ちですか？',
    [Translations.SIGN_UP_HEADER_TEXT]: '新しいアカウントを作成',
    [Translations.SIGN_UP_PASSWORD_PLACEHOLDER]: 'パスワード',
    [Translations.SIGN_UP_SUBMIT_BUTTON_TEXT]: 'アカウントを作成',
    [Translations.SIGN_UP_USERNAME_PLACEHOLDER]: 'ユーザー名',
    [Translations.SUCCESS_MFA_TYPE]: '成功しました。MFAタイプ:',
    [Translations.TOTP_HEADER_TEXT]: 'スキャンして確認コードを入力してください',
    [Translations.TOTP_LABEL]: 'セキュリティコードを入力:',
    [Translations.TOTP_SETUP_FAILURE]: 'TOTPセットアップに失敗しました',
    [Translations.TOTP_SUBMIT_BUTTON_TEXT]: 'セキュリティトークンを検証',
    [Translations.TOTP_SUCCESS_MESSAGE]: 'TOTPセットアップが成功しました',
    [Translations.UNABLE_TO_SETUP_MFA_AT_THIS_TIME]: '失敗しました。現在MFA設定ができません。',
    [Translations.USERNAME_LABEL]: 'ＩＤ(メールアドレス)',
    [Translations.USERNAME_PLACEHOLDER]: 'ユーザー名を入力',
    [Translations.VERIFY_CONTACT_EMAIL_LABEL]: 'Eメール',
    [Translations.VERIFY_CONTACT_HEADER_TEXT]: 'アカウントの復元には確認済みの連絡先情報が必要です',
    [Translations.VERIFY_CONTACT_PHONE_LABEL]: '電話番号',
    [Translations.VERIFY_CONTACT_SUBMIT_LABEL]: '送信',
    [Translations.VERIFY_CONTACT_VERIFY_LABEL]: '検証',
    [Translations.ADDRESS_LABEL]: '住所',
    [Translations.ADDRESS_PLACEHOLDER]: '住所を入力',
    [Translations.NICKNAME_LABEL]: 'ニックネーム',
    [Translations.NICKNAME_PLACEHOLDER]: 'ニックネームを入力',
    [Translations.BIRTHDATE_LABEL]: '誕生日',
    [Translations.BIRTHDATE_PLACEHOLDER]: '誕生日を入力',
    [Translations.PICTURE_LABEL]: '写真のURL',
    [Translations.PICTURE_PLACEHOLDER]: '写真のURLを入力',
    [Translations.FAMILY_NAME_LABEL]: '苗字',
    [Translations.FAMILY_NAME_PLACEHOLDER]: '苗字を入力',
    [Translations.PREFERRED_USERNAME_LABEL]: '呼び名',
    [Translations.PREFERRED_USERNAME_PLACEHOLDER]: '呼び名を入力',
    [Translations.GENDER_LABEL]: '性別',
    [Translations.GENDER_PLACEHOLDER]: '性別を入力',
    [Translations.PROFILE_LABEL]: 'プロフィールURL',
    [Translations.PROFILE_PLACEHOLDER]: 'プロフィールURLを入力',
    [Translations.GIVEN_NAME_LABEL]: '名前',
    [Translations.GIVEN_NAME_PLACEHOLDER]: '名前を入力',
    [Translations.ZONEINFO_LABEL]: 'タイムゾーン',
    [Translations.ZONEINFO_PLACEHOLDER]: 'タイムゾーンを入力',
    [Translations.LOCALE_LABEL]: 'ロケール',
    [Translations.LOCALE_PLACEHOLDER]: 'ロケールを入力',
    [Translations.UPDATED_AT_LABEL]: '更新日時',
    [Translations.UPDATED_AT_PLACEHOLDER]: '情報が最後に更新された時刻を入力してください',
    [Translations.MIDDLE_NAME_LABEL]: 'ミドルネーム',
    [Translations.MIDDLE_NAME_PLACEHOLDER]: 'ミドルネームを入力',
    [Translations.WEBSITE_LABEL]: 'ウェブサイト',
    [Translations.WEBSITE_PLACEHOLDER]: 'ウェブサイトを入力',
    [Translations.NAME_LABEL]: 'フルネーム',
    [Translations.NAME_PLACEHOLDER]: 'フルネームを入力',
    [Translations.PHOTO_PICKER_TITLE]: 'タイトル',
    [Translations.PHOTO_PICKER_HINT]: '補助的なテキストまたはコンテンツがこのスペースを占める可能性があります',
    [Translations.PHOTO_PICKER_PLACEHOLDER_HINT]: 'プレースホルダーヒント',
    [Translations.PHOTO_PICKER_BUTTON_TEXT]: 'ボタン',
    [Translations.IMAGE_PICKER_TITLE]: 'プロフィール写真を追加',
    [Translations.IMAGE_PICKER_HINT]: 'アップロードする前に画像をプレビューする',
    [Translations.IMAGE_PICKER_PLACEHOLDER_HINT]: 'タップして画像を選択',
    [Translations.IMAGE_PICKER_BUTTON_TEXT]: 'アップロード',
    [Translations.PICKER_TEXT]: 'ファイルを選択',
    [Translations.TEXT_FALLBACK_CONTENT]: 'フォールバックコンテンツ',
  },
};

const amplifyMessages = {
  [globalVariables.JP_LANGUAGE]: {
    'User does not exist.': 'ユーザーが存在しません',
    'Incorrect username or password.': 'ユーザー名またはパスワードが違います',
    'User is not confirmed.': 'ユーザーは検証されていません',
    'User already exists': 'ユーザーは既に存在します',
    'Invalid verification code provided, please try again.': '指定された確認コードが無効です。もう一度お試しください',
    'Invalid password format': 'パスワードのフォーマットが不正です',
    'Account recovery requires verified contact information': 'アカウントの復元には確認済みの連絡先情報が必要です',
    'Invalid phone number format':
      '不正な電話番号フォーマットです。 電話番号は次のフォーマットで入力してください: +12345678900',
    'An account with the given email already exists.': 'そのメールアドレスは既に存在します',
    'Username cannot be empty': 'ユーザー名は必須です',
    'Password attempts exceeded': 'パスワード試行回数が超過しました',
    'Attempt limit exceeded, please try after some time.':
      '試行制限を超過しました。しばらくしてからもう一度お試しください',
    'Username/client id combination not found.': 'ユーザーが存在しません',
    'CUSTOM_AUTH is not enabled for the client.': 'パスワードは必須です', // 本来の意味とは異なるが、パスワード未入力時に発生するのでこの訳としている
    'Password does not conform to policy: Password not long enough':
      'パスワードは8文字以上を入力してください (8文字以上の大文字小文字を含む英数字)', // 適宜修正
    'Password does not conform to policy: Password must have uppercase characters':
      'パスワードには大文字を含めてください (8文字以上の大文字小文字を含む英数字)', // 適宜修正
    'Password does not conform to policy: Password must have lowercase characters':
      'パスワードには小文字を含めてください (8文字以上の大文字小文字を含む英数字)', // 適宜修正
    'Password does not conform to policy: Password must have numeric characters':
      'パスワードには数字を含めてください (8文字以上の大文字小文字を含む英数字)', // 適宜修正
    "1 validation error detected: Value at 'password' failed to satisfy constraint: Member must have length greater than or equal to 6":
      'パスワードは8文字以上、大文字小文字を含む英数字を指定してください', // 適宜修正。本来の意味とは異なるがこれで明示している。
    "2 validation errors detected: Value at 'password' failed to satisfy constraint: Member must have length greater than or equal to 6; Value at 'password' failed to satisfy constraint: Member must satisfy regular expression pattern: ^[S]+.*[S]+$":
      'パスワードは8文字以上、大文字小文字を含む英数字を指定してください', // 適宜修正。本来の意味とは異なるがこれで明示している。
    'Password cannot be empty': 'パスワードを入力してください',
  },
};

const kendo = {
  [globalVariables.JP_LANGUAGE]: {
    // dropdowns
    'kendo.autocomplete.noDataText': 'データなし',
    'kendo.autocomplete.clearTitle': 'クリア',
    'kendo.combobox.noDataText': 'データなし',
    'kendo.combobox.clearTitle': 'クリア',
    'kendo.dropdownlist.noDataText': 'データなし',
    'kendo.dropdownlist.clearTitle': 'クリア',
    'kendo.dropdowntree.noDataText': 'データなし',
    'kendo.dropdowntree.clearTitle': 'クリア',
    'kendo.multicolumncombobox.noDataText': 'データなし',
    'kendo.multicolumncombobox.clearTitle': 'クリア',
    'kendo.multiselect.noDataText': 'データなし',
    'kendo.multiselect.clearTitle': 'クリア',
  },
};

export const defaultTranslations = [amplifyUi, mainUi, amplifyMessages, kendo].reduce((acc, dict) => {
  Object.entries(dict).forEach(([langKey, values]: any[]) => {
    if (!acc.hasOwnProperty(langKey)) {
      // @ts-ignore
      acc[langKey] = {};
    }
    Object.assign(acc[langKey], values);
  });
  return acc;
}, {});
