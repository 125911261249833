import { Pipe, PipeTransform } from '@angular/core';
import { MediaObjectInput, S3Object, S3ObjectInput } from '@kokusai/smacere-shared/types/api';
import { AWS } from '@kokusai/utilities';

@Pipe({
  name: 'getS3Url',
})
export class GetS3UrlPipe implements PipeTransform {
  transform(media: S3Object | S3ObjectInput): string {
    return AWS.getS3Url(media);
  }
}
