import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { HeadService } from 'src/services';
import { skip } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { GoogleAnalyticsService } from 'ngx-google-analytics';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  public title: string = 'スマートセレモニー';
  private routeListener: Subscription = null;
  private prevEvent: NavigationEnd = null;

  constructor(header: HeadService, private route: Router, protected gaService: GoogleAnalyticsService) {}

  ngOnInit(): void {
    this.routeListener = this.route.events.pipe(skip(1)).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (this.prevEvent?.urlAfterRedirects !== event.urlAfterRedirects) {
          this.prevEvent = event;

          const id = event.urlAfterRedirects.split('/')[1];
          this.gaService.pageView(event.urlAfterRedirects, undefined, undefined, {
            funeralId: id,
          });
          return true;
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.routeListener.unsubscribe();
  }
}
