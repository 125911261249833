import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Funeral } from 'src/models';
import { GlobalStorage } from 'src/app/global/storage';
import { Location } from '@angular/common';
import { environment } from 'src/environments/environment';
import { DisplayElementsService } from 'src/app/services/display-elements.service';
import { BundleCartService } from '../../../../services/bundle-cart.service';

@Component({
  selector: 'app-card-main',
  templateUrl: './card-main.component.html',
  styleUrls: ['./card-main.component.scss'],
})
export class CardMainComponent implements OnInit {
  Funeral: Funeral = {} as Funeral;
  environment = environment;

  constructor(
    private router: Router,
    private storage: GlobalStorage,
    private location: Location,
    private displayElementsService: DisplayElementsService,
    private bundleCartService: BundleCartService
  ) {}

  ngOnInit(): void {
    this.bundleCartService.resetCurrentStep();
    this.Funeral = this.storage.Funeral;
    if (!this.displayCard) {
      this.goToCondolenceVideo();
    }
    if (!this.displayCondolenceVideos) {
      this.goToCardSelection();
    }
  }

  get displayCard(): boolean {
    return this.displayElementsService.displayCard;
  }

  get displayCondolenceVideos(): boolean {
    return this.displayElementsService.displayCondolenceVideos;
  }

  goToCondolenceVideo() {
    this.router.navigate([this.Funeral.funeralId, 'order', 'condolence-video', 'upload']);
  }

  goToCardSelection() {
    this.router.navigate([this.Funeral.funeralId, 'order', 'cards', 'products']);
  }

  back(): void {
    this.location.back();
  }
}
