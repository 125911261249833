<div id="card-select" class="no-flex">
  <div class="container">
    <h4 class="page-header">{{ 'condolenceMaterialType' | i18n }}</h4>
    <h5 class="text-center">{{ 'chooseCondolenceMsg' | i18n }}</h5>
  </div>
  <div class="grey-wrapper bg-grey">
    <div class="container">
      <ul class="product-list row">
        <li
          *ngFor="let item of Cards; let i = index"
          class="col-6 col-md-4 mb-2 d-flex flex-column justify-content-between"
          [ngClass]="{ 'product-active': item.id == selectedCard?.id }"
        >
          <label for="cover-{{ i }}" class="d-flex flex-column justify-content-between h-100">
            <div class="position-relative">
              <img class="img-fluid" vtMedia [object]="item.picture" />
              <a
                href="javascript:void(0);"
                class="btn btn-primary btn-link side-round-button"
                zoomImage
                [object]="item.cardImageOpen"
                >{{ 'lookInside' | i18n }} ></a
              >
            </div>
            <div class="d-flex flex-column justify-content-between h-100">
              <!-- title -->
              <div class="product-title">
                {{ 'condolenceMaterialType' | i18n }}（{{ item.cardMaterialType }}）
                <br />
                <span>{{ '【' + item.name }}</span>
                <span *ngIf="item.nameKana; else noNameKana">{{ '＜' + item.nameKana + '＞】' }}</span>
                <ng-template #noNameKana>】</ng-template>
                <br />
                <span>{{ item.cardOpenSide }}</span>
              </div>
              <!-- price -->
              <div>
                <div class="price row font-medium text-left ml-3" *ngIf="isSmacereClubMember | async; else notMember">
                  <span class="col-md-4 p-0 font-small">{{ 'normalPrice' | i18n }}</span>
                  <span class="col-md-8 p-0">{{ item.price | priceWithTax }}({{ 'taxIncluded' | i18n }})</span>
                </div>
                <ng-template #notMember>
                  <div class="price row font-medium text-center">
                    <span class="col-md-12 p-0">{{ item.price | priceWithTax }}({{ 'taxIncluded' | i18n }})</span>
                  </div>
                </ng-template>
                <div
                  class="priceAfterDiscount row font-small text-danger text-left ml-3 align-items-center"
                  *ngIf="isSmacereClubMember | async"
                >
                  <span class="col-md-4 p-0 d-flex flex-column font-xx-small">
                    <span>{{ 'smacereClub' | i18n }}</span>
                    <span>{{ 'memberPrice' | i18n }}</span>
                  </span>
                  <span class="col-md-8 p-0">
                    <app-discounted-price [price]="item.price" [type]="ProductType.Card"></app-discounted-price>({{
                      'taxIncluded' | i18n
                    }})
                  </span>
                </div>
              </div>
            </div>
          </label>
          <RadioButton
            id="cover-{{ i }}"
            name="cover"
            [value]="item.id"
            [checked]="item.id == selectedCard?.id"
            (change)="OnSelectCoverChange(item)"
          ></RadioButton>
        </li>
      </ul>
      <h4 class="page-header">{{ 'condolencePaper' | i18n }}</h4>
      <h5 class="text-center">{{ 'chooseFromCondolenceMsg' | i18n }}</h5>
      <ul class="product-list row card-list">
        <li
          *ngFor="let item of Papers; let i = index"
          class="col-6 col-md-3 mb-2"
          [ngClass]="{ 'product-active': item.id == selectedPaper?.id }"
        >
          <label for="card-{{ i }}">
            <img class="img-fluid" vtMedia [object]="item.picture" />
            <div class="product-title">
              {{ item.name }}
            </div>
          </label>
          <RadioButton
            id="card-{{ i }}"
            name="card"
            [value]="item.id"
            [checked]="item.id == selectedPaper?.id"
            (change)="OnSelectCardChange(item)"
          ></RadioButton>
        </li>
      </ul>
    </div>
  </div>
  <!-- buttons -->
  <div class="buttons-container">
    <button id="return-button" class="nav-button return-button" (click)="back()">
      <!-- {{ 'return' | i18n }} -->
      {{ returnButtonLabel }}
    </button>
    <button id="next-button" class="nav-button next-button" (click)="next()">
      {{ 'decideAndNext' | i18n }}
    </button>
  </div>
  <!-- <div class="container">
    <div class="btn-toolbar justify-content-between mt-2 mb-5" role="toolbar">
      <button id="card-select-return-button" class="btn btn-outline-secondary" (click)="back()">
        {{ 'return' | i18n }}
      </button>
      <button id="card-select-decide-and-next-button" class="btn btn-secondary" type="button" (click)="next()">
        {{ 'decideAndNext' | i18n }}
      </button>
    </div>
  </div> -->
</div>
