import { Present } from 'src/models/Present';
export class Food extends Present {
  constructor(params: Food = {} as Food) {
    super(params);
    let { PDFPath = '', foodAmount = 0, cost = 0 } = params; //Default value;

    // this.PDFPath = '/assets/img/presents/' + PDFPath;
    this.PDFPath = PDFPath; // TODO: DELETE: This is not used any more. It was supposed to be the path to the PDF, but we removed that feature.
    this.foodAmount = foodAmount;
    this.cost = cost;
  }

  PDFPath: string;
  foodAmount?: number;
  cost?: number;
}
