<div class="condolence-video-upload-container">
  <!-- header -->
  <div class="condolence-video-upload-header-container">
    <h5 class="condolence-video-upload-header">{{ 'condolenceVideo' | i18n }}</h5>
    <span class="condolence-video-upload-sub-header">{{ 'pleaseUploadVideoFromBelow' | i18n }}</span>
  </div>
  <!-- contents -->
  <div class="condolence-video-contents">
    <div class="contents-header">
      <span class="video-price"
        >{{ 'videoPrice' | i18n }}：{{ videoCard?.price | priceWithTax }}（{{ 'taxIncluded' | i18n }}）</span
      >
      <span class="video-price-discount text-danger" *ngIf="isSmacereClubMember | async"
        >{{ 'smacereClub' | i18n }}{{ 'memberPrice' | i18n }}：<app-discounted-price
          [product]="videoCard"
        ></app-discounted-price
        >（{{ 'taxIncluded' | i18n }}）</span
      >
    </div>
    <!-- upload-form -->
    <div class="upload-form">
      <div class="drag-zone" (appDropZone)="onDrop($event)">
        <div>
          <button id="memorialImage" class="drag-zone-button" (click)="fileInput.click()">
            <span class="drag-zone-button-title">{{ 'uploadVideoButton' | i18n }}</span>
            <img class="drag-zone-button-image" alt="Icon" src="assets/svg/upload.svg" width="32" height="32" />
          </button>
          <input
            #fileInput
            type="file"
            class="d-none"
            (change)="onChange($event)"
            accept="video/*"
            [disabled]="isCondolenceVideoUploading"
          />
        </div>
        <div class="drag-zone-title">
          <img class="drag-zone-title-icon" src="assets/svg/video.svg" alt="Icon" width="25" height="25" />
          <span class="drag-zone-title-text">{{ 'dragVideoToUpload' | i18n }}</span>
        </div>
      </div>
      <div>
        <button class="upload-form-button" (click)="fileInput.click()">
          {{ 'selectAVideoFromTheHolder' | i18n }}
        </button>
      </div>
    </div>

    <!-- uploaded video -->
    <div class="uploaded-video-container">
      <div class="uploaded-video-wrapper">
        <img class="uploaded-video" [src]="thumbnail" alt="Thumbnail" *ngIf="thumbnail !== null" />
        <div class="uploaded-file-container">
          <p class="file-name">
            <span>{{ 'fileName' | i18n }}：</span><span>{{ file?.name }}</span>
          </p>
          <div class="progress-container">
            <progress class="progress-bar" [value]="uploadProgress" max="100"></progress>
            <button class="cancel-button" [disabled]="!file" (click)="deleteVideo()">
              {{ 'cancel' | i18n }}
            </button>
          </div>
          <p>
            <span class="uploaded-file-attribute">{{ 'fileSize' | i18n }}：</span
            ><span>{{ fileSize(file?.size) }}</span>
          </p>
        </div>
      </div>
      <div class="uploaded-video-controls">
        <button class="uploaded-video-button" [disabled]="!file" (click)="playVideo(content)">
          {{ 'playVideo' | i18n }}
        </button>
        <button class="uploaded-video-button" [disabled]="!file" (click)="deleteVideo()">
          {{ 'deleteVideo' | i18n }}
        </button>
      </div>
    </div>

    <!-- access level -->
    <div class="access-level-container">
      <label for="access-level" class="access-label">{{ 'access' | i18n }} :</label>
      <div class="radio-button-container">
        <div class="form-check form-check-inline" style="margin-right: 3em">
          <input
            class="form-check-input"
            id="access-level-private"
            name="access-level"
            type="radio"
            value="Private"
            [(ngModel)]="accessLevel"
          />
          <label [for]="'access-level-private'" class="form-check-label">
            {{ 'privateAccess' | i18n }}
          </label>
        </div>
        <div class="form-check form-check-inline">
          <input
            class="form-check-input"
            id="access-level-public"
            name="access-level"
            type="radio"
            value="Public"
            [(ngModel)]="accessLevel"
          />
          <label [for]="'access-level-public'" class="form-check-label">
            {{ 'publicAccess' | i18n }}
          </label>
        </div>
      </div>
    </div>
    <!-- user agreement -->
    <div class="user-agreement-container">
      <strong>動画弔電につきましては、下記内容をご確認頂きご了承をお願いいたします。</strong>
      <ol class="user-agreement-list">
        <li>本サービスの利用規約をご確認頂いたうえで動画の投稿をお願いします。</li>
        <li>
          「動画再生」ボタンにて、投稿した動画が正しく再生されることをご確認下さい。
          <br />
          ※動画が正しく再生されない場合は、「動画削除」ホタンにて動画を削除し弔電動画のお申込みはおやめ下さい。
        </li>
        <li>動画の映像時間につきましては、5分以内でお願いします。</li>
        <li>動画の形式は、MOV,MP4 でお願いします。</li>
        <li>
          動画の仕様などは、HD/30fps)を推奨いたします。
          <br />
          ※推奨形式の動画でも、本サービスにて動画をご利用頂けない場合がございます。
          <br />
          ※スマートセレモニー運営会社にて、適切な動画の仕様に変換させて頂く場合がございます。
        </li>
        <li>
          動画の内容につきましては、葬家又はスマートセレモニー運営会社にて確認させて頂き、
          葬儀に不適切と判断した場合には公開を取り止めさせて頂きます。
          その際、葬家又はスマートセレモニー運営会社からご投稿者様へのご連絡は行いません。
          また、ご返金にも応じかねますので、ご了承願います。
          <br />
          ※葬家又はスマートセレモニー運営会社の確認環境にて、内容確認できないなどの事象も含みます。
        </li>
        <li>動画公開までには、数日のお時間を頂く場合がございます。</li>
        <li>動画以外に、お名前、コメント、会社名、役職なども公開情報に含まれますのでご注意ください。</li>
      </ol>
      <!-- footer -->
      <div class="footer">
        <form class="form-container">
          <input
            id="agreement-checkbox"
            name="agreement-checkbox"
            class="checkbox"
            type="checkbox"
            [(ngModel)]="userAgreement"
          />
          <label class="label" for="agreement-checkbox">上記内容を承諾し、動画弔電の申し込みを行います。</label>
        </form>
      </div>
    </div>
  </div>

  <!-- buttons -->
  <div class="buttons-container">
    <button id="return-button" class="nav-button return-button" (click)="back()">
      {{ 'return' | i18n }}
    </button>
    <button id="next-button" class="nav-button next-button" (click)="save()">
      {{ 'decideAndNext' | i18n }}
    </button>
  </div>
</div>

<ng-template #content let-modal>
  <div class="modal-container">
    <a href="javascript:void(0);" (click)="closeModal()">&times;</a>
    <video
      *ngIf="videoUrl"
      muted
      controls
      controlsList="nodownload"
      oncontextmenu="return false;"
      [src]="videoUrl"
      class="card-img-top img-fluid"
    ></video>
  </div>
</ng-template>
