import { Component, OnInit } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

import Swal from 'sweetalert2';

import { GlobalStorage } from 'src/app/global/storage';

import { PresentsService } from 'src/services/present.service';

import { Order, Present } from 'src/models';

import { ProductType } from '@kokusai/smacere-shared/api';

import { Sorts } from 'src/app/core/utilities/sorts';
import { BundleCartService, BundleType } from '../../../../services/bundle-cart.service';

@Component({
  selector: 'app-flowers-present',
  templateUrl: './flowers-present.component.html',
  styleUrls: ['./flowers-present.component.scss'],
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [style({ opacity: 0 }), animate('1s ease-out', style({ opacity: 1 }))]),
      transition(':leave', [style({ opacity: 1 }), animate('1s ease-in', style({ opacity: 0 }))]),
    ]),
  ],
})
export class FlowersPresentComponent implements OnInit {
  constructor(
    private presentsService: PresentsService,
    private storage: GlobalStorage,
    private route: Router,
    private location: Location,
    private bundleCartService: BundleCartService
  ) {}

  private Order: Order = null;
  Presents: Present[] = [];
  selectedPresent?: Present;
  public isEdit: boolean = false;

  ngOnInit(): void {
    this.bundleCartService.checkStep(BundleType.Flower, 'present');
    this.Order = this.storage.Order;
    this.selectedPresent = this.Order.FlowerPresent;

    let price: number = 0;
    if (this.Order.FlowerOrder) {
      price = price + this.Order.FlowerOrder.Product.price;
    }

    this.presentsService
      .gets(ProductType.Flower, price, this.storage.Funeral.funeralDate)
      .subscribe((presents: Present[]) => {
        this.Presents = presents.sort((a, b) => {
          return Sorts.byJapanAlpha(a, b, 'name');
        });
      });

    this.isEdit = this.bundleCartService.isEdit();
  }

  private async saveToStorage(): Promise<boolean> {
    if (!this.selectedPresent) {
      await Swal.fire({
        icon: 'warning',
        title: '供花返礼品が未選択です',
        text: '返礼品一覧より、ご選択ください',
      });
      return false;
    }

    this.Order.FlowerPresent = this.selectedPresent;
    this.storage.Order = this.Order;

    return true;
  }

  async saveAction(): Promise<void> {
    if (await this.saveToStorage()) {
      await this.bundleCartService.saveFlowerFromStorage();
      await this.bundleCartService.goToNextStep(BundleType.Flower);
    }
  }

  OSelectChange(value: Present): void {
    this.selectedPresent = value;
  }

  async addAction(): Promise<void> {
    if (await this.saveToStorage()) {
      await this.bundleCartService.saveFlowerFromStorage();
      await this.bundleCartService.goToAdd(BundleType.Flower);
    }
  }

  back(): void {
    this.location.back();
  }
}
