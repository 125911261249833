import { Component, OnInit, TemplateRef } from '@angular/core';
import { Location } from '@angular/common';
import { CondolenceVideo, Funeral, Order } from 'src/models';
import { GlobalStorage } from 'src/app/global/storage';
import { APIService, Product, ProductType } from '@kokusai/smacere-shared/api';
import { FilesService } from 'src/services/files.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { AccessLevel } from 'src/types/access-level';
import { Format } from '@kokusai/utilities';
import { BundleCartService, BundleType } from '../../../services/bundle-cart.service';
import { AuthService } from 'src/app/services/auth.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-condolence-video-upload',
  templateUrl: './condolence-video-upload.component.html',
  styleUrls: ['./condolence-video-upload.component.scss'],
})
export class CondolenceVideoUploadComponent implements OnInit {
  Funeral: Funeral = {} as Funeral;
  Order: Order = null;
  file: File = null;
  isCondolenceVideoUploading = false;
  uploadProgress = 0;
  mediaKey: string = null;
  thumbnail: string = null;
  videoCard: Product = null;
  accessLevel: AccessLevel = AccessLevel.Private;
  userAgreement: boolean = false;
  isSmacereClubMember;

  constructor(
    private location: Location,
    private storage: GlobalStorage,
    private apiService: APIService,
    private filesService: FilesService,
    private router: Router,
    private bundleCartService: BundleCartService,
    private authService: AuthService,
    private modalService: NgbModal,
    private sanitizer: DomSanitizer
  ) {}

  async ngOnInit() {
    this.bundleCartService.initStep(BundleType.CondolenceVideo, 'order');

    this.Funeral = this.storage.Funeral;
    this.Order = this.storage.Order;
    const videoCards = await this.apiService.ListProducts({
      productType: { eq: ProductType.CondolenceVideo },
      isDeleted: { ne: true },
    });
    if (videoCards.items.length === 0) {
      this.back();
      return;
    }
    this.videoCard = videoCards.items[0];
    this.isSmacereClubMember = this.authService.isSmacereClubMember;
  }

  async save() {
    if (!this.mediaKey) {
      await Swal.fire({
        icon: 'warning',
        title: '動画弔電',
        text: '動画弔電一覧より、動画弔電をアップロードしてください。',
      });
      return;
    }

    if (!this.userAgreement) {
      await Swal.fire({
        icon: 'warning',
        title: '投稿時の注意点について',
        text: '内容をご確認いただき、ご承諾にチェックをお願いします',
      });
      return;
    }

    this.Order.CondolenceVideoOrder.Thumbnail = this.thumbnail;
    this.Order.CondolenceVideoOrder.UploadedVideo = this.mediaKey;
    this.Order.CondolenceVideoOrder.AccessLevel = this.accessLevel;
    this.Order.CondolenceVideoOrder.Product = new CondolenceVideo({
      id: this.videoCard.id,
      sku: this.videoCard.sku,
      name: this.videoCard.name,
      picture: this.videoCard.picture,
      price: this.videoCard.price,
    });
    this.storage.Order = this.Order;

    await this.router.navigate([this.Funeral.funeralId, 'order', 'condolence-video', 'order']);
  }

  back() {
    this.location.back();
  }

  deleteVideo() {
    this.file = null;
    this.thumbnail = null;
    this.uploadProgress = 0;
  }

  async onDrop(event: Array<File>) {
    await this.uploadFile(event[0]);
  }

  async onChange(event: Event) {
    await this.uploadFile((event.target as HTMLInputElement).files[0]);
  }

  async uploadFile(file: File) {
    if (!file) {
      return;
    }

    this.file = file;
    this.filesService.getThumbnail(file).then((result) => (this.thumbnail = result));
    this.isCondolenceVideoUploading = true;

    const upload = this.filesService.uploadFile(file, 'condolence-video');
    upload.progress.subscribe((value) => {
      this.uploadProgress = value;
    });
    upload.result
      .then((value) => {
        this.mediaKey = value;
      })
      .finally(() => {
        this.isCondolenceVideoUploading = false;
      });
  }

  fileSize(size: number | undefined): string | undefined {
    return size ? Format.fileSize(size) : undefined;
  }

  playVideo(template: TemplateRef<any>) {
    this.modalService.open(template, { centered: true, size: 'xl' });
  }

  closeModal() {
    this.modalService.dismissAll();
  }

  get videoUrl() {
    const myFile = URL.createObjectURL(this.file);
    const safeFile = this.sanitizer.bypassSecurityTrustResourceUrl(myFile);
    return safeFile;
  }
}
