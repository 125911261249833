export class DateUtils {
  static convertToJapaneseEra(date: Date): string {
    const eras: Eras[] = [
      {
        name: '明治',
        from: new Date(1868, 10 - 1, 23),
        to: new Date(1912, 7 - 1, 29),
      },
      {
        name: '大正',
        from: new Date(1912, 7 - 1, 30),
        to: new Date(1926, 12 - 1, 24),
      },
      {
        name: '昭和',
        from: new Date(1926, 12 - 1, 25),
        to: new Date(1989, 1 - 1, 7),
      },
      {
        name: '平成',
        from: new Date(1989, 1 - 1, 8),
        to: new Date(2019, 4 - 1, 30),
      },
      {
        name: '令和',
        from: new Date(2019, 5 - 1, 1),
        to: new Date(2040, 4 - 1, 30),
      },
    ];

    const era: Eras = eras.find((item) => date >= item.from && date <= item.to);
    if (era == null) {
      console.error('era is null');
      return '';
    }

    const year = date.getFullYear() - era.from.getFullYear() + 1;
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return `${era.name}${year}年${month}月${day}日`;
  }
}

export interface Eras {
  name: string;
  from: Date;
  to?: Date;
}
